import * as Yup from "yup";

export interface RegistrationPlanValidationProp {
  country: string; 
}

export const registratinoFormInitialValue: RegistrationPlanValidationProp = {
  country: "", 
};

export const registrationFormSchema = Yup.object().shape({
  country: Yup.string().required("Required"), 
});
