import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Col, Label, Row, Container } from "reactstrap";
import { AfterLoginRedirectUrl, ForgotPassword } from "../../utils/Constant";
import { Btn } from "../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import {
  ConfirmPasswordFormProps,
  confirmPasswordFormInitialValues,
  confirmPasswordFormSchema,
} from "./FormSchema";
import { toast } from "react-toastify";
import { useConfirmPasswordMutation } from "../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";
import { useCookies } from "react-cookie"; 
import { useAppSelector } from "../../ReduxToolkit/Hooks"; 

function ConfrimForm(props: any) {
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies(["confirmUser"]);

  const [confirmPasswordApiCall] = useConfirmPasswordMutation();

  const {  previousUrl }  = useAppSelector((state) => state.common);
  const { userInfo } = useAppSelector((state) => state.auth);

  const navigate = useNavigate(); 

  const redirect = previousUrl || AfterLoginRedirectUrl;

  useEffect(()=> {
    if(userInfo && previousUrl === ''){
      navigate(AfterLoginRedirectUrl);
    } 
  },[userInfo, navigate])

  const handleLogin = async (values: ConfirmPasswordFormProps) => {
    try {
      if (values.password) {
        const response = await confirmPasswordApiCall(values.password).unwrap();
        const { message, success }: any = response;
        if (success === true) { 
          toast.success(message, { position: "bottom-right" });
          setCookie('confirmUser',true);
          navigate(redirect); 
        } else {
          toast.error(message, { position: "bottom-right" });
        }
      }
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong.",{ position: "bottom-right" });
    }
  };

  return (
    <Container className="p-3">
      <Formik
        initialValues={confirmPasswordFormInitialValues}
        onSubmit={handleLogin}
        validationSchema={confirmPasswordFormSchema}
      >
        {({ errors }) => (
          <Form>
            <Row className="g-3">
              <Col md="12">
                <p>Please confirm your password before continuing.</p>
                <Label>Confirm Password</Label>
                <Field
                  type="password"
                  name="password"
                  className={`form-control ${
                    submitErrors &&
                    `${errors.password ? "is-invalid" : "is-valid"}`
                  }`}
                  placeholder="Enter Confirm Password"
                  autoFocus={true}
                />
                <ErrorMessage
                  name="password"
                  component="span"
                  className="invalid-feedback"
                />
              </Col>
              <Col md="12">
                <Btn
                  color="primary"
                  type="submit"
                  onClick={() => setSubmitError(true)}
                >
                  Send
                </Btn>
              </Col>
              <Col md="12">
                <Link to={`${process.env.PUBLIC_URL}/forgot-password`}>
                  {ForgotPassword}{" "}
                </Link>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default ConfrimForm;
