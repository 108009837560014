import { useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
    ProductServicesColumnData,
    supportTicketData,
} from "../../../../../Data/Tools/BrandSupportTools/ButtonActions";
import {
    ManageOnlineStore,
    ManageProductServicesAction,
} from "../../../../../Data/Tools/ManageContents/ManageContentButtons";
import ManageOnlineSubMenu from "../../ManageOnlineSubMenu/ManageOnlineSubMenu";
import ProductServiceSubMenu from "./ProductServiceSubMenu";

const ManageProductServices = () => {
    const toggleDelete = useState(false);
    const [data, setData] = useState(supportTicketData);
    const handleRowSelected = () => {};
    return (
        <div className="page-body">
            <Breadcrumbs
                mainTitle={`Manage Products and Services (Manage Online Store)`}
                parent={`Manage Store`}
            />
            <Container fluid>
                <CardHeader className="pb-0 pt-0  ">
                    <ManageOnlineSubMenu />
                </CardHeader>
            </Container>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <div className="mt-2">
                                <ProductServiceSubMenu />
                            </div>
                            <CardBody className="p-2">
                                <>
                                    <div className="table-responsive ">
                                        <DataTable
                                            className="custom-scrollbar"
                                            columns={ProductServicesColumnData}
                                            data={data}
                                            striped={true}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={
                                                handleRowSelected
                                            }
                                            clearSelectedRows={toggleDelete}
                                        />
                                    </div>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ManageProductServices;
