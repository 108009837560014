import { Field } from "formik";
import CustomSelect from "./CustomSelect";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { useGetRegistrationTypesQuery } from "../../ReduxToolkit/Reducers/Registration/Type/typeApliSlice";
import { setRegistrationTypes } from "../../ReduxToolkit/Reducers/commonInputSlice";
import Loading from "../Loading/Loading";

const RegistrationTypes = (props: any) => { 

  const { name, className, isMulti, placeholder, countryId } = props;

  const { registrationTypes } = useAppSelector((state) => state.common); 
  const { data: fetchRegistrationTypes, isLoading } =
    useGetRegistrationTypesQuery(countryId,{
      skip: countryId === undefined || countryId === '' ? true : false
    });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchRegistrationTypes?.data !== undefined) {
      let filterRegistrationTypes = Object.keys(
        fetchRegistrationTypes?.data
      ).map((item) => {
        return { label: fetchRegistrationTypes?.data[item], value: item };
      });
      if (filterRegistrationTypes?.length > 0) {
        dispatch(setRegistrationTypes(filterRegistrationTypes));
      }
    }
  }, [fetchRegistrationTypes?.data, dispatch]); 

  // if (isLoading) return <Loading />;
  
  return (
    <Field
      className={`custom-select ${className}`}
      name={name}
      options={registrationTypes?.length <= 0 || registrationTypes === undefined ? [] : registrationTypes}
      component={CustomSelect}
      placeholder={placeholder}
      isMulti={isMulti}
    />
  );
};

export default RegistrationTypes;
