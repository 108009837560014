import { Card, CardBody, Col, Collapse, Container, Row } from "reactstrap";
import DragCalendar from "./DragCalendar/DragCalendar";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Apps, CalenderHeading } from "../../../utils/Constant";
import { useState } from "react";
import CardHeaderCollapse from "../../../CommonElements/CardHeaderCommon/CardHeaderCollapse";
import { ProductItemInterface } from "../../../Types/Application/Ecommerce/Product";
import { getVisibleProducts } from "../../../Service/Ecommerce.service";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import { Carousel } from "react-responsive-carousel";
import { dynamicImage } from "../../../Service";
import ProductGrid from "../Ecommerce/Products/ProductGrid/ProductGrid";
import MediaData from "../../App/MediaData/MediaData";

const images_a = ["masonry/1.jpg","masonry/1.jpg","masonry/1.jpg","masonry/1.jpg","masonry/1.jpg"];
const images_b = ["masonry/2.jpg","masonry/2.jpg","masonry/2.jpg","masonry/2.jpg","masonry/2.jpg"];

const CalenderContainer = () => {
  const { productItem } = useAppSelector((state) => state.product);
  const[isOpen, setIsOpen] = useState(true);
  const { filter } = useAppSelector((state) => state.filterData);
  const products = getVisibleProducts(productItem, filter);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { listView, colClass } = useAppSelector((state) => state.filterData);
  const [dataId, setDataId] = useState<undefined | number>();
  const handlePopupClick = (id:number) =>{
    setDataId(id);
    setOpenModal(true);
  }

  return (
    <> 
      <Container fluid className="calendar-basic">
        <Card> 
          <Collapse isOpen={isOpen}>
            <CardBody>
              <Row>
                <Col sm="12">
                  <Row className="flex-column flex-md-row"> 
                    <DragCalendar />
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Collapse>
        </Card> 
      </Container>
    </>
  );
};

export default CalenderContainer;
