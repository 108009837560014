import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H6 } from "../../../../AbstractElements";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import InfoBox from "./InfoBox";
import { setActiveIcon } from "../../../../ReduxToolkit/Reducers/ActiveHeaderRightIcon";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";

const Info = () => {
  const dropdownRef: any = useRef(null);
  const dispatch = useAppDispatch();
  const activeIcon = useAppSelector((state) => state.activeIcon.activeIcon);
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);

  const toggleDropdown = () => {
    // Toggle logic for dropdown visibility
    if (activeIcon === "info") {
      dispatch(setActiveIcon("")); // Hide dropdown
    } else {
      dispatch(setActiveIcon("info")); // Show dropdown
    }
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (activeIcon === "info") {
        dispatch(setActiveIcon("")); // Hide dropdown when clicking outside
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeIcon]);

  return (
    <li
      className={`position-relative ${
        activeIcon === "info" ? "actvie-righticon" : ""
      }`}
      onClick={toggleDropdown}
    >
      <div className="position-relative" ref={dropdownRef}>
        <div className="message">
          <FontAwesomeIcon icon={faCircleInfo} />
        </div>
        {activeIcon === "info" ? (
          <div className="info message-dropdown position-absolute bg-white">
            <H6 className="f-18 mb-0 dropdown-title py-2">Information</H6>
            <InfoBox headerPages={websiteSettingsData.header_pages} />
          </div>
        ) : null}
      </div>
    </li>
  );
};

export default Info;
