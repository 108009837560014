 

// export default TagsInput;
import { useEffect, useState } from "react";
import "./TagsInput.scss";
import { Field } from "formik";
import { useAppSelector } from "../../ReduxToolkit/Hooks";

function TagsInput(props: any) {
  const [tags, setTags] = useState(props?.tags || []);
  const [value, setValue] = useState("");

  // Fetch tags limit from Redux and set a default value
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);
  const maxTagsLength = websiteSettingsData?.tags_limit || 10;

  const removeTags = (indexToRemove: number) => {
    const updatedTags = tags.filter((_: any, index: number) => index !== indexToRemove);
    setTags(updatedTags);
    props.setFieldValue(props.name, updatedTags.toString());
  };

  const addTags = (event: any) => {
    if (value !== "" && tags.length < maxTagsLength) {
      const updatedTags = [...tags, value];
      setTags(updatedTags);
      props.setFieldValue(props.name, updatedTags.toString());
      setValue("");
    }
  };

  const tagsHandler = (event: any) => {
    const inputValue = event.target.value;
    if (inputValue?.charAt(inputValue?.length - 1) !== "." && tags.length < maxTagsLength) {
      setValue(inputValue);
    }
  };

  useEffect(() => {
    if (props?.tags?.length > 0) {
      setTags(props?.tags);
    }
  }, [props?.tags]);

  return (
    <>
      <div className={`tags-input w-100 ${props.className}`}>
        <ul id="tags">
          {tags &&
            tags.map((tag: any, index: number) => (
              <li key={index} className="tag bg-primary">
                <span className="tag-title">{tag}</span>
                <span className="tag-close-icon" onClick={() => removeTags(index)}>
                  x
                </span>
              </li>
            ))}
        </ul>
        {/* <Field
          name={props.name}
          type="text"
          className="pt-0"
          // value={value}
          value={tags.length > 0 ? tags.toString() : ""}
          onKeyUp={(event: any) =>
            event.key === "Enter" || event.key === "." ? addTags(event) : null
          }
          onChange={tagsHandler}
          placeholder={props.placeholder}
          
        />
        <Field
          name={props.name}
          type="hidden"
          value={tags.length > 0 && tags.toString()}
        /> */}
        <Field
  name={props.name}
  type="text"
  className="pt-0"
  value={value} // Use input state here
  onKeyUp={(event: any) =>
    event.key === "Enter" || event.key === "." ? addTags(event) : null
  }
  onChange={tagsHandler}
  placeholder={props.placeholder}
/>
<Field
  name={props.name}
  type="hidden"
  value={tags.length > 0 ? tags.toString() : ""}
/>
      </div>
      {props.username ? (
        <span className="text-muted small">
          * Press Enter or dot to add other Usernames ({tags.length}/{maxTagsLength})
        </span>
      ) : (
        <span className="text-muted small">
          * Press Enter or dot to add {props.description || "keywords"} ({tags.length}/{maxTagsLength})
        </span>
      )}
    </>
  );
}

export default TagsInput;
