import { createSlice  } from '@reduxjs/toolkit';

interface TabState {
  activeTab: number;
  formValue:any;
}

const initialState: TabState = {
  activeTab: 1,   
  formValue:{
    userName : "",
    fileName : "",
    fileName1: "",
    category : "",
    status : "",
    initialCost : "",
    initialPrice : "",
    select:"",
    productStocks : "",
    stock:"",
    lowStock:"",
    sku:"",
    quantity:"",
    restock:"",
    additionalTag:"",
    zipCode:"",
    city:"",
    state:"",
    inputCity:"",
    inputState:""
}
};

const tabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setFormValue : (state,action) => {
      state.formValue[action.payload.name] = action.payload.value
  },
  },
});

export const { setActiveTab,setFormValue } = tabSlice.actions;

export default tabSlice.reducer;