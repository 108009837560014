import { apiSlice } from "../../apiSlice";
import {
  USER_ADDRESSES_LIST,
  USER_ADDRESSES_SAVE,
  USER_ADDRESSES_UPDATE,
  USER_ADDRESSES_DELETE,
  USER_ADDRESSES_SET_DEFAULT,
  GET_USER_ADDRESS_BY_ID
} from "../../../Api";
import { AddressValidationProp, updateAddressValidationProp } from "../../../Data/App/AccountSettings/Addresses";

interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const addressApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserAddressesList: builder.mutation<SuccessDataProps, void>({
      query: () => ({
        url: USER_ADDRESSES_LIST,
        method: "GET",
      }),
    }),
    saveUserAddress: builder.mutation<SuccessDataProps, AddressValidationProp>({
      query: (data) => ({
        url: USER_ADDRESSES_SAVE,
        method: "POST",
        body: data
      }),
    }),
    updateUserAddress: builder.mutation<SuccessDataProps, updateAddressValidationProp>({
      query: (data) => ({
        url: USER_ADDRESSES_UPDATE,
        method: "PUT",
        body: data
      }),
    }),
    getUserAddressById : builder.mutation<SuccessDataProps, { id : number }>({
      query: (data) => ({
        url: `${GET_USER_ADDRESS_BY_ID}/${data.id}`,
        method: "GET", 
      }),
    }),
    deleteUserAddress: builder.mutation<SuccessDataProps, { id : number }>({
      query: (data) => ({
        url: `${USER_ADDRESSES_DELETE}/${data.id}`,
        method: "DELETE",
      }),
    }),
    setDefaultUserAddress: builder.mutation<SuccessDataProps, { id : number }>({
      query: (data) => ({
        url: `${USER_ADDRESSES_SET_DEFAULT}/${data.id}`,
        method: "GET", 
      }),
    }),
  }),
});

export const {
  useGetUserAddressesListMutation,
  useSaveUserAddressMutation,
  useUpdateUserAddressMutation,
  useGetUserAddressByIdMutation,
  useDeleteUserAddressMutation,
  useSetDefaultUserAddressMutation
} = addressApiSlice;
