// ReduxToolkit/Reducers/ActiveHeaderRightIcon.js
import { createSlice } from '@reduxjs/toolkit';

const activeHeaderRightIconSlice = createSlice({
  name: 'activeIcon',
  initialState: {
    activeIcon: null, // or default value
  },
  reducers: {
    setActiveIcon: (state, action) => {
      state.activeIcon = action.payload;
    },
  },
});

export const { setActiveIcon } = activeHeaderRightIconSlice.actions;
export default activeHeaderRightIconSlice.reducer;
