import { Link } from "react-router-dom";
import { H3, H6, P, SVG } from "../../../../../../AbstractElements";
import { Href } from "../../../../../../utils/Constant";
import ProductGallery from "./ProductGallery";
import { useState } from "react";
import { Dropzone, ExtFile, FileMosaic } from "@dropzone-ui/react";
import { useAppDispatch } from "../../../../../../ReduxToolkit/Hooks";
import { setFormValue } from "../../../../../../ReduxToolkit/Reducers/AddProductSlice";
import { CardHeader } from "reactstrap";

const ProductTwo = () => {
  const [files, setFiles] = useState<ExtFile[]>([]);
  const dispatch = useAppDispatch()
  const updateFiles = (incomingFiles: ExtFile[]) => {
    setFiles(incomingFiles);
    dispatch(setFormValue({name:"fileName",value:incomingFiles[0].file?.name}))
  };
  const removeFile = (id: string | number | undefined) => {
    setFiles(files.filter((x: ExtFile) => x.id !== id));
  };
  return (
    <>
     <CardHeader className="ps-0 pt-2">
                <H3>{'Gallery (step 1)'}</H3>
              </CardHeader>
    <div className="sidebar-body mt-4"> 
      <ProductGallery />
    </div>
    </>
  );
};

export default ProductTwo;
