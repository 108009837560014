import * as Yup from "yup";

export const formSchema = Yup.object().shape({
  firstname: Yup.string().min(2, "Too Short!").max(15, "Too Long!").required(),
  lastname: Yup.string().min(2, "Too Short").max(15, "Too Long").required(),
  email: Yup.string().email().required(),
});

// Login Form
export interface LoginFormProps {
  username: string;
  password: string;
}

export const loginFormInitialValues: LoginFormProps = {
  username: "",
  password: "",
};

export const loginFormSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
});

// Forgot Password Form
export interface ForgotPasswordFormProps {
  email: string;
}

export const forgotPasswordFormInitialValues: ForgotPasswordFormProps = {
  email: "",
};

export const forgetPasswordFormSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
});

// Confirm Password Form

export interface ConfirmPasswordFormProps {
  password: string;
}

export const confirmPasswordFormInitialValues: ConfirmPasswordFormProps = {
  password: "",
};

export const confirmPasswordFormSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
});

// Reset Password Form

export interface ResetPasswordFormProps {
  password: string;
  confirmPassword: string;
}

export const resetPasswordFormInitialValues: ResetPasswordFormProps = {
  password: "",
  confirmPassword: "",
};

export const resetPasswordFormSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string().required("Required"),
});

// Two Factor Authetication Form

export interface TwoFactorAutheticationFormProps {
  code: string; 
}

export const twoFactorAuthenticationFormInitialValues: TwoFactorAutheticationFormProps = {
  code: "",
};

export const twoFactorAuthenticationFormSchema = Yup.object().shape({
  code: Yup.string().required("Required"), 
});
