import BookmarkContainer from "../../../Component/Application/Bookmark/Bookmark"

const Bookmark = () => {
  return (
    <div className='page-body'>
      <BookmarkContainer />
    </div>
  )
}

export default Bookmark