import { useState } from "react";
import { Row, Col, Label, Container, FormGroup, Button, Table, Input } from "reactstrap"; 
import { ErrorMessage, Field, Form } from "formik"; 
import Branches from "../../../../../CommonInput/Branches";
import City from "../../../../../CommonInput/City";
import State from "../../../../../CommonInput/State";
import Country from "../../../../../CommonInput/Country";
import { SchedulesFormValidationProp } from "../../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import { H3, H4, H6 } from "../../../../../../AbstractElements";
import CategoryDataDrop from "../../../../../CommonInput/CategoryDataDrop";
import CategoryTable from "./CategoryTable";
import Sharing from "../../../../../Tools/BrandSupportTools/SurveyAndQuizzes/Sharing";

const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function BranchSetupPrice(props: any) {
  const { errors, submitErrors,setFieldValue, setSubmitError,values,validateField,handleSubmit   } = props;
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([false, false]);
  const [autoSchedule, setAutoSchedule] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const onChangeHandler = () => {
    setAutoSchedule((oldFlag) => !oldFlag);
  }; 
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(selectedRows.map(() => newSelectAll));
  };

  const handleRowSelect = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);

    // If any row is unselected, uncheck the header checkbox
    if (!newSelectedRows.includes(false)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  }; 

  const renderTableRows = () => {
    if (!selectedCategory) return null;
    return data[selectedCategory].map((item, index) => (
      <tr key={index}>
        <th scope="row">
          <Input type="checkbox" />
        </th>
        <td>{item.addon}</td>
        <td>{item.name}</td>
        <td>{item.quantity}</td>
        <td>{item.price}</td>
      </tr>
    ));
  };
 
  return (
    <>
     
        <Row>
        <Col sm="8" className="mb-3">
          <H3 className="text-gray">Branch Selection </H3>
        </Col> 
      </Row> 
      <Row>
        <Col sm="4" className="mb-3">
          <Label check>
            Country <span className="txt-danger">*</span>
          </Label> 
          <Country name="country" isMulti={false} placeholder="Select"  className={`${
                  `${errors.country ? "is-invalid" : "is-valid"}`
            }`} />
          <ErrorMessage
            name="country"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>State</Label> 
          <State name='state' countryId={values.country} isMulti={false} placeholder="Select"/>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>City</Label>
          <City name='city' countryId={values.country} stateId={values.state} isMulti={false} placeholder='Select'/>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm="4" className="mb-3">
          <Label check>Branch</Label>
          <Branches name="finalBranchList" isMulti={true} placeholder="Select"/>
        </Col> 
        <Col sm="4" className="mt-2">
           <Button className="btn btn-primary">
            Select Branch
           </Button>
        </Col>
      </Row> 
      <Row>
      <Col sm="4" className="mb-3">
          <Label check>
            Final Branch List <span className="txt-danger">*</span>
          </Label> 
          <ErrorMessage
            name="finalBranchList"
            component="span"
            className="invalid-feedback"
          />
        </Col> 
      </Row> 
        <Row>
        <Col sm="12" className="mb-3">
          <H3 className="text-gray">Price</H3>
          <h4 className="my-2 fw-medium">Regular Price for SPECIFIC DAYS  (optional):</h4>
      
          <Row> 
          
          {Object.keys(values.days).map((day) => (
  <Col lg={4} key={day} className="mb-4">
    <label className="form-label">{day}:</label>
    <div className="d-flex align-items-center">
      <FormGroup
        className={`form-check mx-2 form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
          values?.days[day]?.action === 'Regular' ? 'transform-scale' : ''
        }`}
      >
        <Label
          className="form-check-label me-3"
          for={`Days-${day}`}
          onClick={() => setFieldValue(`days.${day}.action`, 'Regular')}
        >
          <Field
            id={`Days-${day}`}
            type="checkbox"
            name={`days.${day}.action`}
            value="Regular"
            className="form-check-input me-2"
            checked={values.days[day]?.action === 'Regular'}
            onChange={() => setFieldValue(`days.${day}.action`, 'Regular')}
          />
          <H6 className="mx-2">Regular Price</H6>
        </Label>
      </FormGroup>

      <FormGroup
        className={`form-check mx-2 form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
          values?.days[day]?.action === 'Increase' ? 'transform-scale ' : ''
        }`} style={{marginBottom:'10px !important'}}
      >
        <Label
          className="form-check-label me-3"
          for={`Days2-${day}`}
          onClick={() => setFieldValue(`days.${day}.action`, 'Increase')}
        >
          <Field
            id={`Days2-${day}`}
            type="checkbox"
            name={`days.${day}.action`}
            value="Increase"
            className="form-check-input me-2"
            checked={values.days[day]?.action === 'Increase'}
            onChange={() => setFieldValue(`days.${day}.action`, 'Increase')}
          />
          <H6 className="mx-2">Increase Price</H6>
        </Label>
      </FormGroup>
    </div>

    {values.days[day]?.action === 'Increase' && (
      <Field name={`days.${day}.priceDifference`}>
        {({ field, form }) => (
          <input
            {...field}
            type="text"
            placeholder="Extra Price Difference"
            className="form-control"
            value={
              field.value !== undefined && field.value !== ''
                ? `${field.value}%`
                : ''
            }
            onChange={(e) => {
              let value = e.target.value.replace('%', ''); // Remove '%' symbol
              // Allow only valid numbers and decimals
              if (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value)) {
                form.setFieldValue(field.name, value); // Store numeric value
              }
            }}
          />
        )}
      </Field>
    )}
  </Col>
))}

          </Row>
 <h3 className="mb-4">Regular Price for SPECIFIC DATES or SEASONS (optional)</h3>
      
      {/* Date Range Fields */}
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="dateRange.from" className="form-label">
            From:
          </label>
          <Field
            type="date"
            name="dateRange.from" 
            className="form-control"
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="dateRange.to" className="form-label">
            To:
          </label>
          <Field
            type="date"
            name="dateRange.to"
            className="form-control"
          />
        </div>
      </div>

      {/* Radio Buttons for Action */}
      <div className="mb-3 ">
        <label className="form-label">Action:</label>
        <div className="d-flex align-items-center">
        <FormGroup
        className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
          values.dateRange.action === 'Regular' ? 'transform-scale' : ''
        }`}
      >
        <Label
          className="form-check-label "
          for="hoursDay1"
          onClick={() => setFieldValue('dateRange.action', 'Regular')}
        >
          <Field
            id="hoursDay1"
            type="checkbox"
            name="dateRange.action"
            value="Regular"
            className="form-check-input me-2"
            checked={values.dateRange.action === 'Regular'}
            onChange={() => setFieldValue('dateRange.action', 'Regular')}
          />
          <H6 className="mx-3">Regular Price</H6>
          
        </Label>
      </FormGroup>

      {/* Increase Price */}
      <FormGroup
        className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
          values.dateRange.action === 'Increase' ? 'transform-scale' : ''
        }`}
      >
        <Label
          className="form-check-label me-3"
          for="hoursDay2"
          onClick={() => setFieldValue('dateRange.action', 'Increase')}
        >
          <Field
            id="hoursDay2"
            type="checkbox"
            name="dateRange.action"
            value="Increase"
            className="form-check-input me-2"
            checked={values.dateRange.action === 'Increase'}
            onChange={() => setFieldValue('dateRange.action', 'Increase')}
          />
          Increase Price
        </Label>
      </FormGroup>
        </div>
      </div>

      {/* Price Difference */} 
      {values.dateRange?.action === "Increase" && (
  <div className="mb-3">
    <label htmlFor="dateRange.priceDifference" className="form-label">
      Extra Price Difference (%):
    </label>
    <Field name="dateRange.priceDifference">
      {({ field, form }) => (
        <input
          {...field}
          type="text"
          className={`form-control ${
            errors?.dateRange?.priceDifference ? "is-invalid"
              : ""
          }`}
          placeholder="Extra price difference (%)"
          value={
            field.value !== undefined && field.value !== ""
              ? `${field.value}%`
              : ""
          }
          onChange={(e) => {
            let value = e.target.value.replace('%', ''); // Remove % symbol
            if (!isNaN(value) && /^[0-9]*\.?[0-9]*$/.test(value)) {
              form.setFieldValue(field.name, value); // Update numeric value in state
            }
          }}
        />
      )}
    </Field>
    {errors?.dateRange?.priceDifference   (
        <div className="invalid-feedback">
          {errors.dateRange.priceDifference}
        </div>
      )}
  </div>
)}
 <h3 className="mb-2">Follower's Discount Membership (recommended)</h3>
 <p className="mb-0">(Offer your followers special discounts to be more loyal to your business.)</p>
  
     
      {/* Radio Buttons for Action */}
      <div className="mb-3 "> 
        <div className="d-flex align-items-center"> 
         
          {/* <label className="form-check-label">Increase Price</label> */}
        </div>
      </div>

      {/* Price Difference */} 
      {/* {values.dateRange?.action === "Increase" && ( */}
      <Row>  
             <Col lg={4} className="mb-3">
              <label htmlFor="silverDiscount">Silver Discount (%)</label>
              <Field
                name="silverDiscount"
                type="text"
                className={`form-control ${
                  errors?.silverDiscount 
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Enter Silver Discount (%)"
                value={
                  values?.silverDiscount !== ""
                    ? `${values?.silverDiscount}%`
                    : ""
                }
                onChange={(e) => {
                  let value = e.target.value.replace("%", "");
                  if (/^\d*\.?\d*$/.test(value)) {
                    setFieldValue("silverDiscount", value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
              
                    // Trigger validation manually
                    validateField("silverDiscount");
                    validateField("goldDiscount");
                    validateField("diamondDiscount");
                  }
                }}
              />
              <ErrorMessage
                name="silverDiscount"
                component="div"
                className="invalid-feedback"
              />
            </Col>

            <Col lg={4} className="mb-3">
              <label htmlFor="goldDiscount">Gold Discount (%)</label>
              <Field
                name="goldDiscount"
                type="text"
                className={`form-control ${
                  errors?.goldDiscount 
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Enter Gold Discount (%)"
                value={
                  values?.goldDiscount !== "" ? `${values?.goldDiscount}%` : ""
                }
                onChange={(e) => {
                  let value = e.target.value.replace("%", "");
                  if (/^\d*\.?\d*$/.test(value)) {
                    setFieldValue("goldDiscount", value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default Enter behavior
                    validateField("goldDiscount"); // Validate the field
                    
                  }
                }}
              />
              <ErrorMessage
                name="goldDiscount"
                component="div"
                className="invalid-feedback"
              />
            </Col>

            <Col lg={4} className="mb-3">
              <label htmlFor="diamondDiscount">Diamond Discount (%)</label>
              <Field
                name="diamondDiscount"
                type="text"
                className={`form-control ${
                  errors?.diamondDiscount  
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Enter Diamond Discount (%)"
                value={
                  values?.diamondDiscount !== ""
                    ? `${values?.diamondDiscount}%`
                    : ""
                }
                onChange={(e) => {
                  let value = e.target.value.replace("%", "");
                  if (/^\d*\.?\d*$/.test(value)) {
                    setFieldValue("diamondDiscount", value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent default Enter behavior
                    validateField("diamondDiscount"); // Validate the field
                    
                  }
                }}
              />
              <ErrorMessage
                name="diamondDiscount"
                component="div"
                className="invalid-feedback"
              />
            </Col>
      </Row>
{/* )} */} 
        </Col> 
      </Row>   
     
        <Row>
        <Col sm="8" className="mb-3">
          <H3 className="text-gray">Addons</H3>
        </Col> 
      </Row> 
      <CategoryTable /> 
      {/* <Sharing  nomediacenter={true} /> this is used in future Dont delete */}
     
    </>
   
 
  );
}

export default BranchSetupPrice;
