import { useEffect } from "react";
import CustomSelect from "./CustomSelect";
import { Field } from "formik";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { useGetCityDataMutation } from "../../ReduxToolkit/Reducers/City/cityApiSlice";
import { setCities } from "../../ReduxToolkit/Reducers/commonInputSlice"; 

function City(props: any) {
  const { cities } = useAppSelector((state: any) => state.common);
  const { name, className, isMulti, placeholder, countryId, stateId } = props;
  const [getCityDataApiCall, { isLoading }] = useGetCityDataMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getCitiedData = async (countryId: any,stateId: any) => {
      const response = await getCityDataApiCall({
        state_id: stateId,
        country_id: countryId,   
      }).unwrap();
      const { data, success }: any = response;

      if (success === true) {
        dispatch(setCities([]));
        // Map the fetched countries to your desired structure
        let filter_cities = data.map((item: any) => {
          return { label: item.name, value: item.id };
        });

        // Add the clearable option
        filter_cities = [...filter_cities];

        // Dispatch the countries to the Redux store
        dispatch(setCities(filter_cities));
      }
    }; 
    if (countryId > 0 && stateId > 0) {
      getCitiedData(countryId, stateId);
    } 
  }, [countryId,stateId]);

  if (!cities || cities.length === 0) return <div>Data not found!</div>;

  return (
    <Field
      className={`custom-select ${className}`}
      name={name}
      options={cities}
      component={CustomSelect}
      placeholder={placeholder}
      isMulti={isMulti}
      isClearable={!isMulti}
    />
  );
}

export default City;
