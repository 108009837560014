import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the type for your form's state
interface QuizMaterialFormState {
  formValues: any; // Adjust this type based on your actual form structure
}

const initialState: QuizMaterialFormState = {
  formValues: null,
};

const quizMaterialSlice = createSlice({
  name: 'quizMaterial',
  initialState,
  reducers: {
    setFormValues(state, action: PayloadAction<any>) {
      // PayloadAction can be typed with the shape of your form values
      state.formValues = action.payload;
    },
    resetFormValues(state) {
      state.formValues = null;
    },
  },
});

// Export actions to use them in components
export const { setFormValues, resetFormValues } = quizMaterialSlice.actions;

// Export reducer to configure in store
export default quizMaterialSlice.reducer;
