import {useState} from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap' 
import { Balance, MinWithdrawlText } from '../../../../utils/Constant'
import { H3 } from '../../../../AbstractElements'
import DataTable from 'react-data-table-component'
import { WithdrawlColumnData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import { supportTicketData} from '../../../../Data/Tools/BrandSupportTools/ButtonActions';
import PageHeader from '../../../Dashboard/Default/PageHeader/PageHeader'

const WithdrawlsContainer = () => {
    const [toggleDelete, setToggleDelete] = useState(false); 
    const handleRowSelected =() =>{}
    return (
        <Container fluid>
        <Card >
            <CardHeader className='py-0 px-0'>
               <PageHeader/>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="4" sm="12" className='rounded bg-primary ms-3'>
                        <div className='pt-2 pb-2'><h4 className='text-light'>{Balance} {`$0.00 USD`}</h4></div>
                        <div className='pb-2'> {MinWithdrawlText} {`$25 USD`}</div>
                    </Col>
                    <Col md="12" className='mt-4'>
                        <H3 >{`Withdrawl Balance`}</H3>
                        <DataTable className='custom-scrollbar border mt-2' columns={WithdrawlColumnData} data={supportTicketData} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
        </Container>
    )
}

export default WithdrawlsContainer