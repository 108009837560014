// import { useEffect } from "react";
// import { H3, Image } from "../../../../AbstractElements";
// import { dynamicImage } from "../../../../Service";
 
 
// const WinnerScreen = ({setShowWinnerScreen,handleNextQuestion,showWinnerScreen}) => {
//     useEffect(() => {
//         if (showWinnerScreen== true) {
            
//             // Show WinnerScreen for 2 minutes
//             setShowWinnerScreen(true);
//             setTimeout(() => {
//                 setShowWinnerScreen(false); // Hide WinnerScreen after 2 minutes
//                 handleNextQuestion(); // Proceed to next question
//             console.log(showWinnerScreen);

//             }, 5000000); // 2 minutes in milliseconds
//         }
//     }, [showWinnerScreen ]); 
//     return (
//         <div className="">
//        <div className="page-body bg-no-repeat winnerscreen_bg bg-cover h-100 d-flex justify-content-center align-items-end">
//        <video className="background-video" autoPlay muted loop>
//         <source src="" type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>
//         <div className="position-absolute top-0 h-100 bg-black w-100" style={{opacity:'0.3'}}></div>
//         <div className="main_wineerbox overflow-hidden">
//             {/* <div  style={{display:"flex"}}> */}
//                 <div className=" first_num winner_box" style={{background:"#ba34eb"}}>
//                     <div className="mx-auto mt-2">
//                     <Image className="img-fluid medal" src={dynamicImage(`first_num.png`)} alt="user image" />
//                     <div><H3 className="fs-1 fw-bold text-white">Hitesh</H3></div>
//                     <div><H3 className="fs-3 fst-italic text-white">1300+</H3></div>
//                     </div>
//                 </div>
//                 <div className="  second_num winner_box" style={{background:"#7056db"}}>
//                     <div className="mx-auto mt-2">
//                     <Image className="img-fluid" src={dynamicImage(`second_num.svg`)}   alt="user image" />
//                     <div><H3 className="fs-1 fw-bold text-white">Dharmik</H3></div>
//                     <div><H3 className="fs-3 fst-italic text-white">800+</H3></div>
//                     </div>
//                 </div>
//                 <div className=" third_num winner_box" style={{background:"#5b0b78"}}>
//                     <div className="mx-auto mt-2">
//                     <Image className="img-fluid " src={dynamicImage(`third_num.svg`)} alt="user image" />
//                     <div><H3 className="fs-1 fw-bold text-white">Priyanka</H3></div>
//                     <div><H3 className="fs-3 fst-italic text-white">600+</H3></div>
//                     </div>
//                 </div>
//             {/* </div> */}
//         </div>
//        </div>
//         </div>
//     );
// };

// export default WinnerScreen;
 
import React, { useCallback, useEffect, useState } from "react";
import { H3, H4, Image } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { AnimatePresence, motion } from "framer-motion";
import { Button, Collapse } from "reactstrap";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";
 
// const winners = [
//     { name: "Player1", score: 600, rank: "bronze" },
//     { name: "Player2", score: 600, rank: "bronze" },
//     { name: "Player3", score: 800, rank: "silver" },
//     { name: "Player4", score: 800, rank: "silver" },
//     { name: "Player5", score: 1300, rank: "gold" },
//   ];
  const winners = [
    { name: "Player1", score: 600, rank: "bronze", avatar: "/assets/avatars/player1.png" },
    { name: "Player2", score: 600, rank: "bronze", avatar: "/assets/avatars/player2.png" },
    { name: "Player3", score: 800, rank: "silver", avatar: "/assets/avatars/player3.png" },
    { name: "Player4", score: 800, rank: "silver", avatar: "/assets/avatars/player4.png" },
    { name: "Player5", score: 1300, rank: "gold", avatar: "/assets/avatars/player5.png" },
];
const WinnerScreen = ({ showAllMedals,handleNextQuestion, isLastQuestion,questionlength ,currentQuestion}) => {
  const [currentRank, setCurrentRank] = useState(""); // Start with Bronze
  const [videoSource, setVideoSource] = useState('/assets/images/teacher-game-bg.mp4');
  const { mobileView } = useAppSelector((state) => state.layout); 
  const [searchTerm, setSearchTerm] = useState("");
 
  const [activeIndex, setActiveIndex] = useState(mobileView == true ?  null : 1 ); 
  const [playerNames, setPlayerNames] = useState([
    { name: "Sunny", score: 1000 },
    { name: "Priyanka", score: 2200 },
    { name: "Hitesh", score: 3300 },
    { name: "Deepak", score: 2000 },
    { name: "Deepak", score: 1200 },
    { name: "Deepak", score: 3200 },
]);
const [scores, setScores] = useState(
  playerNames.reduce((acc, player) => {
    acc[player.name] = player.score;
    return acc;
  }, {})
);
const [shouldResize, setShouldResize] = useState(false);

const updateLayout = useCallback(() => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 768) {
    setShouldResize(true); // Mobile view
  } else {
    setShouldResize(false); // Desktop view
  }
}, []);

useEffect(() => {
  updateLayout(); // Check layout on mount
  window.addEventListener("resize", updateLayout); // Add resize listener

  return () => {
    window.removeEventListener("resize", updateLayout); // Cleanup listener on unmount
  };
}, [updateLayout]);
  // Determine the current players for each rank
  const getRankPlayers = (rank) => winners.filter((winner) => winner.rank === rank);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
};
  const renderCard = (rank, position, isLarge = false) => {
    const rankPlayers = getRankPlayers(rank);
   console.log(rankPlayers);
   
    // Card style based on rank
    const styles = {
        bronze: { background: "#5b0b78" },
        silver: { background: "#7056db" },
        gold: { background: "#ba34eb" },
    };
    
    // Progress bar for score
    const totalScore = winners.reduce((acc, winner) => acc + winner.score, 0);
    const progress = rankPlayers.map(player => (player.score / totalScore) * 100);

    // Position styles for the card animation
    const positionVariants = {
        current: { scale: 1.3, position: "absolute" },
        silver: { x: "40px", opacity: 0.7, scale: 0.9, left: 0, position: "absolute" },
        gold: { x: 0, opacity: 1, scale: 1.3, transform: "translateX(-50%)", position: "absolute" },
        bronze: { x: "-40px", opacity: 0.7, scale: 0.9, right: 0, position: "absolute" },
    };
   
    return (
      <>
       {shouldResize ?  
       <div
            className={`winner_box mx-auto ${shouldResize? '':'position-absolute'} p-10 rounded-2 shadow-lg my-3 ${ rank == 'gold' ? 'order-1': rank == 'silver' ? "order-2": rank== "bronze" ? 'order-3' : ''}`}
            style={{
                ...styles[rank],
                transition: "transform 0.5s, opacity 0.5s",
            }}
            
          //   {...(!shouldResize && {
          //     initial: "right", // Start from right position when first rendering
          //     animate: position, // Animate to the current position based on rank transition
          //     transition: { type: "spring", stiffness: 300 },
          // })}
          // variants: positionVariants,

            // initial="right" // Start from right position when first rendering
            // animate={position} // Animate to the current position based on rank transition
            // transition={{ type: "spring", stiffness: 300 }}
        >
            {rank === 'gold' && (
                <div className="position-absolute" style={{ top: "-111px" }}>
                    <Image
                        className="img-fluid"
                        src={dynamicImage(`crown.png`)}
                        alt={`${rank} medal`}
                        style={{ height: "150px" }}
                    />
                </div>
            )}
            <div className="text-center mt-2">
                <Image
                    className="img-fluid medal"
                    src={dynamicImage(`${rank === 'bronze' ? 'third_num.svg' : rank === "silver" ? 'second_num.svg' : rank === 'gold' ? 'first_num.png' : ''}`)}
                    alt={`${rank} medal`}
                />
                <H3 className="fs-1 fw-bold text-white">{rank.toUpperCase()} WINNERS</H3>
                <H3 className="fs-3 fst-italic text-white">
                    {rankPlayers.length} out of {winners.length}
                </H3>
                {/* Player List with Scores and Avatars */}
                <div className="player-list">
                    {rankPlayers.map((player, index) => (
                        <div key={index} className="player-info d-flex align-items-end mb-3 gap-3">
                          <div className="text-white  align-items-center">

                            <Image
                                className="avatar rounded-circle me-2"
                                src={`https://api.dicebear.com/5.x/avataaars/svg?seed=${player.name}`}
                                alt={`${player.name}'s Avatar`}
                                style={{ width: "40px", height: "40px" }}
                            />
                                <div className="mt-2">{player.name}</div>
                          </div>
                          <div className="text-white text-start flex-2 w-100">
                                    {/* <div>{player.name}</div> */}
                                    <div>Score: {player.score}</div>
                                    {/* Progress Bar */}
                                    <div className="mb-2">
                                    <div className="progress" style={{ height: "10px" }}>
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${progress[index]}%` }}
                                            aria-valuenow={progress[index]}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div>
                                    </div>
                                </div>
                                
                        </div>
                    ))} 
                </div> 
            </div>
        </div>
       :<motion.div
       className="winner_box mx-auto position-absolute p-10 rounded-2 shadow-lg"
       style={{
           ...styles[rank],
           transition: "transform 0.5s, opacity 0.5s",
       }}
       variants={positionVariants}
       initial="right" // Start from right position when first rendering
       animate={position} // Animate to the current position based on rank transition
       transition={{ type: "spring", stiffness: 300 }}
   >
       {rank === 'gold' && (
           <div className="position-absolute" style={{ top: "-90px" }}>
               <Image
                   className="img-fluid"
                   src={dynamicImage(`crown.png`)}
                   alt={`${rank} medal`}
                   style={{ height: "150px" }}
               />
           </div>
       )}
       <div className="text-center mt-2">
           <Image
               className="img-fluid medal"
               src={dynamicImage(`${rank === 'bronze' ? 'third_num.svg' : rank === "silver" ? 'second_num.svg' : rank === 'gold' ? 'first_num.png' : ''}`)}
               alt={`${rank} medal`}
           />
           <H3 className="fs-4 fw-bold text-white">{rank.toUpperCase()} WINNERS</H3>
           <H3 className="fs-5 fst-italic text-white">
               {rankPlayers.length} out of {winners.length}
           </H3>
           {/* Player List with Scores and Avatars */}
           <div className="player-list">
               {rankPlayers.map((player, index) => (
                   <div key={index} className="player-info d-flex align-items-end mb-3 gap-3">
                     <div className="text-white  align-items-center">

                       <Image
                           className="avatar rounded-circle me-2"
                           src={`https://api.dicebear.com/5.x/avataaars/svg?seed=${player.name}`}
                           alt={`${player.name}'s Avatar`}
                           style={{ width: "40px", height: "40px" }}
                       />
                           <div className="mt-2">{player.name}</div>
                     </div>
                     <div className="text-white text-start flex-2 w-100">
                               {/* <div>{player.name}</div> */}
                               <div>Score: {player.score}</div>
                               {/* Progress Bar */}
                               <div className="mb-2">
                               <div className="progress" style={{ height: "10px" }}>
                                   <div
                                       className="progress-bar"
                                       role="progressbar"
                                       style={{ width: `${progress[index]}%` }}
                                       aria-valuenow={progress[index]}
                                       aria-valuemin="0"
                                       aria-valuemax="100"
                                   ></div>
                               </div>
                               </div>
                           </div>
                           
                   </div>
               ))} 
           </div> 
       </div>
   </motion.div>
        
        }
        </>
    );
};
const handleRankChange = (newRank) => {
  setCurrentRank(newRank);
   
}; 
  

  return (
    <div className="winer_Screen page-body  flex-column bg-no-repeat winnerscreen_bg bg-cover h-100 ">
   {currentRank === "gold" ? <video
  className="position-absolute top-0 start-0 h-100 bg-black w-100"
  autoPlay
  muted
  loop
  style={{ width: "100%", height: "100%", objectFit: "cover" }}
>
  <source 
    src= "/assets/images/crackers.mp4" 
    type="video/mp4"
  />
  Your browser does not support the video tag.
</video> : ''
   }
   {currentRank !== "gold" ? <video
  className="position-absolute top-0 start-0 h-100 bg-black w-100"
  autoPlay
  muted
  loop
  style={{ width: "100%", height: "100%", objectFit: "cover" }}
>
  <source 
    src="/assets/images/teacher-game-bg.mp4"
    type="video/mp4"
  />
  Your browser does not support the video tag.
</video>:''}
      <div
        className="position-absolute top-0 start-0 h-100 bg-black w-100"
        style={{ opacity: "0.6" }}
      ></div>
      <div className="d-flex   align-items-center flex-column gap-4" >
      <div className={` main_winnerbox d-flex ${shouldResize ? 'flex-column':''} justify-content-center position-relative w-100 `}  style={{minHeight:"70vh",maxWidth:"1270px",marginTop:shouldResize ? "130px" : "110px"}}>
        {showAllMedals ? (
            <>
              {renderCard("bronze", "bronze")}
              {renderCard("silver", "silver")}
              {renderCard("gold", "gold", true)}
            </>
          ) : (
            <>
              {/* {currentRank === "bronze" && renderCard(" ", " ")} */}
              {currentRank === "bronze" && renderCard("bronze", "current")}
              {currentRank === "silver" && (
                <>
                  {renderCard("bronze", "bronze")}
                  {renderCard("silver", "current")}
                </>
              )}
              {currentRank === "gold" && (
                <>
                  {renderCard("bronze", "bronze")}
                  {renderCard("silver", "silver")}
                  {renderCard("gold", "current", true)}
                </>
              )}
            </>
          )}
      </div>
     
      </div>
      <div className={` quiz-sidebar ms-auto p-3 bg-light ${shouldResize ? 'position-relative w-100' : 'position-absolute end-0 top-0'} `}  style={{ width: "305px" }}>
     {showAllMedals ? '': <div className="my-3">
    
       { currentRank === ""   ? ( <Button className="btn btn-secondary fs-3 fw-bold d-block w-100" onClick={() => handleRankChange("bronze")}>
        Show Bronze Medal
        </Button>):currentRank === "bronze"   ? ( <Button className="btn btn-secondary fs-3 fw-bold d-block w-100" onClick={() => handleRankChange("silver")}>
        Show Silver Medal
        </Button>):currentRank === "silver" ? (<Button className="btn btn-primary fs-3 fw-bold d-block w-100" onClick={() => handleRankChange("gold")}>
        Show Gold Medal
            </Button>) : '' }
      </div> }
                           
                        <div className="d-flex justify-content-between" onClick={() => toggleAccordion(1)}>

                        <H4 className="w-100 fs-4 text-center my-2">Player Rank</H4>

                    <span>
                                {/* Accordion toggle icon */}
                                <i className={`bi text-dark  ${activeIndex === 1 ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                            </span>
                        </div>
                        <Collapse isOpen={activeIndex}>
                    <input
                        type="text"
                        placeholder="Search players..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-control mb-3 mt-2"
                    />
      <ul className="list-unstyled overflow-auto" style={{ height: "530px" }}>
        <AnimatePresence>
          {playerNames.map((player) => (
            <motion.li
              key={player.name}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.5 }}
              layout // Enables reordering animation
              className="mb-3"
            >
              <div className="d-flex align-items-center">
                <img
                  src={`https://api.dicebear.com/5.x/avataaars/svg?seed=${player.name}`}
                  alt={`Avatar for ${player.name}`}
                  className="rounded-circle me-3"
                  style={{ width: "40px", height: "40px" }}
                />
                <p className="text-black mb-0">{player.name}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="mt-2 mb-0 text-black">
                  Score: {scores[player.name]}
                </p>
              </div>
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
      </Collapse>
    </div>
    </div>
  );
};

export default WinnerScreen;
