import { ErrorMessage, Field } from 'formik'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import { ClientName, ClientPlaceholder, ProjectNamePlaceholder, ProjectTitle } from '../../../../utils/Constant'
import TitleInput from '../../../CommonInput/TitleInput'

const TitleAndClientSection = (props:any) => {
  const {values}=props
  return (
    <>
      <Row>
        <Col>
        <TitleInput title={ProjectTitle} values={values.title} name="title"  placeholder={ProjectNamePlaceholder}/>  
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label check>{ClientName}</Label>
            <Field name="client" className="form-control" type="text" placeholder={ClientPlaceholder} />
            <ErrorMessage name="client" component="span" className="text-danger" />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export default TitleAndClientSection