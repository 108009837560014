import { Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { Close, SaveChanges } from "../../../../utils/Constant";
import { CommonModalType } from "../../../../Types/Ui-Kits/UiKitsTypes";
import { Btn, H1, H4, H5, Image, SVG } from "../../../../AbstractElements";
import { X } from "react-feather";
import { dynamicImage } from "../../../../Service";
import Ads from "../../../App/Ads/Ads";
import AdSense from "../../../WebsiteSettings/AdSense/Index";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";

const CommonModal: React.FC<CommonModalType> = ({
  backdrop,
  setIsOpen,
  CommonFooter,
  logo,
  description,
  centered,
  size,
  isOpen,
  toggle,
  title,
  onClosed,
  sizeTitle,
  fullTitle,
  modalBodyClassName,
  largesize,
  children,
  AdShow
} : any) => {
  const handleNext = () => {};

  const handlePrevious = () => {};
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);
  
  return (
    <Modal
      backdrop={backdrop}
      centered={centered}
      size={size}
      className={` modal-dialog-scrollable ${largesize}`}
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
    >
      {(title || sizeTitle || fullTitle) && (
        <div
          className="modal-header d-flex justify-content-between"
          onClick={toggle}
        >
          {logo ? (
            <Image
              className="image-radius m-r-15 align-top"
              src={dynamicImage("logo/logo-1.png")}
              alt="user121"
              style={{ width: "89px" }}
            />
          ) : (
            ""
          )}
          {title && <H5 className="modal-title">{title}</H5>}
          {sizeTitle && (
            <>
              <H4>
                {sizeTitle}
                {description ? (
                  <p className="text-center fw-normal">{`A Multi Purpose Web Platform`}</p>
                ) : (
                  ""
                )}
              </H4>
            </>
          )}
          {fullTitle && <H1 className="fs-5">{fullTitle}</H1>}

          {/* <Btn className="py-0" close><X /></Btn> */}
          <div onClick={toggle} className="search-close-icon">
            <X />
          </div>
        </div>
      )}
      <ModalBody
        className={`  ${modalBodyClassName ? modalBodyClassName : ""}`}
      >
        <Row>
          <Col sm={websiteSettingsData && websiteSettingsData.google_ads_index === "on" && AdShow  ? '9':"12"}>
        {children}
          </Col>
       {websiteSettingsData && websiteSettingsData.google_ads_index === "on" && AdShow && (
       <Col>
         <AdSense/>
        </Col>
        )}
        </Row>
      </ModalBody>
      {(title || fullTitle) && (
        <ModalFooter>
          <Btn color="secondary" onClick={toggle}>
            {Close}
          </Btn>
          <Btn color="primary">{SaveChanges}</Btn>
        </ModalFooter>
      )}
      {CommonFooter && (
        <div className="modal-footer ">
          <div className="next_previous position-relative pt-0">
            <Btn onClick={handlePrevious} className="border">
              <div className="d-flex align-items-center gap-sm-2 gap-1">
                Previous <SVG iconId=" back-arrow" />
              </div>
            </Btn>
            <Btn onClick={handleNext} className="border">
              <div className="d-flex align-items-center gap-sm-2 gap-1">
                Next <SVG iconId="front-arrow" />
              </div>
            </Btn>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CommonModal;
