import { CardHeader, Col, Container, Row } from 'reactstrap'
import NewOrders from './NewOrders'
import ShippedOrders from './ShippedOrders'
import CancelledOrders from './CancelledOrders'
import DataTableOrderHistory from './DataTableOrderHistory'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { Ecommerce, OrderHistory } from '../../../../utils/Constant'
import { referralactions } from '../../Users/MyReferralsContainer/MyReferralsContainer'
import { Link } from 'react-router-dom'
import PageHeader from '../../../Dashboard/Default/PageHeader/PageHeader'

const OrderHistoryContainer = () => {
  return (
    <>
      <Breadcrumbs mainTitle={OrderHistory} parent={Ecommerce} />
      <Container fluid>
      <CardHeader>
                    <PageHeader/>
                </CardHeader>
        <Row>
          <Col sm="12">
            <NewOrders />
            <ShippedOrders />
            <CancelledOrders />
            <DataTableOrderHistory />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default OrderHistoryContainer