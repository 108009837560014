 

// import React, { useState, useEffect } from "react";
// import { Formik, Field, Form } from "formik";
// import Select from "react-select"; // Import react-select
// import "animate.css";
// import { Col, Row } from "reactstrap";
// import { Image } from "../../AbstractElements";
// import { dynamicImage } from "../../Service";

// const AnimationMultiSelect = (props: any) => {
//   const { animation, title } = props;
//   const [selectedAnimation, setSelectedAnimation] = useState<string>("");

//   // Set a default animation when the component mounts
//   useEffect(() => {
//     const defaultAnimation = animation.length > 0 ? animation[0] : ""; // Get the first animation if available
//     setSelectedAnimation(`animate__${defaultAnimation}`); // Apply the default animation
//   }, [animation]);

//   const animationOptions = animation.map((anim: string) => ({
//     value: anim,
//     label: anim,
//   }));

//   return (
//     <>
//       <Row>
//         <Col>
//           <div
//             className={`animate__animated ${selectedAnimation}`}
//             style={{ height: "200px", marginBottom: "20px" }}
//           >
//             <Image
//               className="img-fluid rounded-circle h-100"
//               src={dynamicImage("young.png")}
//               alt="user"
//             />
//           </div>
//         </Col>
//         <Col>
//           <Formik initialValues={{ animation: [0] }} onSubmit={() => {}}>
//             {({ setFieldValue, values }) => (
//               <Form>
//                 <div>
//                   <label htmlFor="animation">Select Animation:</label>
//                   <Field name="animation">
//                     {({ field }: any) => (
//                       <Select
//                         {...field}
//                         isMulti={true} // Enable multi-selection
//                         options={animationOptions} // Animation options
//                         className="basic-multi-select"
//                         classNamePrefix="select"
//                         onChange={(selectedOptions) => {
//                           setFieldValue(
//                             "animation",
//                             selectedOptions ? selectedOptions.map((option: any) => option.value) : []
//                           );
//                         }}
//                         value={animationOptions.filter((option) =>
//                           values.animation.includes(option.value)
//                         )}
//                       />
//                     )}
//                   </Field>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//         </Col>
//       </Row>
//     </>
//   );
// };

// export default AnimationMultiSelect;
import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import Select from "react-select"; // Import react-select
import "animate.css";
import { Col, Row } from "reactstrap";
import { Image } from "../../AbstractElements";
import { dynamicImage } from "../../Service";

const AnimationMultiSelect = (props: any) => {
  const { animation, title } = props;
  const [selectedAnimation, setSelectedAnimation] = useState<string[]>([]);

  useEffect(() => {
    const defaultAnimation = animation.length > 0 ? [`animate__${animation[0]}`] : [];
    setSelectedAnimation(defaultAnimation);
  }, [animation]);

  const animationOptions = animation.map((anim: string) => ({
    value: anim,
    label: anim,
  }));

  return (
    <>
      <Row>
        <Col>
          <div
            className={`animate__animated ${selectedAnimation.join(" ")}`}
            style={{ height: "200px", marginBottom: "20px" }}
          >
            <Image
              className="img-fluid rounded-circle h-100"
              src={dynamicImage("young.png")}
              alt="user"
            />
          </div>
        </Col>
        <Col>
          <Formik initialValues={{ animation: animation[0] }} onSubmit={() => {}}>
            {({ setFieldValue, values }) => (
              <Form>
                <div>
                  <label htmlFor="animation">Select Animation:</label>
                  <Field name="animation">
                    {({ field }: any) => (
                      <Select
                        {...field}
                        isMulti={true}
                        options={animationOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOptions) => {
                          const selectedAnimations = selectedOptions
                            ? selectedOptions.map((option: any) => `animate__${option.value}`)
                            : [];
                          setFieldValue(
                            "animation",
                            selectedOptions ? selectedOptions.map((option: any) => option.value) : []
                          );
                          setSelectedAnimation(selectedAnimations);
                        }}
                        value={animationOptions.filter((option) =>
                          values.animation.includes(option.value)
                        )}
                      />
                    )}
                  </Field>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default AnimationMultiSelect;
