import React, { useState } from 'react'
import { Card, CardBody, Col, Modal, Row, Table } from 'reactstrap'
import { Btn, H3, H4, SVG } from '../../../../AbstractElements';
import { Carousel } from 'react-responsive-carousel';
import AdSense from '../../../WebsiteSettings/AdSense/Index';
import { useAppSelector } from '../../../../ReduxToolkit/Hooks';

export interface ContestModalInterface {
    value: boolean;
    setOpenModal: (value: boolean) => void;
    dataId:   number;
    items: { id: number; title: string }[];
}

const ContestModal = ({value , setOpenModal,modalBodyClassName , dataId,items}:ContestModalInterface) => {
    const [open, setOpen] = useState(value);
    const onCloseModal = () => {
        setOpen(false);
        setOpenModal(false);
    };
    const [currentId, setCurrentId] = useState(dataId);
 

    const handleNext = () => {
        if (currentId < items.length - 1) {
            setCurrentId(currentId + 1);
        }
    };

    const handlePrevious = () => {
        if (currentId > 0) {
            setCurrentId(currentId - 1);
        }
    };
    console.log(items);
    const { websiteSettingsData }: any = useAppSelector((state) => state.common);

    return (
        <Modal wrapClassName='product-box' className={` modal-dialog-scrollable ${ modalBodyClassName ? modalBodyClassName : "" }`}  fade centered size="xl" isOpen={open} toggle={onCloseModal}>
            <div className='modal-header' onClick={onCloseModal}> 
                <H4>{`Contest `}{items[currentId].title}</H4>
                <Btn className="py-0" close></Btn>
            </div>
            <div className='modal-body bg-light'>
                <Row className="product-box">
                    <Col lg="4" sm="12">
                        <Card>
                            <CardBody>
                            <Carousel
                                className="owl-carousel owl-theme"
                                showStatus={false}
                                showIndicators={false}
                                showArrows={true}
                                swipeable={true}
                                autoPlay={true}
                                infiniteLoop={true}
                                showThumbs={true}
                            >
                                <div className="item">
                                <img src="../assets/images/ecommerce/01.jpg" alt="" />
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/02.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/03.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/04.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/05.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/06.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/07.jpg" alt=""/>
                                </div>
                                <div className="item">
                                <img src="../assets/images/ecommerce/08.jpg" alt=""/>
                                </div>
                            </Carousel>
                            </CardBody>
                        </Card>
                    </Col>
                    { websiteSettingsData?.google_ads_index === "on" && <Col lg="3" sm="12">
                    <AdSense />
                    </Col>}
                    <Col lg={websiteSettingsData?.google_ads_index === "on" ? "5" :'12'} sm="12">
                        <Card>
                            <CardBody>
                                <div className="product-page-details"> 
                                    <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                A Previous Photo Contest as a Reference :
                                                                </b>
                                                            </td>
                                                            <td> Latest Contest</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Photo Contest Title:
                                                                </b>
                                                            </td>
                                                            <td> Champio ColdpLay</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Category:</b>
                                                            </td>
                                                            <td>Free Commercial</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Product or Service Name:</b>
                                                            </td>
                                                            <td>Value 1</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Url:</b>
                                                            </td>
                                                            <td> <a href="1weeb.com">1weeb.com</a> </td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Vender’s Country:</b>
                                                            </td>
                                                            <td>India</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Desired Budget:</b>
                                                            </td>
                                                            <td>10</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Media Type :</b>
                                                            </td>
                                                            <td>photos</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Country:</b>
                                                            </td>
                                                            <td>India</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Minimum Shares per Content :</b>
                                                            </td>
                                                            <td>100</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Minimum Likes per Content:</b>
                                                            </td>
                                                            <td>200</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Minimum Votes per Content :</b>
                                                            </td>
                                                            <td>50</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>A competitor must be a follower in my Media Center:</b>
                                                            </td>
                                                            <td>User</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>A competitor must be a previous customer on my online store:</b>
                                                            </td>
                                                            <td>Past 30 Days</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                 Description:
                                                                </b>
                                                            </td>
                                                            <td>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores dolorem obcaecati commodi numquam est libero sint accusantium quo ea explicabo.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio, iure possimus eum nisi ab sed quas veritatis. Quaerat, veniam voluptates.</td>
                                                        </tr> 
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    alignContent:
                                                                        "baseline",
                                                                }}>
                                                                <b>Keywords:</b>
                                                            </td>
                                                            <td>
                                                                High-Quality
                                                                Photos,Stock
                                                                Images,Free
                                                                Photos,
                                                                Royalty-Free
                                                                Images,
                                                                Photography
                                                                Collection, HD
                                                                Photos, Curated
                                                                Photo Library,
                                                                Creative Commons
                                                                Photos, Free
                                                                Image Downloads,
                                                                Professional
                                                                Photography,
                                                                Background
                                                                Images, Nature
                                                                Photos
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                 
                </Row>
            </div>
            <div className="modal-footer ">
                <div className="product-buttons position-relative pt-0" >

                <Btn onClick={handlePrevious} className="border" disabled={currentId === 0}>
                    <div className="d-flex align-items-center gap-sm-2 gap-1">
                    Previous           <SVG iconId=" back-arrow" />
                    </div>
                </Btn>
                <Btn onClick={handleNext} className="border" disabled={currentId === items.length - 1}>
                    <div className="d-flex align-items-center gap-sm-2 gap-1">
                    Next             <SVG iconId="front-arrow"/>
                    </div>
                </Btn>
                </div>
                {/* <CommonButton step={true} /> */}
            </div>
        </Modal>
    )
}

export default ContestModal