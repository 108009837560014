import InvoiceSixContainer from "../../../../../Component/Application/Ecommerce/Invoices/Invoice-6/Invoice-6";

const InvoiceSix = () => {
  return (
    <div className="page-body">
      <InvoiceSixContainer />
    </div>
  );
};

export default InvoiceSix;
