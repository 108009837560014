import React, { Fragment, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { Button, Col, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import CalendarEvents from './CalendarEvents';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { calenderInitialData } from '../../../../Data/Application/Calendar/Calendar';
// import { calenderEventClick } from './CalenderFunction';
import MediaData from '../../../App/MediaData/MediaData';
import { H4 } from '../../../../AbstractElements';
import ActivityModal from './ActivityModal';
import MediaModal from '../../../App/MediaData/MediaModal';

const DragCalendar = ({modalBodyClassName}) => {
  const [state, setState] = useState(calenderInitialData);
  const [draggedEventInfo, setDraggedEventInfo] = useState({ title: '', start: '', end: '' });
 
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  function handleEventResize(info) {
    console.log("sdsds", info.event.end);
    const event = info.event;
  }

  function handleEventReceive(info) {
    handleEventResize(info);
    const event = info.event;
    console.log("Received Event:", event._instance.range.end);

    if (event.start) {
      setDraggedEventInfo({
        title: event.title,
        start: event.start.toLocaleString(),
        end: event._instance.range.end
      });
    } else {
      setDraggedEventInfo({
        title: event.title,
        start: 'No start time',
        end: event._instance.range.end
      });
    }
  }

  function handleEventDrop(info) {
    const event = info.event;
    const updatedEvents = state.calendarEvents.map(e => {
      if (e.id === event.id) {
        return {
          ...e,
          start: event.start,
          end: event.end
        };
      }
      return e;
    });
    
    setState(prevState => ({
      ...prevState,
      calendarEvents: updatedEvents
    }));
  
    setDraggedEventInfo({
      title: event.title,
      start: event.start.toLocaleString(),
      end: event.end ? event.end.toLocaleString() : 'N/A'
    });
  } 

  useEffect(() => {
    let draggableEl = document.getElementById('external-events');
    new Draggable(draggableEl, {
      itemSelector: '.fc-event',
      eventData: function (eventEl) {
        let title = eventEl.getAttribute('title');
        let id = eventEl.getAttribute('data');
        return {
          title: title,
          id: id,
        };
      },
    });
  }, []);

  const toggleCalendar = () => {
    setIsCalendarVisible(prev => !prev);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState({});

  // Toggle modal visibility
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const calenderEventClick = (info) => { 
    
    const eventData = {
      title: info.event.title,
      checkIn: info.event.start ? info.event.start.toLocaleString() : 'N/A',
      checkOut: info.event.end ? info.event.end.toLocaleString() : 'N/A',
      duration: '2 hours', // You can calculate the actual duration here
      totalAttendance: '10', // Replace with actual data
      attendees: 'John Doe, Jane Smith', // Replace with actual attendees
      supervisor: 'Alice Brown', // Replace with actual supervisor
      comment: 'No issues', // Replace with actual comment
    };
    setSelectedEventData(eventData);
    toggleModal();
    setOpenModal(true);

  };
  const [openModal, setOpenModal] = useState<boolean>(false);
 
  const items = [
    { id: 1, title: "Media 1" },
    { id: 2, title: "Media 2" },
    { id: 3, title: "Media 3" },
    { id: 4, title: "Media 4" },
  ];
  return (
    <Fragment>
      <CalendarEvents />
       
      <Col xl="9" lg="9"   className={`box-col-8 ${isCalendarVisible ? 'd-none' : ' d-block'}`}>
      <Button color='primary' className='mb-2' onClick={toggleCalendar}>
          {isCalendarVisible ? 'Hide Calendar' : 'Hide Calendar'}
        </Button>
        {/* {isCalendarVisible && ( */}
          <div >
          <div className="demo-app-calendar" id="mycalendartest">
            <FullCalendar
              initialView="dayGridMonth"
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              rerenderDelay={10}
              eventDurationEditable={true}
              editable={true}
              droppable={true}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              events={calenderInitialData.calendarEvents}
              eventClick={calenderEventClick}
              eventResize={handleEventResize}
              eventReceive={handleEventReceive}
              eventDrop={handleEventDrop}
              eventContent={(arg) => {
                const startOptions = { day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true };
                const endOptions = { day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true };
              
                const startTime = arg.event.start ? arg.event.start.toLocaleString('en-US', startOptions) : 'N/A';
                const endTime = arg.event.end ? arg.event.end.toLocaleString('en-US', endOptions) : 'N/A';
              
                return (
                  <div style={{
                    backgroundColor: arg.backgroundColor,
                    borderColor: arg.borderColor,
                    padding: '5px',
                    color: '#fff',
                    borderRadius: '5px',
                    textAlign: 'center',
                    width: "100%",
                    textWrap:"wrap"
                  }}>
                    <strong>{arg.event.title}</strong><br />
                    <span>
                      {startTime} - {endTime}
                    </span>
                  </div>
                );
              }}
            />
          </div>
          </div>
        {/* )} */}
      </Col>
      <Col xl={isCalendarVisible? 9 : 12} lg={isCalendarVisible? 9 : 12} className="box-col-8  mt-xl-3">
     {isCalendarVisible ? <Button color='primary' className='mb-2' onClick={toggleCalendar}>
          {isCalendarVisible ? 'Show Calendar' : 'Show Calendar'}
        </Button>:''}
      <MediaData form={true} />
      </Col>
      {openModal && ( <MediaModal
      Modaltype="activites"
            value={openModal}
            setOpenModal={setOpenModal}
            // dataId={dataId}
            modalBodyClassName={modalBodyClassName}
            items={items}    
          />)}
    </Fragment>
  );
}

export default DragCalendar;
