import { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Col, Label, Row, Container } from "reactstrap";
import {
  EnterUsername,
  Login,
  ForgotPassword,
  DoNotAccount,
  CreateAccount,
  Password,
  Next,
  EnterPassword,
  AfterLoginRedirectUrl,
  TwoFAUrl,
  AccountListUrl,
} from "../../utils/Constant";
import { Btn, P } from "../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineModeEdit } from "react-icons/md";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import {
  LoginFormProps,
  loginFormInitialValues,
  loginFormSchema,
} from "./FormSchema";
import {
  useLoginMutation,
  useCheckUsernameMutation,
} from "../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";
import { toast } from "react-toastify";
import {
  setChooseAccountFlag,
  setCredentials,
  setToken,
} from "../../ReduxToolkit/Reducers/Authentication/authSlice";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { useLocation } from "react-router-dom";
import DangerMessage from "../CommonMessage/DangerMessage";
import Loading from "../Loading/Loading";

const LoginForm = (props: any) => {
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { privateFlag } = useParams();

  const userInfo: any = localStorage.getItem("userInfo");

  const [checkUsername] = useCheckUsernameMutation();
  const [login] = useLoginMutation();

  const location = useLocation();

  const redirect = location.state?.from?.pathname || AfterLoginRedirectUrl;

  useEffect(() => {
    if (userInfo && userInfo.accounts > 0) {
      navigate(AccountListUrl);
    } else if (userInfo) {
      navigate(redirect, { replace: true });
    }
  }, [userInfo, redirect, navigate]);

  const modiftyUsername = (show: boolean, setFieldValue: any) => {
    setShow(show);
    setFieldValue("password", "");
  };

  const handleLogin = async (values: LoginFormProps) => {
    try {
      // when username checking below block is called
      if (values?.username && values?.password === "") {
        const res = await checkUsername({
          username: values?.username,
        }).unwrap();
        if (res?.success === true) {
          setShow(true);
        } else {
          setShow(false);
          toast.error(res?.message, { position: "bottom-right" });
        }
      }

      if (show && values?.username && values?.password === "") {
        toast.error("Please enter password.", { position: "bottom-right" });
      }
      // when username && password checking below block is called
      if (values?.username !== "" && values?.password !== "") {
        const loginResp = await login({
          username: values?.username,
          password: values?.password,
        }).unwrap();

        const { data, success, message }: any = loginResp;
        if (success === true) {
          if (data?.twoFA === true) {
            dispatch(setToken({ token: data?.token }));
            navigate(TwoFAUrl);
          } else if (data?.accounts > 0) {
            dispatch(setToken({ token: data?.token }));
            dispatch(setCredentials({ ...data }));
            dispatch(setChooseAccountFlag(false));
            navigate(AccountListUrl);
          } else {
            dispatch(setToken({ token: data?.token }));
            dispatch(setCredentials({ ...data }));
            toast.success(message, { position: "bottom-right" });
            navigate(redirect);
            props?.setOpenModal(false);
          }
        } else {
          toast.error(message, { position: "bottom-right" });
        }
      }
    } catch (error: any) {
      toast.error(error?.data?.message, { position: "bottom-right" });
    }
    setSubmitError(false);
  };

  if (userInfo) return <Loading />;

  return (
    <Container className="p-3">
      {privateFlag && !show ? (
        <DangerMessage message="Login is required" />
      ) : (
        ""
      )}
      <Formik
        initialValues={loginFormInitialValues}
        onSubmit={handleLogin}
        validationSchema={loginFormSchema}
      >
        {({ errors, values, setFieldValue }) => (
          <Form>
            <Row className="g-3">
              {show ? (
                <>
                  <Col md="12">
                    <Label>{values?.username}</Label>
                    <MdOutlineModeEdit
                      onClick={() => modiftyUsername(false, setFieldValue)}
                    />
                  </Col>

                  <Col md="12">
                    <Label>{Password}</Label>
                    <Field
                      type={showPassword ? "password" : "text"}
                      name="password"
                      className={`form-control ${
                        submitErrors &&
                        `${errors.password ? "is-invalid" : "is-valid"}`
                      }`}
                      placeholder={EnterPassword}
                      autoFocus={true}
                    />
                    <div
                      className="show-hide pe-4 pt-0"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col md="12">
                    <Btn
                      color="primary"
                      type="submit"
                      onClick={() => setSubmitError(true)}
                    >
                      {Login}
                    </Btn>
                  </Col>
                </>
              ) : (
                <>
                  <Col md="12">
                    <Label>User name</Label>
                    <Field
                      type="text"
                      name="username"
                      className={`form-control ${
                        submitErrors &&
                        `${errors.username ? "is-invalid" : "is-valid"}`
                      }`}
                      placeholder={EnterUsername}
                      autoFocus={true}
                      autoComplete={"username"}
                    />
                    <ErrorMessage
                      name="username"
                      component="span"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col md="12">
                    <Btn
                      color="primary"
                      type="submit"
                      onClick={() => setSubmitError(true)}
                    >
                      {Next}
                    </Btn>
                  </Col>
                </>
              )}
              <Col md="12">
                <Link to={`${process.env.PUBLIC_URL}/forgot-password`}>
                  {ForgotPassword}{" "}
                </Link>
              </Col>
              <Col md="12">
                <P className="mt-1 mb-0 ">
                  {DoNotAccount}
                  <Link
                    className="ms-2"
                    to={`${process.env.PUBLIC_URL}/register-account`}
                  >
                    {CreateAccount}
                  </Link>
                </P>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default LoginForm;
