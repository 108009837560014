export const whishListProduct = [
    {
      name: "Women Top",
      image: "product-table-6.png",
    },
    {
      name: "Women Shorts",
      image: "product-table-5.png",
    },
    {
      name: "Cyclamen",
      image: "product-table-4.png",
    },
    {
      name: "Men Dashed Denim Jacket",
      image: "product-table-3.png",
    },
    {
      name: "Blue Shirt",
      image: "product-table-2.png",
    },
    {
      name: "Red Shirt",
      image: "product-table-1.png",
    },
    {
      name: "Red Shirt",
      image: "product-table-1.png",
    },
    {
      name: "Women Top",
      image: "product-table-6.png",
    },
    {
      name: "Women Shorts",
      image: "product-table-5.png",
    },
    {
      name: "Cyclamen",
      image: "product-table-4.png",
    },
    {
      name: "Men Dashed Denim Jacket",
      image: "product-table-3.png",
    },
    {
      name: "Men Dashed Denim Jacket",
      image: "product-table-3.png",
    },
    {
      name: "Blue Shirt",
      image: "product-table-2.png",
    },
    {
      name: "Women Top",
      image: "product-table-6.png",
    },
    {
      name: "Women Shorts",
      image: "product-table-5.png",
    },
  ];