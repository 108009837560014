import React, { useState, useCallback, useRef } from "react";
import SimpleMdeReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import { Label } from "reactstrap";

const LongDescription = ({ id, lable,value}: { id: string; lable: string }) => {
    const [content, setContent] = useState<string>(value);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const { websiteSettingsData }: any = useAppSelector(
        (state) => state.common
    );
    const maxDescriptionLength = websiteSettingsData?.long_desc_length ?? 500;

    const editorRef = useRef<any>(null); // Ref to hold editor instance

    const handleEditorChange = useCallback(
        (value: string) => {
            if (value.length > maxDescriptionLength) {
                setErrorMessage(
                    `Content exceeds the maximum length of ${maxDescriptionLength} characters.`
                );
                return; // Prevent updating content if limit is exceeded
            }
            setContent(value);
            setErrorMessage("");
        },
        [maxDescriptionLength]
    );

    const handleKeyPress = (e: React.KeyboardEvent) => {
        const currentLength = content.length;
        if (currentLength >= maxDescriptionLength && e.key !== "Backspace") {
            e.preventDefault(); // Prevent any keypress except backspace when limit is reached
        }
    };

    return (
        <div className="position-relative">
            <Label className="w-100" check>
                {lable}:
            </Label>
            <SimpleMdeReact
                id={id}
                value={content}
                onChange={handleEditorChange}
                getMdeInstance={(instance) => (editorRef.current = instance)} // Get editor instance
                options={{
                    spellChecker: false, 
                    autofocus: true, // Focus on editor load 
                    placeholder: "Type your description here...",
                }}
                onKeyDown={handleKeyPress} // Intercept keydown to prevent typing beyond limit
            />  
            <p className="position-absolute" style={{bottom:'4px'}}>
                {content.length}/{maxDescriptionLength} characters
            </p>
        </div>
    );
};

export default LongDescription;
