import { apiSlice } from "../../../apiSlice";
import { GET_PLANS } from "../../../../Api";

interface Plans {
  data: any;
  message: string;
  success: boolean;
}

export const registrationPlanApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrationPlanList: builder.query<
      Plans,
      { country: any; registrationType: any }
    >({
      query: (data) => ({
        url: GET_PLANS,
        method: "POST",
        body: {
          country_id: data.country,
          registration_type: data.registrationType,
        },
      }),
      providesTags: ["Plans"],
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetRegistrationPlanListQuery } = registrationPlanApiSlice;
