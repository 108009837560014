import { Row, Col, Label, Button, Container } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { ErrorMessage, Field, FieldArray, Form } from "formik";
import Country from "../../../CommonInput/Country";
import State from "../../../CommonInput/State";
import TextCounter from "../../../CommonInput/TextCounter";
import TitleInput from "../../../CommonInput/TitleInput";
import CustomSelect from "../../../CommonInput/CustomSelect";
import { useState } from "react";
import City from "../../../CommonInput/City";
import Branches from "../../../CommonInput/Branches";
import PhoneField from "../../ManageOnlineStore/ManageBranches/BranchPublicContact/PhoneField";
import EmailField from "../../ManageOnlineStore/ManageBranches/BranchPublicContact/EmailField";
import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import Timeslot from "../../ManageOnlineStore/ManageBranches/Schedules/Timeslot";
import SubCategory from "../../../CommonInput/SubCategory";
import TagsInput from "../../../CommonInput/TagsInput";
const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const AddSpecialParametersForm = (props: any) => {
  const { errors, submitErrors, setSubmitError, values,setFieldValue } = props;
    const [phoneFieldCount, setPhoneFieldCount] = useState(["1"]);
    const [emailFieldCount, setEmailFieldCount] = useState(["1"]);
    const [autoSchedule, setAutoSchedule] = useState(true);
  const years = Array.from({ length: 50 }, (_, i) => (new Date().getFullYear() - i));
  // const handleChange = (selectedOption: any) => {
  //   setFieldValue('years', selectedOption); // Update Formik's state with selected value
  // };
  if (!years || years.length === 0) return <div>Data not found!</div>;
 
  const yearsOptions = years.map((year) => ({
    value: year,
    label: year.toString(), // Convert the year to a string for the label
  }));
   

  const onChangeHandler = () => {
    setAutoSchedule((oldFlag) => !oldFlag);
  };
     
  return (
    <Form className="form-wizard">
      <Row>
        <Col sm="4" className="mb-3">
          <Label check>
            Country <span className="txt-danger">*</span>
          </Label> 
          <Country name="country" isMulti={false} placeholder="Select"  className={`${
              submitErrors && `${errors.country ? "is-invalid" : "is-valid"}`
            }`} />
          <ErrorMessage
            name="country"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>State</Label> 
          <State name='state' isMulti={false} placeholder="Select"/>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>City</Label>
          <City name='city' isMulti={false} placeholder='Select'/>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm="4" className="mb-3">
          <Label check>Branch</Label>
          <Branches name="branch" isMulti={true} placeholder="Select"/>
        </Col>
        <Col sm="4" className="mt-2">
           <Button className="btn btn-primary">
            Select Branch
           </Button>
        </Col>
      </Row>
      <Row> 
      <Col sm="4" className="mb-3">
          <Label check>
            Final Branch List  
          </Label> 
          <ErrorMessage
            name="finalBranchList"
            component="span"
            className="invalid-feedback"
          />
        </Col>
         
      </Row>
    
      <Row>
        <Col>
        <Label check>Knowledge Type</Label>
        <div>
        <Field 
        type="checkbox"
        />
        <span className="ms-2">Lesson</span>
        </div>
        <div>
        <Field 
        type="checkbox"
        />
        <span className="ms-2">Questionnaire (educational game, test or survey)</span>
        </div>
        </Col>
        <Col sm="4" className="mb-3">
        <TitleInput title='Title' values={values.title} name="title"  placeholder="Title"/>  
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>Years</Label>
           
          <Field
                className={`custom-select `}
                name='years'
                options={yearsOptions}
                component={CustomSelect}
              placeholder="Select"
                isMulti={true}
                // onChange={handleChange}
              />
          <ErrorMessage name="years" component="span" className="invalid-feedback" />
        </Col> 
        <Col sm="4" className="mb-3">
                    <SubCategory name="subcategory"
                        className={`${
                            submitErrors &&
                            `${errors.subcategory ? "is-invalid" : "is-valid"}`
                        }`}
                    />
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Keywords (Optional)</Label>
                    <TagsInput
                        name="keywords"
                        placeholder="Enter keywords"
                        className="form-control"
                        setFieldValue={setFieldValue}
                    />
                </Col>
        <Col sm="4" className="mb-3">
          <Label check>Course</Label>
          <Field type="text" name="course" maxLen gth='140'placeholder="Course" className='form-control' />
          <TextCounter length={values?.course?.length} totalLength='140' />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>Teacher</Label>
          <Field type="text" name="course" maxLen gth='140'placeholder="Course" className='form-control' />
          <TextCounter length={values?.course?.length} totalLength='140' />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>Book</Label>
          <Field type="text" name="book" maxLength='140' placeholder="Book" className='form-control'/>
          <TextCounter length={values?.book?.length} totalLength='140' />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>Exam</Label>
          <Field type="text" name="exam" maxLength='140' placeholder="Exam" className='form-control'/>
          <TextCounter length={values?.exam?.length} totalLength='140' />
        </Col>
      </Row>
      <Row>
      
      </Row>
      
        {/* <Row className="justify-content-between">
                <Col sm="auto" className="mb-3">
                    <Btn
                        color="secondary"
                        type="submit"
                        onClick={() => setSubmitError(true)}>
                       Save as a Draft
                    </Btn> 
                </Col>
                
            </Row> */}
    </Form>
  );
};

export default AddSpecialParametersForm;
