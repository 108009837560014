import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { PostType } from '../../../../utils/Constant'
import {useState} from 'react';

export const radioOption = [
  {id:0, text:"Private C-Chat", defaultChecked:true},
  {id:3, text:"Activity & Event C-Chat"},
  {id:2, text:"Application C-Chat : work and study"},
  {id:4, text:"Marketplace C-Chat (with buyers or sellers)"},
  {id:5, text:"Project C-Chat (with colleagues or clients)"}
];

const RadioTypeForm = (props:any) => {
  const {submitErrors, errors} = props;
  const[type, setType] = useState(0);
  return (
    <FormGroup>
      <Label check>{PostType} <span className="txt-danger">*</span>:</Label>
      <div className="m-checkbox-inline">
        {radioOption.map((data, index) => (
          <Label for={`edo-ani-${index}`} key={index} check className={`${submitErrors && ` ${errors.posttype ? "text-danger" : "text-success"}`}`}>
            <Input
              className="radio_animated"
              id={`edo-ani-${index}`}
              type="radio"
              name="rdo-ani"
              defaultChecked={data.defaultChecked}
              onChange={()=>setType(data.id)}
            />
            {data.text}
          </Label>
        ))}
      </div>
      <Row className='py-2'>
      {type === 1 && (
        <Col md="6">
          <FormGroup floating>
            <Input type="select">
                <option>{`Select an Opening`}</option>
                <option>value -1</option>
                <option>value -2</option>
            </Input>
            <Label>{`Select an Opening *`}</Label>
          </FormGroup>
        </Col>
      )}
      {type === 2 && (
        <Col md="6">
          <FormGroup floating>
            <Input type="select">
                <option>{`Select an Opening`}</option>
                <option>value -1</option>
                <option>value -2</option>
            </Input>
            <Label>{`Select an Opening *`}</Label>
          </FormGroup>
        </Col>
      )}
      {type === 3 && (
        <Col md="6">
          <FormGroup floating>
            <Input type="select">
                <option>{`Select an Activity or Event`}</option>
                <option>value -1</option>
                <option>value -2</option>
            </Input>
            <Label>{`Select an Activity or Event (Optional)`}</Label>
          </FormGroup>
        </Col>
      )}
      {type === 4 && (
        <Col md="6">
          <FormGroup floating>
            <Input type="select">
                <option>{`Select an Product or Service (optional)`}</option>
                <option>value -1</option>
                <option>value -2</option>
            </Input>
            <Label>{`Select an Product or Service (optional)`}</Label>
          </FormGroup>
        </Col>
      )}
      {type === 5 && (
        <Col md="6">
          <FormGroup floating>
            <Input type="select">
                <option>{`Select an Project (optional)`}</option>
                <option>value -1</option>
                <option>value -2</option>
            </Input>
            <Label>{`Select an Project (optional)`}</Label>
          </FormGroup>
        </Col>
      )}
      </Row>
    </FormGroup>
  )
}

export default RadioTypeForm