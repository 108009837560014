import { Col, Container, Row } from "reactstrap";
import { H2 } from "../../AbstractElements";   
import ConfrimForm from "./ConfrimForm";
import { Link } from "react-router-dom";
import { Logo } from "../Images";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import Loading from "../Loading/Loading";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AfterLoginRedirectUrl } from "../../utils/Constant";
import { useCookies } from "react-cookie";

function ConfirmPassword() {

  const { userInfo } : any = useAppSelector((state) => state.auth);
  const [cookies] = useCookies(["confirmUser"]);
  const navigate = useNavigate();
  const redirect = AfterLoginRedirectUrl;
  const confirmUser = cookies.confirmUser === true ? true : false;

  useEffect(() => {
    if(userInfo && confirmUser){
      navigate(redirect);
    }
  },[userInfo,navigate, redirect, confirmUser]);
  
  if(userInfo && confirmUser) return <Loading/>;

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card  login-dark" style={{ paddingTop : '130px' }}>
            <div> 
            <div>
                <Link className="logo text-center" to={'/'}>
                  <Logo width={500} className="w-25" />
                </Link>
              </div>
              <div className="login-main"> 
                <H2 className="text-center mb-4">Confirm Password</H2> 
                <ConfrimForm />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default ConfirmPassword