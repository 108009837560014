import { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import {
  ApartmentBuilding,
  AreaStreetVillage,
  DefaultAddress,
  FullName,
  IntercomCode,
  LandMark,
  PhoneNumber,
  Save,
} from "../../../utils/Constant";
import { Btn } from "../../../AbstractElements";
import CommonSwitchSpan from "../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import { Form, ErrorMessage, Field } from "formik";
import Country from "../../../Component/CommonInput/Country";
import State from "../../../Component/CommonInput/State";
import City from "../../../Component/CommonInput/City"; 
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetUserAddressByIdMutation } from "../../../ReduxToolkit/Reducers/AccountSettings/addressApiSlice";
import Loading from "../../../Component/Loading/Loading";

function ShippingAddressForm(props: any) {
  const [userAddresses, setUserAddresses] = useState<any>();

  const { errors, values, setFieldValue, submitErrors, setSubmitErrors } =
    props;
 
  const { id } = useParams();

  const [getUserAddressesListApi, { isLoading: loadingList }] =
    useGetUserAddressByIdMutation();

  const fetchUserAddressesList = async () => {
    try {
      const response = await getUserAddressesListApi({
        id: Number(id),
      }).unwrap();
      const { data, success, message } = response;
      if (success === true) {
        setUserAddresses(data);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    if (values.country_id && id === undefined) {
      setFieldValue("state_id", "");
      setFieldValue("city_id", "");
    }
  }, [values.country_id,id,setFieldValue]);

  useEffect(() => {
    if (id) {
      fetchUserAddressesList();
    }
  }, [id]);

  useEffect(() => {
    if (userAddresses && id) {
      if (userAddresses) {
        setFieldValue("id", userAddresses.id);
        setFieldValue("full_name", userAddresses.full_name);
        setFieldValue("country_id", userAddresses.country_id);
        setFieldValue("state_id", userAddresses.state_id);
        setFieldValue("city_id", userAddresses.city_id);
        setFieldValue("mobile_number", userAddresses.mobile_number);
        setFieldValue("pincode", userAddresses.pincode);
        setFieldValue("intercom_code", userAddresses.intercom_code);
        setFieldValue("addressline1", userAddresses.addressline1);
        setFieldValue("addressline2", userAddresses.addressline2);
        setFieldValue("landmark", userAddresses.landmark);
        setFieldValue(
          "is_default",
          userAddresses.is_default === true ? true : false
        );
      }
    }
  }, [userAddresses, id,setFieldValue]);

  if(loadingList) return <Loading/>
  
  return (
    <Form className="form-wizard">
      <Card>
        <CardBody>
          <Row>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>
                  {FullName}
                  <span className="font-danger">*</span>
                </Label>
                <Field
                  name="full_name"
                  className={`form-control ${
                    submitErrors &&
                    `${errors.full_name ? "is-invalid" : "is-valid"}`
                  }`}
                  type="text"
                  placeholder={FullName}
                />
                <ErrorMessage
                  name="full_name"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>
                  {"Country"}
                  <span className="font-danger">*</span>
                </Label>
                <Country
                  placeholder="Select"
                  name="country_id"
                  isMulti={false}
                  className={`${
                    submitErrors &&
                    `${errors.country_id ? "is-invalid" : "is-valid"}`
                  }`}
                />
                <ErrorMessage
                  name="country_id"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>{"State"}</Label>
                <State
                  name="state_id"
                  isMulti={false}
                  setFieldValue={setFieldValue}
                  countryId={values.country_id}
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>
                  {"City"} <span className="font-danger">*</span>
                </Label>
                <City
                  name="city_id"
                  isMulti={false}
                  countryId={values.country_id}
                  stateId={values.state_id}
                  placeholder="Select"
                  className={`${
                    submitErrors &&
                    `${errors.city_id ? "is-invalid" : "is-valid"}`
                  }`}
                />
                <ErrorMessage
                  name="city_id"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>{"Pincode"}</Label>
                <Field
                  name="pincode"
                  className={`form-control ${
                    submitErrors &&
                    `${errors.pincode ? "is-invalid" : "is-valid"}`
                  }`}
                  type="text"
                  placeholder={"Pincode"}
                />
                <ErrorMessage
                  name="pincode"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>
                  {ApartmentBuilding} <span className="font-danger">*</span>
                </Label>
                <Field
                  name="addressline1"
                  className={`form-control ${
                    submitErrors &&
                    `${errors.addressline1 ? "is-invalid" : "is-valid"}`
                  }`}
                  type="text"
                  placeholder={ApartmentBuilding}
                />
                <ErrorMessage
                  name="addressline1"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>
                  {AreaStreetVillage} <span className="font-danger">*</span>
                </Label>
                <Field
                  name="addressline2"
                  className={`form-control ${
                    submitErrors &&
                    `${errors.addressline2 ? "is-invalid" : "is-valid"}`
                  }`}
                  type="text"
                  placeholder={AreaStreetVillage}
                />
                <ErrorMessage
                  name="addressline2"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>{LandMark}</Label>
                <Field
                  name="landmark"
                  className={`form-control ${
                    submitErrors &&
                    `${errors.landmark ? "is-invalid" : "is-valid"}`
                  }`}
                  type="text"
                  placeholder={LandMark}
                />
                <ErrorMessage
                  name="landmark"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>
                  {PhoneNumber} <span className="font-danger">*</span>
                </Label>
                <Field
                  name="mobile_number"
                  className={`form-control ${
                    submitErrors &&
                    `${errors.mobile_number ? "is-invalid" : "is-valid"}`
                  }`}
                  type="text"
                  placeholder={PhoneNumber}
                />
                <ErrorMessage
                  name="mobile_number"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>{IntercomCode}</Label>
                <Field
                  name="intercom_code"
                  className={`form-control ${
                    submitErrors &&
                    `${errors.intercom_code ? "is-invalid" : "is-valid"}`
                  }`}
                  type="text"
                  placeholder={IntercomCode}
                />
                <ErrorMessage
                  name="intercom_code"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            {id === undefined && (
              <Col md="12" className="pt-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0  text-end icon-state switch-outline">
                    <CommonSwitchSpan
                      name="is_default"
                      color={`success`}
                      defaultChecked
                    />
                  </div>
                  <Label className="m-l-10" check>
                    {DefaultAddress}
                  </Label>
                </div>
              </Col>
            )}
          </Row>
        </CardBody>
        <CardFooter className="text-left">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitErrors(true)}
          >
            {id !== undefined ? "Update" : Save}
          </Btn>
        </CardFooter>
      </Card>
    </Form>
  );
}

export default ShippingAddressForm;
