import InvoiceOne from "../Pages/Application/Ecommerce/Invoices/Invoice-1/Invoice-1";
import InvoiceTwo from "../Pages/Application/Ecommerce/Invoices/Invoice-2/Invoice-2";
import InvoiceThree from "../Pages/Application/Ecommerce/Invoices/Invoice-3/Invoice-3";
import InvoiceFour from "../Pages/Application/Ecommerce/Invoices/Invoice-4/Invoice-4";
import InvoiceFive from "../Pages/Application/Ecommerce/Invoices/Invoice-5/Invoice-5";
import InvoiceSix from "../Pages/Application/Ecommerce/Invoices/Invoice-6/Invoice-6";
import ProjectCreate from "../Pages/Application/Project/ProjectCreate/ProjectCreate";
import ProjectList from "../Pages/Application/Project/ProjectList/ProjectList";
import Tasks from "../Pages/Application/Tasks/Tasks";
import Todo from "../Pages/Application/Todo/Todo";
import DetailsCourse from "../Pages/Miscellaneous/Learning/DetailsCourse/DetailsCourse";
import LearningList from "../Pages/Miscellaneous/Learning/LearningList/LearningList";
import SupportTicket from "../Pages/Miscellaneous/SupportTicket/SupportTicket";
import AttendanceSystem from "../Pages/Tools/BrandSupportTools/ManageAttendance/AttendanceSystem"; 
//New Pages
import AddBranchPublicContact from "../Pages/Tools/ManageOnlineStore/ManageBranches/BranchPublicContact/AddBranchPublicContact";
import AddManageBranch from "../Pages/Tools/ManageOnlineStore/ManageBranches/AddManageBranch";
import AddAddonCategories from "../Pages/Tools/ManageOnlineStore/AddonCategories/AddAddonCategories";
import AddCoupon from "../Pages/Tools/ManageOnlineStore/ManageMyCoupons/AddCoupon";
import AddDeliveryFees from "../Pages/Tools/ManageOnlineStore/DeliveryFees/AddDeliveryFees";
import AddDeliveryWorkers from "../Pages/Tools/ManageOnlineStore/DeliveryWorkers/AddDeliveryWorkers";
import Contact from "../Pages/Contacts/Contact";
import ManageExternalLink from "../Pages/Tools/BrandSupportTools/ManageMyExternalLinks/ManageExternalLink";
import ManageMyPhotoContests from "../Pages/Tools/BrandSupportTools/ManageMyPhotoContest/ManageMyPhotoContests";
import SurveyQuizes from "../Pages/Tools/BrandSupportTools/SurveyAndQuizzes/SurveyQuizes";
import AddManageMyExternalLink from "../Pages/Tools/BrandSupportTools/ManageMyExternalLinks/AddManageMyExternalLink";
import AddManageMyPhotoContest from "../Pages/Tools/BrandSupportTools/ManageMyPhotoContest/AddManageMyPhotoContest";  
import QuizMaterials from "../Pages/Tools/BrandSupportTools/QuizMaterial/QuizMaterials";
import SpecialParameters from "../Pages/Tools/BrandSupportTools/SpecialParameters/SpecialParameters";
import Questions from "../Pages/Tools/BrandSupportTools/Questions/Questions";
import MyUploadedContent from "../Pages/Tools/ManageContents/MyUpdatedContent/MyUploadedContent";
import ManageMyCollections from "../Pages/Tools/ManageContents/ManageMyCollections/ManageMyCollections";
import ManageMyAlbums from "../Pages/Tools/ManageContents/ManageMyAlbums/ManageMyAlbums";
import TrashPhotos from "../Pages/Tools/ManageContents/TrashPhotos/TrashPhotos";
import AddonCategories from "../Pages/Tools/ManageOnlineStore/AddonCategories/AddonCategories";
import ManageMyCoupons from "../Pages/Tools/ManageOnlineStore/ManageMyCoupons/ManageMyCoupons";
import DeliveryFees from "../Pages/Tools/ManageOnlineStore/DeliveryFees/DeliveryFees";
import DeliveryWorkers from "../Pages/Tools/ManageOnlineStore/DeliveryWorkers/DeliveryWorkers";
import ManageBranches from "../Pages/Tools/ManageOnlineStore/ManageBranches/ManageBranches";
import BranchPublicContact from "../Pages/Tools/ManageOnlineStore/ManageBranches/BranchPublicContact/BranchPublicContact";
import AddSchedule from "../Pages/Tools/ManageOnlineStore/ManageBranches/Schedules/AddSchedule";
import Schedules from "../Pages/Tools/ManageOnlineStore/ManageBranches/Schedules/Schedules";
import ManageProductServices from "../Pages/Tools/ManageOnlineStore/ManageBranches/ManageProductServices/ManageProductServices";
import ManageAttendanceSystem from "../Pages/Tools/BrandSupportTools/ManageAttendance/ManageAttendanceSystem";
import KnowledgeSystem from "../Pages/Tools/BrandSupportTools/KnowledgeSystem/KnowledgeSystem";
import ManageMyAlbumsAdd from "../Pages/Tools/ManageContents/ManageMyAlbums/ManageAlbums";
import StudyMaterialLessionPage from "../Pages/Tools/BrandSupportTools/StudyMaterialLesson/StudyMaterialLessionPage";
import DeliverySystem from "../Pages/Tools/ManageOnlineStore/DeliveryWorkers/DeliverySystem";
import ReadyTemplates from "../Pages/Application/Templates/ReadyTemplates";
import MarketplaceChat from "../Pages/Miscellaneous/Communication/MarketplaceChat/MarketplaceChat";
import ApplicationChat from "../Pages/Miscellaneous/Communication/ApplicationChat/ApplicationChat";
import ProjectEventChat from "../Pages/Miscellaneous/Communication/ProjectEventChat/ProjectEventChat";
import ActivitiesChat from "../Pages/Miscellaneous/Communication/ActivitiesChat/ActivitiesChat";
import PrivateCChat from "../Pages/Miscellaneous/Communication/GroupChat/PrivateCChat";
import AddPost from "../Pages/Miscellaneous/Communication/AddPost/AddPost";
import LetterBox from "../Pages/Miscellaneous/Communication/LetterBox/LetterBox";
import Contacts from "../Pages/Application/Contacts/Contacts";
import About from "../Pages/App/MyMediaCenter/About/About";
import MyCoupons from "../Pages/App/MyMediaCenter/MyCoupons/MyCoupons";
import Photos from "../Pages/App/MyMediaCenter/Photos/Photos";
import ActivitiesAndMeetings from "../Pages/App/MyMediaCenter/ActivitiesAndMeetings/ActivitiesAndMeetings";
import QuizzesAndSurverys from "../Pages/App/MyMediaCenter/QuizzesAndSurverys/QuizzesAndSurverys";
import Likes from "../Pages/App/MyMediaCenter/Likes/Likes";
import Albums from "../Pages/App/MyMediaCenter/Albums/Albums";
import Reviews from "../Pages/App/MyMediaCenter/Reviews/Reviews";
import Collections from "../Pages/App/MyMediaCenter/Collections/Collections"; 
import OnlineStore from "../Pages/App/MyMediaCenter/OnlineStore/OnlineStore";
import Calender from "../Pages/Application/Calender/Calender";
import Followers from "../Pages/App/MyMediaCenter/Followers/Followers";
import PendingPhotos from "../Pages/App/MyMediaCenter/PendingPhotos/PendingPhotos";
import IFollow from "../Pages/App/MyMediaCenter/IFollow/IFollow";
import PhotoContests from "../Pages/App/MyMediaCenter/PhotoContests/PhotoContests";
import IDownloaded from "../Pages/App/MyMediaCenter/IDownloaded/IDownloaded";
import ExternalLinks from "../Pages/App/MyMediaCenter/ExternalLinks/ExternalLinks"; 
import Upload from "../Pages/App/Upload/Upload"; 
import ConfirmPassword from "../Component/Authentication/ConfirmPassword"; 
import PersonalInformation from "../Pages/App/AccountSettings/PersonalInformation";
import OrderHistory from "../Pages/Application/Ecommerce/OrderHistory/OrderHistory";
import AddProduct from "../Pages/Application/Ecommerce/AddProduct/AddProduct";
import KnowledgeLesson from "../Pages/Application/KnowledgeLesson/KnowledgeLesson";
import KnowledgeQuizTest from "../Pages/Application/KnowledgeQuizTest/KnowledgeQuizTest";
import BrandSupportKnowledgeTab from "../Pages/Tools/BrandSupportTools/BrandSupportKnowledgeTab/BrandSupportKnowledgeTab";
import MyPost from "../Pages/Application/MyPost/MyPost";
import CreatePost from "../Pages/App/Post/CreatePost/CreatePost";
import ManagePost from "../Pages/Tools/BrandSupportTools/ManagePost/ManagePost";
import QuizzGame from "../Component/QuizzAnimation/QuizGame";
import TechersquizScreen from "../Pages/Tools/BrandSupportTools/TechersquizScreen/TechersquizScreen";
import FequentlyQuestion from "../Pages/Tools/BrandSupportTools/FequentlyQuestion/FequentlyQuestion";
import AddnewFequentlyQuestion from "../Pages/Tools/BrandSupportTools/FequentlyQuestion/AddnewFequentlyQuestion";
import FaqPages from "../Pages/App/MyMediaCenter/Faq/Faq";

export const authRoutes = [ 
  {
    path: `${process.env.PUBLIC_URL}/account-settings/personal-information`,
    Component: <PersonalInformation />,
  },
  //Manage Store
  // {
  //   path: `${process.env.PUBLIC_URL}/tools/manage-store/product-and-service-list`,
  //   Component: <ProductList />,
  // },
  //Tools
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support/invoice-1`,
    Component: <InvoiceOne />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support/invoice-2`,
    Component: <InvoiceTwo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support/invoice-3`,
    Component: <InvoiceThree />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support/invoice-4`,
    Component: <InvoiceFour />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support/invoice-5`,
    Component: <InvoiceFive />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support/invoice-6`,
    Component: <InvoiceSix />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/quiz-and-survey-system`,
    Component: <LearningList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/quizzes-and-surveys-list/view`,
    Component: <DetailsCourse />,
  },

  // SupportTicket
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/support-ticket-system`,
    Component: <SupportTicket />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list`,
    Component: <ProjectList />,
  },
  {
    path: `${process.env.PUBLIC_URL}tools/brand-support-tools/project-list/new-project`,
    Component: <ProjectCreate />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list/task-list`,
    Component: <Tasks />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/project-list/to-do-list`,
    Component: <Todo />,
  },
  //  Atttendance system
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/activities-and-attendance/add-new-activity`,
    Component: <AttendanceSystem />,
  },
  //Manage Online Store link
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/schedules/add-new`,
    Component: <AddSchedule />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/branch-public-contacts/add-new`,
    Component: <AddBranchPublicContact />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/branch-public-contacts`,
    Component: <BranchPublicContact />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/add-new`,
    Component: <AddManageBranch />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches`,
    Component: <ManageBranches />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/schedules/add-new`,
    Component: <AddSchedule />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/schedules`,
    Component: <Schedules />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-my-external-links`,
    Component: <ManageExternalLink />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-posts`,
    Component: <ManagePost />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/frequently-asked-question-system`,
    Component: <FequentlyQuestion />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/frequently-asked-question-system/add-new`,
    Component: <AddnewFequentlyQuestion />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/quizzes-and-surveys`,
    Component: <KnowledgeSystem />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-my-external-links/add-new`,
    Component: <AddManageMyExternalLink />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-my-photo-contests`,
    Component: <ManageMyPhotoContests />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-my-photo-contests/add-new`,
    Component: <AddManageMyPhotoContest />,
  }, 
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/lessons`,
    Component: <StudyMaterialLessionPage />,
  }, 
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/quiz-materials`,
    Component: <QuizMaterials />,
  },
  
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/questions`,
    Component: <Questions />,
  }, 
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/quizzes-and-surveys`,
    Component: <SurveyQuizes />,
  }, 
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/special-parameters`,
    Component: <SpecialParameters />,
  },
  //ready templates
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support/ready-templates`,
    Component: <ReadyTemplates />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-contents/manage-my-albums/add-new`,
    Component: <ManageMyAlbumsAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/addon-categories/add-new`,
    Component: <AddAddonCategories />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/addon-categories`,
    Component: <AddonCategories />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-my-coupons/add-new`,
    Component: <AddCoupon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-my-coupons`,
    Component: <ManageMyCoupons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/delivery-system/delivery-fees-and-settings/add-new`,
    Component: <AddDeliveryFees />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/delivery-system/delivery-fees-Settings`, 
    Component: <DeliveryFees />,
  },
   {
     path: `${process.env.PUBLIC_URL}/tools/manage-online-store/delivery-system/delivery-workers/add-new`,
     Component: <AddDeliveryWorkers />,
   },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/delivery-system/delivery-workers`,
    Component: <DeliverySystem />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/delivery-system/delivery-workers`,
    Component: <DeliveryWorkers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-products-and-services`,
    Component: <ManageProductServices />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-contents-of-products-and-services`,
  //   Component: <ManageContentofProductServices />,
  // },
  { path: `${process.env.PUBLIC_URL}/contact`, Component: <Contact /> },

  {
    path: `${process.env.PUBLIC_URL}/tools/manage-contents/my-uploaded-contents`,
    Component: <MyUploadedContent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-contents/manage-my-collections`,
    Component: <ManageMyCollections />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-contents/manage-my-albums`,
    Component: <ManageMyAlbums />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/activities-and-attendance`,
    Component: <ManageAttendanceSystem />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-contents/trash`,
    Component: <TrashPhotos />,
  },
  // Communication
  {
    path: `${process.env.PUBLIC_URL}/communication/private-classified-chat`,
    Component: <PrivateCChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/communication/start-classified-chat`,
    Component: <AddPost />,
  },
  {
    path: `${process.env.PUBLIC_URL}/communication/message-threads-inbox`,
    Component: <LetterBox />,
  },
  {
    path: `${process.env.PUBLIC_URL}/communication/marketplace-classified-chat`,
    Component: <MarketplaceChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/communication/applications-classified-chat`,
    Component: <ApplicationChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/communication/activities-and-events-classified-chat`,
    Component: <ActivitiesChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/communication/projects-classified-chat`,
    Component: <ProjectEventChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/communication/marketplace-classified-chat`,
    Component: <MarketplaceChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/communication/contacts`,
    Component: <Contacts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/email/letterbox`,
    Component: <LetterBox />,
  },
  //My Media
  {
    path: `${process.env.PUBLIC_URL}/about`,
    Component: <About />,
  },
  {
    path: `${process.env.PUBLIC_URL}/my-media-center/calendar`,
    Component: <Calender />,
  },
  {
    path: `${process.env.PUBLIC_URL}/my-media-center/posts`,
    Component: <MyPost />,
  },
  {
    path: `${process.env.PUBLIC_URL}/online-store`,
    Component: <OnlineStore />,
  },
  {
    path: `${process.env.PUBLIC_URL}/my-coupons`,
    Component: <MyCoupons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/photos`,
    Component: <Photos />,
  },
  {
    path: `${process.env.PUBLIC_URL}/albums`,
    Component: <Albums />,
  },
  {
    path: `${process.env.PUBLIC_URL}/collections`,
    Component: <Collections />,
  },
  {
    path: `${process.env.PUBLIC_URL}/likes`,
    Component: <Likes />,
  },
  {
    path: `${process.env.PUBLIC_URL}/reviews`,
    Component: <Reviews />,
  }, 
  {
    path: `${process.env.PUBLIC_URL}/activities-meetings`,
    Component: <ActivitiesAndMeetings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/frequently-asked-questions`,
    Component: <FaqPages />,
  },
  {
    path: `${process.env.PUBLIC_URL}/quizzes-surveys`,
    Component: <QuizzesAndSurverys />,
  },
  {
    path: `${process.env.PUBLIC_URL}/followers`,
    Component: <Followers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/i-downloaded`,
    Component: <IDownloaded />,
  },
  {
    path: `${process.env.PUBLIC_URL}/external-links`,
    Component: <ExternalLinks />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pending-photos`,
    Component: <PendingPhotos />,
  },
  {
    path: `${process.env.PUBLIC_URL}/i-follow`,
    Component: <IFollow />,
  },
  {
    path: `${process.env.PUBLIC_URL}/my-photo-contests`,
    Component: <PhotoContests />,
  }, 
  {
    path: `${process.env.PUBLIC_URL}/upload`,
    Component: <Upload />,
  },
  {
    path: `${process.env.PUBLIC_URL}/confirm-password`,
    Component: <ConfirmPassword />,
  },  
  {
    path: `${process.env.PUBLIC_URL}/dashboard/my-purchases`,
    Component: <OrderHistory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/my-sales`,
    Component: <OrderHistory />,
  },
  
  {
    path: `${process.env.PUBLIC_URL}/dashboard/my-purchases`,
    Component: <OrderHistory />,
  }, 
  //Account Settings  
  {
    path: `${process.env.PUBLIC_URL}/tools/manage-online-store/manage-products-and-services/add-new`,
    Component: <AddProduct />,
  },
  { path: `${process.env.PUBLIC_URL}/knowledge/lessons`, Component: <KnowledgeLesson/> },
  { path: `${process.env.PUBLIC_URL}/knowledge/quizzes-and-tests`, Component:<KnowledgeQuizTest/>},
  { path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/knowledge/add-new`, Component: <BrandSupportKnowledgeTab /> },

  {
    path: `${process.env.PUBLIC_URL}/tools/brand-support-tools/manage-posts/add-new`,
    Component: <CreatePost />,
  },
  {
    path: `${process.env.PUBLIC_URL}/quiz-game`,
    Component: <QuizzGame />,
  },
  {
    path: `${process.env.PUBLIC_URL}/teacher-quiz`,
    Component: <TechersquizScreen />,
  }
];
