import KnowledgeContainer from "../../../Component/Application/Knowledge/KnowledgeContainer";
import MarketplaceContainer from "../../../Component/Application/Marketplace/Marketplace";

const Knowledge = () => {
  return (
    <div className="page-body"> 
      <KnowledgeContainer  /> 
    </div>
  );
};

export default Knowledge;
