import { apiSlice } from "../../apiSlice";
import {
  CHECK_USERNAME,
  CONFIRM_PASSWORD,
  FORGET_PASSWORD,
  LOGIN,
  LOGOUT,
  RESEND_2FA_CODE,
  RESET_PASSWORD,
  VERIFY_2FA,
  GET_ASSISTANT_ACCOUNT,
  SWITCH_ASSISTANT_ACCOUNT,
  CHANGE_PASSWORD
} from "../../../Api";
import { LoginFormProps } from "../../../Component/Authentication/FormSchema";
import { PasswordFormValidationProp } from "../../../Data/App/AccountSettings/Password";

export interface Types {
  data: any;
  message: string;
  success: boolean;
}

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<Types, LoginFormProps>({
      query: (data) => {
        const query = {
          url: LOGIN,
          method: "POST",
          body: data,
        };
        
        console.log(query);  // Log the query object
    
        return query;  // Make sure to return the query object
      }
    }),
    checkUsername: builder.mutation<Types, { username: string }>({
      query: (username) => ({
        url: CHECK_USERNAME,
        method: "POST",
        body: { username: username },
      }),
    }),
    logout: builder.mutation<Types, void>({
      query: () => ({
        url: LOGOUT,
        method: "POST",
      }),
    }),
    resetPassword: builder.mutation<
      Types,
      { password: string; password_confirmation : string; token: string }
    >({
      query: (data) => ({
        url: RESET_PASSWORD,
        method: "POST",
        body: data,
      }),
    }),
    forgotPassword: builder.mutation<Types, { email: string }>({
      query: (email) => ({
        url: FORGET_PASSWORD,
        method: "POST",
        body: { email: email },
      }),
    }),
    confirmPassword: builder.mutation<Types, string>({
      query: (password) => ({
        url: CONFIRM_PASSWORD,
        method: "POST",
        body: { password: password },
      }),
    }),
    verifyTwoFactorAuthetication : builder.mutation<Types, { code : string }>({
      query: (data) => ({
        url: VERIFY_2FA,
        method: "POST",
        body: data,
      }),
    }),
    resendTwoFactorAutheticationCode : builder.mutation<Types, void>({
      query: () => ({
        url: RESEND_2FA_CODE,
        method: "POST",
      }),
    }),
    getAssistantAccount : builder.mutation<Types, void>({
      query: () => ({
        url: GET_ASSISTANT_ACCOUNT,
        method: "GET",
      }),
    }),
    switchAssitantAccount : builder.mutation<Types, { account_id : number }>({
      query: (data) => ({
        url: SWITCH_ASSISTANT_ACCOUNT,
        method: "POST",
        body: data,
      }),
    }),
    changePassword : builder.mutation<Types, PasswordFormValidationProp>({
      query: (data) => ({
        url: CHANGE_PASSWORD,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useCheckUsernameMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useConfirmPasswordMutation,
  useVerifyTwoFactorAutheticationMutation,
  useResendTwoFactorAutheticationCodeMutation,
  useGetAssistantAccountMutation,
  useSwitchAssitantAccountMutation,
  useChangePasswordMutation
} = authApiSlice;
