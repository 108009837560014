import { apiSlice } from "../../apiSlice";
import { META_TAGS_LIST } from "../../../Api";
interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const tagsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMetaTagsData: builder.mutation<SuccessDataProps, void>({
      query: () => ({
        url: META_TAGS_LIST,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetMetaTagsDataMutation } = tagsApiSlice;