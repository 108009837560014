import { useEffect, useRef } from "react";
import UserHeader from "../../../Component/App/MyMediaCenter/About/UserHeader";
import CalenderContainer from "../../../Component/Application/Calender/Calender";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { MyMediaCenter } from "../../../utils/Constant";
import PostsubMenu from "../../../Component/App/PostSubMenu/PostsubMenu";
import ArticlesBlogsContainer from "../../../Component/Application/ArticlesBlogs/ArticlesBlogsContainer";

const MyPost = () => {
    useEffect(() => {
        var scroll_top = window.innerWidth > 415 ? 360 : 230;
        window.scrollTo({ top: scroll_top, behavior: "smooth" });
    });
    return (
        <div className="page-body">
            <Breadcrumbs mainTitle={`My Posts`} parent={MyMediaCenter} />
            <UserHeader />
      <div className="mt-3 px-1">
            <ArticlesBlogsContainer postFilter={true}/>
      </div>
        </div>
    );
};

export default MyPost;
