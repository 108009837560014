import { useEffect, useState } from "react";
import CommonModal from "../../Ui-Kits/Modal/Common/CommonModal";
import { Btn, Image, LI, P, UL } from "../../../AbstractElements";
import { dynamicImage } from "../../../Service";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import { setCookie, getCookie } from "../../../Data/Ui-Kits/HelperClasses/HelperClassesData";
function Popup() {
  const [isOpen, setIsOpen] = useState(true);
  const [privacyCookies, setPrivacyCookies] = useState('')
  
  const popupToggle = () => { 
    setIsOpen(!isOpen);
  };

  const submitPopup = () => {
    setCookie('privacyCookies', true);
    setIsOpen(!isOpen);
  }

  const { websiteSettingsData } : any = useAppSelector((state) => state.common);
  
  useEffect(() => {
    const cookiesName = getCookie('privacyCookies');
    setPrivacyCookies(cookiesName);
  },[privacyCookies]);

  return (
    <>
      {websiteSettingsData && websiteSettingsData.banner_cookies === 1 &&  privacyCookies === '' &&  (
        <CommonModal centered isOpen={isOpen} toggle={popupToggle}>
          <div className="modal-toggle-wrapper">
            <UL className="modal-img">
              <LI className="text-center">
                <Image
                  src={dynamicImage(`cookies/cookies.png`)}
                  alt="cookies"
                />
              </LI>
            </UL>
            <P className="text-center">
              This site uses cookies, by continuing to use the service, you
              accept our use of cookies
            </P>
            <Btn
              color="primary"
              className="d-flex m-auto"
              onClick={submitPopup}
            >
              {"Got It!"}
            </Btn>
          </div>
        </CommonModal>
      )}
    </>
  );
}

export default Popup;
