import { Table } from "reactstrap";
import React from "react";

const StaticPlanData = (staticData: any, allregistrationplans: any) => {
  const planData = staticData.staticData.data.static;
  // console.log(JSON.stringify(staticData.staticData))
  return (
    <div className="border-bottom-0 auto text-nowrap px-2  pb-5" style={{paddingTop:"31px !impo"}}>
      <Table bordered hover>
        <tbody>
          <tr>
            <td >
              <b>{planData.plan_name}</b>
            </td>
          </tr>
          <tr  className="border-0">
            <td rowSpan={planData.pricings.length} style={{ height: "225px", borderWidth:'1px',
                  borderColor:'rgba(153, 153, 153, 0.3)' }}>
              {Object.keys(planData.pricings).map((item, index) => (
                <p key={index}>{/*planData.pricings[item]*/}</p>
              ))}
            </td>
          </tr>
          {planData.details.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <tr className="border-0">
                <td  style={{
                  WebkitLineClamp:'2',
                  textWrap:'wrap',
                  minHeight:'80px',
                  display:'inline-block',
                  width:'100%',
                  height:'100%',
                  borderWidth:'1px',
                  borderColor:'rgba(153, 153, 153, 0.3)'
                      // -webkit-line-clamp: 2;
                      // text-wrap: wrap;
                      // min-height: 80px;
                      // display: inline-block;
                      // width: 100%;
                      // height: 100%;
                      // border-color: rgba(153, 153, 153, 0.3);
                      // border-width: 0 var(--bs-border-width);
                }}>
                  <b>{item.heading}</b>
                </td>
              </tr>
              <tr className="border-0">
                <td
                  rowSpan={item.sub_headings.length}
                  className=""
                  style={{
                    WebkitLineClamp:'2',
                  // textWrap:'wrap',
                  minHeight:'80px',
                  display:'inline-block',
                  width:'100%',
                  height:'100%',
                  borderWidth:'1px',
                  borderColor:'rgba(153, 153, 153, 0.3)'
                  }}
                >
                  {Object.keys(item.sub_headings).map((sub_item, i_index) => (
                    <p key={i_index}>{item.sub_headings[sub_item]}</p>
                  ))}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default StaticPlanData;
