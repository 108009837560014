import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LayoutRoutes from "./LayoutRoutes";
import { authRoutes } from "./AuthRoutes";
import Login from "../Component/Authentication/Login";
import SignUp from "../Component/SignUp/SignUp";
import Error400 from "../Pages/OtherPages/Error/Error400/Error400";
import ScrollToTop from "../Component/ScrollToTop.js/ScrollToTop";
import Layout from "../Layout/Layout";
import ForgotPassword from "../Component/Authentication/ForgotPassword";
import ResetPassword from "../Component/Authentication/ResetPassword";
import SecureRoute from "./SecureRoutes";
import { secureAuthRoutes } from "./SecureAuthRoutes";
import TwoFactorConfirmation from "../Component/Authentication/TwoFactorConfirmation";
import EmailVerfication from "../Component/Authentication/EmailVerfication";
import AccountList from "../Pages/Application/AccountList/AccountList";
import Tags from "../Component/Meta/Tags";
import { useAppSelector } from "../ReduxToolkit/Hooks";
import FaqPages from "../Pages/App/MyMediaCenter/Faq/Faq";

export const getUrlAsKeyName = (url: any) => {
  if (url !== undefined) {
    let filterUrl = url.split("/").pop();
    filterUrl = filterUrl.replace(/-/g, "_");
    return filterUrl;
  }
};

// this function get the routes are off & developer so user can not access and its data get using website seting data.
export const getFilterUrl = (routes: any, websiteSettingsData: any) => {
  return routes.filter((item: any) => { 
    if (
      websiteSettingsData.frontend_modules[getUrlAsKeyName(item.path)] !==
      undefined
    ) {
      return (
        websiteSettingsData.frontend_modules[getUrlAsKeyName(item.path)] ===
          "off" ||
        websiteSettingsData.frontend_modules[getUrlAsKeyName(item.path)] ===
          "developer"
      );
    }
  });
};

// this function get the routes are off so user can not access and its data get using website seting data.
export const getDeveloperFilterUrl = (
  routes: any,
  websiteSettingsData: any
) => {
  return routes.filter((item: any) => {
    if (
      websiteSettingsData.frontend_modules[getUrlAsKeyName(item.path)] !==
      undefined
    ) {
      return (
        websiteSettingsData.frontend_modules[getUrlAsKeyName(item.path)] ===
        "off"
      );
    }
  });
};


const RouterData = () => {
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);
  const { userInfo }: any = useAppSelector((state) => state.auth);
  const { authUser }: any = (userInfo && userInfo) || {};

  // This url get for all user except developer role.
  const allAuthUrl = getFilterUrl(authRoutes, websiteSettingsData);
  const allSecureUrl = getFilterUrl(secureAuthRoutes, websiteSettingsData);

  // This url get for all user with developer role.
  const allDeveloperAuthUrl = getFilterUrl(authRoutes, websiteSettingsData);
  const allDeveloperSecureUrl = getFilterUrl(
    secureAuthRoutes,
    websiteSettingsData
  );

  // enable auth routes for all user and all user with role developer base on conditions.
  let enableAuthRoutes =
    authUser !== undefined && authUser.roles.includes("Developer")
      ? authRoutes.filter((x) => !allDeveloperAuthUrl.includes(x))
      : authRoutes.filter((x) => !allAuthUrl.includes(x));
  
  // enable secure routes for all user and all user with role developer base on conditions.
  let enableSecureAuthRoutes =
    authUser !== undefined && authUser.roles.includes("Developer")
      ? secureAuthRoutes.filter((x) => !allDeveloperSecureUrl.includes(x))
      : secureAuthRoutes.filter((x) => !allSecureUrl.includes(x)); 

  return (
    <BrowserRouter basename={"/"}>
      <ScrollToTop />
      <Tags />
      <Routes>
        <Route
          path={`${process.env.PUBLIC_URL}` || "/"}
          element={
            <Navigate to={`${process.env.PUBLIC_URL}/see-all-contents`} />
          }
        />
        <Route path={`/*`} element={<LayoutRoutes />} />
        <Route path="*" element={<Error400 />} />
        
        <Route path={"/"} element={<PrivateRoute />}>
          {enableAuthRoutes.map(({ path, Component }, i) => (
            <Route element={<Layout />} key={i}>
              <Route path={path} element={Component} key={i} />
            </Route>
          ))}
        </Route>
        <Route path={"/"} element={<SecureRoute />}>
          {enableSecureAuthRoutes.map(({ path, Component }, i) => (
            <Route element={<Layout />} key={i}>
              <Route path={path} element={Component} key={i} />
            </Route>
          ))}
        </Route>
        <Route
          path={`${process.env.PUBLIC_URL}/login/:privateFlag?`}
          element={<Login />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/register-account/:plan_id?/:countryName?/:registrationTypeIds1?`}
          element={<SignUp />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/forgot-password`}
          element={<ForgotPassword />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/password/reset/:token?`}
          element={<ResetPassword />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/2fa`}
          element={<TwoFactorConfirmation />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/verify-email`}
          element={<EmailVerfication />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/account-list`}
          element={<AccountList />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterData;
