import MasonryImageDescContainer from "../../../../Component/Miscellaneous/Gallery/MasonaryImageDesc/MasonaryImageDesc"

const MasonryImageDesc = () => {
  return (
    <div className='page-body'>
      <MasonryImageDescContainer />
    </div>
  )
}

export default MasonryImageDesc