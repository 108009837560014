import React from 'react'
import DragCalendar from '../../../Application/Calender/DragCalendar/DragCalendar'
import { Row } from 'reactstrap'

const BookAppointment = () => {
  return (
    <div className='px-4 py-2'>
        <Row>
            <DragCalendar />
        </Row>
    </div>
  )
}

export default BookAppointment