import { useState } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ManageBranches, ManagesBranchesAdd } from "../../../../utils/Constant"; 
import { Formik } from "formik"; 
import {
  ManageBranchFormValidationProp,
  manageBranchFormInitialValue,
  manageBranchFormValidationSchema,
} from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import AddManageBranchesForm from "../../../../Component/Tools/ManageOnlineStore/ManageBranches/AddManageBranchesForm";
import { BranchsAction } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import { Link } from "react-router-dom";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import BranchSubMenu from "./BranchSubMenu/BranchSubMenu";

const AddManageBranch = () => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleSubmit = (values: ManageBranchFormValidationProp) => {
    console.log(values);
    setSubmitError(false);
  };

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={`${ManagesBranchesAdd} (Manage Online Store)`} parent={ManageBranches} />
      <Container fluid>  
            <CardHeader className='pb-0 pt-0  m'>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
      <Container  fluid> 
      <div className='ms-1 my-2'>
      <BranchSubMenu sliced='-4'/> 
                  </div>  
                  <Card >
                    <CardBody className="p-2">
                    <Formik
          initialValues={manageBranchFormInitialValue}
          onSubmit={handleSubmit}
          validationSchema={manageBranchFormValidationSchema}
        >
          {({ errors, values,setFieldValue }) => (
            <AddManageBranchesForm
              submitErrors={submitErrors}
              setSubmitError={setSubmitError}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
          )}
        </Formik>
                    </CardBody>
                  </Card>
        
      </Container>
    </div>
  );
};

export default AddManageBranch;
