import { useState } from "react";
import UserBanner from "../../../Component/App/User/UserBanner";
import { Col, Container, Row } from "reactstrap";
import { DeleteMyAccount, Home } from "../../../utils/Constant";
import { Btn } from "../../../AbstractElements";
import { Formik } from "formik";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  AddressValidationProp,
  addressFormInitialValue,
  addressInformationFormSchema,
} from "../../../Data/App/AccountSettings/Addresses";
import ShippingAddressForm from "./ShippingAddressForm";
import { useSaveUserAddressMutation } from "../../../ReduxToolkit/Reducers/AccountSettings/addressApiSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ServerErrorMessages from "../../../Component/CommonMessage/ServerErrorMessages";
import Loading from "../../../Component/Loading/Loading";

const ShippingAddressAddNew = () => {
  const [submitErrors, setSubmitErrors] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<any>();

  const [saveUserAddressMutation, { isLoading }] = useSaveUserAddressMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: AddressValidationProp) => {
    setSubmitErrors(false);
    setErrorMessages('');
    try {
      const response = await saveUserAddressMutation(values).unwrap();
      const { success, message } = response;
      if (success === true) {
        toast.success(message);
        navigate("/account-settings/shipping-addresses");
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message);
      setErrorMessages(error?.data?.message);
    }
  };

  if(isLoading) return <Loading/>;
  
  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={`Shipping Addresses`} parent={Home} />
      <UserBanner />
      <Container fluid> 
        <Row>
          <Col xl="12">
            <Formik
              initialValues={addressFormInitialValue}
              validationSchema={addressInformationFormSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, values, setFieldValue }) => (
                <ShippingAddressForm
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  submitErrors={submitErrors}
                  setSubmitErrors={setSubmitErrors}
                />
              )}
            </Formik>
            <ServerErrorMessages errorMessages={errorMessages} />
            <hr />
            <Col>
              <Btn color="primary" type="button">
                {DeleteMyAccount}
              </Btn>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ShippingAddressAddNew;
