import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DeliveryFeeAction } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import {
    Col,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import {
    branchPublicContactFormInitialValue,
    branchPublicContactFormValidationSchema,
} from "../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";

import { ErrorMessage, Formik } from "formik";
import City from "../../../../Component/CommonInput/City";
import Branches from "../../../../Component/CommonInput/Branches";
import CommonSwitchSpan from "../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import { H5 } from "../../../../AbstractElements";
import State from "../../../../Component/CommonInput/State";
import Country from "../../../../Component/CommonInput/Country";

const DeliverySubMenu = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    
    const isActive = (path) => {
        return (
            path && currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`)
        );
    };
    
    return (
        <>
            <div className="d-flex">
                {DeliveryFeeAction.map((item, index) => {
                    // Determine the active class only if the item has a path
                    const activeClass =
                        item.path && isActive(item.path) ? "" : "";
                    const isChecked = item.path && isActive(item.path);

                    return (
                        <div
                            key={index}
                            className={` p-2 delivery_input ${activeClass}`}>
                            <Link
                                to={`${process.env.PUBLIC_URL}/${item.path}`}
                                className="text-decoration-none text-reset d-flex align-items-center">
                                {/* Radio button */}
                                <div className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
              isChecked === true ? "transform-scale" : ""
            }`}>
                <Label>

                                <Input
                                    type="checkbox"
                                    name="deliveryFee" 
                                     className="form-check-input me-2"
                                    value={item.path}
                                    checked={isChecked} // Set checked state based on whether the path is active
                                    readOnly // Make the radio button read-only to prevent user interaction
                                />
                                 <span className="ms-2">{item.actionName}</span>
                                </Label>
                                </div> 
                                {/* Item text */}
                               
                            </Link>
                        </div>
                    );
                })}
            </div>
            
        </>
    );
};

export default DeliverySubMenu;
