import {
    Btn,
    H3,
    H5,
    H6,
    LI,
    UL,
} from "../../../../AbstractElements";
import CommonModal from "../../../../Component/Ui-Kits/Modal/Common/CommonModal";
import { Cart } from "../../../../utils/Constant";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { ChevronDown, ChevronUp, X } from "react-feather";
import { Card, CardBody, Collapse, Table } from "reactstrap";

const HeaderCart = () => {
    const { mobileView } = useAppSelector((state) => state.layout);
    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // New state for edit mode
    const openModalToggle = () => setIsOpen(!isOpen);
    const [cards, setCards] = useState([
        {
            title: "Burger King",
            LogoThumbnail:
                "https://stratfordshopping.co.uk/newsite23/wp-content/uploads/2023/05/BurgerKing.jpg",
            isOpen: true,
            items: [
                {
                    id: 1,
                    thumbnail: "",
                    productName: "Chicken & Fish",
                    priceUnit: 8.49,
                    quantity: 2,
                },
                {
                    id: 2,
                    thumbnail: "",
                    productName: "Chicken & Fish",
                    priceUnit: 8.49,
                    quantity: 2,
                },
            ],
        },
        {
            title: "Pizza Hut",
            isOpen: true,
            LogoThumbnail:
                "https://play-lh.googleusercontent.com/0Tg1WjgxG7heMONj-WOwarkvBaUfQ4SUV--JetZgDw-vRUxAZfKB6pMd9fzeREc_-Q0K",
            items: [
                {
                    id: 3,
                    thumbnail: "",
                    productName: "Pepperoni Pizza",
                    priceUnit: 12.99,
                    quantity: 1,
                },
            ],
        },
    ]);

    const toggleCollapse = (index) => {
        const newCards = cards.map((card, i) => {
            if (i === index) {
                return { ...card, isOpen: !card.isOpen };
            }
            return card;
        });
        setCards(newCards);
    };

    const calculateSubtotal = (items) => {
        return items.reduce((acc, item) => acc + item.priceUnit * item.quantity, 0).toFixed(2);
    };

    const calculateTotalItems = () => {
        return cards.reduce((acc, card) => acc + card.items.length, 0);
    };

    const calculateGrandTotal = () => {
        return cards.reduce((acc, card) => acc + parseFloat(calculateSubtotal(card.items)), 0).toFixed(2);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    const handleQuantityChange = (cardIndex, itemIndex, value) => {
        const updatedCards = [...cards];
        const updatedItems = [...updatedCards[cardIndex].items];
        updatedItems[itemIndex].quantity = value ? parseInt(value) : 0;
        updatedCards[cardIndex].items = updatedItems;
        setCards(updatedCards);
    };

    const toggleEditMode = () => {
        setIsEditing((prevState) => !prevState); // Toggle the edit mode
    };

    return (
        <LI
            className={`cart-nav onhover-dropdown ${isOpen ? "actvie-righticon" : ""}`}
            onClick={() => setIsOpen(!isOpen)}>
            <div className="cart-box">
                <FontAwesomeIcon icon={fas.faCartShopping} />
            </div>
            <CommonModal
                isOpen={isOpen}
                toggle={openModalToggle}
                modalBodyClassName="  modal-custom"
                largesize="modal-custom"
                sizeTitle="Cart"
                AdShow={true}
                size={mobileView ? "md" : "lg"}>
                <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                    <div className="cart-dropdown modal-body px-3">
                        <UL className="mb-3">
                            <LI className="total  ">
                                <H5 className="mb-0 px-3 py-2"  style={{backgroundColor:"#b0b0b2cc"}}>
                                  <span className="  ">Subtotal:</span>   <span className="  fw-bold">${calculateGrandTotal()}</span>
                                </H5>
                            </LI>
                            <LI className="total  border-bottom">
                                <H5 className="mb-0 px-3 py-2"  style={{backgroundColor:"#b0b0b2cc"}}>
                                    Total Number of Items: <span className=" fw-bold">{calculateTotalItems()}</span>
                                </H5>
                            </LI>
                        </UL>
                        <UL className="simple-list">
                            {cards.map((card, index) => (
                                <Card key={index} className="mb-2">
                                    <div onClick={() => toggleCollapse(index)} className="d-flex gap-2 align-items-center justify-content-between">
                                        <div className="d-flex gap-2 align-items-center">
                                            <img src={card.LogoThumbnail} alt="" className="rounded-5" />
                                            <h5>{card.title} ({`items number: ${card.items.length}`})</h5>
                                        </div>
                                        {card.isOpen ? <ChevronUp /> : <ChevronDown />}
                                    </div>
                                    <Collapse isOpen={card.isOpen}>
                                        <CardBody className="py-2">
                                            <Table responsive bordered>
                                                <thead>
                                                    <tr>
                                                    <th style={{ padding: '8px', border: '1px solid #ddd' }}>#</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Image</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Product Name</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Price</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Price Unit</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {card.items.map((item, idx) => (
                                                        <tr key={item.id}>
                                                            <th scope="row">{idx + 1}</th>
                                                            <td>{item.thumbnail}</td>
                                                            <td>{item.productName}</td>
                                                            <td>${(item.priceUnit * item.quantity).toFixed(2)}</td>
                                                            <td>${item.priceUnit.toFixed(2)}</td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    value={item.quantity}
                                                                    onChange={(e) =>
                                                                        handleQuantityChange(index, idx, e.target.value)
                                                                    }
                                                                    min="1"
                                                                    className="form-control"
                                                                    style={{ width: "60px" }}
                                                                    disabled={!isEditing} // Disable if not in edit mode
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <div className="mb-3 px-3 mt-2">
                                                <h5 className="mb-0 text-right">
                                                    Total: <span className="f-right">${calculateSubtotal(card.items)}</span>
                                                </h5>
                                            </div>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            ))}

                        </UL>
                    </div>
                    <Card className="px-3 position-sticky bottom-0 bg-white mt-2 mb-0">
                        <UL>
                        <LI className="py-3">
                                <button
                                    onClick={toggleEditMode}
                                    className="px-3 rounded-2 py-2  text-dark border-0  " style={{backgroundColor:"#b0b0b2cc"}}>
                                    {isEditing ? "Save Changes" : "Edit Quantity"}
                                </button>
                                <Link to="/ecommerce/checkout" onClick={handleLinkClick} className="view-checkout btn btn-primary f-right">
                                    Check Out
                                </Link>
                            </LI>
                        </UL>
                    </Card>
                            
                </div>
            </CommonModal>
        </LI>
    );
};

export default HeaderCart;
