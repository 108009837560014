import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import { Field } from "formik";
import CommonModal from "../../../Ui-Kits/Modal/Common/CommonModal";
import GroupMain from "../../../Communication/GroupMain";
import TagsInput from "../../../CommonInput/TagsInput";
import ScheduleType from "../../../CommonInput/ScheduleType";
import { useState } from "react";
import Groups from "../../../CommonInput/Groups";
import { H3 } from "../../../../AbstractElements";

function Sharing(props: any) {
  const { values, setFieldValue,heading,mediacenter,comanagement,whocanView,inviteGroups,inviteUsername }: any = props;
  const [openFromModal, setOpenFromModal] = useState(false);
  const [reloadGroupsData, setReloadGroupsData] = useState(0);

  const reloadGroups = () => {
    setReloadGroupsData((prev) => prev + 1);
    console.log(reloadGroupsData);
  }

  const openFromModalToggle = () => setOpenFromModal(!openFromModal);
  return (
    <>
      <Row>
                        <Col className="mb-3">
                            <h4> Sharing and Managing </h4>
                        </Col>
                    </Row>
                   {whocanView && <Row>
                        <Col sm="auto">
                            <FormGroup>
                                <div>
                                    <Label check>
                                        Who can view and use this content
                                    </Label>
                                </div>
                                <div className="d-flex flex-wrap gap-2 align-items-center">
                                    <div className="m-checkbox-inline px-2">
                                        <Label for={`edo-ani-a`} check>
                                            <Field
                                                className="radio_animated"
                                                id={`edo-ani-a`}
                                                type="radio"
                                                name="display_on_media_center"
                                                value={1}
                                                checked={
                                                    values?.display_on_media_center ==
                                                    "1"
                                                }
                                            />
                                            {` Anyone`}
                                        </Label>
                                    </div>
                                    <div className="m-checkbox-inline px-2 mt-1">
                                        <Label for={`edo-ani-b`} check>
                                            <Field
                                                className="radio_animated"
                                                id={`edo-ani-b`}
                                                type="radio"
                                                name="display_on_media_center"
                                                value={2}
                                                checked={
                                                    values?.display_on_media_center ==
                                                    "2"
                                                }
                                            />
                                            {`Only Followers and Invited Users`}
                                        </Label>
                                    </div>
                                    <div className="m-checkbox-inline px-2 mt-1">
                                        <Label for={`edo-ani-c`} check>
                                            <Field
                                                className="radio_animated"
                                                id={`edo-ani-c`}
                                                type="radio"
                                                name="display_on_media_center"
                                                value={3}
                                                checked={
                                                    values.display_on_media_center ==
                                                    "3"
                                                }
                                            />
                                            {`Only Invited Users`}
                                        </Label>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>}
                   {inviteGroups && <Row>
                        <Col sm="12">
                            <FormGroup>
                                <FormGroup>
                                    <Groups
                                        title="Invite Groups"
                                        className={""}
                                        isRequired={false}
                                        name="groups"
                                        isMulti={true}
                                        reloadGroupsData={reloadGroupsData}
                                    />
                                </FormGroup>
                                <span>Create a New Group</span>
                                <span
                                    className="mt-1 btn btn-primary px-3 mx-2"
                                    onClick={openFromModalToggle}>
                                    <i className="icofont icofont-users"></i>
                                </span>
                                <CommonModal
                                    isOpen={openFromModal}
                                    toggle={openFromModalToggle}
                                    sizeTitle="Add a Group"
                                    size="lg">
                                    <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                                        <GroupMain
                                            reloadGroups={reloadGroups}
                                            openFromModalToggle={
                                                openFromModalToggle
                                            }
                                        />
                                    </div>
                                </CommonModal>
                            </FormGroup>
                        </Col>
                    </Row>}
                    {inviteUsername && <Row>
                        <Col sm="12">
                            <FormGroup>
                                <div className=" col-form-Label">
                                    <FormGroup className=" ">
                                        <Label check className="mb-1">
                                            Invite Usernames :
                                        </Label>
                                        <TagsInput
                                            name="individual_users"
                                            username={true}
                                            placeholder="Enter Invite Usernames"
                                            className={`form-control pt-0`}
                                            setFieldValue={setFieldValue}
                                        />
                                    </FormGroup>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>}
                   { comanagement && <Row>
                <Col sm="12">
                    <div className="">
                        <H3>
                            {`Co-Management`}{" "}
                            <span className="font-danger">*</span>{" "}
                        </H3>
                        <small>
                            A co-manager can view and manage a copy content from
                            his account without accessing your account.
                        </small>

                        <Row className="mt-3">
                            <Col sm="12">
                            <div className="mb-3">
                                <Label className="d-block">
                                    Allow Co-Manager
                                </Label>
                                <div className="form-check form-check-inline radio radio-primary   mx-2">
                                    <Field
                                        id={`comanage_yes`}
                                        type="radio"
                                        name={`comanage`}
                                        value={1}
                                        checked={
                                            values.comanage === 1 ||
                                            values.comanage === "1"
                                        }
                                    />
                                    <Label
                                        className="mb-0"
                                        for={`comanage_yes`}
                                        check>
                                        Yes
                                    </Label>
                                </div>
                                <div className="form-check form-check-inline radio radio-primary   mx-2">
                                    <Field
                                        id={`comanage_no`}
                                        type="radio"
                                        value={0}
                                        checked={
                                            values.comanage === 0 ||
                                            values.comanage === "0"
                                        }
                                        name={`comanage`} 
                                    />
                                    <Label
                                        className="mb-0"
                                        for={`comanage_no`}
                                        check>
                                        No
                                    </Label>
                                </div>
                            </div>
                            </Col>
                            <Col sm="12" className="mt-2">
                                <FormGroup>
                                    <div className=" col-form-Label">
                                        <FormGroup className=" ">
                                            <Label check className="mb-1">
                                                Co-Manager Username :
                                            </Label>
                                            <Field
                                                name="comanager_users"
                                                placeholder="Enter Invite Usernames"
                                                className={`form-control pt-0`}
                                            />
                                        </FormGroup>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col sm="12" className="mb-2">
                                <Label className="d-block">
                                    Permission{" "}
                                    <span className="font-danger">*</span>{" "}
                                </Label>
                                <div className="form-check form-check-inline radio radio-primary   mx-2">
                                    <Field
                                        id={`read_yes`}
                                        type="radio"
                                        name={`permission`}
                                        value={1}
                                        checked={
                                            values.permission === 1 ||
                                            values.permission === "1"
                                        }
                                    />
                                    <Label
                                        className="mb-0"
                                        for={`read_yes`}
                                        check>
                                        Read Only
                                    </Label>
                                </div>
                                <div className="form-check form-check-inline radio radio-primary   mx-2">
                                    <Field
                                        id={`Edit`}
                                        type="radio"
                                        value={0}
                                        checked={
                                            values.permission === 0 ||
                                            values.permission === "0"
                                        }
                                        name={`permission`}
                                    />
                                    <Label className="mb-0" for={`Edit`} check>
                                        Edit
                                    </Label>
                                </div>
                            </Col>
                            <Col sm="12" className="mb-2">
                                <Label className="d-block">
                                Display a copy Co-Manager’s Media Center{" "}
                                    <span className="font-danger">*</span>{" "}
                                </Label>
                                <div className="form-check form-check-inline radio radio-primary   mx-2">
                                    <Field
                                        id={`Display_yes`}
                                        type="radio"
                                        name={`dispaymedia`}
                                        value={1}
                                        checked={
                                            values.dispaymedia === 1 ||
                                            values.dispaymedia === "1"
                                        }
                                    />
                                    <Label
                                        className="mb-0"
                                        for={`Display_yes`}
                                        check>
                                        Read Only
                                    </Label>
                                </div>
                                <div className="form-check form-check-inline radio radio-primary   mx-2">
                                    <Field
                                        id={`Display_no`}
                                        type="radio"
                                        value={0}
                                        checked={
                                            values.dispaymedia === 0 ||
                                            values.dispaymedia === "0"
                                        }
                                        name={`dispaymedia`}
                                    />
                                    <Label className="mb-0" for={`Display_no`} check>
                                        Edit
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                    </Row>}
    </>
  );
}

export default Sharing;