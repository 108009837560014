import { Container, Row, Card, CardBody, Col, Button } from "reactstrap";
import { Btn, H3, H5, H6, Image, P } from "../../../AbstractElements";
import { dynamicImage } from "../../../Service";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import CommonModal from "../../Ui-Kits/Modal/Common/CommonModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Popup from "../../MasterPopup/Popup";
import BookAppointment from "../MyMediaCenter/Appointment/BookAppointment";
import { DeleteMyAccount } from "../../../utils/Constant";
import AddOnlineAssistant from "../AccountSettings/OnlineAssistant/AddOnlineAssistant";

export const data = { name: "Jmaes", test: "" };

export const menu = [
  {
    title: "Personal Information",
    path: "personal-information",
    icon: <FontAwesomeIcon icon={fas.faUser} className="pe-2" />,
  },
  {
    title: "Manage Resume",
    path: "manage-resume",
    icon: <FontAwesomeIcon icon={fas.faUser} className="pe-2" />,
  },
  {
    title: "Shipping Addresses",
    path: "shipping-addresses",
    icon: <FontAwesomeIcon icon={fas.faUser} className="pe-2" />,
  },
  {
    title: "Password",
    path: "password",
    icon: <FontAwesomeIcon icon={fas.faUser} className="pe-2" />,
  },
  {
    title: "My Registration Plan ",
    path: "my-registration-plan",
    icon: <FontAwesomeIcon icon={fas.faUser} className="pe-2" />,
  },
  {
    title: "Account History",
    path: "account-history",
    icon: <FontAwesomeIcon icon={fas.faUser} className="pe-2" />,
  },
];

const UserBanner = () => {
  const { mobileView } = useAppSelector((state) => state.layout);
  const [isOpen, setIsOpen] = useState(false);
  const qrFullSize = useState(mobileView ? 200 : 1024);
  const [openModal, setOpenModal] = useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const openModalToggle = () => setOpenModal(!openModal);

  const { userInfo } = useAppSelector((state) => state.auth);

  const downloadQR = () => {
    var canvas: any = document.getElementById("react-qrcode-logo");
    var url = canvas?.toDataURL("image/png");
    var link = document.createElement("a");
    link.download = "QRCode.png";
    link.href = url;
    link.click();
  };
  const location = useLocation();
  const currentPath = location.pathname;

  // Function to determine if the current path matches or starts with the link's path
  const isActive = (path: any) => {
    return currentPath.startsWith(
      `${process.env.PUBLIC_URL}/account-settings/${path}`
    );
  };

  const avtarContent = userInfo && (
    <>
      <Image
        className="rounded-circle image-radius m-r-15"
        src={userInfo.authUser.avatar}
        alt={userInfo.authUser.name}
        style={{ width: "90px", height: "90px" }}
      />
      <div className="flex-grow-1 ps-2">
        <div
          className=""
          style={{ display: "inline-flex", alignItems: "baseline" }}
        >
          <H3 className="mb-0 f-w-700">
            {userInfo.authUser.name_with_user_name}
          </H3>
          <Link className="ms-3 mb-2 btn btn-danger" to={""}>
            Delete My Account
          </Link>
        </div>
        <div>
          {mobileView ? (
            ""
          ) : (
            <>
              <span className="ms-1 me-1 text-decoration-underline">
                <FontAwesomeIcon icon={fas.faHand} className="pe-2" />
                {`I'm Available for Work`}
              </span>
              <Link
                to={`${process.env.PUBLIC_URL}/account-settings/manage-resume`}
              >
                <span className="ms-1 me-1 text-decoration-underline">
                  <FontAwesomeIcon icon={fas.faIdBadge} className="pe-2" />
                  {`Manage Resume`}
                </span>
              </Link>
              <AddOnlineAssistant title="Add an Online Assistant" />
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <>
      <Container fluid>
        <div className="user-profile">
          <Card className="mb-0">
            <CardBody className="pb-0">
              <Row>
                <Col md={9}>
                  <div className="new-users-social mb-3">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ps-2">
                        <div
                          className={
                            mobileView ? "" : "d-flex align-items-center"
                          }
                        >
                          <div
                            className={
                              mobileView ? "" : "d-flex align-items-center"
                            }
                          >
                            {avtarContent}
                          </div>
                          <div
                            className={
                              mobileView ? "" : "d-flex flex-column ps-2"
                            }
                          >
                            {mobileView ? (
                              <ul>
                                <li></li>
                                <li>
                                  <span className="ms-1 me-1">
                                    <FontAwesomeIcon
                                      icon={fas.faHand}
                                      className="pe-2"
                                    />
                                    {`I'm Available for Work`}
                                  </span>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/account-settings/manage-resume`}
                                  >
                                    <span className="ms-1 me-1">
                                      <FontAwesomeIcon
                                        icon={fas.faIdBadge}
                                        className="pe-2"
                                      />
                                      {`Manage Resume`}
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <span className="pull-right mt-0">
                        {/* <FeatherIcons iconName='MoreVertical'/> */}
                      </span>
                      <Popup
                        CommonFooter={false}
                        title={`Book an Appointment`}
                        openModalToggle={() =>
                          setOpenCalendarModal(!openCalendarModal)
                        }
                        isOpen={openCalendarModal}
                        size={`xl`}
                        backdrop={true}
                        isClose={false}
                      >
                        <BookAppointment />
                      </Popup>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div
                    className={` ${
                      mobileView ? "w-100 text-center" : "pull-right"
                    }`}
                    style={{
                      marginTop: mobileView ? "" : "0%",
                      height: "100",
                      width: "100",
                      zIndex: 20,
                    }}
                  >
                    <span
                      className="mt-0 mx-4"
                      onClick={openModalToggle}
                      style={{ zIndex: 5 }}
                    >
                      <QRCode
                        logoImage={dynamicImage("logo/qr-logo.png")}
                        logoWidth={40}
                        logoHeight={40}
                        value="http://1webbb.com/qr-code-popup"
                        size={95}
                        bgColor={"#ffffff"}
                        style={{
                          height: "auto",
                          maxWidth: "120px",
                          width: "100%",
                          backgroundColor: "#fff",
                          border: "2px solid black",
                          zIndex: 2,
                        }}
                      />
                    </span>
                    <CommonModal
                      isOpen={openModal}
                      toggle={openModalToggle}
                      modalBodyClassName="p-0"
                      size="lg"
                    >
                      <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                        <div className="modal-header">
                          {mobileView ? (
                            <>
                              <Row>
                                <Col className="col-9">
                                  <Image
                                    className="image-radius m-r-15 align-top"
                                    src={dynamicImage("logo/logo-1.png")}
                                    alt="user121"
                                    style={{ width: "89px" }}
                                  />
                                </Col>
                                <Col className="col-3">
                                  <IoClose
                                    size="2rem"
                                    className="float-end bg-danger p-1 rounded-circle"
                                    onClick={openModalToggle}
                                  />
                                </Col>
                                <Col className="col-12 py-3">
                                  <H5 className="w-100 text-center border-0">
                                    {`My Media Center - 1webbb.com | 2 B with 1 Web`}
                                  </H5>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <div className="d-flex w-100">
                                <Image
                                  className="image-radius m-r-15 align-top"
                                  src={dynamicImage("logo/logo-1.png")}
                                  alt="user121"
                                  style={{ width: "89px" }}
                                />
                                <H3 className="w-100 text-center border-0">
                                  {`My Media Center - 1webbb.com | 2 B with 1 Web`}
                                </H3>
                                <IoClose
                                  size="2rem"
                                  className="float-end"
                                  onClick={openModalToggle}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="modal-body p-2 justify-content-center">
                          <Row>
                            <Col md="12">
                              <div className="d-flex px-2 align-items-center align-items-sm-center">
                                <Image
                                  className="image-radius m-r-15 align-top"
                                  src={dynamicImage("user/user.png")}
                                  alt="user121"
                                  style={{ width: "49px" }}
                                />
                                <H3 className="d-flex ">Cfotos admin</H3>
                              </div>
                            </Col>
                            <Col md="12">
                              <H6 className="w-100  border-0 pt-2 text-warning">
                                {" "}
                                {` (https://1webbb.com/cfp-admin-images)`}
                              </H6>
                              <hr />
                            </Col>
                            <Col md="12" className="d-flex justify-content-end">
                              <Btn
                                className="btn btn-primary"
                                onClick={downloadQR}
                              >
                                Download
                              </Btn>
                            </Col>
                            <Col
                              md="12"
                              className="d-flex justify-content-center"
                            >
                              <div
                                className="p-2"
                                style={{ height: "500px", width: "500px" }}
                              >
                                <QRCode
                                  logoImage={dynamicImage("logo/qr-logo.png")}
                                  logoWidth={100}
                                  logoHeight={100}
                                  value="http://1webbb.com/qr-code-popup"
                                  size={500}
                                  bgColor={"#ffffff"}
                                  style={{
                                    height: "auto",
                                    maxWidth: "100%",
                                    width: "100%",
                                    backgroundColor: "#fff",
                                    border: "2px solid black",
                                    zIndex: 2,
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CommonModal>
                    {mobileView ? <hr /> : ""}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mb-0">
            <CardBody className="p-1">
              <div className="timeline-content">
                {/* <P>{ "The only way to do something in depth is to work hard. I've always thought of the T-shirt as the Alpha of the fashion alphabet. My breakfast is very important. Everything I do is a matter of heart, body and soul." } </P> */}
                {/* <LikeContent />
                            <SocialChat data={data}/>
                            <CommentsBox /> */}
                <Row>
                  {mobileView ? (
                    <>
                      <Col md="12">
                        {isOpen ? (
                          <IoClose
                            size="2em"
                            onClick={() => setIsOpen(false)}
                            className="p-1 rounded-circle"
                            style={{ backgroundColor: "#b6c8e7" }}
                          />
                        ) : (
                          <HiOutlineMenuAlt2
                            size="2em"
                            onClick={() => setIsOpen(true)}
                            className="p-1 rounded-circle"
                            style={{ backgroundColor: "#b6c8e7" }}
                          />
                        )}
                      </Col>
                      <Col md="12">
                        {isOpen ? (
                          <>
                            {menu.map((item, index) => {
                              return (
                                <Link
                                  to={`${process.env.PUBLIC_URL}/account-settings/${item.path}`}
                                  key={index}
                                >
                                  <span className="p-1 m-1 ps-2 pe-2  border border-primary">
                                    {item.icon}
                                    {item.title}
                                  </span>
                                </Link>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    </>
                  ) : (
                    <Col md="12">
                      {menu.map((item, index) => {
                        const activeClass = isActive(item.path)
                          ? "sub-active"
                          : "";

                        return (
                          <Link
                            to={`${process.env.PUBLIC_URL}/account-settings/${item.path}`}
                            key={index}
                            className={`p-1 m-1 ps-2 pe-2 d-inline-block border border-primary rounded ${activeClass}`}
                          >
                            <span>
                              {item.icon} {item.title}
                            </span>
                          </Link>
                        );
                      })}
                    </Col>
                  )}
                </Row>
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default UserBanner;
