import {useState} from 'react'
import { Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import {  BranchesColumnData, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import {  BranchsAction,  ManageOnlineStore } from '../../../../Data/Tools/ManageContents/ManageContentButtons'
import ManageOnlineSubMenu from '../ManageOnlineSubMenu/ManageOnlineSubMenu'
import BranchSubMenu from './BranchSubMenu/BranchSubMenu'
import CommonSwitchSpan from '../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan'
import CommonSwitch from '../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitch'


const ManageBranches = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const [showModal, setShowModal] = useState(false);
    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    const handleRowSelected =() =>{}
    const updatedBranchesColumnData = [
      ...BranchesColumnData.slice(0, 3), // First three columns (SR. No, Branch Name, Thumbnail)
      {
        name: 'Verified Business Tags', // This is the new column we want to add
        selector: (row) => (
          <div>
            <p className='mb-2'>Location: {row.locationVerified ? 'Yes' : 'No'}</p>
            <p className='mb-2'>License: {row.licenseVerified ? 'Yes' : 'No'}</p>
            <p className='mb-2 text-info' onClick={() => handleModalOpen()}  >
              Verify
            </p>
          </div>
        ),
        sortable: true,
        center: true,
      },
      {
        name: '1Webbb Delivery', // This is the new column we want to add
        selector: (row) => (
          <div>
             <CommonSwitch />
          </div>
        ),
        sortable: true,
        center: true,
      },
      {
        name: 'Photographer at the branch', // This is the new column we want to add
        selector: (row) => (
          <div>
             <p>10 USD (4 hours)</p>
          </div>
        ),
        sortable: true,
        center: true,
      },
      ...BranchesColumnData.slice(3), // Remaining columns (Thumbnail column will be before this)
    ];
    return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`Manage Branches (Manage Online Store)`} parent={`Manage Store`} />
              <CardHeader className='pb-0 pt-0 px-2'> 
                <div>
                <ManageOnlineSubMenu />  
                </div>
              </CardHeader>
      <Container fluid>
        <Row>
          <div className='mt-2'> 
                <BranchSubMenu sliced="9"/> 
          </div>
          <div> 
                       <Link
                         to={`${process.env.PUBLIC_URL}/tools/manage-online-store/manage-branches/add-new`}
                         className={  "bg-gray text-white  py-2 ms-2 px-2 d-inline-block rounded mt-2"  }
                        
                       >
                         Add a New Branch
                       </Link>
                     </div>
          <Col sm="12">
            <Card>
              <CardBody className='p-2'>
                <>
                <div className=''>
                    </div> 
                    <div className="table-responsive ">
                    <DataTable className='custom-scrollbar' columns={updatedBranchesColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
                                            isOpen={showModal}
                                            toggle={handleModalClose}
                                            size="xl">
                                            <ModalHeader toggle={handleModalClose}>
                                            Verify Branch
                                            </ModalHeader>
                                            <ModalBody>
                                                {/* You can display additional data here */}
                                          
    
    <div className="form-wizard">
      <Row>
        <Col sm="4" className="mb-3">
          <p  >
            Country:  india
          </p>  
        </Col>
        <Col sm="4" className="mb-3">
        <p  >
        State: Gujrat
          </p>  
        </Col>
        <Col sm="4" className="mb-3">
        <p  >
        City: Surat
          </p>  
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm="4" className="mb-3">
        <p>
        Branches : Surat 
        </p>
          
        </Col>
        {/* <Col sm="4" className="mt-2">
           <Button className="btn btn-primary">
            Select Branch
           </Button>
        </Col>  */}
      </Row>
    </div> 
                                            </ModalBody>
                                        </Modal>
      </Container>
    </div>
  )
}

export default ManageBranches