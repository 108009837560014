import { Row, Col, Label } from "reactstrap";
import { AlreadhavePassword, LoginPageRedirectUrl, Send, SignIn } from "../../utils/Constant";
import { Btn, P } from "../../AbstractElements";
import { ErrorMessage, Field, Form } from "formik";
import { Link } from "react-router-dom";

function ResetForm({ submitErrors, errors, setSubmitError }: any) {
  return (
    <Form>
      <Row className="g-3">
        <Col md="12">
          <Label>New Password</Label>
          <Field
            type="password"
            name="password"
            placeholder={'Enter New Password'}
            className={`form-control ${
              submitErrors && `${errors.password ? "is-invalid" : "is-valid"}`
            }`}
            autoFocus={true}
          />
          <ErrorMessage
            name="password"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col md="12">
          <Label>Confrim Password</Label>
          <Field
            type="password"
            name="confirmPassword"
            placeholder={'Enter Confirm Password'}
            className={`form-control ${
              submitErrors && `${errors.confirmPassword ? "is-invalid" : "is-valid"}`
            }`} 
          />
          <ErrorMessage
            name="confirmPassword"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col md="12">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
          >
            {Send}
          </Btn>
        </Col>
        <Col md="12">
          <P className="mt-4 mb-0">
            {AlreadhavePassword}
            <Link className="ms-2" to={LoginPageRedirectUrl}>
              {SignIn}
            </Link>
          </P>
        </Col>
      </Row>
    </Form>
  )
}

export default ResetForm