// import { Container, Row } from "reactstrap";
// import LeftSidebar from "../PrivateChat/LeftSidebar/LeftSidebar";
// import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
// import { ActivitieChat, ApplicationChat, Communication } from "../../../../utils/Constant";
// import UserGroupChat from "../GroupChat/UserGroupChat/UserGroupChat";
// import CommunicationSubMenu from "../CommunicationSubMenu";

// const ActivitiesCChatContainer = () => {
//   return (
//     <>
//       <Breadcrumbs mainTitle={ActivitieChat} parent={Communication} />
//       <CommunicationSubMenu/>
//       <Container fluid>
//         <Row className="g-0">
//           <LeftSidebar />
//           <UserGroupChat />
//         </Row>
//       </Container>
//     </>
//   );
// };

// export default ActivitiesCChatContainer;

import { Container, Row } from "reactstrap";
import LeftSidebar from "../PrivateChat/LeftSidebar/LeftSidebar"; 
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ActivitieChat, Communication, PrivateCChat } from "../../../../utils/Constant"; 
import CommunicationSubMenu from "../CommunicationSubMenu";
import { useEffect, useState } from "react";
import UserGroupChat from "../GroupChat/UserGroupChat/UserGroupChat";

const ActivitiesCChatContainer = () => {
  const [responsive, Setresponsive] = useState(true);

  const ToogleContactList = () => {
    Setresponsive(!responsive);
  };

  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      console.log("responsive");
      
      setIsResponsive(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
 

  return (
    <>
            <Breadcrumbs mainTitle={ActivitieChat} parent={Communication} />
      <CommunicationSubMenu />
      <Container fluid>
        <Row className="g-0">
          {isResponsive ? (
            responsive ? (
              <LeftSidebar ToogleContactList={ToogleContactList} />
            ) : (
              <UserGroupChat
                responsive={responsive}
                ToogleContactList={ToogleContactList}
              />
            )
          ) : (
            <>
              <LeftSidebar ToogleContactList={ToogleContactList} />
              <UserGroupChat
                responsive={responsive}
                ToogleContactList={ToogleContactList}
              />
              {/* <UserStatus /> */}
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default ActivitiesCChatContainer;
