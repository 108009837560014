import { Card, Col, Container, Row } from 'reactstrap'
import ProjectListTabContent from './ProjectListTabContent'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { BrandTools, Project, ProjectListHeading } from '../../../../utils/Constant'
import ProjectListHeaderSubmenu from './ProjectListHeaderSubmenu'
import SubMenu from '../../SubMenu/SubMenu'


const ProjectListContainer = () => {
  return (
    <>
      <Breadcrumbs mainTitle={`${ProjectListHeading} (${BrandTools})`} parent={Project} />
      <SubMenu />
      <Container fluid>
        <Row className="project-cards">
          <Col md="12" className="project-list">
          <Card className='my-md-1 '> 
            <div className='my-md-1'> 
              <ProjectListHeaderSubmenu/>
            </div>
          </Card>
          </Col>
          <Col sm="12">
            <ProjectListTabContent />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProjectListContainer