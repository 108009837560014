import { useState } from "react";
import UserBanner from "../../../Component/App/User/UserBanner";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { AddNewAddress, Home, ShippingAddress } from "../../../utils/Constant";
import { Btn, H1 } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  useGetUserAddressesListMutation,
  useSetDefaultUserAddressMutation,
} from "../../../ReduxToolkit/Reducers/AccountSettings/addressApiSlice";
import Loading from "../../../Component/Loading/Loading";
import { toast } from "react-toastify";
import ShippingAddressesList from "./ShippingAddressesList"; 
import SweetAlert from "sweetalert2";

const ShippingAndDeliveryAddresses = () => {
  const [userAddresses, setUserAddresses] = useState();

  const [getUserAddressesListApi, { isLoading: loadingList }] =
    useGetUserAddressesListMutation();

  const [setDefaultUserAddressApi, { isLoading: loadingSetDefault }] =
    useSetDefaultUserAddressMutation();

  const fetchUserAddressesList = async () => {
    try {
      const response = await getUserAddressesListApi().unwrap();
      const { data, success, message } = response;
      if (success === true) {
        setUserAddresses(data);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  };

  const setDefaultUserADdressApiHandler = async (id: number) => {
    try {
      const response = await setDefaultUserAddressApi({ id: id }).unwrap();
      const { data, success, message } = response;
      if (success === true) {
        toast.success(message);
        setUserAddresses(data);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  };

  const setDefaultUserAddressHandler = (id: number) => {
    SweetAlert.fire({
      text: "Are you sure?",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes, Make it default!",
      confirmButtonColor: "var(--theme-default)",
    }).then((result) => {
      if (result.isConfirmed) {
        setDefaultUserADdressApiHandler(id);
      }
    });
  };

  useEffect(() => {
    fetchUserAddressesList();
  }, []);

  if (loadingList) return <Loading />;

  if (loadingSetDefault) return <Loading />;

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={`Shipping Addresses`} parent={Home} />
      <UserBanner />
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Row className="px-3 py-3">
                <Col xl="12">
                  <Link
                    to={`${process.env.PUBLIC_URL}/account-settings/shipping-addresses/add-new`}
                  >
                    <Btn className="me-1 btn-dark">{AddNewAddress}</Btn>
                  </Link>
                </Col>
                <Col
                  xl="12"
                  className="d-flex justify-content-between ps-2 pe-2 mt-3"
                >
                  <H1>{ShippingAddress}</H1>
                </Col>
              </Row>
              <CardBody>
                <Row className="shopping-wizard">
                  <Col xs="12">
                    <Row className="shipping-form g-5">
                      <Col xl="8" sm="12" className="">
                        <div className="shipping-wizard">
                          <Row className="g-3">
                            {userAddresses && (
                              <ShippingAddressesList
                                userAddresses={userAddresses}
                                fetchUserAddressesList={fetchUserAddressesList}
                                setDefaultUserAddressHandler={
                                  setDefaultUserAddressHandler
                                }
                              />
                            )}
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ShippingAndDeliveryAddresses;
