import { useState } from "react";
import { Formik } from "formik";
import SchedulesForm from "./SchedulesForm";
import { Card, CardHeader, Container } from "reactstrap";
import {
    SchedulesFormValidationProp,
    scheduleFormInitialValue,
    scheduleFormValidationSchema,
} from "../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import Breadcrumbs from "../../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ManageBranches, SchedulesTitle } from "../../../../../utils/Constant";
import Message from "../../../../CommonMessage/Message";
import SubMenu from "../../../../Application/SubMenu/SubMenu";
import { Link } from "react-router-dom";
import { MyPhotoContestTableAction } from "../../../../../Data/Tools/BrandSupportTools/ButtonActions";
import ManageOnlineSubMenu from "../../../../../Pages/Tools/ManageOnlineStore/ManageOnlineSubMenu/ManageOnlineSubMenu";
import BranchSubMenu from "../../../../../Pages/Tools/ManageOnlineStore/ManageBranches/BranchSubMenu/BranchSubMenu";

function SchedulesMain() {
    const [submitErrors, setSubmitError] = useState<boolean>(false);

    const handleSubmit = (values: SchedulesFormValidationProp) => {
        console.log(values);
        setSubmitError(false);
    };

    return (
        <div >
            <Breadcrumbs mainTitle={`${SchedulesTitle} (Manage Online Store)`} parent={ManageBranches} />
            <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
            <Container fluid>
                <div className="my-2">
                <BranchSubMenu sliced='-4'/> 
                </div>
                <div className="">
                    {/* {MyPhotoContestTableAction.slice(1).map((action, index) => {
                        return (
                            <Link
                                to={`${process.env.PUBLIC_URL}/${action.path}`}
                                key={index + 1}>
                                <span className="border rounded p-2 m-1 text-white bg-dark ps-2 pe-2">
                                    {action.actionName}
                                </span>
                            </Link>
                        );
                    })} */}
                </div>
                <Card className="p-2">
                    <Message message="Please add at least one branch first to be able to use this form." />
                    <Formik
                        initialValues={scheduleFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={scheduleFormValidationSchema}>
                        {({ errors }) => (
                            <SchedulesForm
                                submitErrors={submitErrors}
                                setSubmitError={setSubmitError}
                                errors={errors}
                            />
                        )}
                    </Formik>
                </Card>
            </Container>
        </div>
    );
}

export default SchedulesMain;
