import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "../../../MasterPopup/Popup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { Btn } from "../../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
interface OnlineAssitantValidationProp {
  assistant_username: string;
  assistant_email: string;
  assistant_price: string;
}

const onlineAssistantInitialValue: OnlineAssitantValidationProp = {
  assistant_username: "",
  assistant_email: "",
  assistant_price: "",
};

const onlineAssistantSchema = Yup.object().shape({
  assistant_username: Yup.string().required("Required"),
  assistant_email: Yup.string().required("Required"),
  assistant_price: Yup.string().required("Required"),
});

function AddOnlineAssistant(props: any) {
  const [isAssistantModalOpen, setIsAssistantModalOpen] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(false);

  const navigate = useNavigate();

  const openAssistantModalToggle = () => {
    console.log("Hello");
    setIsAssistantModalOpen(!isAssistantModalOpen);
  };

  const { title } = props;

  const handleSubmit = (values: OnlineAssitantValidationProp) => {
    setSubmitErrors(false);
    navigate("/cart");
  };

  return (
    <>
      <Link
        to=""
        onClick={openAssistantModalToggle}
        className="ms-1 me-1 text-decoration-underline"
      >
        <span>{title}</span>
      </Link>
      <Popup
        title={`Add an Online Assistant`}
        openModalToggle={openAssistantModalToggle}
        isOpen={isAssistantModalOpen}
        size={`lg`}
        backdrop={false}
        isClose={true}
        CommonFooter={false}
      >
        <div className="mx-4 my-2">
          <Formik
            initialValues={onlineAssistantInitialValue}
            onSubmit={handleSubmit}
            validationSchema={onlineAssistantSchema}
          >
            {({ errors, values, setFieldValue }) => (
              <Form className="mt-2">
                <Row>
                  <Col sm="12">
                    <Label check>{"Assistant’s 1 Webbb Username"}:</Label>
                    <Field
                      type="text"
                      placeholder={"Enter Assistant’s 1 Webbb Username"}
                      name="assistant_username"
                      className={`form-control ${
                        submitErrors &&
                        `${
                          errors.assistant_username ? "is-invalid" : "is-valid"
                        }`
                      }`}
                      autoFocus={true}
                    />
                    <ErrorMessage
                      name="assistant_username"
                      component="span"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col sm="12">
                    <Label check>
                      {"Assistant’s Email Address on 1Webbb"}:
                    </Label>
                    <Field
                      type="text"
                      placeholder={"Assistant’s Email Address on 1Webbb"}
                      name="assistant_email"
                      className={`form-control ${
                        submitErrors &&
                        `${errors.assistant_email ? "is-invalid" : "is-valid"}`
                      }`}
                      autoFocus={true}
                    />
                    <ErrorMessage
                      name="assistant_email"
                      component="span"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col sm="12">
                    <Label check>{"Price (30 Days) USD"}:</Label>
                    <Field
                      type="text"
                      placeholder={"Enter Price (30 Days) USD"}
                      name="assistant_price"
                      className={`form-control ${
                        submitErrors &&
                        `${errors.assistant_price ? "is-invalid" : "is-valid"}`
                      }`}
                      autoFocus={true}
                    />
                    <ErrorMessage
                      name="assistant_price"
                      component="span"
                      className="invalid-feedback"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Btn
                      color="primary mx-1"
                      type="submit"
                      onClick={() => setSubmitErrors(true)}
                    >
                      Continue to cart
                    </Btn>
                    <Btn
                      color="light"
                      type="button"
                      onClick={openAssistantModalToggle}
                    >
                      Cancel
                    </Btn>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </Popup>
    </>
  );
}

export default AddOnlineAssistant;
