import { Link, useLocation } from "react-router-dom";
import { ManageContents } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import { Col, Row } from "reactstrap";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { useState } from "react";

const ManageSubMenu = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const isActive = (path) => {
    // Check if the current path starts with the path of the link
    return currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`);
  };
  const { mobileView } = useAppSelector((state) => state.layout);

  const [isOpen, setIsOpen] = useState(false); 
  return (
    <div>
      {/* {ManageContents.map((item, index) => {
        // Determine if the current link or any of its child links should be active
        const activeClass = isActive(item.path) ? 'sub-active' : '';

        return (
          <Link
            key={index}
            to={`${process.env.PUBLIC_URL}/${item.path}`}
            className={`border d-inline-block p-2 m-1 rounded ${activeClass}`}
          >
            <span>
              {item.title} {item.total_contest >= 0 ? `(${item.total_contest})` : ''}
            </span>
          </Link> */}
          <Row>
          {mobileView ? (
                  <>
                      
              <Col md="12">
                  {isOpen ? (
                      <IoClose size='2em' onClick={() => setIsOpen(false)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                  ) : (
                      <HiOutlineMenuAlt2 size='2em' onClick={() => setIsOpen(true)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                  )}
              </Col>
              <Col md="12">
                  {isOpen ? (
                      <>
                          {ManageContents.map((item, index) => { 
                              const activeClass = isActive(item.path) ? 'sub-active' : '';

                              return (
                                <Link
            key={index}
            to={`${process.env.PUBLIC_URL}/${item.path}`}
            className={`border d-inline-block p-2 m-1 rounded ${activeClass}`}
          >
            <span>
              {item.title} {item.total_contest >= 0 ? `(${item.total_contest})` : ''}
            </span>
          </Link>
                              );
                          })}
                      </>
                  ) : ("")}
              </Col>
              </>
          ) : (
                  <Col md="12">
                      {ManageContents.map((item, index) =>{
                          
                          const activeClass = isActive(item.path) ? 'sub-active' : ''; 
                          return ( 
                            <Link
                            key={index}
                            to={`${process.env.PUBLIC_URL}/${item.path}`}
                            className={`border d-inline-block p-2 m-1 rounded ${activeClass}`}
                          >
                            <span>
                              {item.title} {item.total_contest >= 0 ? `(${item.total_contest})` : ''}
                            </span>
                          </Link>
                          )
                      }
                      )}
                  </Col>
              )}
          </Row>
        {/* );
      })} */}
    </div>
  );
};

export default ManageSubMenu;
