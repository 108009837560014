import { Row, Col, Label } from "reactstrap";
import { Send, SignIn, EnterEmail, AlreadhavePassword, LoginPageRedirectUrl } from "../../utils/Constant";
import { Btn, P } from "../../AbstractElements";
import { ErrorMessage, Field, Form } from "formik";
import { Link } from "react-router-dom";
function ForgotForm({ submitErrors, errors, setSubmitError }: any) {
  return (
    <Form>
      <Row className="g-3">
        <Col md="12">
          <Label>Enter Email</Label>
          <Field
            type="email"
            name="email"
            placeholder={EnterEmail}
            className={`form-control ${
              submitErrors && `${errors.email ? "is-invalid" : "is-valid"}`
            }`}
            autoFocus={true}
          />
          <ErrorMessage
            name="email"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col md="12">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
          >
            {Send}
          </Btn>
        </Col>
        <Col md="12">
          <P className="mt-4 mb-0">
            {AlreadhavePassword}
            <Link className="ms-2" to={LoginPageRedirectUrl}>
              {SignIn}
            </Link>
          </P>
        </Col>
      </Row>
    </Form>
  );
}

export default ForgotForm;
