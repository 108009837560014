import { FormGroup, Label } from "reactstrap";
import { BirthdateProof } from "../../../../utils/Constant";
import { Field } from "formik";

function BirthdateProofContent(props: any) {
  const {
    values,
    setFieldValue,
    uploadFileHandler,
    showModalToggle,
    personalInfo,
  } = props;

  return (
    <FormGroup>
      <Label>
        {BirthdateProof}
        {values.birthday_verify_status === "awaiting" ? (
          <p className="txt-secondary">(Awaiting for Approval)</p>
        ) : values.birthday_verify_status === "approved" ? (
          <p className="txt-secondary">(Approved)</p>
        ) : values.birthday_verify_status === "disapproved" ? (
          <p className="txt-secondary">(Disapproved)</p>
        ) : (
          ""
        )}
      </Label>
      {values.birthday_verify_status === "none" || values.birthday_verify_status === "disapproved" ? (
        <Field
          name="uploadImage"
          className="form-control"
          type="file"
          onChange={(event: any) => uploadFileHandler(event, setFieldValue)}
        />
      ) : (
        <>
          <br />
          {values.upload_birthday_proof !== "" && (
            <span onClick={showModalToggle}>
              <img
                src={values.upload_birthday_proof}
                height="100px"
                width="100px"
              />
            </span>
          )}
        </>
      )}
    </FormGroup>
  );
}

export default BirthdateProofContent;
