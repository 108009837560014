import React, {useState} from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { Btn, H3, SVG } from '../../../AbstractElements'
import { supportTicketData } from '../../../Data/Miscellaneous/SupportTicket/SupportTicket'
import { TableHeadColumn } from '../../../Pages/App/AccountSettings/MyRegistrationPlan'
import PageHeader from '../../Dashboard/Default/PageHeader/PageHeader'
import { GradeData } from '../../../Data/GradeSurvey/GradeData' 
import { ActionButtons, AttendanceActionButtons, AttendanceSystemDataType } from '../../../Data/Tools/BrandSupportTools/ButtonActions'
import Popup from '../../MasterPopup/Popup'
import CommonModal from '../../Ui-Kits/Modal/Common/CommonModal'
import { X } from 'react-feather'
import { AttendanceSystemData } from '../../../Data/ReservationsAttendance/ReservationsAttendanceData'

export interface ActionButtonProps{
    action:number;
  }

 

 
export interface SupportDataType {
    id: number;
    title: string;
    score: Number;
    type: string;
    question: Number;
    answered: Number;
    unanswered: Number;
    correct: Number;
    mistake: Number;
    comment: string;
    date: string;
  }
  
  const ReservationsAttendanceContainer = () => {
    const [isOpen , setIsOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<SupportDataType | null>(null);
const [data, setData] = useState(AttendanceSystemData);
    const [isEditOpen, setIsEditOpen] = useState(false); 
    const [clickedRow, setClickedRow] =
        useState<AttendanceSystemDataType | null>(null);
    const toggleDelete = useState(false); 
    const handleRowSelected =() =>{}
    const openPopup = (row: SupportDataType) =>{
        setIsOpen(!isOpen); 
        setSelectedRow(row); // Store the clicked row's data
        
    }
    const openModalToggle = () =>{
        setIsOpen(!isOpen);
    }
    const handleActivityAction = (
            type: string,
            rowData: AttendanceSystemDataType
        ) => {
            // const data = rowData;
            setClickedRow(rowData);
            if (type == "edit") {
                setIsEditOpen(true);
                setIsOpen(!isOpen);
                // navigate(
                //   `${process.env.PUBLIC_URL}/tools/brand-support-tools/activities-and-attendance/edit-activity`,
                //   { state: data }
                // );
            } else if (type == "view") {
                // navigate(
                //   `${process.env.PUBLIC_URL}/tools/brand-support-tools/activities-and-attendance/view-activity`,
                //   { state: data }
                // );
                setIsEditOpen(false);
                setIsOpen(!isOpen);
            } else {
                // api call to delete the row
            }
    
            // setIsEditOpen(type);
        };
    const ReservationsAttendanceListColumns:TableColumn<SupportDataType>[] = [
             {
                       name: <TableHeadColumn title={`ID`} />,
                       selector: (row) => row["referenceid"],
                       sortable: true,
                       center: true,
                       cell: (row) => (
                           <button
                               onClick={() => handleIdClick(row["referenceid"])}
                               style={{
                                   border: "none",
                                   background: "transparent",
                                   cursor: "pointer",
                               }}>
                               {row["referenceid"]}
                           </button>
                       ),
                   },
                   {
                       name: <TableHeadColumn title={`QR Code`} />,
                       selector: (row) => row["qrcode"],
                       sortable: true,
                       center: true,
                       cell: (row) => (
                           <button
                               
                               style={{
                                   border: "none",
                                   background: "transparent",
                                   cursor: "pointer",
                               }}>
                               {row["qrcode"]}
                           </button>
                       ),
                   },
                   {
                       name: <TableHeadColumn title={`Activity Type`} />,
                       selector: (row) => row["activitytype"],
                       sortable: true,
                       center: true,
                   }, 
                   {
                       name: <TableHeadColumn title={`Activity Title`} />,
                       selector: (row) => row["activitytitle"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`No. of Reservations`} />,
                       selector: (row) => row["reservations"],
                       sortable: true,
                       center: true, 
                   },
                   {
                       name: <TableHeadColumn title={`No. of Attendees`} />,
                       selector: (row) => row["attendees"],
                       sortable: true,
                       center: true,
                       
                   },
                   {
                       name: <TableHeadColumn title={`Description`} />,
                       selector: (row) => row["description"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Category`} />,
                       selector: (row) => row["subcategory"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Activity Location`} />,
                       selector: (row) =>
                           row["activitylocation"] ? "Physical Address" : "Online",
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Country`} />,
                       selector: (row) => row["country"].join(","),
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`State`} />,
                       selector: (row) => row["state"].join(","),
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`City`} />,
                       selector: (row) => row["city"].join(","),
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Full Address`} />,
                       selector: (row) => row["fulladdress"],
                       sortable: true,
                       center: true,
                   }, 
                   {
                       name: <TableHeadColumn title={`Keywords`} />,
                       selector: (row) => row["keywords"].join(","),
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Duration`} />,
                       selector: (row) => row["duration"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Start Date`} />,
                       selector: (row) => row["startdates"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Expiry Date`} />,
                       selector: (row) => row["expirydate"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Leaving Activity`} />,
                       selector: (row) => (row["leavingactivity"] ? "Yes" : "No"),
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Break in Activity`} />,
                       selector: (row) => (row["breakinactivity"] ? "Yes" : "No"),
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Public Activity`} />,
                       selector: (row) => row["publicactivity"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Supervisor Confirmirmation`} />,
                       selector: (row) => (row["supervisorconfirm"] ? "Yes" : "No"),
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Supervisor Name`} />,
                       selector: (row) => row["supervisorname"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Manual Check`} />,
                       selector: (row) => (row["manualcheck"] ? "Yes" : "No"),
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Invite Users`} />,
                       selector: (row) => row["inviteusers"].join(","),
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Meeting URL`} />,
                       selector: (row) => row["meetingurl"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Phone Number`} />,
                       selector: (row) => row["phonenumber"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Meeting Platform`} />,
                       selector: (row) => row["meetingplatform"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Activity's Frequency`} />,
                       selector: (row) => row["activityfrequency"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Rate Ranges`} />,
                       selector: (row) => row["dateranges"],
                       sortable: true,
                       center: true,
                   },
                   {
                       name: <TableHeadColumn title={`Thumbnail`} />,
                       selector: (row) => row["image"],
                       sortable: true,
                       center: true,
                   },
           
                   {
                       name: <TableHeadColumn title={`Actions`} />,
                       cell: (row) => (
                           <AttendanceActionButtons
                               handleActivityAction={handleActivityAction}
                               rowData={row}
                           />
                       ),
                       center: false,
                       right: true,
                   },
       ]
    return (
        <Container fluid>
        <Card>
            <CardHeader className='py-0 px-0'>
                    <PageHeader/>
                </CardHeader>
            <CardBody>
                <Row className=' py-3'>
                    <Col md="12" >
                        <div className="table-responsive">   
                        {/* <DataTable className='custom-scrollbar' columns={StudyMaterialColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />                      */}
                            <DataTable className='custom-scrollbar' columns={ReservationsAttendanceListColumns} data={AttendanceSystemData} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                        </div>
                    </Col>
                </Row>
                 
                        <CommonModal
  isOpen={isOpen}
  toggle={openModalToggle}
  modalBodyClassName="px-3"
  size= 'xl' 
//   backdrop={backdrop}
>
  <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
    {/* Header Section */}
    <div className="modal-header align-items-center">
      <H3 className="w-50 text-left">{selectedRow?.title || "Quiz Summary"}</H3>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex justify-content-between align-items-center w-75">
          <p className='mb-0'><strong>Username:</strong> {selectedRow?.username || "Guest"}</p>
          <p className='mb-0'><strong>Date:</strong> {selectedRow?.date || new Date().toLocaleDateString()}</p>
          <p className='mb-0'><strong>Start Time:</strong> {selectedRow?.startTime || "N/A"}</p>
        </div>
        <div onClick={openModalToggle} className="search-close-icon">
          <X />
        </div>
      </div>
    </div>

    {/* Body Section */}
    <div style={{ height: "auto", overflow: "auto", padding: "1rem" }}>
      <div className="quiz-summary">
        <h4>Quiz Results</h4>
        <p><strong>Score:</strong> {  "N/A"}</p>
        <p><strong>Correct Answers:</strong> {  0}</p>
        <p><strong>Total Questions:</strong> {  0}</p>
        <p><strong>Unanswered:</strong> {   0}</p>
        <p><strong>Remarks:</strong> {  "Good job!"}</p>
      </div>
    </div>

    {/* Footer Section */}
    {/* {CommonFooter && ( */}
      <div className="modal-footer">
        <div className="next_previous position-relative pt-0">
          <Btn  className="border">
            <div className="d-flex align-items-center gap-sm-2 gap-1">
              Previous <SVG iconId="back-arrow" />
            </div>
          </Btn>
          <Btn className="border">
            <div className="d-flex align-items-center gap-sm-2 gap-1">
              Next <SVG iconId="front-arrow" />
            </div>
          </Btn>
        </div>
      </div>
    {/* )} */}
  </div>
</CommonModal>
            </CardBody>
        </Card>
        </Container>
    )
}

export default ReservationsAttendanceContainer