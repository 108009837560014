import GroupChat from "../Pages/Application/Chat/GroupChat/GroupChat";
import Contacts from "../Pages/Application/Contacts/Contacts";
import Cart from "../Pages/Application/Ecommerce/Cart/Cart";
import Checkout from "../Pages/Application/Ecommerce/Checkout/Checkout";
import Bookmark from "../Pages/Application/Bookmark/Bookmark";
import ProductList from "../Pages/Application/Ecommerce/ProductList/ProductList";
import ProductPage from "../Pages/Application/Ecommerce/ProductPage/ProductPage";
import Products from "../Pages/Application/Ecommerce/Products/Products";
import WishList from "../Pages/Application/Ecommerce/WishList/WishList";
import FileManager from "../Pages/Application/FileManager/FileManager";
import Marketplace from "../Pages/Application/Marketplace/Marketplace";
import SocialApp from "../Pages/Application/SocialApp/SocialApp";
import GoogleChart from "../Pages/Charts/GoogleChart/GoogleChart";
import GalleryGridDesc from "../Pages/Miscellaneous/Gallery/GalleryGridDesc/GalleryGridDesc";
import MasonryImageDesc from "../Pages/Miscellaneous/Gallery/MasonryImageDesc/MasonryImageDesc";
import JobDetails from "../Pages/Miscellaneous/JobSearch/JobDetails/JobDetails";
import TwoFactor from "../Pages/Forms/FormsLayout/TwoFactor/TwoFactor";
import FromMyMediaMasters from "../Pages/App/MyMediaCenter/FromMyMediaMasters/FromMyMediaMasters";
import ReConfirmPassword from "../Pages/App/ConfirmPassword/ReConfirmPassword";
import Pricing from "../Pages/Pricing/Pricing";
import Contests from "../Pages/App/Contests/Contests";
import Coupons from "../Pages/App/Coupons/Coupons";
import Media from "../Pages/App/Media/Media";
import WithdrawlConfigure from "../Pages/Application/Users/WithdrawlConfigure";
import EventActivites from "../Pages/App/Post/EventsActivites/EvenetActivites";
import JobOpnings from "../Pages/App/Post/JobOpenings/JobOpnings";
import QRCodePopup from "../Pages/Application/QRCode/QRCodePopup";
import CustomerWishes from "../Pages/App/Post/CustomerWishes/CustomerWishes";  
import Knowledge from "../Pages/Application/Knowledge/Knowledge"; 
import ArticlesBlogs from "../Pages/App/Post/ArticlesBlogs/ArticlesBlogs";
import Surveys from "../Pages/App/Post/Surveys/Surveys"; 
import Statics from "../Pages/Information/Statics";

export const routes = [
  // Applications
  {
    path: `${process.env.PUBLIC_URL}/app/filemanager`,
    Component: <FileManager />,
  },
  {
    path: `${process.env.PUBLIC_URL}/chat/groupchat`,
    Component: <GroupChat />,
  },
  { path: `${process.env.PUBLIC_URL}/app/contacts`, Component: <Contacts /> },
  { path: `${process.env.PUBLIC_URL}/app/socialapp`, Component: <SocialApp /> },
  // {
  //   path: `${process.env.PUBLIC_URL}/search-results-screen`,
  //   Component: <SearchResult />,
  // },
  // Coupons
  // { path: `${process.env.PUBLIC_URL}/bonusui/tour`, Component: <Tour /> },
  //Marketplace
  {
    path: `${process.env.PUBLIC_URL}/Knowledge`,
    Component: <Knowledge />,
  },
  {
    path: `${process.env.PUBLIC_URL}/posts/surveys`,
    Component: <Surveys />,
  },
  {
    path: `${process.env.PUBLIC_URL}/services-and-products`,
    Component: <Marketplace />,
  },
  {
    path: `${process.env.PUBLIC_URL}/post/articles-and-blogs`,
    Component: <ArticlesBlogs />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/marketplace/shopping-cart`,
  //   Component: <Cart />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/marketplace/checkout`,
    Component: <Checkout />,
  },
  {
    path: `${process.env.PUBLIC_URL}/marketplace/view`,
    Component: <ProductPage />,
  },
  // Ecommerce
  // {
  //   path: `${process.env.PUBLIC_URL}/tools/manage-store/product-and-service-list/add-new`,
  //   Component: <AddProduct />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/products`,
    Component: <Products />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productpage`,
    Component: <ProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productlist`,
    Component: <ProductList />,
  },
  
  
  { path: `${process.env.PUBLIC_URL}/ecommerce/cart`, Component: <Cart /> },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/checkout`,
    Component: <Checkout />,
  },
  // Charts
  {
    path: `${process.env.PUBLIC_URL}/charts/googlechart`,
    Component: <GoogleChart />,
  },
  // Gallery
  {
    path: `${process.env.PUBLIC_URL}/gallery/gallerygriddesc`,
    Component: <GalleryGridDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/masonrywithdesc`,
    Component: <MasonryImageDesc />,
  },
  // Post
  {
    path: `${process.env.PUBLIC_URL}/post/events-and-activities`,
    Component: <EventActivites />,
  },
  {
    path: `${process.env.PUBLIC_URL}/post/customers-wishes`,
    Component: <CustomerWishes />,
  },
  {
    path: `${process.env.PUBLIC_URL}/post/job-openings`,
    Component: <JobOpnings />,
  }, 
  // Job Search
  {
    // path: `${process.env.PUBLIC_URL}/jobsearch/cardview`,
    // Component: <CardView />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/jobsearch/listview`,
  //   Component: <ListView />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/jobsearch/jobdetail`,
    Component: <JobDetails />,
  },

  // forms
  {
    path: `${process.env.PUBLIC_URL}/forms/formslayout/twofactor`,
    Component: <TwoFactor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/from-my-media-masters`,
    Component: <FromMyMediaMasters />,
  },
  {
    path: `${process.env.PUBLIC_URL}/media-center/customers-wish-list`,
    Component: <WishList />,
  }, 
  
  {
    // path: `${process.env.PUBLIC_URL}/wallet/withdrawals/configure`,
    Component: <WithdrawlConfigure />,
  },
  // Confirm password
  {
    path: `${process.env.PUBLIC_URL}/confirm_password`,
    Component: <ReConfirmPassword />,
  },
  // Others
  {
    path: `${process.env.PUBLIC_URL}/all-registration-plans`,
    Component: <Pricing />,
  },
  {
    path: `${process.env.PUBLIC_URL}/contests`,
    Component: <Contests />,
  },
  {
    path: `${process.env.PUBLIC_URL}/coupons`,
    Component: <Coupons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/see-all-contents`,
    Component: <Media />,
  }, 
  //QR Code
  {
    path: `${process.env.PUBLIC_URL}/qr-code-popup`,Component: <QRCodePopup />,
  }, 
  { path: `${process.env.PUBLIC_URL}/saved-search`, Component: <Bookmark /> },
   
  {
    path: `statics/:id/:slug`,
    Component: <Statics />,
  }
];
