import {useState} from 'react'
import { BrandTools, KnowledgeSystems } from '../../../../utils/Constant' 
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs' 
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import StudyMaterialLessions from '../StudyMaterialLesson/StudyMaterialLessions'


const KnowledgeSystem = () => {   
  return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`${KnowledgeSystems} (${BrandTools})`} parent={BrandTools} /> 
      <StudyMaterialLessions />
    </div>
  )
}

export default KnowledgeSystem;