import InvoiceOneContainer from "../../../../../Component/Application/Ecommerce/Invoices/Invoice-1/Invoice-1";

const InvoiceOne = () => {
  return (
    <div className="page-body">
      <InvoiceOneContainer />
    </div>
  );
};

export default InvoiceOne;
