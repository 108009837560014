import GalleryGridDescContainer from "../../../../Component/Miscellaneous/Gallery/GalleryGridDesc/GalleryGridDesc"

const GalleryGridDesc = () => {
  return (
    <div className='page-body'>
      <GalleryGridDescContainer />
    </div>
  )
}

export default GalleryGridDesc