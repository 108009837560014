// import { H6, P, SVG } from "../../../../../../AbstractElements";
// import { useState } from "react";
// import { Dropzone, ExtFile, FileMosaic } from "@dropzone-ui/react";
// import { DragFilesHere } from "../../../../../../utils/Constant";
// import { useDispatch } from "react-redux";
// import { setFormValue } from "../../../../../../ReduxToolkit/Reducers/AddProductSlice";

// const ProductGallery = () => {
//   const [files, setFiles] = useState<ExtFile[]>([]);
//   const dispatch = useDispatch()
//   const updateFiles = (incomingFiles: ExtFile[]) => {
//     setFiles(incomingFiles);
//     dispatch(setFormValue({name:"fileName1",value:incomingFiles[0].file?.name}))
//   };
//   const removeFile = (id: string | number | undefined) => {
//     setFiles(files.filter((x: ExtFile) => x.id !== id));
//   };
//   return (
//     <div className="product-upload">
//       <P>Cover Image <span className="txt-danger">*</span></P>
//       <Dropzone onChange={(files)=>updateFiles(files)} value={files} maxFiles={1} header={false} footer={false} minHeight="80px" name="fileName1">
//         {files.map((file: ExtFile) => (
//           <FileMosaic key={file.id} {...file} onDelete={removeFile} info={true} />
//         ))}
//         {files.length === 0 && (
//           <div className="dz-message mt-5">
//             <SVG iconId="file-upload1"/>
//             <H6>{DragFilesHere}</H6>
//             <span className="note">SVG,PNG,JPG or GIF</span>
//           </div>
//         )}
//       </Dropzone>
//       <div className="mt-5">
//       <P>Aditional Images</P>
//       <Dropzone onChange={(files)=>updateFiles(files)} value={files} maxFiles={10} header={false} footer={false} minHeight="80px" name="fileName1">
//         {files.map((file: ExtFile) => (
//           <FileMosaic key={file.id} {...file} onDelete={removeFile} info={true} />
//         ))}
//         {files.length === 0 && (
//           <div className="dz-message mt-5">
//             <SVG iconId="file-upload1"/>
//             <H6>{DragFilesHere}</H6>
//             <span className="note">SVG,PNG,JPG or GIF</span>
//           </div>
//         )}
//       </Dropzone>
//       </div>
//     </div>
//   );
// };

// export default ProductGallery;
import { H6, P, SVG } from "../../../../../../AbstractElements";
import { useState } from "react";
import { Dropzone, ExtFile, FileMosaic } from "@dropzone-ui/react";
import { DragFilesHere } from "../../../../../../utils/Constant";
import { useDispatch } from "react-redux";
import { setFormValue } from "../../../../../../ReduxToolkit/Reducers/AddProductSlice";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const ProductGallery = () => {
  const [coverFiles, setCoverFiles] = useState<ExtFile[]>([]);
  const [additionalFiles, setAdditionalFiles] = useState<ExtFile[]>([]);
  const [submitErrors, setSubmitError] = useState<boolean>(false); 
  const dispatch = useDispatch();

  
  const updateCoverFiles = (incomingFiles: ExtFile[]) => {
    setCoverFiles(incomingFiles);
    dispatch(setFormValue({ name: "fileName", value: incomingFiles.map((file) => file.file?.name) }));
  };

  const updateAdditionalFiles = (incomingFiles: ExtFile[]) => {
    setAdditionalFiles(incomingFiles);
    dispatch(setFormValue({ name: "fileName1", value: incomingFiles.map((file) => file.file?.name) }));
  };
 
  const removeCoverFile = (id: string | number | undefined) => {
    setCoverFiles(coverFiles.filter((file) => file.id !== id));
  };

  const removeAdditionalFile = (id: string | number | undefined) => {
    setAdditionalFiles(additionalFiles.filter((file) => file.id !== id));
  };

  // Yup validation schema
  const validationSchema = Yup.object({
    coverImage: Yup.array()
      .min(1, "Cover image is required")
      .required("Cover image is required"),
    additionalImages: Yup.array()
      .min(1, "additional image is required")
      .required("Additional images are required"),
  });

  return (
    <Formik
      initialValues={{
        coverImage: [],
        additionalImages: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
         
        
        console.log(values);  // Handle form submission logic here
      }}
    >
      {({ setFieldValue, errors, touched }) => (
        <Form>
          <div className="product-upload">
            {/* Cover Image */}
            <P>
              Cover Image <span className="txt-danger">*</span>
            </P>
            <Field name="coverImage">
              {({ field }: any) => (
                <Dropzone
                  onChange={updateCoverFiles}
                  value={coverFiles}
                  maxFiles={1}
                  header={false}
                  footer={false}
                  minHeight="80px"
                  name="fileName"
                  className={`${touched.coverImage && errors.coverImage ? "form-control is-invalid" : ""}`}
                >
                  {coverFiles.map((file: ExtFile) => (
                    <FileMosaic key={file.id} {...file} onDelete={removeCoverFile} info={true} />
                  ))}
                  {coverFiles.length === 0 && (
                    <div className="dz-message mt-5">
                      <SVG iconId="file-upload1" />
                      <H6>{DragFilesHere}</H6>
                      <span className="note">SVG, PNG, JPG or GIF</span>
                    </div>
                  )}
                </Dropzone>
              )}
            </Field>
            {/* Error message for cover image */}
            <ErrorMessage name="coverImage" component="div" className="invalid-feedback" />

            {/* Additional Images */}
            <div className={` mt-5`}>
              <P>Additional Images </P>
              <Field name="additionalImages">
                {({ field }: any) => (
                  <Dropzone
                    onChange={updateAdditionalFiles}
                    value={additionalFiles}
                    maxFiles={10}
                    header={false}
                    footer={false}
                    minHeight="80px"
                    name="fileName1"
                    className={`${touched.additionalImages && errors.additionalImages ? "form-control is-invalid" : ""}`}
                     
                  >
                    {additionalFiles.map((file: ExtFile) => (
                      <FileMosaic key={file.id} {...file} onDelete={removeAdditionalFile} info={true} />
                    ))}
                    {additionalFiles.length === 0 && (
                      <div className="dz-message mt-5">
                        <SVG iconId="file-upload1" />
                        <H6>{DragFilesHere}</H6>
                        <span className="note">SVG, PNG, JPG or GIF</span>
                      </div>
                    )}
                  </Dropzone>
                )}
              </Field> 
            </div>

            <button type="submit"  onClick={() => setSubmitError(true)}>Save</button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProductGallery;
