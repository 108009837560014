import { useState, useEffect } from "react";
import {
  FormGroup,
  Label,
  Col,
  Row,
  Card,
  Collapse,
  CardBody,
} from "reactstrap";
import {
  Email,
  UserName,
  Username,
  Password,
  ConfirmPassword,
  ConnectNewRegisterAccount,
  RegistrationPlanText,
  AccountCountry,
  AgreeToTermsAndConditions,
  TermsAndConditions,
  PrivacyPolicy,
  PlanDetails,
  RegistrationPlan,
  RegistrationType,
} from "../../utils/Constant";
import { Btn } from "../../AbstractElements";
import { Link, useParams } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { ErrorMessage, Field, Form } from "formik";
import Country from "../CommonInput/Country";
import RegistrationTypes from "../CommonInput/RegistrationTypes";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import CardHeaderCollapse from "../../CommonElements/CardHeaderCommon/CardHeaderCollapse";
import PricingForm from "./PricingForm";
import StaticPlanData from "../Pricing/StaticPlanData";
import DynamicPlanData from "../Pricing/DynamicPlanData";

import { useGetRegistrationPlanListQuery } from "../../ReduxToolkit/Reducers/Registration/Plan/planApliSlice";
import Loading from "../Loading/Loading"; 

type registerParams = {
  plan_id: string;
  countryName: string;
  registrationTypeIds: any;
};

function RegisterForm(props: any) {
  const [countryId, setCountryId] = useState<any>();
  const [registrationType, setRegistrationType] = useState<any>();
  const [planId, setPlanId] = useState<any>(0);
  const [Plans, setPlans] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);
  const [isMyPlanOpen, setIsMyPlanOpen] = useState(false);

  const { submitErrors, errors, setFieldValue, values, setSubmitError, setIsUnderAge } = props;
 

  
  const { data: plansList, isLoading } = useGetRegistrationPlanListQuery(
    {
      country: countryId,
      registrationType: registrationType === "" ? 0 : registrationType,
    },
    {
      skip: countryId !== "" && countryId !== undefined ? false : true,
    }
  );

  // get country list
  const { countries } = useAppSelector((state) => state.common);

  const { plan_id, countryName, registrationTypeIds } =
    useParams<registerParams>();

  useEffect(() => {
    setCountryId(values.country_id);
    setRegistrationType(values.subscription_type);
    setPlanId(values.plan_id);

    //when Subscription type selected is Under Age
    if(values.subscription_type.indexOf('5')){
      setIsUnderAge(true);
    }else{
      setIsUnderAge(true);
    }
  }, [values]);

  useEffect(() => {
    if (
      plan_id !== undefined &&
      countryName !== undefined &&
      registrationTypeIds !== undefined
    ) {
      const selectedCountry: any = countries.filter((item: any) => {
        return item.label == countryName;
      });
      setFieldValue("subscription_type", registrationTypeIds?.split(","));
      setFieldValue("plan_id", plan_id);
      setFieldValue("country_id", selectedCountry[0]?.value);
      setRegistrationType(registrationTypeIds?.split(","));
      setPlanId(plan_id);
      setCountryId(selectedCountry[0]?.value);
    }
  }, [countries, countryName, registrationTypeIds, plan_id]);

  useEffect(() => {
    if (countryId !== "" && countryId !== undefined) {
      setPlans(plansList?.data);
    }
  }, [plansList, countryId, registrationType]);

  let filterDynamicPlan = Plans?.dynamic.filter((item: any) => {
    return item.plan_id === planId;
  });

  let priceCode = filterDynamicPlan?.map((item: any, index: number) => {
    return (
      <Row key={index}>
        <PricingForm
          title={item.pricings.monthly}
          value="monthly"
          index={1}
          values={values}
        />
        <PricingForm
          title={item.pricings.quarterly}
          value="quarterly"
          index={2}
          values={values}
        />
        <PricingForm
          title={item.pricings.semiannually}
          value="semiannually"
          index={3}
          values={values}
        />
        <PricingForm
          title={item.pricings.annually}
          value="annually"
          index={4}
          values={values}
        />
      </Row>
    );
  });

  const showingPriceList = filterDynamicPlan?.map(
    (item: any, index: number) => {
      return (
        <div className="d-flex overflow-auto" key={index}>
          <Col className="ps-0">
            <StaticPlanData staticData={{ data: Plans }} />
          </Col>
          <Col>
            <DynamicPlanData
              dData={{ data: { dynamic: filterDynamicPlan } }}
              isButton={false}
              countryId={countryId}
            />
          </Col>
        </div>
      );
    }
  );

  const registrationPlanOptions = Plans?.dynamic.map(
    (d_item: any, index: any) => (
      <option key={index} value={d_item.plan_id}>
        {d_item.plan_name}
      </option>
    )
  ); 

  return (
    <Form className="form-wizard">
      <Row>
        <Col xs="12" lg="6">
          <Label>
            {Username}
            <span className="font-danger">*</span>
          </Label>
          <Field
            type="text"
            name="username"
            className={`form-control ${
              submitErrors && `${errors.username ? "is-invalid" : "is-valid"}`
            }`}
            placeholder={UserName}
            autoFocus={true}
          />
          <ErrorMessage
            name="username"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col xs="12" lg="6" className="py-1">
          <Label>
            {Email}
            <span className="font-danger">*</span>
          </Label>
          <Field
            type="email"
            name="email"
            className={`form-control ${
              submitErrors && `${errors.email ? "is-invalid" : "is-valid"}`
            }`}
            placeholder={Email}
          />
          <ErrorMessage
            name="email"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col xs="12" lg="12" className="py-1">
          <Label>
            {Password}
            <span className="font-danger">*</span>
          </Label>
          <Field
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder={`${Password}`}
            className={`form-control ${
              submitErrors && `${errors.username ? "is-invalid" : "is-valid"}`
            }`}
          />
          <div
            className="show-hide"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <FaEye className="me-4" />
            ) : (
              <FaEyeSlash className="me-4" />
            )}
          </div>
          <ErrorMessage
            name="password"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col xs="12" className="py-1">
          <FormGroup className="position-relative">
            <Label>
              {ConfirmPassword}
              <span className="font-danger">*</span>
            </Label>
            <Field
              type={showPassword ? "text" : "password"}
              name="password_confirmation"
              placeholder={` ${ConfirmPassword}`}
              className={`form-control ${
                submitErrors &&
                `${errors.password_confirmation ? "is-invalid" : "is-valid"}`
              }`}
            />
            <ErrorMessage
              name="password_confirmation"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" lg="6">
          <Label>
            {AccountCountry}
            <span className="font-danger">*</span>
          </Label>
          <Country
            name="country_id"
            placeholder="Select Country"
            className={`${
              submitErrors && `${errors.country_id ? "is-invalid" : "is-valid"}`
            }`}
          />
          <ErrorMessage
            name="country_id"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col xs="12" lg="6">
          <FormGroup className="position-relative">
            <Label>
              {RegistrationType}
              <span className="font-danger">*</span>
            </Label>
            <RegistrationTypes
              name="subscription_type"
              placeholder="Select Registration Type"
              isMulti={true}
              className={`${
                submitErrors &&
                `${errors.subscription_type ? "is-invalid" : "is-valid"}`
              }`}
              countryId={countryId}
            />
            <ErrorMessage
              name="subscription_type"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col> 
        {values.subscription_type.indexOf('5') >= 0 && (
          <>
            <Col xs="12" lg="6">
              <FormGroup className="position-relative">
                <Label>
                  1Webbb Username of Parent or Guardian
                  <span className="font-danger">*</span>
                </Label>
                <Field
                  type="text"
                  name="guardian_username"
                  className={`form-control ${
                    submitErrors &&
                    `${
                      errors.guardian_username
                        ? "is-invalid"
                        : "is-valid"
                    }`
                  }`}
                  placeholder={"Enter username"}
                />
                <ErrorMessage
                  name="guardian_username"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="6">
              <FormGroup className="position-relative">
                <Label>
                  Email Address of Parent or Guardian
                  <span className="font-danger">*</span>
                </Label>
                <Field
                  type="email"
                  name="guardian_email"
                  className={`form-control ${
                    submitErrors &&
                    `${
                      errors.guardian_email
                        ? "is-invalid"
                        : "is-valid"
                    }`
                  }`}
                  placeholder={"Enter Email Address"}
                />
                <ErrorMessage
                  name="guardian_email"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
          </>
        )}

        <Col xs="12">
          <Link to={`${process.env.PUBLIC_URL}/all-registration-plans`}>
            {RegistrationPlanText}
          </Link>
          <FormGroup className="position-relative">
            <Label>
              {RegistrationPlan}
              <span className="font-danger">*</span>
            </Label>
            <Field
              as="select"
              name="plan_id"
              className={`form-control ${
                submitErrors && `${errors.plan_id ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option>
                {"Select"} {RegistrationPlan}
              </option>
              {registrationPlanOptions}
            </Field>
            <ErrorMessage
              name="plan_id"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" className="ms-2">
          {priceCode}
        </Col>
        {showingPriceList?.length > 0 && (
          <Col xs="12" className="mt-4">
            <Card key={`3`}>
              <CardHeaderCollapse
                title={PlanDetails}
                isOpen={isMyPlanOpen}
                setIsOpen={setIsMyPlanOpen}
              />
              <Collapse isOpen={isMyPlanOpen}>
                <CardBody>{showingPriceList}</CardBody>
              </Collapse>
            </Card>
          </Col>
        )}
        <Col xs="12" className="ms-4">
          <FormGroup check>
            <Field
              id="invalid-check-wizard"
              name="agreeTermsConditions"
              type="checkbox"
              className="form-check-input"
            />
            <Label
              for="invalid-check-wizard"
              className={`mb-0 d-block ${
                submitErrors &&
                ` ${
                  errors.agreeTermsConditions ? "text-danger" : "text-success"
                }`
              }`}
            >
              {AgreeToTermsAndConditions}
              <Link to="" className="px-1">
                {TermsAndConditions},
              </Link>
              <Link to="" className="px-1">
                {PrivacyPolicy}.
              </Link>
            </Label>
          </FormGroup>
        </Col>
        <Col xs="12 pt-4">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
          >
            {ConnectNewRegisterAccount}
          </Btn>
        </Col>
      </Row>
    </Form>
  );
}

export default RegisterForm;
