import React from 'react'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs'  
import ReservationsAttendanceContainer from '../../../Component/Application/ReservationsAttendanceList/ReservationsAttendanceContainer'

const ReservationsAttendance = () => {
  return (
    <div className='page-body'>
        <Breadcrumbs mainTitle={`My Grades and Surveys`} parent={`User Dashboard`} />
        <ReservationsAttendanceContainer/>
    </div>
  )
}

export default ReservationsAttendance