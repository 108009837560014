import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Close } from "../../../../utils/Constant"; 
import { Btn, H1, H4, H5 } from "../../../../AbstractElements";

const BirthdateProofModal:React.FC<any> = ({backdrop, centered, size, isOpen, toggle, title, onClosed, sizeTitle, fullTitle, modalBodyClassName,onSubmitClick, footer, children } ) => {
  return (
    <Modal backdrop={backdrop} centered={centered} size={size} isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
      {(title || sizeTitle || fullTitle) && (
        <div className="modal-header" onClick={toggle}>
          {title && <H5 className="modal-title">{title}</H5>}
          {sizeTitle && <H4>{sizeTitle}</H4>}
          {fullTitle && <H1 className="fs-5">{fullTitle}</H1>}
          <Btn className="py-0" close></Btn>
        </div>
      )}
      <ModalBody className={modalBodyClassName ? modalBodyClassName : ""}>{children}</ModalBody>
      {(footer) && (
        <ModalFooter>
          <Btn color="secondary" onClick={toggle}>{Close}</Btn>
          <Btn onClick={onSubmitClick} color="primary">{'Confirm & Save'}</Btn>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default BirthdateProofModal;