import { useEffect } from 'react';
import UserHeader from '../../../../Component/App/MyMediaCenter/About/UserHeader'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs';
import { Parent1Web } from '../../../../utils/Constant';
import OnlineStoreContainer from '../../../../Component/App/MyMediaCenter/OnlineStore/OnlineStoreContainer';

const OnlineStore = () => {
  useEffect(()=>{
    var scroll_top  =  window.innerWidth > 415 ? 360 : 210;
    window.scrollTo({ top: scroll_top, behavior: "smooth" });
  });
  return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`Online Store`} parent={Parent1Web} />
      <UserHeader/> 
      <OnlineStoreContainer/>
    </div>
  )
}

export default OnlineStore