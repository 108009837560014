import * as Yup from "yup";
import { RequiredFieldValidationText } from "../../../utils/Constant";
/* Start -- Manage Album Form */
export interface ManageAlbumFormValidationProp {
  // albumName: string;
  description: string;
}

export const manageAlbumFormInitialValue: ManageAlbumFormValidationProp = {
  // albumName: "",
  description:""
};

export const manageAlbumFormValidationSchema = Yup.object().shape({
  // albumName: Yup.string().required(RequiredFieldValidationText),
  description: Yup.string().required(RequiredFieldValidationText),
});

/* End -- Manage Album Form */
