import { Label } from "reactstrap"; 
import { ErrorMessage, Field } from "formik";
import CustomSelect from "./CustomSelect";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { useGetGroupsDataMutation } from "../../ReduxToolkit/Reducers/Group/groupApiSlice";
import { toast } from "react-toastify";
import { setGroups } from "../../ReduxToolkit/Reducers/commonInputSlice";
import { useEffect } from "react";

const Groups = (props: any) => {
  
  const { className, isMulti, name, title, isRequired , reloadGroupsData} = props;

  const { groups } = useAppSelector((state) => state.common);

  const [getGroupsDataApiCall, { isLoading }] =
  useGetGroupsDataMutation();

  const dispatch = useAppDispatch(); 

  useEffect(() => {
    const getGroups = async () => {
      try {
        const response = await getGroupsDataApiCall().unwrap();
        const { data, success, message } = response;
        if (success === true) {
          let filterGroups = data.map((item: any) => {
            return { label: item.name, value: item.id };
          });
          // Add the clearable option
          filterGroups = [...filterGroups];
          dispatch(setGroups(filterGroups));
        }
      } catch (error: any) {
        toast.error(error?.data?.message);
      }
    };
    getGroups();
  }, [dispatch,setGroups,reloadGroupsData]);


  if (isLoading) return <div>Data not found!</div>;

  if (!groups || groups.length === 0)
    return <div>Data not found!</div>;

  return (
    <>
      <Label check>
        {title} { isRequired && <span className="txt-danger">*</span> }
      </Label>
      <Field
        className={`custom-select ${className || ""}`}
        name={name}
        options={groups}
        component={CustomSelect}
        placeholder={`Please select a ${title}`}
        isMulti={isMulti || false}
      />
      { isRequired && <ErrorMessage name={name} component="span" className="invalid-feedback" /> }
    </>
  );
};

export default Groups;
