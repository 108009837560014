import CustomSelect from "./CustomSelect";
import { Field } from "formik"; 

const jobLocation = [
  { label: "Remote", value: "Remote" },
  { label: "Specific Address", value: "Specific Address" },
  { label: "Job Location", value: "Job Location" },
];

function JobLocationType(props: any) { 
  const { name, className, isMulti, placeholder } = props;  
  
  return (
    <Field
      className={`custom-select ${className}`}
      name={name}
      options={jobLocation}
      component={CustomSelect}
      placeholder={placeholder}
      isMulti={isMulti}
    />
  );
}

export default JobLocationType;
