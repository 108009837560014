import { useEffect, useRef } from "react";
import { Container } from 'reactstrap'
import UserHeader from '../../../../Component/App/MyMediaCenter/About/UserHeader'
import AlbumsData from '../../../../Component/App/MyMediaCenter/AlbumsData/AlbumsData'
import { Parent1Web } from "../../../../utils/Constant";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";

const Albums = () => {
  useEffect(()=>{
    var scroll_top  =  window.innerWidth > 415 ? 360 : 210;
    window.scrollTo({ top: scroll_top, behavior: "smooth" });
  });
  return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`Albums`} parent={Parent1Web} />
      <UserHeader/>
      <Container fluid className="search-page">
        <AlbumsData/>
      </Container>
    </div>
  )
}

export default Albums