import AttendanceContainer from '../../../../Component/Tools/BrandSupportTools/AttendanceSystem/AttendanceContainer'

const AttendanceSystem = () => {
  return (
    <div className="page-body">
        <AttendanceContainer/>
    </div>
  )
}

export default AttendanceSystem