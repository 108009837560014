import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import CommonSwitchSpan from "../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import { ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";
import RadioButtonGroup from "../../../Buttons/ButtonGroup/RadioButtonGroup/RadioButtonGroup";

const TestOrQuizSettingsForm = (props: any) => {
    const {
        submitErrors,
        errors,
        addquizsurveysTab,
        values,
        setFieldValue,
        testquizsetting,
        heading
    } = props;
    // State to track if "Negative Grade" is selected
    const [selectedNegativeMarking, setSelectedNegativeMarking] = useState(
        values.negativeMakingOptional === "zero" ? true : false
    );
    const [isRepeatAllowed, setIsRepeatAllowed] = useState(false);

    const handleNegativeMarkingChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        setSelectedNegativeMarking(!selectedNegativeMarking);
        // setSelectedNegativeMarking(value); // Update the state with the selected value
        setFieldValue("negativeMakingOptional", value); // Update Formik field value
        setFieldValue("pointGrade", value === "negative-grade" ? -5 : 0); // Set point grade based on selection
    };
    const [
        selectedNegativeMarkingObligator,
        setSelectedNegativeMarkingObligator,
    ] = useState("zero1");
    const handleNegativeMarkingObligatorChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: any
    ) => {
        const { value } = e.target;
        setSelectedNegativeMarkingObligator(value); // Update the selected value in state
        setFieldValue("negativeMakingObligator", value); // Update the Formik field value
    };


    const handleRadioChange = (event) => {
      setIsRepeatAllowed(event.target.value === 'allow');
    };
    return (
        <>
          {heading == false ? '' :  <Row>
                <Col className="mb-3">
                    <h4 style={{ color: addquizsurveysTab ? "" : "gray" }}>
                        Test or Quiz Settings
                    </h4>
                </Col>
            </Row>}
            {testquizsetting ? (
                " "
            ) : (
                <Row>
                    <Col sm="4" className="mb-3">
                        <Label check>
                            Maximum Percentage % of Wrong Answers to Stop the
                            Test{" "}
                        </Label>
                        <Field
                            type="text"
                            name="maximum-incorrect-answer"
                            placeholder="Maximum Incorrect Answer"
                            className="form-control"
                        />
                    </Col> 
                    <Col sm="4" className="mb-3">
                        <Label check>
                            Show the Correct Answers after Finishing the Quiz
                            <span className="txt-danger">*</span>
                        </Label>
                        <br />
                        <div className="d-flex">
                            <div className="flex-shrink-0  text-end icon-state switch-outline">
                                <CommonSwitchSpan
                                    color={"primary"}
                                    name="showAnswerList"
                                    className={`${
                                        submitErrors &&
                                        `${
                                            errors.showAnswerList
                                                ? "danger"
                                                : "primary"
                                        }`
                                    }`}
                                    defaultChecked
                                />
                            </div>
                            <Label className="m-l-10" check></Label>
                        </div>
                    </Col>
                    {testquizsetting ? (
                        ""
                    ) : (
                        <>
                            <Row>
      <Col sm="12" className="mb-3">
        <Label check>
          Retaking and Repeating the Quiz <span className="txt-danger">*</span>
        </Label>
        <div className="mb-3 d-flex">
          <Col sm="4" className="m-checkbox-inline px-2">
            <Label for="edo-ani-A" check>
              <Input
                className="radio_animated"
                id="edo-ani-A"
                type="radio"
                name="rdo-aniA"
                value="no-repeat"
                onChange={handleRadioChange}
                checked={!isRepeatAllowed}
              />
              No Repeat (0)
            </Label>
          </Col>
          <Col sm="4" className="m-checkbox-inline px-2 mt-1">
            <Label for="edo-ani-B" check>
              <Input
                className="radio_animated"
                id="edo-ani-B"
                type="radio"
                name="rdo-aniB"
                value="allow"
                onChange={handleRadioChange}
                checked={isRepeatAllowed}
              />
              Allow Repeating
            </Label>
          </Col>
        </div>
        
        {/* Conditionally render this div based on isRepeatAllowed */}
        {isRepeatAllowed && (
          <div className="mb-3 d-flex gap-3">
            <Col sm="4" className="mb-3">
              <FormGroup check>
                <Label className="mb-0" for="maxRepeats" check>
                  Maximum Number of Repeats
                </Label>
                <Field
                  name="maxRepeats"
                  type="number"
                  value="1"
                  className="form-control mt-2"
                />
              </FormGroup>
            </Col>
            <Col sm="4" className="mb-3">
              <FormGroup check>
                <Label className="mb-0" for="waitingHours" check>
                  Number of Waiting Hours Before Repeating
                </Label>
                <Field
                  name="waitingHours"
                  type="number"
                  value="1"
                  className="form-control mt-2"
                />
              </FormGroup>
            </Col>
          </div>
        )}
      </Col>
    </Row>
                        </>
                    )}
                </Row>
            )}

            <Row>
                {testquizsetting ? (
                    <>
                    <Col sm="4" className="mb-3">
         
         <Label check>
         Negative Marking for Wrong Answer (Optional)
         </Label>
         <Row>
           <Col sm="auto" className="mb-3 ms-4">
             <FormGroup check>
               <Field
                 id="zero"
                 name="negativeMakingOptional"
                 type="radio"
                 value="zero"
                 className="form-check-input"
                 checked={selectedNegativeMarking == false? true :false} // Check if "zero" is selected
                 onChange={handleNegativeMarkingChange} // Handle radio button change
               />
               <Label className="mb-0" for="zero" check>Zero ( 0 )</Label>
             </FormGroup>
           </Col>

           <Col sm="auto" className="mb-3 ms-4">
             <FormGroup check>
               <Field
                 id="negative-grade"
                 name="negativeMakingOptional"
                 type="radio"
                 value="negative-grade"
                 className="form-check-input"
                 checked={selectedNegativeMarking == true? true :false} // Check if "negative-grade" is selected
                 onChange={handleNegativeMarkingChange} // Handle radio button change
               />
               <Label className="mb-0" for="negative-grade" check>Negative Grade</Label>
             </FormGroup>
           </Col>
         </Row>
       </Col> 
                    {values.negativeMakingOptional === "negative-grade" && (
                        <Col sm="4" className="mb-3">
                            <FormGroup check>
                                <Label check>
                                    Negative Marking Value{" "}
                                    <span className="txt-danger">*</span>
                                </Label>
                                <Field
                                    className="form-control"
                                    type="number"
                                    name="pointGrade"
                                    placeholder="-0.5"
                                    step="0.5" // Allow changes in increments of 0.5
                                    min="-100" // Set a minimum value to allow negative numbers
                                    value={
                                        values.pointGrade < 0
                                            ? values.pointGrade
                                            : -Math.abs(values.pointGrade)
                                    } // Ensure it's negative
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const value = parseFloat(
                                            e.target.value
                                        );
                                        // Ensure the value is a valid number and adjust to negative if necessary
                                        if (!isNaN(value)) {
                                            setFieldValue(
                                                "pointGrade",
                                                value < 0
                                                    ? value
                                                    : -Math.abs(value)
                                            );
                                        }
                                    }}
                                />
                                <ErrorMessage
                                    name="pointGrade"
                                    component="span"
                                    className="invalid-feedback"
                                />
                            </FormGroup>
                        </Col>
                    )}
                    </>
                ) : (
                    ""
                )}
            </Row>
        </>
    );
};

export default TestOrQuizSettingsForm;