import CartContainer from "../../../../Component/Application/Ecommerce/Cart/Cart";

const Cart = () => {
  return (
    <div className="page-body">
      <CartContainer />
    </div>
  );
};

export default Cart;
