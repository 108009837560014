import { useState } from "react";
import { Card, CardBody, Col, Modal, Row } from "reactstrap";
import { Btn, H3, H4, SVG } from "../../../AbstractElements";
import { Carousel } from "react-responsive-carousel";
import AdSense from "../../WebsiteSettings/AdSense/Index";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";

export interface CouponModalInterface {
    value: boolean;
    setOpenModal: (value: boolean) => void;
    dataId: number; 
    items: { id: number; title: string }[];
}

const CouponModal = ({value ,modalBodyClassName,adsShow, setOpenModal , dataId,items}:CouponModalInterface) => {
    const [open, setOpen] = useState(value);
    const [currentId, setCurrentId] = useState(dataId);
 
    const onCloseModal = () => {
        setOpen(false);
        setOpenModal(false);
    };

    const handleNext = () => {
        if (currentId < items.length - 1) {
            setCurrentId(currentId + 1);
        }
    };

    const handlePrevious = () => {
        if (currentId > 0) {
            setCurrentId(currentId - 1);
        }
    };
    const { websiteSettingsData }: any = useAppSelector((state) => state.common);

    return (
        <Modal wrapClassName='product-box' className={` modal-dialog-scrollable ${ modalBodyClassName ? modalBodyClassName : "" }`}  fade centered size="xl" isOpen={open} toggle={onCloseModal}>
                <div className='modal-header' onClick={onCloseModal}>
                   
                <H4> {`Coupon Name `} {''}{items[currentId].title}</H4>

                    <Btn className="py-0" close></Btn>
                </div>
                <div className='modal-body bg-light'>
                    <Row className="product-box">
                        <Col lg="4" sm="12">
                            <Card>
                                <CardBody>
                                <Carousel
                                    className="owl-carousel owl-theme"
                                    showStatus={false}
                                    showIndicators={false}
                                    showArrows={true}
                                    swipeable={true}
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showThumbs={true}
                                >
                                    <div className="item">
                                    <img src="../assets/images/ecommerce/01.jpg" alt="" />
                                    </div>
                                    <div className="item">
                                    <img src="../assets/images/ecommerce/02.jpg" alt=""/>
                                    </div>
                                    <div className="item">
                                    <img src="../assets/images/ecommerce/03.jpg" alt=""/>
                                    </div>
                                    <div className="item">
                                    <img src="../assets/images/ecommerce/04.jpg" alt=""/>
                                    </div>
                                    <div className="item">
                                    <img src="../assets/images/ecommerce/05.jpg" alt=""/>
                                    </div>
                                    <div className="item">
                                    <img src="../assets/images/ecommerce/06.jpg" alt=""/>
                                    </div>
                                    <div className="item">
                                    <img src="../assets/images/ecommerce/07.jpg" alt=""/>
                                    </div>
                                    <div className="item">
                                    <img src="../assets/images/ecommerce/08.jpg" alt=""/>
                                    </div>
                                </Carousel>
                                </CardBody>
                            </Card>
                        </Col>
                        { websiteSettingsData?.google_ads_index === "on" && adsShow &&<Col lg="3" sm="12">
                    <AdSense />
                    </Col>}
                        <Col lg={websiteSettingsData?.google_ads_index === "on" ? "5" :'12'}  sm="12">
                            <Card>
                                <CardBody>
                                    <div className="product-page-details">
                                        <H3>{`Coupon Details`}</H3>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer ">
                <div className="next_previous position-relative pt-0" > 
                <Btn onClick={handlePrevious} className="border" disabled={currentId === 0}>
                    <div className="d-flex align-items-center gap-sm-2 gap-1">
                    Previous           <SVG iconId=" back-arrow" />
                    </div>
                </Btn>
                <Btn onClick={handleNext} className="border" disabled={currentId === items.length - 1}>
                    <div className="d-flex align-items-center gap-sm-2 gap-1">
                    Next             <SVG iconId="front-arrow"/>
                    </div>
                </Btn>
                </div>
                {/* <CommonButton step={true} /> */}
            </div>
        </Modal>
    )
}

export default CouponModal