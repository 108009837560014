import React from 'react'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs'
import PaymentMethodsContainer from '../../../Component/Application/Users/PaymentMethods/PaymentMethodsContainer'
import DashboardSubMenu from '../../../Component/Application/DashboardSubMenu/DashboardSubMenu'
import PageHeader from '../../../Component/Dashboard/Default/PageHeader/PageHeader'

const PaymentMethods = () => {
  return (
    <div className="page-body">
        <Breadcrumbs mainTitle={`Payment Methods`} parent={`User Dashboard`} /> 
        
        <PaymentMethodsContainer/>
    </div>
  )
}

export default PaymentMethods