import { apiSlice } from "../../apiSlice";
import { GET_CITIES_BY_COUNTRY } from "../../../Api";

interface City {
  data: any;
  message: string;
  success: boolean;
}

export const cityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCityData: builder.mutation<City,{ country_id: number; state_id: number }>({
      query: (body) => ({
        url: `${GET_CITIES_BY_COUNTRY}`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useGetCityDataMutation } = cityApiSlice;
