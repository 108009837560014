import { Col, TabContent, TabPane } from 'reactstrap' 
import ProductTwo from './ProductTwo'
import ProductThree from './ProductThree'
import ProductFour from './ProductFour'
import ProductFive from './ProductFive'
import { useAppSelector } from '../../../../../../ReduxToolkit/Hooks' 
import ProductCommonbtn from '../CommonButton/ProductCommonbtn'

const ProductTabContent = () => {
  const {navId} = useAppSelector((state) => state.addProductService)
  console.log(navId);
  
  return (
    <Col xxl="9" xl="8" className="box-col-8 mt-1 position-relative">
      <TabContent activeTab={navId}>
         
        <TabPane tabId={1} >
          <ProductTwo />
        </TabPane>
        <TabPane tabId={2} >
          <ProductThree />
        </TabPane>
        <TabPane tabId={3} >
          <ProductFive />
        </TabPane>
        <TabPane tabId={4} >
          <ProductFour />
        </TabPane>
      </TabContent>
      <ProductCommonbtn step={true} bottombutton={true}/>
    </Col>
  )
}

export default ProductTabContent