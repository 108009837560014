import { useState } from "react";
import { AddonCategory, ManageAlbums } from "../../../../utils/Constant";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
    AddonCategoryColumnData,
    supportTicketData,
} from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import {
    AddonCategoriesTableAction,
    ManageOnlineStore,
} from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import AddCategoriesSubMenu from "./AddCategoriesSubMenu/AddCategoriesSubMenu";

const AddonCategories = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);
    const handleRowSelected = () => {};
    return (
        <div className="page-body">
            <Breadcrumbs mainTitle={`${AddonCategory} (Manage Online Store)`} parent={`Manage Store`} />
            <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
            <Container fluid> 
                <Row>
                    <Col sm="12">
                    
                        <Card>  
                        <div className="mt-2">
                                        <AddCategoriesSubMenu />
                                    </div>
                            <CardBody  className="p-2">
                                <>
                                    
                                    {/* </Col></Row> */}
                                    <div className="table-responsive">
                                        <DataTable
                                            className="custom-scrollbar"
                                            columns={AddonCategoryColumnData}
                                            data={data}
                                            striped={true}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={
                                                handleRowSelected
                                            }
                                            clearSelectedRows={toggleDelete}
                                        />
                                    </div>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddonCategories;
