import { Row } from "reactstrap"; 
import UploadTabContent from "./UploadTabContent";
 

const UploadBody = () => {
  return (
    <Row className="g-xl-5 g-3"> 
      <UploadTabContent />
    </Row>
  );
};

export default UploadBody;
