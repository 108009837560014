import { useEffect, useState } from "react";
import { useAppDispatch} from "../../../ReduxToolkit/Hooks";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Parent1Web } from "../../../utils/Constant";
import { productsData } from "../../../Data/Application/Ecommerce/Product";
import { setProductItem } from "../../../ReduxToolkit/Reducers/ProductSlice"; 
import ProductGrid from "../Ecommerce/Products/ProductGrid/ProductGrid"; 
import DefaultFilter from "../Ecommerce/Products/ProductFeatures/DefaultFilter"; 

export const productst= [
  { id:1, src:"faq/1.jpg", name:"", fillStar:true},
  { id:2, src:"faq/1.jpg", name:"", fillStar:true},
  { id:3, src:"faq/1.jpg", name:"", fillStar:true},
  { id:4, src:"faq/1.jpg", name:"", fillStar:true},
  { id:5, src:"faq/1.jpg", name:"", fillStar:true},
  { id:6, src:"faq/1.jpg", name:"", fillStar:true},
  { id:7, src:"faq/1.jpg", name:"", fillStar:true},
  { id:8, src:"faq/1.jpg", name:"", fillStar:true},
  { id:9, src:"faq/1.jpg", name:"", fillStar:true},
  { id:10, src:"faq/1.jpg", name:"", fillStar:true},    
];


const KnowledgeContainer = ( ) => {
  const [sideBarOn, setSideBarOn] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setProductItem(productsData));
  }, []);

  return (
    <>
      <Breadcrumbs mainTitle={'knowledge'} parent={Parent1Web} />
      <Container
        fluid
        className={`product-wrapper ${sideBarOn ? "sidebaron" : ""}`}
      >       
        {/* <DeliveryService/> */}

        <DefaultFilter remove={true}  />      
        <div className="product-grid">
          {/* <ProductFeatures /> */}
          <ProductGrid ModalType="quiz" modalBodyClassName="modal-custom "/>
          {/* <ProductList/> */}
        </div>
      </Container>
    </>
  );
};

export default KnowledgeContainer;
