import { useState } from "react";
import { Formik } from "formik";
import AddGroupForm from "./AddGroupForm";
import {
  GroupFormValidationProp,
  groupFormInitialValue,
  groupFormValidationSchema,
} from "../../Data/Communication/FormControlValidation";
import { toast } from "react-toastify";
import { useSaveGroupDataMutation } from "../../ReduxToolkit/Reducers/Group/groupApiSlice";

function GroupMain(props: any) {

  const { openFromModalToggle, reloadGroups } = props;

  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const [saveGroupDataApiCall, { isLoading }] = useSaveGroupDataMutation();

  const handleSubmit = async (values: GroupFormValidationProp) => {
    console.log(values);
    try {
      const formData = new FormData();
      formData.append("group_name", values.group_name);
      formData.append("group_description", values.group_description);
      formData.append("group_members", values.group_members);
      values.uploadImage && formData.append("group_image", values.uploadImage);

      const response = await saveGroupDataApiCall(formData).unwrap();
      const { data, success, message } = response;
      if (success === true) {
        toast.success(message);
        openFromModalToggle();
        reloadGroups();
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message);
    }
    setSubmitError(false);
  };

  return (
    <Formik
      initialValues={groupFormInitialValue}
      onSubmit={handleSubmit}
      validationSchema={groupFormValidationSchema}
    >
      {({ errors, values, setFieldValue }) => (
        <AddGroupForm
          submitErrors={submitErrors}
          setSubmitError={setSubmitError}
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
        />
      )}
    </Formik>
  );
}

export default GroupMain;
