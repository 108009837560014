import { Col, TabContent, TabPane } from "reactstrap";

import UploadData from "./UploadData";
import CommonButton from "../../Application/Ecommerce/AddProduct/ProductBody/CommonButton/CommonButton";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import AddDetails from "./AddDetails";

const UploadTabContent = () => {
    const { navId } = useAppSelector((state) => state.addProduct);
    return (
        <Col xxl="9" xl="8" className="box-col-8 position-relative">
            <TabContent activeTab={navId}>
                <TabPane tabId={1}>
                    <UploadData />
                </TabPane>
                <TabPane tabId={2}>
                    {/* <UploadData /> */}
                    <AddDetails heading={true} />
                </TabPane>
            </TabContent>
        </Col>
    );
};

export default UploadTabContent;
