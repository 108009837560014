import React from 'react'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs'
import TransactionHistoryContainer from '../../../Component/Application/TransactionHistory/TransactionHistoryContainer'

const TransactionHistory = () => {
  return (
    <div className='page-body'>
        <Breadcrumbs mainTitle={`Transaction History`} parent={`User Dashboard`} />
        <TransactionHistoryContainer/>
    </div>
  )
}

export default TransactionHistory