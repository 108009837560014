import { useState } from "react";
import AddQuestionsForm from "./AddQuestionsForm";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import {
  QuestionFormValidationProp,
  questionFormValidationSchema,
  questionFormInitialValue,
  answerFormValidationSchema,
  answerFormInitialValue,
  answerFormValidationProp,
} from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
const AddAnswersMain = (props: any) => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleSubmit = (values: answerFormValidationProp) => {
    console.log(values);
    setSubmitError(false);
  };
  const [showOptions, setShowOptions] = useState(false);
  const [showinputanswer, setshowinputanswer] = useState(true);
  return(
    <Formik
          initialValues={answerFormInitialValue}
          onSubmit={handleSubmit}
          validationSchema={answerFormValidationSchema}
        >
          {({ errors, values,setFieldValue }) => (
             <Form>
                
      {props.showOptions && (
              <Row className="mt-3">
                <FieldArray name="options">
                  {({ remove, push }) => (
                    <>
                    {values.options.map((option, index) => {
  const letters = ['A', 'B', 'C', 'D']; // Array of option letters
  return (
    <Col sm="6" key={index}>
      <FormGroup>
        <Label>{letters[index]}</Label> {/* Use letters array for labels */}
        <Field
          as="textarea"
          name={`options[${index}]`}
          placeholder={`Enter Option ${letters[index]}`} // Update placeholder as well
          className={`form-control ${errors.options && errors.options[index] ? 'is-invalid' : ''}`}
        />
        <FormGroup check className="mt-2">
          <Field
            type="radio"
            name="correctAnswer"
            value={option}
            className="form-check-input"
          />
          <Label check>Correct Answer</Label>
        </FormGroup>
      </FormGroup>
    </Col>
  );
})}
                      {/* <Col sm="12" className="mb-3">
                        <Button
                          type="button"
                          onClick={() => push("")}
                          color="dark"
                        >
                          Add Option
                        </Button>
                      </Col> */}
                    </>
                  )}
                </FieldArray>
                <ErrorMessage name="correctAnswer" component="div" className="text-danger" />
              </Row>
            )} 
             </Form>
          )}
        </Formik> 
  );
};

export default AddAnswersMain;
