import { apiSlice } from "../../apiSlice";
import {
  USER_MY_RESUME,
  USER_MY_RESUME_SAVE,
  USER_MY_RESUME_UPDATE,
  USER_MY_RESUME_GET_SINGLE,
  USER_MY_RESUME_DELETE,
  WORK_AVAILABILITY_SAVE
} from "../../../Api";

interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const resumeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getResumeData: builder.mutation<SuccessDataProps, void>({
      query: () => ({
        url: USER_MY_RESUME,
        method: "GET",
      }),
    }),
    saveResume: builder.mutation<SuccessDataProps, any>({
      query: (data) => ({
        url: USER_MY_RESUME_SAVE,
        method: "POST",
        body: data,
      }),
    }),
    updateResume: builder.mutation<SuccessDataProps, any>({
      query: (data) => ({
        url: USER_MY_RESUME_UPDATE,
        method: "POST",
        body: data,
      }),
    }),
    getResumeById: builder.mutation<SuccessDataProps, { id: number }>({
      query: (data) => ({
        url: `${USER_MY_RESUME_GET_SINGLE}/${data.id}`,
        method: "GET",
      }),
    }),
    deleteResume: builder.mutation<SuccessDataProps, { id: number }>({
      query: (data) => ({
        url: `${USER_MY_RESUME_DELETE}/${data.id}`,
        method: "DELETE",
      }),
    }),
    saveWorkAvailability: builder.mutation<SuccessDataProps, any>({
      query: (data) => ({
        url: WORK_AVAILABILITY_SAVE,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetResumeDataMutation,
  useSaveResumeMutation,
  useUpdateResumeMutation,
  useGetResumeByIdMutation,
  useDeleteResumeMutation,
  useSaveWorkAvailabilityMutation
} = resumeApiSlice;
