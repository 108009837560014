import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import { ErrorMessage, Field, Form } from "formik";
import { Btn } from "../../../../AbstractElements"; 
import TextCounter from "../../../CommonInput/TextCounter";
import TagsInput from "../../../CommonInput/TagsInput"; 
import TitleInput from "../../../CommonInput/TitleInput";
import LongDescription from "../../../CommonInput/LongDescription";

const AddStudyMaterialLessonForm = (props: any) => {
    const { errors, submitErrors, setSubmitError, values, setFieldValue,category } =
        props;

    return (
        <Form className="form-wizard mt-3">
            <Row> 
                <Col sm="12" className="mb-3">
          <Label check>A Previous Lesson as a Reference (Optional){" "}</Label>
          <Field as="select" className="form-control" name="reference-coupon">
            <option value={""}>Select a Reference</option>
          </Field>
        </Col>
                
                <Col sm="4" className="mb-3">
                <TitleInput title='Title' values={values.title} name='title'  placeholder="Title"/>  

                     
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Cover Image </Label>
                    <span className="txt-danger">*</span>
                    <FormGroup>
                        <Field
                            name="image"
                            className={`form-control`}
                            type="file"
                        />
                    </FormGroup>
                </Col>
                
                <Col sm="4" className="mb-3">
                    <Label check>Url</Label>
                    <FormGroup>
                        <Field
                            type="text"
                            name="url"
                            placeholder="URL"
                            className={`form-control ${
                                submitErrors &&
                                `${errors.url ? "is-invalid" : "is-valid"}`
                            }`}
                        />
                        <ErrorMessage
                            name="url"
                            component="span"
                            className="invalid-feedback"
                        />
                    </FormGroup>
                </Col>
            </Row>  
            <Row> 
                <Col sm="12" md="12">
                    <FormGroup>
                    <LongDescription lable={'Lesson Text'}   value={values.studymateialdescription} id="editor_container"/>

                    </FormGroup>
                </Col>
                <Col sm="4" className="mb-3">
                    <Label check>Homework (Optional)</Label>
                    <Field
                        as="textarea"
                        name="homework"
                        placeholder="Homework"
                        className="form-control"
                        maxLength="300"
                    />
                    <TextCounter
                        length={values?.homework?.length}
                        totalLength="300"
                    />
                </Col>
            </Row>
            {/* <Row className="justify-content-between">
                <Col sm="auto" className="mb-3">
                    <Btn
                        color="secondary"
                        type="submit"
                        onClick={() => setSubmitError(true)}>
                        Save as a Draft
                    </Btn>
                </Col>
            </Row> */}
        </Form>
    );
};

export default AddStudyMaterialLessonForm;
