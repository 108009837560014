import { useState } from "react";
import { Card, CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  ManageBranches,
  AddNewBranchPublicContact,
} from "../../../../../utils/Constant";
import AddBranchPublicContactForm from "../../../../../Component/Tools/ManageOnlineStore/ManageBranches/BranchPublicContact/AddBranchPublicContactForm";
import { Formik } from "formik";
import {
  BranchPublicContactFormValidationProp,
  branchPublicContactFormInitialValue,
  branchPublicContactFormValidationSchema,
} from "../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import Message from "../../../../../Component/CommonMessage/Message";
import SubMenu from "../../../../../Component/Application/SubMenu/SubMenu"; 
import { Link } from "react-router-dom";
import { BranchsAction } from "../../../../../Data/Tools/ManageContents/ManageContentButtons";
import BranchSubMenu from "../BranchSubMenu/BranchSubMenu";
import ManageOnlineSubMenu from "../../ManageOnlineSubMenu/ManageOnlineSubMenu";

const AddBranchPublicContact = () => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleSubmit = (values: BranchPublicContactFormValidationProp) => {
    console.log(values);
    setSubmitError(false);
  };

  return (
    <div className="page-body ">
      <Breadcrumbs
        mainTitle={`${AddNewBranchPublicContact} (Manage Online Store)`}
        parent={ManageBranches}
      />
       <Container fluid>   
            <CardHeader className='pb-0 pt-0'>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
      <Container  fluid>
      <div className='ms-1 mt-2'>
      <BranchSubMenu sliced='-4'/>
       </div> 
        <Card className="p-4">
        <Message message="Please add at least one branch first to be able to use this form."/>
        <Formik
          initialValues={branchPublicContactFormInitialValue}
          onSubmit={handleSubmit}
          validationSchema={branchPublicContactFormValidationSchema}
        >
          {({ errors, values, setFieldValue }) => (
            <AddBranchPublicContactForm
              submitErrors={submitErrors}
              setSubmitError={setSubmitError}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
          )}
        </Formik>
        </Card>
      </Container>
    </div>
  );
};

export default AddBranchPublicContact;
