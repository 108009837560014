import { Container } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { CustomFileInputsUpload, Home, MediaCap } from "../../../utils/Constant";
import UploadData from "../../../Component/App/UploadData/UploadData";
import ProductLeftSidebar from "../../../Component/Application/Ecommerce/AddProduct/ProductBody/ProductLeftSidebar/ProductLeftSidebar";
import UploadBody from "../../../Component/App/UploadData/UploadBody";

const Upload = () => {
  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={CustomFileInputsUpload} parent={Home} />
      <Container fluid> 
        <UploadBody />
      </Container>
    </div>
  );
};

export default Upload;
