import { Col, FormGroup, Input, Label } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useDeleteUserAddressMutation } from "../../../ReduxToolkit/Reducers/AccountSettings/addressApiSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import Loading from "../../../Component/Loading/Loading";

function ShippingAddressesList(props: any) {
  const {
    userAddresses,
    fetchUserAddressesList,
    setDefaultUserAddressHandler,
  }: any = props;

  const [deleteUserAddressApi, { isLoading }] = useDeleteUserAddressMutation();

  const navigate = useNavigate();

  const deleteAddressHandler = async (id: number) => {
    try {
      const response = await deleteUserAddressApi({ id: id }).unwrap();
      const { success, message } = response;
      if (success === true) {
        toast.success(message);
        fetchUserAddressesList();
        navigate("/account-settings/shipping-addresses");
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message);
    }
  };

  const deletePopupHandler = (id: number) => {
    SweetAlert.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover.",
      showCancelButton: true,
      cancelButtonText: "No, Cancel!",
      confirmButtonText: "Yes, Delete it!",
      confirmButtonColor: "var(--theme-default)",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAddressHandler(id);
      }
    });
  };

  const updateAddressHandler = (id: number) => {
    navigate(`/account-settings/shipping-addresses/update/${id}`);
  };

  const content =
    userAddresses.addressList.length > 0 ? (
      userAddresses.addressList.map((item: any) => {
        const {
          id,
          full_name,
          addressline1,
          addressline2,
          landmark,
          pincode,
          mobile_number,
          is_default,
        } = item;
        return (
          <Col xxl="4" sm="6" key={id}>
            <div className="card-wrapper border rounded-3 h-100 light-card">
              <div className="collect-address d-flex justify-content-between">
                <div className="d-flex gap-2 align-items-center">
                  <FormGroup check className="radio radio-primary ps-3">
                    <Input
                      type="radio"
                      name="address"
                      checked={is_default > 0 ? true : false}
                    />
                    {is_default > 0 ? (
                      <Label
                        className="form-check-label mb-0"
                        for={`Home`}
                      >{`Default`}</Label>
                    ) : (
                      <Label
                        className="form-check-label mb-0"
                        for={`Home`}
                        onClick={() => setDefaultUserAddressHandler(id)}
                      >{`Default`}</Label>
                    )}
                  </FormGroup>
                </div>
                <div className="card-icon">
                  <i
                    className="fa fa-pencil p-1"
                    onClick={() => updateAddressHandler(id)}
                  />
                  {is_default > 0 ? (
                    ""
                  ) : (
                    <i
                      className="fa fa-trash-o p-1"
                      onClick={() => deletePopupHandler(id)}
                    />
                  )}
                </div>
              </div>
              <div className="shipping-address">
                <H3>{full_name}</H3>
                <span>
                  Address:{" "}
                  {`${addressline1} ${addressline2} ${landmark}, ${pincode}`}
                </span>
                {mobile_number && <span>Contact: {mobile_number}</span>}
              </div>
            </div>
          </Col>
        );
      })
    ) : (
      <div>Address Not Found</div>
    );
  
  if(isLoading) return <Loading/>;
  
  return content;
}

export default ShippingAddressesList;
