import React from 'react';
import { Spinner } from 'reactstrap';
import style from './loading.module.css';

const Loading: React.FC = () => { 
  return (
    <div className={style['loading-overlay']}>
      <Spinner color="primary" />
    </div>
  );
};

export default Loading;