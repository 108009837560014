// import React, { useState } from "react";
// import { Formik, Field, Form } from "formik";
// import { Col, Row, Button, Label } from "reactstrap";
// import Select from "react-select"; // Import react-select

// const NextAudioDropdownPlayer = (props: any) => {
//   const { audioEffects } = props;
//   const [selectedAudios, setSelectedAudios] = useState<string[]>([]); // Store an array of selected audio effects
//   const [audioPlayers, setAudioPlayers] = useState<HTMLAudioElement[]>([]); // Store Audio objects for each selection
//   const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false); // Track if user has made a selection

//   // Handle audio selection change
//   const handleAudioChange = (selectedOptions: any, setFieldValue: (field: string, value: any) => void) => {
//     const selectedAudioNames = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];

//     setFieldValue("audio", selectedAudioNames); // Update Formik state
//     setSelectedAudios(selectedAudioNames); // Update the selected audios with names

//     // Create audio objects for each selected audio effect and play immediately
//     const players = selectedAudioNames.map((name) => {
//       const audioSrc = audioEffects.find(effect => effect.name === name)?.src || "";
//       const audio = new Audio(audioSrc);
//       audio.play(); // Play the audio immediately upon selection
//       return audio; // Return Audio object to store for replay
//     });

//     setAudioPlayers(players); // Store the Audio objects
//     setHasUserInteracted(true); // Mark that user has selected audio
//   };

//   // Handle replay of the selected audio effects
//   const handleReplay = () => {
//     audioPlayers.forEach((audioPlayer) => {
//       audioPlayer.pause(); // Pause if already playing
//       audioPlayer.currentTime = 0; // Reset playback to the beginning
//       audioPlayer.play(); // Replay the selected audio
//     });
//   };

//   const audioOptions = audioEffects.map((effect) => ({
//     value: effect.name,
//     label: effect.name,
//   }));

//   return (
//     <Row className="d-flex justify-content-between align-items-end">
//       <Col>
//         <Formik
//           initialValues={{ audio: [] }} // Set the default audio effect to an empty array for multi-selection
//           onSubmit={() => {}}
//         >
//           {({ setFieldValue, values }) => (
//             <Form>
//               <div>
//                 <Label htmlFor="audio">Select Sound Effects:</Label>
//                 <Field name="audio">
//                   {({ field }: any) => (
//                     <Select
//                       {...field}
//                       isMulti={true} // Enable multi-selection
//                       options={audioOptions} // Audio effect options
//                       className="basic-multi-select"
//                       classNamePrefix="select"
//                       onChange={(selectedOptions) => handleAudioChange(selectedOptions, setFieldValue)}
//                       value={audioOptions.filter((option) =>
//                         values.audio.includes(option.value)
//                       )}
//                     />
//                   )}
//                 </Field>
//               </div>
//             </Form>
//           )}
//         </Formik>
//       </Col>
//       <Col>
//         <Button color="primary" onClick={handleReplay} disabled={!hasUserInteracted || audioPlayers.length === 0}>
//           Replay Selected Sounds
//         </Button>
//       </Col>
//     </Row>
//   );
// };

// export default NextAudioDropdownPlayer;
// import React, { useState } from "react";
// import { Formik, Field, Form } from "formik";
// import { Col, Row, Button, Label } from "reactstrap";
// import Select from "react-select"; // Import react-select

// const NextAudioDropdownPlayer = (props: any) => {
//   const { audioEffects } = props;
//   const [audioPlayers, setAudioPlayers] = useState<HTMLAudioElement[]>([]); // To store multiple Audio objects
//   const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false); // Track if user has made a selection

//   // Handle audio selection change
//   const handleAudioChange = (selectedOptions: any, setFieldValue: (field: string, value: any) => void) => {
//     // Stop all currently playing audios
//     audioPlayers.forEach((player) => {
//       player.pause();
//       player.currentTime = 0; // Reset playback to the start
//     });

//     // Clear out the previous audio players
//     setAudioPlayers([]);

//     // Update Formik state with selected audio values
//     const selectedAudioNames = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
//     setFieldValue("audio", selectedAudioNames); // Update Formik state

//     // Create audio elements for each selected option and play them
//     selectedAudioNames.forEach((audioName: string) => {
//       const audioSrc = audioEffects.find(effect => effect.name === audioName)?.src || "";

//       if (audioSrc) {
//         const newAudioPlayer = new Audio(audioSrc);
//         newAudioPlayer.play(); // Play the selected audio
//         setAudioPlayers((prev) => [...prev, newAudioPlayer]); // Add the new audio player to the array
//       }
//     });

//     setHasUserInteracted(true); // Mark that user has selected an audio
//   };

//   const audioOptions = audioEffects.map((effect) => ({
//     value: effect.name,
//     label: effect.name,
//   }));

//   return (
//     <Row className="d-flex justify-content-between align-items-end">
//       <Col>
//         <Formik
//           initialValues={{ audio: audioEffects[0]?.name || "" }} // Set the default audio effect to the first item
//           onSubmit={() => {}}
//         >
//           {({ setFieldValue, values }) => (
//             <Form>
//               <div>
//                 <Label htmlFor="audio">Select Sound Effect:</Label>
//                 <Field name="audio">
//                   {({ field }: any) => (
//                     <Select
//                       {...field}
//                       options={audioOptions} // Audio effect options
//                       isMulti={true} // Enable multi-selection
//                       className="basic-multi-select"
//                       classNamePrefix="select"
//                       onChange={(selectedOptions) => handleAudioChange(selectedOptions, setFieldValue)}
//                       value={audioOptions.filter(option => values.audio.includes(option.value))} // Set the selected value(s) in the dropdown
//                     />
//                   )}
//                 </Field>
//               </div>
//             </Form>
//           )}
//         </Formik>
//       </Col>
//       <Col>
//         <Button color="primary" disabled={!hasUserInteracted || audioPlayers.length === 0} onClick={() => {
//           // Replay all selected sounds
//           audioPlayers.forEach((audioPlayer) => {
//             audioPlayer.pause();
//             audioPlayer.currentTime = 0; // Reset playback
//             audioPlayer.play(); // Replay the selected audio
//           });
//         }}>
//           Replay Sound
//         </Button>
//       </Col>
//     </Row>
//   );
// };

// export default NextAudioDropdownPlayer;
import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { Col, Row, Button, Label } from "reactstrap";
import Select from "react-select"; // Import react-select

const NextAudioDropdownPlayer = (props: any) => {
  const { audioEffects } = props;
  const [audioPlayers, setAudioPlayers] = useState<HTMLAudioElement[]>([]); // To store multiple Audio objects
  const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false); // Track if user has made a selection

  // Handle audio selection change
  const handleAudioChange = (selectedOptions: any, setFieldValue: (field: string, value: any) => void) => {
    // Stop all currently playing audios by pausing and resetting them
    audioPlayers.forEach((player) => {
      player.pause();
      player.currentTime = 0; // Reset playback to the start
    });

    // Clear out the previous audio players
    setAudioPlayers([]);

    // Update Formik state with selected audio values
    const selectedAudioNames = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setFieldValue("audio", selectedAudioNames); // Update Formik state

    // Create audio elements for each selected option and play them
    const newAudioPlayers: HTMLAudioElement[] = [];
    selectedAudioNames.forEach((audioName: string) => {
      const audioSrc = audioEffects.find(effect => effect.name === audioName)?.src || "";

      if (audioSrc) {
        const newAudioPlayer = new Audio(audioSrc);
        newAudioPlayer.play(); // Play the selected audio
        newAudioPlayers.push(newAudioPlayer); // Add the new audio player to the array
      }
    });

    setAudioPlayers(newAudioPlayers); // Update the state with new audio players
    setHasUserInteracted(true); // Mark that user has selected an audio
  };

  const audioOptions = audioEffects.map((effect) => ({
    value: effect.name,
    label: effect.name,
  }));

  return (
    <Row className="d-flex justify-content-between align-items-end">
      <Col>
        <Formik
          initialValues={{ audio: audioEffects[0]?.name || "" }} // Set the default audio effect to the first item
          onSubmit={() => {}}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div>
                <Label htmlFor="audio">Select Sound Effect:</Label>
                <Field name="audio">
                  {({ field }: any) => (
                    <Select
                      {...field}
                      options={audioOptions} // Audio effect options
                      isMulti={true} // Enable multi-selection
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(selectedOptions) => handleAudioChange(selectedOptions, setFieldValue)}
                      value={audioOptions.filter(option => values.audio.includes(option.value))} // Set the selected value(s) in the dropdown
                    />
                  )}
                </Field>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col>
        <Button color="primary" disabled={!hasUserInteracted || audioPlayers.length === 0} onClick={() => {
          // Replay all selected sounds
          audioPlayers.forEach((audioPlayer) => {
            audioPlayer.pause();
            audioPlayer.currentTime = 0; // Reset playback
            audioPlayer.play(); // Replay the selected audio
          });
        }}>
          Replay Sound
        </Button>
      </Col>
    </Row>
  );
};

export default NextAudioDropdownPlayer;
