export const NextScreenaudioEffects = [
  { name: "Warning Sound", src: "/assets/audio/5.mp3" },
    { name: "Click Sound", src: "/assets/audio/1.mp3" },
    { name: "Notification Sound", src: "/assets/audio/2.mp3" },
    { name: "Error Sound", src: "/assets/audio/3.mp3" },
    { name: "Success Sound", src: "/assets/audio/4.mp3" },
  ];
export const CorrectAnsweraudioEffects = [
  { name: "Success Sound", src: "/assets/audio/4.mp3" },
  { name: "Click Sound", src: "/assets/audio/1.mp3" },
  { name: "Notification Sound", src: "/assets/audio/2.mp3" },
  { name: "Error Sound", src: "/assets/audio/3.mp3" },
    { name: "Warning Sound", src: "/assets/audio/5.mp3" },
  ];
export const WrongAnsweraudioEffects = [
  { name: "Error Sound", src: "/assets/audio/3.mp3" },
  { name: "Success Sound", src: "/assets/audio/4.mp3" },
  { name: "Click Sound", src: "/assets/audio/1.mp3" },
  { name: "Warning Sound", src: "/assets/audio/5.mp3" },
  { name: "Notification Sound", src: "/assets/audio/2.mp3" },
  ];
export const WinneraudioEffects = [
  { name: "Notification Sound", src: "/assets/audio/2.mp3" },
  { name: "Error Sound", src: "/assets/audio/3.mp3" },
  { name: "Success Sound", src: "/assets/audio/4.mp3" },
  { name: "Warning Sound", src: "/assets/audio/5.mp3" },
  { name: "Click Sound", src: "/assets/audio/1.mp3" },
  ];