import { Modal, ModalBody } from "reactstrap";
import { Btn, H5 } from "../../../AbstractElements";

const Popup: React.FC<any> = ({
  backdrop,
  centered,
  size,
  isOpen,
  toggle,
  title,
  onClosed,
  modalBodyClassName,
  children
}) => {
  return (
    <Modal
      backdrop={backdrop}
      centered={centered}
      size={size}
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
    >
      <div className="modal-header" onClick={toggle}>
        {title && <H5 className="modal-title">{title}</H5>}
        <Btn className="py-0" close></Btn>
      </div>
      <ModalBody className={modalBodyClassName ? modalBodyClassName : ""}>
        {children}
      </ModalBody>
    </Modal>
  );
};

export default Popup;
