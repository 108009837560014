
import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { links } from '../../../Data/Tools/BrandSupportTools/ButtonActions';
import { useAppSelector } from '../../../ReduxToolkit/Hooks';

// Replace this with your actual links data
 

const SubMenu = () => {
    const { mobileView } = useAppSelector((state) => state.layout);

    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation(); // Get the current location

    // Function to determine if the path is active
    const isActive = (path) => {
        return location.pathname.startsWith(`${process.env.PUBLIC_URL}/${path}`);
    };
   
    return (
        <Container fluid>
            <Card className='mb-0'>
                <CardBody className='pb-0 px-0 pt-0'>
                    <Row>
                    {mobileView ? (
                            <>
                                
                        <Col md="12">
                            {isOpen ? (
                                <IoClose size='2em' onClick={() => setIsOpen(false)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                            ) : (
                                <HiOutlineMenuAlt2 size='2em' onClick={() => setIsOpen(true)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                            )}
                        </Col>
                        <Col md="12">
                            {isOpen ? (
                                <>
                                    {links.map((item, index) => {
                                        const linkPath = `${process.env.PUBLIC_URL}/${item.path}`;
                                        const active = isActive(item.path);
                                        return (
                                            <Link
                                                key={index}
                                                to={linkPath}
                                                className={`p-2 m-1 ps-2 pe-2 border rounded border-primary d-inline-block ${active ? 'sub-active' : ''}`}
                                            >
                                                {item.icon}{item.title}
                                            </Link>
                                        );
                                    })}
                                </>
                            ) : ("")}
                        </Col>
                        </>
                    ) : (
                            <Col md="12  ">
                                {links.map((item, index) =>{
                                    const linkPath = `${process.env.PUBLIC_URL}/${item.path}`;
                                    const active = isActive(item.path);
                                    return (
                                
                                    <Link
                                    key={index}
                                    to={linkPath}
                                    className={`rounded p-2 m-1 ps-2 pe-2 border border-primary d-inline-block ${active ? 'sub-active' : ''}`}
                                    >
                                        {item.icon}{item.title}
                                    </Link>
                                    )
                                }
                                )}
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
};

export default SubMenu;
