import JobDetailsContainer from "../../../../Component/Miscellaneous/JobSearch/JobDetails/JobDetails"

const JobDetails = () => {
  return (
    <div className='page-body'>
      <JobDetailsContainer />
    </div>
  )
}

export default JobDetails