import BrandSupportTab from "../../../../Component/Tools/BrandSupportTools/TabBrandSupport/AddQuizzMain";

 
const BrandSupportKnowledgeTab = () => {
  return (
    <div className="page-body"> 
      <BrandSupportTab  /> 
    </div>
  );
};

export default BrandSupportKnowledgeTab;
