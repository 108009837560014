import { Col, Label } from "reactstrap";
import { Field } from "formik";
import TextCounter from "../../../CommonInput/TextCounter";
import DescriptionInput from "../../../CommonInput/DescriptionInput";

function CorrectAnswerField(props: any) {
  const { item, index, values } = props;
  const currentValue = values?.answers?.length && values?.answers[index];

  return (
    <Col sm={item !== "1" ? "12" : "12"} className="mb-3">
       <DescriptionInput  name='correctAnswer' placeholder="Correct Answer with Explanation" values={values.correctAnswer} lable='Correct Answer with Explanation'/>

      {/* <Label check>Correct Answer with Explanation</Label>
      <Field
        as="textarea"
        name={`answers.${index}.correctAnswer`}
        placeholder="Correct Answer with Explanation"
        className="form-control"
        maxLength='300'
      />
      <TextCounter length={currentValue?.correctAnswer?.length} totalLength='300'/> */}
    </Col>
  );
}

export default CorrectAnswerField;
