import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import DeliveryService from '../../Marketplace/DeliveryService'
import DefaultFilter from '../../../Application/Ecommerce/Products/ProductFeatures/DefaultFilter'
import ProductFeatures from '../../../Application/Ecommerce/Products/ProductFeatures/ProductFeatures'
import ProductGrid from '../../../Application/Ecommerce/Products/ProductGrid/ProductGrid'
import { setProductItem } from '../../../../ReduxToolkit/Reducers/ProductSlice'
import { useAppDispatch } from '../../../../ReduxToolkit/Hooks'
import { productsData } from '../../../../Data/Application/Ecommerce/Product'




const OnlineStoreContainer = () => {
    const [sideBarOn, setSideBarOn] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setProductItem(productsData));
    }, []);

    return (
        <>        
        <Container
            fluid
            className={`product-wrapper py-4 ${sideBarOn ? "sidebaron" : ""}`}
        >       
            <DeliveryService/>
            <DefaultFilter/>      
            <div className="product-grid">
            <ProductFeatures />
            <ProductGrid />
            {/* <ProductList/> */}
            </div>
        </Container>
        </>
    )
}

export default OnlineStoreContainer