import React from 'react'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs' 
import GradeSurveyListContainer from '../../../Component/Application/GradeSurveyList/GradeSurveyListContainer'

const GradeSurvey = () => {
  return (
    <div className='page-body'>
        <Breadcrumbs mainTitle={`My Grades and Surveys`} parent={`User Dashboard`} />
        <GradeSurveyListContainer/>
    </div>
  )
}

export default GradeSurvey