import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../ReduxToolkit/Hooks';
import { Link, useLocation } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';

export const communication_menu = [
    { title: "Start C-Chat", path: 'start-classified-chat', icon: <FontAwesomeIcon icon={fas.faCommentMedical} className='pe-2' /> },
    { title: "Inbox", path: 'message-threads-inbox', icon: <FontAwesomeIcon icon={fas.faEnvelope} className='pe-2' /> },
    { title: "Private C-Chats", path: 'private-classified-chat', icon: <FontAwesomeIcon icon={fas.faCommentDots} className='pe-2' /> },
    { title: "Activity and Event C-Chats", path: 'activities-and-events-classified-chat', icon: <FontAwesomeIcon icon={fas.faCommentDots} className='pe-2' /> },
    { title: "Application: For Work and Study", path: 'applications-classified-chat', icon: <FontAwesomeIcon icon={fas.faUsersRectangle} className='pe-2' /> },
    { title: "Marketplace C-Chat", path: 'marketplace-classified-chat', icon: <FontAwesomeIcon icon={fas.faCommentsDollar} className='pe-2' /> },
    { title: "Project C-Chat", path: 'projects-classified-chat', icon: <FontAwesomeIcon icon={fas.faUsersRectangle} className='pe-2' /> },
    { title: "Contacts", path: 'contacts', icon: <FontAwesomeIcon icon={fas.faUser} className='pe-2' /> },
];

const CommunicationSubMenu = () => {
    const { mobileView } = useAppSelector((state) => state.layout);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation(); // Get the current location

    return (
        <Container fluid>
            <Card className='mb-0'>
                <CardBody className='pb-0 px-2 pt-0 ps-0'>
                    <Row>
                        {mobileView ? (
                            <>
                                <Col md="12">
                                    {isOpen ? (
                                        <IoClose size='2em' onClick={() => setIsOpen(false)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                                    ) : (
                                        <HiOutlineMenuAlt2 size='2em' onClick={() => setIsOpen(true)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                                    )}
                                </Col>
                                <Col md="12">
                                    {isOpen ? (
                                        <>
                                            {communication_menu.map((item, index) => (
                                                <Link
                                                    key={index}
                                                    to={`${process.env.PUBLIC_URL}/communication/${item.path}`}
                                                    className={`p-1 m-1 ps-2 pe-2 border border-primary d-inline-block ${location.pathname.includes(item.path) ? 'sub-active' : ''}`}
                                                >
                                                    {item.icon}{item.title}
                                                </Link>
                                            ))}
                                        </>
                                    ) : ("")}
                                </Col>
                            </>
                        ) : (
                            <Col md="12">
                                {communication_menu.map((item, index) => (
                                    <Link
                                        key={index}
                                        to={`${process.env.PUBLIC_URL}/communication/${item.path}`}
                                        className={`d-inline-block p-1 m-1 ps-2 pe-2 border border-primary rounded ${location.pathname.includes(item.path) ? 'sub-active' : ''}`}
                                    >
                                        {item.icon}{item.title}
                                    </Link>
                                ))}
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
}

export default CommunicationSubMenu;
