// import { Col, Nav, NavItem, NavLink } from "reactstrap";
// import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
// import { addProductNav, addQuizNav } from "../../../../Data/Application/Ecommerce/AddProduct"; 
// import { Href } from "../../../../utils/Constant";
// import { H6, P, SVG } from "../../../../AbstractElements";
// import { setNavId } from "../../../../ReduxToolkit/Reducers/AddProductSlice"; 
// import { setActiveTab } from "../../../../ReduxToolkit/Reducers/tabSlice";
 

// const TabAddQuizzLeftSIde = () => {
//   const {navId} = useAppSelector((state) => state.addProduct) 
//   const dispatch = useAppDispatch();
//   const activeTab = useAppSelector((state) => state.tabs.activeTab);
//   return (
//     <Col xxl="3" xl="3" className="box-col-4e mt-1 sidebar-left-wrapper">
//       <Nav pills className="sidebar-left-icons d-flex " style={{whiteSpace:"normal"}} tabs>
//         {addQuizNav.map((data, i) =>  {
//           console.log(data);
          
//           return(
//           <NavItem key={i} className="flex-fill">
            
//             <NavLink active={navId === data.id ? true : false} onClick={()=>dispatch(setActiveTab(data.id))} href={Href}>
//               <div className="nav-rounded">
//                 <div className="product-icons">
//                   <SVG className="stroke-icon" iconId={data.icon} />
//                 </div>
//               </div>
//               <div className="product-tab-content">
//                 <H6>{data.title}</H6>
//                 <P>{data.detail}</P>
//               </div>
//             </NavLink>
//           </NavItem>
//           )
//         })}
//       </Nav>
//     </Col>
//   );
// };

// export default TabAddQuizzLeftSIde ;
import { Col, Nav, NavItem, NavLink } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { addQuizNav } from "../../../../Data/Application/Ecommerce/AddProduct";
import { Href } from "../../../../utils/Constant";
import { H6, P, SVG } from "../../../../AbstractElements";
import { setActiveTab } from "../../../../ReduxToolkit/Reducers/tabSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TabAddQuizzLeftSIde = () => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector((state) => state.tabs.activeTab);

  return (
    <Col xxl="3" xl="3" className="box-col-4e mt-1 sidebar-left-wrapper">
      <div pills className="sidebar-left-icons" style={{ whiteSpace: "normal" }} tabs>
        {addQuizNav.map((data, i) => (
          <NavItem key={i} className="flex-fill">
            <NavLink
              active={activeTab === data.id} // Compare activeTab from Redux
              onClick={() => dispatch(setActiveTab(data.id))} // Set active tab on click
              href={Href}
            >
              <div className="nav-rounded">
                <div className="product-icons">
                {data.iconfont &&  <FontAwesomeIcon icon={data.iconfont} color="text-muted"/>}
                {data.icon &&  <SVG className="stroke-icon" iconId={data.icon} />}
                </div>
              </div>
              <div className="product-tab-content">
                <H6>{data.title}</H6>
                <P>{data.detail}</P>
              </div>
            </NavLink>
          </NavItem>
        ))}
      </div>
    </Col>
  );
};

export default TabAddQuizzLeftSIde;
