import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  StartChat,
  Communication,
} from "../../../../utils/Constant";
import FormPost from "./FormPost";

import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CommunicationSubMenu from "../../../Application/Chat/CommunicationSubMenu";

export const postTypeData = [{ name: "Lifestyle" }, { name: "Travel" }];

const AddPostContainer = () => {
  const setFieldValue =() =>{}
  
  return (
    <>
      <Breadcrumbs mainTitle={StartChat} parent={Communication} />
      <CommunicationSubMenu/>
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeaderCommon title={PostEdit} /> */}
              <CardBody className="add-post">
              <FormPost/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddPostContainer;
