import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { Col, Row, Button, Label } from 'reactstrap';

const NextAudioDropdownPlayer = (props: any) => {
  const [selectedAudio, setSelectedAudio] = useState<string>(""); // To store audio source
  const [audioPlayer, setAudioPlayer] = useState<HTMLAudioElement | null>(null); // To store the Audio object
  const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false); // Track if user has made a selection
  const { audioEffects } = props;

  // Handle audio selection change
  const handleAudioChange = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: (field: string, value: any) => void) => {
    const value = e.target.value;
    const audioSrc = audioEffects.find(effect => effect.name === value)?.src || "";

    setFieldValue("audio", value); // Update Formik state
    setSelectedAudio(value); // Update the selectedAudio with the name (not src)

    if (audioSrc) {
      const audio = new Audio(audioSrc);
      audio.play();
      setAudioPlayer(audio); // Store the Audio object to allow replay
      setHasUserInteracted(true); // Mark that user has selected an audio
    }
  };

  // Handle replay of the selected audio
  const handleReplay = () => {
    if (audioPlayer) {
      audioPlayer.pause(); // Pause if already playing
      audioPlayer.currentTime = 0; // Reset playback to the beginning
      audioPlayer.play(); // Replay the selected audio
    }
  };

  return (
    <Row className="d-flex justify-content-between align-items-end">
      <Col>
        <Formik
          initialValues={{ audio: audioEffects[0]?.name || "" }} // Set the default audio effect
          onSubmit={() => {}}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div>
                <Label htmlFor="audio">Select Sound Effect:</Label>
                <Field
                  as="select"
                  name="audio"
                  className="form-control"
                  onChange={(e) => handleAudioChange(e, setFieldValue)}
                  value={values.audio} // Bind the Formik value here to ensure the selected item shows up
                >
                  {audioEffects.map((effect) => (
                    <option key={effect.name} value={effect.name}>
                      {effect.name}
                    </option>
                  ))}
                </Field>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col>
        <Button color="primary" onClick={handleReplay} disabled={!hasUserInteracted || !audioPlayer}>
          Replay Sound
        </Button>
      </Col>
    </Row>
  );
};

export default NextAudioDropdownPlayer;
