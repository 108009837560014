import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import TabOne from "./TabOne";
import TabTwo from "./TabTwo";
import TabThree from "./TabThree";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../ReduxToolkit/Hooks";
import { Href } from "../../../../../../utils/Constant";
import { setTabId } from "../../../../../../ReduxToolkit/Reducers/AddProductSlice";
import { productFiveNavData } from "../../../../../../Data/Application/Ecommerce/AddProduct";

const ProductFive = () => {
  const {tabId} = useAppSelector((state)=>state.addProduct)
  const dispatch = useDispatch()
  return (
    <div className="sidebar-body advance-options">  
     
    </div>
  );
};

export default ProductFive;
