import React, { useEffect, useState } from "react";
import { Btn, H4, H5, LI, UL } from "../../../AbstractElements";
import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { Field, Formik } from "formik";
import {
    AddNewIdeaStoryAlbum,
    AttributionRequired,
    Keywords,
} from "../../../utils/Constant";
import CommonSwitchSpan from "../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import Popup from "../../MasterPopup/Popup";
import {
    manageAlbumFormInitialValue,
    manageAlbumFormValidationSchema,
} from "../../../Data/Tools/ManageContents/FormControlsValidation";
import AddManageMyAlbumsForm from "../../Tools/ManageContents/ManageMyAlbums/AddManageMyAlbumsForm";
import ProductBody from "../../Application/Ecommerce/AddProduct/ProductBody/ProductBody";
import TagsInput from "../../CommonInput/TagsInput";
import CustomSelect from "../../CommonInput/CustomSelect";
import {
    createPostInitialValue,
    createPostSchema,
} from "../../../Data/Post/CreatePostValidationSchema";
import AddDetails from "./AddDetails";
import LicenseForm from "./LicenseForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone-uploader";
import { usePhotostockuploadPhotoMutation, useUploadPhotoMutation } from "../../../ReduxToolkit/Reducers/Upload/SocialMediaApiSlice";

type ProductStockContentProps = {
    form?: string;
};

const PhotoStock: React.FC<ProductStockContentProps> = ({ form }) => {
    const [openModal, setOpenModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);  
    const [subalbumType, setSubAlbumType] = useState<string>('1');
    const [submitErrors, setSubmitError] = useState<boolean>(false);

    const openModalToggle = () => setOpenModal(!openModal);
    const openModalDetailToggle = () => setIsOpen(!isOpen);
    const handleSubmit = () => { return console.log("hello");};

    const cityOptions = [
        { label: "Free for Commercial and Non-profit use", value: "Free for Commercial and Non-profit use" },
        { label: "Free for Non-Profit use only", value: "Free for Non-Profit use only" },
    ];
 const [filesToUpload, setFilesToUpload] = useState<any[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState([]); // Track total uploads
    const [dailyUploadedFiles, setDailyUploadedFiles] = useState(0); // Track daily uploads 
    const [isLimitReached, setIsLimitReached] = useState(false);
    // Set your bulk upload limit
    const [PhotostockuploadPhoto] = usePhotostockuploadPhotoMutation();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    
    const dailyLimit = userInfo?.limits.photo_stock_daily_limit_for_upload_files || 0; // Daily upload limit from localStorage
    const bulkLimit = userInfo?.limits.bulk_limit_for_upload_files || 0;
    const fieSize = userInfo?.limits.photo_stock_single_file_size_allowed || 0;
    const heightSize = userInfo?.limits.photo_stock_min_image_height || 0;
    const widthSize = userInfo?.limits.photo_stock_min_image_width || 0;
     const [resposneAPi,setResponseApi] =useState()

     // Reset daily uploads at midnight
     useEffect(() => {
         const resetDailyUploads = () => {
           const now = new Date();
           const midnight = new Date(
             now.getFullYear(),
             now.getMonth(),
             now.getDate() + 1,
             0,
             0,
             0
           );
           const timeUntilMidnight = midnight.getTime() - now.getTime();
     
           setTimeout(() => {
             localStorage.removeItem("photostockFileCount"); // Clear the local storage count at midnight
             setDailyUploadedFiles(0); // Reset the local state
             setIsLimitReached(false); // Reset the limit reached flag
           }, timeUntilMidnight);
         };
     
         // Load the upload count from localStorage if it exists
         const storedCount = localStorage.getItem("dailyUploadedFiles");
         const count = storedCount ? parseInt(storedCount, 10) : 0;
         setDailyUploadedFiles(count);
     
         // Check if the limit is already reached
         if (count >= dailyLimit) {
           setIsLimitReached(true);
         }
     
         resetDailyUploads();
       }, [dailyLimit]);
       useEffect(() => {
         const storedFileCount = localStorage.getItem("photostockFileCount");
         console.log(storedFileCount);
         
         if (storedFileCount && parseInt(storedFileCount) >= 15) {
             console.log(storedFileCount);
             
           setIsLimitReached(true); // If 15 or more files are uploaded, set limit reached
         }
       }, []);
       
       // File upload handler
       const handleChangeStatus = async ({ meta, file }: any, status: string) => {
         if (status === "done") {
             const currentUploads = uploadedFiles.length;
             console.log(file.size ,fieSize);
             
             if (file.size < fieSize) { 
                 console.error(
                   `${meta.name} exceeds the maximum file size limit of 1MB.`
                 );
                 alert(`${meta.name} exceeds the maximum file size limit of 1MB.`);
                 return;
               }
              // Validate file dimensions
         // const image = new Image();
         // image.src = URL.createObjectURL(file);
         // console.log(image.src);
         
         // image.onload = () => {
         //   const { width, height } = image;
         //   const maxWidth = 1920; // Maximum width in pixels
         //   const maxHeight = 1080; // Maximum height in pixels
         //   if (width > maxWidth || height > maxHeight) {
         //     console.error(
         //       `${meta.name} dimensions exceed the maximum allowed size of ${maxWidth}x${maxHeight}.`
         //     );
         //     alert(
         //       `${meta.name} dimensions exceed the maximum allowed size of ${maxWidth}x${maxHeight}.`
         //     );
         //     return;
         //   }
     
         //   // If all validations pass, proceed with upload
         //   console.log(`${meta.name} passed all validations.`); 
         // };
           if (currentUploads >= 15) {
             console.error("Daily upload limit reached!");
             setIsLimitReached(true);
             return; // Prevent further uploads
           }
     
           try {
             
           console.log(`${meta.name} passed dimension validation.`);
             // Simulate API upload
             await handleUpload({ file, albumId: "123", attribution: "yes",price:'200',license_type:"free" });
             console.log(`${meta.name} uploaded successfully`);
     
     
              // Add the new file to the uploadedFiles array
           setUploadedFiles((prevFiles) => {
             const updatedFiles = [...prevFiles, file]; // Add the new file
             localStorage.setItem("photostockFileCount", String(updatedFiles.length)); // Save the updated file count to localStorage
             
             // Log the updated file count in both state and localStorage
             console.log(`Files in state: ${updatedFiles.length}`); // Logs the count of files in state
             console.log(`Files stored in localStorage: ${localStorage.getItem("photostockFileCount")}`); // Logs the count in localStorage
             
             return updatedFiles; // Update the state with the new file array
           });
             
           if (currentUploads + 1 >= 15) {
             setIsLimitReached(true); // Set the limit reached flag
           }
           } catch (error) {
             console.error(`${meta.name} failed to upload`, error);
           }
         } else if (status === "removed") {
           // Remove the file from the list
           setFilesToUpload((prevFiles) =>
             prevFiles.filter((f) => f.meta.id !== meta.id)
           );
         }
       }; 
         
           const handleUpload = async ({ file , albumId, attribution,price,license_type }) => { 
               try {
                 const response = await PhotostockuploadPhoto({ file, albumId, attribution,price,license_type  }) 
                 console.log('Upload success:', response  );
                 if(response.error.data){ 
                     
                     setResponseApi(response.error.data.message)
                 }
               } catch (error) {
                 console.error('Upload failed:', error);
               } 
           };
    return (
        <div className="px-4 py-4">
            <div>
                <Btn className="ps-1" onClick={openModalToggle}>
                    <i className="bi bi-plus-circle mx-1 text-warning"></i>
                    {AddNewIdeaStoryAlbum}
                </Btn>
            </div>
            <Popup
                title={AddNewIdeaStoryAlbum}
                openModalToggle={openModalToggle}
                isOpen={openModal}
                size={`lg`}
                backdrop={true}
                isClose={false}
                CommonFooter={true}
            >
                <div className="px-4 pt-2">
                    <Col>
                        <div className="form-check form-check-inline popup_radio radio-primary mt-2 px-2">
                            <Input
                                id={`select_album`}
                                type="checkbox"
                                name={`radio_upload`}
                                onChange={() => setSubAlbumType("1")}
                                checked={subalbumType === "1"}
                            />
                            <Label className="mb-0" for={`select_album`} check>
                                <H5>{`Select an existing album `}</H5>
                            </Label>
                        </div>
                        <div className="form-check form-check-inline popup_radio radio-primary mt-2 px-2">
                            <Input
                                id={`create_album`}
                                type="checkbox"
                                name={`radio_upload`}
                                onChange={() => setSubAlbumType("2")}
                                checked={subalbumType === "2"}
                            />
                            <Label className="mb-0" for={`create_album`} check>
                                <H5>{`Create a new album `}</H5>
                            </Label>
                        </div>
                    </Col>
                </div>
                {subalbumType === "2" && (
                    <div className="px-4 py-2">
                        <Formik
                            initialValues={manageAlbumFormInitialValue}
                            onSubmit={handleSubmit}
                            validationSchema={manageAlbumFormValidationSchema}
                        >
                            {({ errors, values, setFieldValue }) => (
                                <AddManageMyAlbumsForm
                                    submitErrors={submitErrors}
                                    setSubmitError={setSubmitError}
                                    errors={errors}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Formik>
                    </div>
                )}
                {subalbumType === "1" && (
                    <div className="px-4 py-2">
                        <FormGroup>
                            <Input type="select">
                                <option>{`Select`}</option>
                                <option>Free for Commercial and Non-profit use</option>
                                <option>Free for Non-Profit use only</option>
                            </Input>
                        </FormGroup>
                    </div>
                )}
            </Popup>
            <LicenseForm add={false}/>
            <Row className="py-2">
                <Col xxl="8">
                    <Formik
                        initialValues={{ email: "" }}
                        onSubmit={handleSubmit}
                        validationSchema={createPostSchema}
                    >
                        {({ errors, setFieldValue }) => (
                            <Form>
                                <div className={`d-flex align-items-center ${form}`}>
                                    <div className="flex-shrink-0 text-end icon-state switch-outline">
                                        <CommonSwitchSpan color={`primary`} defaultChecked />
                                    </div>
                                    <Label className="m-l-10" check>
                                        {AttributionRequired}
                                    </Label>
                                </div>
                                <Card className="mb-0">
                                    <CardBody className="pb-0">
                                    {isLimitReached ?  (
         <div style={{ padding: '20px', textAlign: 'center', color: 'red', border: '1px solid red', borderRadius: '10px' }}>
         <h3>Upload Limit Reached!</h3>
         <p>You have reached the daily limit of {dailyLimit} uploads or the bulk limit of {bulkLimit} uploads.</p>
        
       </div>
      ) : resposneAPi ?  
      <div style={{ padding: '20px', textAlign: 'center', color: 'red', border: '1px solid red', borderRadius: '10px' }}>
      {resposneAPi}
      </div>
       :  ( <Dropzone
                                              onChangeStatus={handleChangeStatus} 
                                              maxFiles={bulkLimit}  
                                              accept="image/*" 
                                              canCancel={false}
                                              multiple={true}
                                              inputContent="Drag and drop files or click to select"
                                              submitButtonText="Upload Files" 
                                              onDropRejected={() => console.log("File rejected")} 
                                        />)}
                                    </CardBody>
                                </Card>
                                <div className={form}>
                                    {/* <Formik
                                        initialValues={createPostInitialValue}
                                        onSubmit={handleSubmit}
                                        validationSchema={createPostSchema}
                                    >
                                        {({ errors, values, setFieldValue }) => (
                                            <Form>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Label check>{`Category *`}:</Label>
                                                            <FormGroup floating>
                                                                <Field
                                                                    className={`custom-select`}
                                                                    name="CategorySelect"
                                                                    options={cityOptions}
                                                                    component={CustomSelect}
                                                                    placeholder="Category"
                                                                    isMulti={true}
                                                                />
                                                            </FormGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <div className="col-form-Label">
                                                                <Label check>{Keywords}:</Label>
                                                                <TagsInput
                                                                    name="keywords"
                                                                    placeholder="Add keywords"
                                                                    className="w-100"
                                                                    setFieldValue={setFieldValue}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik> */}
                                </div>
                                <div>
                                    <H4>{`Notes:`}</H4>
                                    <div className="border rounded border-danger p-2">
                                        <p>
                                            <i className="bi-exclamation-triangle me-1 text-warning"></i>
                                            <span>These attributes will be applied to all the uploaded images.</span>
                                        </p>
                                        <UL className="list-content">
                                            <LI>
                                                <FontAwesomeIcon icon={fas.faCircle} className="pe-2" size="xs" />
                                                {`Pending Content: Admin's approval is required for each of your uploaded contents, before displaying it to users. Check your Media Profile >> "Pending Photos" page.`}
                                            </LI>
                                            <LI>
                                                <FontAwesomeIcon icon={fas.faCircle} className="pe-2" size="xs" />
                                                {`Please read the terms and conditions to avoid sanctions`}
                                            </LI>
                                            <LI>
                                                <FontAwesomeIcon icon={fas.faCircle} className="pe-2" size="xs" />
                                                {`Daily maximum number of files to upload: (100)`}
                                            </LI>
                                            <LI>
                                                <FontAwesomeIcon icon={fas.faCircle} className="pe-2" size="xs" />
                                                {`Daily upload files left: (100)`}
                                            </LI>
                                            <LI>
                                                <FontAwesomeIcon icon={fas.faCircle} className="pe-2" size="xs" />
                                                {`Not allowed to upload files of violence or pornographic content of any kind`}
                                            </LI>
                                            <LI>
                                                <FontAwesomeIcon icon={fas.faCircle} className="pe-2" size="xs" />
                                                {`File must be of Authoring`}
                                            </LI>
                                        </UL>
                                    </div>
                                </div>
                                <div className="d-flex py-3 gap-2">
                                    <Btn color="primary" type="button">{"Start a New Session "}</Btn>
                                    <Btn color="primary" type="button" onClick={openModalDetailToggle}>{"Add Details"}</Btn>
                                    <Popup
                                        title={`Content Details`}
                                        openModalToggle={openModalDetailToggle}
                                        isClose={false}
                                        isOpen={isOpen}
                                        size={`xl`}
                                        backdrop={true}
                                    >
                                        <AddDetails popup={true}/>
                                    </Popup>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </div>
    );
};

export default PhotoStock;
