import { useEffect, useState } from "react";
import { Btn, H4 } from "../../../AbstractElements";
import { motion } from "framer-motion";
import { CardBody, Col, Collapse, Form, Label, Modal, Row } from "reactstrap";
import { ChevronDown, ChevronUp } from "react-feather";
import {
    quizMaterialFormInitialValue,
    QuizMaterialFormValidationProp,
    quizMaterialLessonFormValidationSchema,
} from "../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { ErrorMessage, Formik } from "formik";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import QuizzGame from "../../QuizzAnimation/QuizGame";
import ReviewQuiz from "./ExamReview";
import QuizTest from "../../QuizzAnimation/QuizzTest";
import QuizmaterialBoxValue from "./QuizmaterialBoxValue";
// import QuizTest from "../../QuizzAnimation/QuizzTest";

export const clothsDetailsData = ["General Parameters", "Quiz Materials"];

export interface ExamModalInterface {
    value: boolean;
    setquizopen: (value: boolean) => void;
}

// const questions = [
//     { id: 1, text: 'What is the capital of France?', options: ['Paris', 'Berlin', 'Madrid', 'Rome'], correct: 0 },
//     { id: 2, text: 'What is 2 + 2?', options: ['3', '4', '5', '6'], correct: 1 },
//     { id: 3, text: 'Who wrote "Hamlet"?', options: ['Shakespeare', 'Dickens', 'Hemingway', 'Austen'], correct: 0 },
//     { id: 4, text: 'What is the largest planet in our solar system?', options: ['Earth', 'Jupiter', 'Mars', 'Venus'], correct: 1 },
//     { id: 5, text: 'What is the chemical symbol for gold?', options: ['Au', 'Ag', 'Pb', 'Fe'], correct: 0 },
//     { id: 6, text: 'In which year did the Titanic sink?', options: ['1912', '1905', '1920', '1898'], correct: 0 },
//     // { id: 7, text: 'What is the hardest natural substance on Earth?', options: ['Gold', 'Iron', 'Diamond', 'Platinum'], correct: 2 },
//     // { id: 8, text: 'Who painted the Mona Lisa?', options: ['Da Vinci', 'Van Gogh', 'Picasso', 'Monet'], correct: 0 },
//     // { id: 9, text: 'What is the capital of Japan?', options: ['Tokyo', 'Seoul', 'Beijing', 'Bangkok'], correct: 0 },
//     // { id: 10, text: 'What is the smallest prime number?', options: ['0', '1', '2', '3'], correct: 2 },
//     // { id: 11, text: 'Who discovered penicillin?', options: ['Fleming', 'Edison', 'Curie', 'Pasteur'], correct: 0 },
//     // { id: 12, text: 'What is the largest ocean on Earth?', options: ['Atlantic', 'Indian', 'Pacific', 'Arctic'], correct: 2 },
//     // { id: 13, text: 'What element does "O" represent on the periodic table?', options: ['Oxygen', 'Gold', 'Osmium', 'Oganesson'], correct: 0 },
//     // { id: 14, text: 'In which continent is Egypt located?', options: ['Asia', 'Africa', 'Europe', 'South America'], correct: 1 },
//     // { id: 15, text: 'What is the main ingredient in guacamole?', options: ['Tomato', 'Pepper', 'Avocado', 'Onion'], correct: 2 },
//     // { id: 16, text: 'Who was the first President of the United States?', options: ['Lincoln', 'Washington', 'Jefferson', 'Adams'], correct: 1 },
//     // { id: 17, text: 'What is the chemical symbol for water?', options: ['HO', 'H2O', 'O2', 'CO2'], correct: 1 },
//     // { id: 18, text: 'What is the largest land animal?', options: ['Elephant', 'Giraffe', 'Rhino', 'Hippopotamus'], correct: 0 },
//     // { id: 19, text: 'Which planet is known as the Red Planet?', options: ['Venus', 'Mars', 'Jupiter', 'Saturn'], correct: 1 },
//     // { id: 20, text: 'What is the capital of Canada?', options: ['Toronto', 'Ottawa', 'Vancouver', 'Montreal'], correct: 1 },
//     // { id: 21, text: 'Which gas do plants primarily use for photosynthesis?', options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Hydrogen'], correct: 2 },
//     // { id: 22, text: 'Who is known as the "Father of Computers"?', options: ['Turing', 'Babbage', 'Jobs', 'Gates'], correct: 1 },
//     // { id: 23, text: 'What is the smallest country in the world by land area?', options: ['Vatican City', 'Monaco', 'San Marino', 'Liechtenstein'], correct: 0 },
//     // { id: 24, text: 'What is the currency of Japan?', options: ['Yuan', 'Yen', 'Won', 'Ringgit'], correct: 1 },
//     // { id: 25, text: 'Which planet is closest to the Sun?', options: ['Venus', 'Earth', 'Mercury', 'Mars'], correct: 2 },
//     // { id: 26, text: 'What is the freezing point of water in Celsius?', options: ['0°C', '32°C', '100°C', '-10°C'], correct: 0 },
//     // { id: 27, text: 'What is the largest species of shark?', options: ['Great White', 'Hammerhead', 'Whale Shark', 'Tiger Shark'], correct: 2 },
//     // { id: 28, text: 'Who was the 16th President of the United States?', options: ['Lincoln', 'Grant', 'Roosevelt', 'Jackson'], correct: 0 },
//     // { id: 29, text: 'What is the most abundant gas in Earth’s atmosphere?', options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Hydrogen'], correct: 1 },
//     // { id: 30, text: 'What is the capital of Australia?', options: ['Sydney', 'Melbourne', 'Canberra', 'Brisbane'], correct: 2 }
// ];
export const questions = [
    {
        text: "What is the value of 2 + 3 × 4?",
        options: ["14", "20", "18", "11"],
        correct: 0, // Index of the correct answer
        explanation:
            "According to the order of operations, multiplication comes before addition. So, 3 × 4 = 12, and then 2 + 12 = 14.",
    },
    {
        text: "What is the square root of 144?",
        options: ["10", "12", "14", "16"],
        correct: 1,
        explanation: "The square root of 144 is 12 because 12 × 12 = 144.",
    },
    {
        text: "If x + 5 = 12, what is x?",
        options: ["5", "7", "12", "17"],
        correct: 1,
        explanation: "To find x, subtract 5 from both sides: x = 12 - 5 = 7.",
    },
    {
        text: "What is 15% of 200?",
        options: ["30", "25", "15", "20"],
        correct: 0,
        explanation: "To find 15% of 200, multiply 200 × 0.15 = 30.",
    },
    {
        text: "What is the value of 7^2 - 3^2?",
        options: ["40", "34", "46", "20"],
        correct: 0,
        explanation: "7^2 = 49 and 3^2 = 9. Therefore, 49 - 9 = 40.",
    },
    {
        text: "Solve for y: 3y - 9 = 0.",
        options: ["3", "9", "0", "-3"],
        correct: 0,
        explanation: "Add 9 to both sides: 3y = 9. Then divide by 3: y = 3.",
    },
    {
        text: "What is the area of a triangle with a base of 10 units and a height of 5 units?",
        options: [
            "25 square units",
            "20 square units",
            "50 square units",
            "15 square units",
        ],
        correct: 0,
        explanation:
            "The area of a triangle is calculated as (1/2) × base × height, so (1/2) × 10 × 5 = 25.",
    },
    {
        text: "If the circumference of a circle is 31.4 units, what is the radius? (Use π ≈ 3.14)",
        options: ["5", "10", "15", "20"],
        correct: 0,
        explanation:
            "The circumference is given by C = 2πr. Solving for r: r = C / (2π) = 31.4 / (2 × 3.14) = 5.",
    },
];

const ExamModal = ({ value, setquizopen }: ExamModalInterface) => {
    const [isOpen, setIsOpen] = useState(value);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [timeLeft, setTimeLeft] = useState(7200);
    const [timeLeftMini, setTimeLeftMini] = useState(120);
    const [animate, setAnimate] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
    const [correctAnswer, setCorrectAnswer] = useState<number | null>(null);
    // const [answerLocked, setAnswerLocked] = useState(false);
    const quizName = "General Knowledge Quiz"; // Hardcoded quiz name
    const [quizCompleted, setQuizCompleted] = useState(false);
    let audioSrc = "";
    // Timer effect
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);
        return () => clearInterval(timer);
    }, []);
    useEffect(() => {
        setTimeLeftMini(120); // Reset to 2 minutes
    }, [currentQuestion]);
    // Timer for each question
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeftMini((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        // Automatically move to the next question when the timer reaches zero
        if (timeLeftMini === 0) {
            handleNext();
        }

        return () => clearInterval(timer);
    }, [timeLeftMini]);
    const getTimerStyle = () => {
        if (timeLeft <= 60) {
            return { backgroundColor: "red", color: "white" }; // Less than 1 minute
        } else if (timeLeft <= 300) {
            return { backgroundColor: "grey", color: "white" }; // Less than 5 minutes
        }
        return { backgroundColor: "green", color: "white" }; // Default (more than 5 minutes)
    };

    const totalQuestions = questions.length;

    const handleNext = () => {
        if (currentQuestion < totalQuestions) {
            setAnimate(true);
            // audioSrc="/assets/audio/right.mp3"
            // playAudio(audioSrc);
            // audioSrc=''
            setTimeout(() => {
                setCurrentQuestion(currentQuestion + 1);
                setSelectedAnswer(null);
                setCorrectAnswer(null);
                // setAnswerLocked(false);
                setAnimate(false);
            }, 300);
        } else {
            setQuizCompleted(true);
        }
    };

    const handlePrevious = () => {
        if (currentQuestion > 1) {
            setAnimate(true);
            setTimeout(() => {
                setCurrentQuestion(currentQuestion - 1);
                setSelectedAnswer(null);
                setCorrectAnswer(null);
                // setAnswerLocked(false);
                setAnimate(false);
            }, 300);
        }
    };
   const [userAnswers, setUserAnswers] = useState<number[]>(
        Array(questions.length).fill(undefined)
    );
    const handleAnswerClick = (index: number) => {
        // if (!answerLocked) {
        audioSrc = "/assets/audio/right.mp3";
        playAudio(audioSrc);
        const correct = questions[currentQuestion - 1].correct;
        setSelectedAnswer(index);
        setCorrectAnswer(correct);

        setUserAnswers((prev) => {
            const updatedAnswers = [...prev];
            updatedAnswers[currentQuestion - 1] = index; // Store user's selected answer
            return updatedAnswers;
        });
        // const isCorrect = index === questions[currentQuestion].correct;
        // const answerText = questions[currentQuestion].options[index];
        // const feedbackMessage = isCorrect ? "Correct!" : "Incorrect!";
        handleNext();
        // setAnswerLocked(true);
        // }
    };

    const getButtonClass = (index: number) => {
        if (selectedAnswer === index) {
            return index === correctAnswer ? "btn-dark" : "btn-dark";
        }
        return " ";
    };

    const currentQuestionData = questions[currentQuestion - 1];
    const color2 = "#ff6600"; // First color
    const color1 = "#5cbe1c"; // Second color
    // const [submitErrors, setSubmitError] = useState<boolean>(false);

    const handleSubmit = (values: QuizMaterialFormValidationProp) => {
        // setSubmitError(false);
    };
    const { mobileView } = useAppSelector((state) => state.layout);

    // Format timeLeft to HH:MM:SS
    const formatTimehours = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${
            secs < 10 ? "0" + secs : secs
        }`;
    };

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? "0" + secs : secs}`;
    };
    const displayResults = () => {
        return questions.map((question, idx) => (
            <div key={idx} className="my-3">
                <H4>{`${idx + 1}. ${question.text}`}</H4>
                <div>
                    <strong>Your Answer:</strong>{" "}
                    <span
                        className={
                            userAnswers[idx] === question.correct
                                ? "text-success"
                                : "text-danger"
                        }>
                        {question.options[userAnswers[idx] ?? -1] ||
                            "Not answered"}
                    </span>
                </div>
                <div>
                    <strong>Correct Answer:</strong>{" "}
                    <span className="text-success">
                        {question.options[question.correct]}
                    </span>
                </div>
            </div>
        ));
    };
    const playAudio = (audioFile: string) => {
        const audio = new Audio(audioFile);
        audio.play();
    };
    return (
        <>
            <Modal
                wrapClassName="product-box"
                fade
                centered
                size="xl"
                isOpen={value}
                toggle={() => setquizopen(false)}
                className="modal-dialog-scrollable">
                <div
                    className="modal-header"
                    onClick={() => setquizopen(false)}>
                    <div className="d-flex justify-content-between flex-fill align-items-center">
                        {quizCompleted ? (
                            <>
                                <H4>Review Your Results </H4>
                                <Btn className="py-0" close></Btn>
                            </>
                        ) : (
                            <>
                                {" "}
                                <H4>
                                    {quizName}(
                                    {`Question: ${currentQuestion}/${totalQuestions}`}
                                    )
                                </H4>
                                <div className="quiz-info">
                                    <H4>
                                        <span
                                            style={{
                                                ...getTimerStyle(),
                                                padding: "14px",
                                                fontSize: "1em",
                                                borderRadius: "8px",
                                            }}
                                            className="f-right me-5 ">{`Overall Time Left: ${formatTimehours(
                                            timeLeft
                                        )}`}</span>
                                    </H4>
                                    <Btn className="py-0" close></Btn>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {quizCompleted ? (
                    <ReviewQuiz
                        questions={questions}
                        userAnswers={userAnswers}
                    />
                ) : (
                    <div className="modal-body bg-light">
                        <QuizmaterialBoxValue />
                        <div className=" ">
                            <motion.div
                                key={currentQuestion}
                                initial={{ opacity: 0, x: 100 }}
                                animate={{
                                    opacity: 1,
                                    x: 0,
                                    backgroundPosition: `100% 0%`,
                                }}
                                exit={{ opacity: 0, x: -100 }}
                                transition={{ duration: 0.5 }}
                                className="question-content exam-quiz"
                                style={{
                                    padding: "20px",
                                    borderRadius: "8px",
                                    backgroundImage: `linear-gradient(135deg, ${color1} 50%, ${color2} 50%)`,
                                    backgroundSize: "200% 100%",
                                    backgroundPosition: animate
                                        ? "100% 0%"
                                        : "0% 0%",
                                    transition:
                                        "background-position 0.5s ease-in-out",
                                }}>
                                <div
                                    className={` text-center mt-0 text-white rounded-2 p-3 ms-auto ${
                                        timeLeftMini > 120
                                            ? "bg-success "
                                            : timeLeftMini > 60
                                            ? "bg-success "
                                            : timeLeftMini <= 10
                                            ? "bg-danger animate__heartBeat"
                                            : "bg-warning  "
                                    }`} style={{maxWidth:'300px'}}>
                                    <strong>
                                        Time Left for this question:{" "}
                                        {formatTime(timeLeftMini)}
                                    </strong>
                                </div>
                                <div
                                    className="d-flex flex-column  justify-content-center"
                                    style={{
                                        marginLeft: mobileView
                                            ? "50px"
                                            : "125px",
                                    }}>
                                    <H4 className="text-white">
                                        {currentQuestion}/{totalQuestions}:{" "}
                                        {currentQuestionData.text}
                                    </H4>
                                    <div className="mt-3 d-flex flex-column gap-2">
                                        {currentQuestionData.options.map(
                                            (option, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() =>
                                                        handleAnswerClick(index)
                                                    }
                                                    className={`btn ${getButtonClass(
                                                        index
                                                    )} w-100 border py-2 px-3 text-white`}>
                                                    {option}
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                            </motion.div>
                            {/* <QuizzGame /> */}
                            <QuizTest value={value} />
                            <footer className="sticky-footer p-2">
                                <div className="d-block d-md-flex justify-content-between">
                                    <div className="pt-2 ms-0 d-flex align-items-center">
                                        <Formik
                                            initialValues={
                                                quizMaterialFormInitialValue
                                            }
                                            onSubmit={handleSubmit}
                                            validationSchema={
                                                quizMaterialLessonFormValidationSchema
                                            }>
                                            <Form
                                                style={{ Width: "400px" }}
                                                className="d-flex align-items-center gap-2">
                                                <Label
                                                    className="text-black text-left"
                                                    check>
                                                    Question Number:{" "}
                                                </Label>
                                                <input
                                                    type="text"
                                                    // value={item.quantity}
                                                    // onChange={(e) =>
                                                    //     // handleQuantityChange( )
                                                    // }
                                                    min="1"
                                                    className="form-control"
                                                    style={{ width: "50px" }} // Disable if not in edit mode
                                                />
                                            </Form>
                                        </Formik>
                                        <div className="next_previous pt-0">
                                            <button className="btn ms-3">
                                                Jump
                                            </button>
                                        </div>
                                    </div>
                                    <div className="next_previous">
                                        <button
                                            className="btn"
                                            onClick={handlePrevious}
                                            disabled={currentQuestion === 1}>
                                            Previous
                                        </button>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default ExamModal;
