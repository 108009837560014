import React, { useState } from 'react';
import { CardHeader, Col, Input, Label, Row } from 'reactstrap'; 
import PhotoContestContent from './PhotoContestContent';
import ProductStockContent from './ProductStockContent';
import { H3, H5 } from '../../../AbstractElements';
import PhotoStock from './PhotoStock';

const UploadData: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('1');

  // Function to toggle tabs
  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div className="product-wrapper-grid upload">
      <CardHeader className="ps-2 pt-2">
                <H3>{'Upload Contents (Step 1)'}</H3>
              </CardHeader>
      <Row className='ms-1 ms-md-4 mt-3'>
        <Col md="12" className='d-flex align-items-start'>
          <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
            <Input
              id="radioinline_upload"
              type="radio"
              name="radio_upload"
              onChange={() => handleTabChange("1")}
              checked={activeTab === "1"}
            />
            <Label className="mb-0" htmlFor="radioinline_upload" check>
              <H5>{`Social Media `}</H5>
              <small>{`(view only, cannot be downloaded by others)`}</small>
            </Label>
          </div>

          <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
            <Input
              id="radioinline_uploadd"
              type="radio"
              name="radio_upload"
              onChange={() => handleTabChange("3")}
              checked={activeTab === "3"}
            />
            <Label className="mb-0" htmlFor="radioinline_uploadd" check>
              <H5>{`Participate in a Photo Contest`}</H5>
            </Label>
          </div>

          <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
            <Input
              id="radioinline_uploadds"
              type="radio"
              name="radio_upload"
              onChange={() => handleTabChange("4")}
              checked={activeTab === "4"}
            />
            <Label className="mb-0" htmlFor="radioinline_uploadds" check>
              <H5>{`Photo Stock `}</H5>
              <span><small>{`(can be downloaded and used by others)`}</small></span>
            </Label>
          </div>
        </Col>
      </Row>

      <Row>
        <div className="tab-content">
          {activeTab === "1" && <ProductStockContent form="d-none" />}
          {activeTab === "3" && <PhotoContestContent />}
          {activeTab === "4" && <PhotoStock />}
        </div>
      </Row>
    </div>
  );
};

export default UploadData;
