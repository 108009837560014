import { Nav, NavItem, NavLink } from "reactstrap";
import { Badges, SVG } from "../../../../../AbstractElements";
import { useAppSelector } from "../../../../../ReduxToolkit/Hooks"; 
import { Href, Inbox } from "../../../../../utils/Constant";
import { letterBoxSidebar } from "../../../../../Data/Application/Email/LetterBox/LetterBox";
import { LetterBoxNavType } from "../../../../../Types/Application/Email/LetterBox/LetterBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

const EmailNavMenu: React.FC<LetterBoxNavType> = ({ navId, setNavId }) => {
  const {starredValue} = useAppSelector((state)=>state.letterBox)
  let starBadges = starredValue.filter((data)=> data.star === true && 1)
  return (
    <Nav pills tabs className="main-menu email-category border-bottom-0 text-wrap">
      {letterBoxSidebar.map((data, i) => (
        <NavItem key={i}>
          <NavLink className={navId === data.id ? "active" : ""} id={data.id} onClick={()=>setNavId(data.id)} href={Href}>
            {data.icon ? 
            <SVG className={`stroke-icon ${data.color ? `stroke-${data.color}` : ""}`} iconId={data.icon} />
            :
            <FontAwesomeIcon  icon={ data.ficon }/>
            }
            <div>
              {data.title}
              {data.badge && <Badges color="">{data.title === Inbox ? starredValue.length : starBadges.length}</Badges>}
            </div>
          </NavLink>
        </NavItem>
      ))} 
    </Nav>
  );
};

export default EmailNavMenu;
