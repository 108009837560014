import { useEffect, useState } from "react";
import { Btn, H4, P } from "../../../AbstractElements";
import { motion } from "framer-motion";
import { CardBody, Col, Collapse, Form, Label, Modal, Row } from "reactstrap";
import { ChevronDown, ChevronUp } from "react-feather";
import {
    quizMaterialFormInitialValue,
    QuizMaterialFormValidationProp,
    quizMaterialLessonFormValidationSchema,
} from "../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { ErrorMessage, Formik } from "formik";

// import QuizTest from "../../QuizzAnimation/QuizzTest";

export const clothsDetailsData = ["General Parameters", "Quiz Materials"];

export interface ExamModalInterface {
    value: boolean;
    setquizopen: (value: boolean) => void;
}

const QuetionGroup = ({ value, setquizopen }: ExamModalInterface) => {
    const [isOpen, setIsOpen] = useState(true);
    const [currentQuestion, setCurrentQuestion] = useState(1);

    const handleSubmit = (values: QuizMaterialFormValidationProp) => {
        // setSubmitError(false);
    };

    return (
        <>
            <div className="mt-2">
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className="d-flex bg-gray rounded-2 mb-2 p-2 gap-2 align-items-center justify-content-between">
                    <div className="d-flex gap-2 align-items-center">
                        <H4 className="text-white">Question Groups</H4>
                    </div>
                    {isOpen ? <ChevronUp /> : <ChevronDown />}
                </div>
                <Collapse isOpen={isOpen}>
                    <CardBody className="py-2 bg-light2 border p-2 mb-2">
                        <Formik
                            initialValues={quizMaterialFormInitialValue}
                            onSubmit={handleSubmit}
                            validationSchema={
                                quizMaterialLessonFormValidationSchema
                            }>
                            {({ errors, values, setFieldValue }) => (
                                <Form className="form-wizard">
                                    <Row>
                                        <Col sm="8" className="mb-3">
                                            <Label check>
                                                <P className="txt-black mb-0 fw-bold">
                                                    Grammar:
                                                </P>
                                                <ul>
                                                    <li>
                                                        Score: 21 - Number of
                                                        Correct Answers: 4 -
                                                        Number of Wrong Answers:
                                                        3
                                                    </li>{" "}
                                                    <li> Description:</li>
                                                    <li>0-12: Failed</li>
                                                    <li>13-20: Passed</li>
                                                    <li>21-25: Good</li>
                                                    <li>26-30: Very Good</li>
                                                </ul>
                                            </Label>
                                            <ErrorMessage
                                                name="title"
                                                component="span"
                                                className="invalid-feedback"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="8" className="mb-3">
                                            <Label check>
                                            <P className="txt-black mb-0 fw-bold">
                                            Reading:
                                                </P>
                                                <ul>
                                                    <li>
                                                    Score: 11 - Number of Correct Answers: 3 - Number of Wrong Answers: 7
                                                    </li>{" "}
                                                    <li>Description:</li>
                                                    <li>0-12: Failed</li>
                                                    <li>13-20: Passed</li>
                                                    <li>21-25: Good</li>
                                                    <li>26-30: Very Good</li>
                                                </ul>
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="8" className=" mb-0">
                                            <Label check>
                                            <P className="txt-black mb-0 fw-bold">
                                            Writing:
                                                </P>
                                                <ul>
                                                    <li>
                                                    Score: 11 - Number of Correct Answers: 1 - Number of Wrong Answers: 0
                                                    </li>{" "}
                                                    <li>Description:</li>
                                                    <li>0-12: Failed</li>
                                                    <li>13-20: Passed</li>
                                                    <li>21-25: Good</li>
                                                    <li>26-30: Very Good</li>
                                                </ul>
                                            </Label>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                </Collapse>
            </div>
        </>
    );
};

export default QuetionGroup;
