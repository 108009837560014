import { useState } from "react";
import { Formik } from "formik";
import AddGroupMemberForm from "./AddGroupMemberForm";
import {
  GroupMemberFormValidationProp,
  groupMemberFormInitialValue,
  groupMemberFormValidationSchema,
} from "../../Data/Communication/FormControlValidation";
import { useSaveGroupMemberDataMutation } from "../../ReduxToolkit/Reducers/Group/memberApiSlice";
import { toast } from "react-toastify";

function GroupMembersMain(props: any) {
  const { openToModalToggle } = props;

  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const [SaveGroupMemberDataApiCall, { isLoading }] =
    useSaveGroupMemberDataMutation();

  const handleSubmit = async (values: GroupMemberFormValidationProp) => {
    try {
      const response = await SaveGroupMemberDataApiCall(values).unwrap();
      const { data, success, message } = response;

      if (success === true) {
        toast.success(message);
        openToModalToggle();
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message);
    }
    setSubmitError(false);
  };
  return (
    <Formik
      initialValues={groupMemberFormInitialValue}
      onSubmit={handleSubmit}
      validationSchema={groupMemberFormValidationSchema}
    >
      {({ errors, values, setFieldValue }) => (
        <AddGroupMemberForm
          submitErrors={submitErrors}
          setSubmitError={setSubmitError}
          errors={errors}
          values={values}
          openToModalToggle={openToModalToggle}
          setFieldValue={setFieldValue}
        />
      )}
    </Formik>
  );
}

export default GroupMembersMain;
