import React, { useState, useEffect } from "react";
import { Button, Col, Collapse, Input, Progress, Row } from "reactstrap";
import "animate.css";
import { QRCode } from "react-qrcode-logo";
import TimeoutPopup from "../../../../Component/QuizzAnimation/TimeoutPopup";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { Btn, H2, H4 } from "../../../../AbstractElements";
import WinnerScreen from "./WinnerScreen";
import { motion, AnimatePresence } from "framer-motion";
import QuizmaterialBoxValue from "../../../../Component/Application/Marketplace/QuizmaterialBoxValue";
const TechersquizScreen = () => {
    const [quizStarted, setQuizStarted] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [timeLeft, setTimeLeft] = useState(10); // 2 minutes in seconds
    const [animationClass, setAnimationClass] = useState(""); // Class to control heart beat animation
    const [questionAnimationClass, setQuestionAnimationClass] = useState("");
    const [answerAnimationClass, setAnswerAnimationClass] = useState("");
    const [showTimeoutPopup, setShowTimeoutPopup] = useState(false); // State for the timeout popup
    const [userAnswer, setUserAnswer] = useState(null); // Track user's answer
    const [isCorrect, setIsCorrect] = useState(null); // Track if the answer is correct
    const [correctAnswersCount, setCorrectAnswersCount] = useState(0); // Track correct answers
    const [jumpQuestion, setJumpQuestion] = useState(""); // State for the Jump to Question input
    const [showExplanation, setShowExplanation] = useState(false);
    const [timerColor, setTimerColor] = useState("bg-success");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const gamePin = Math.floor(1000 + Math.random() * 9000).toString();
    const quizData = [
        {
            question: "What is the capital of France?",
            answers: ["Paris", "London", "Berlin", "Madrid"],
            correctAnswer: 0,
        },
        {
            question: "What is 2 + 2?",
            answers: ["3", "4", "5", "6"],
            correctAnswer: 1,
        },
        {
            question: "Which planet is known as the Red Planet?",
            answers: ["Earth", "Mars", "Jupiter", "Saturn"],
            correctAnswer: 1,
        },
        {
            question: "What is the largest ocean on Earth?",
            answers: [
                "Atlantic Ocean",
                "Indian Ocean",
                "Arctic Ocean",
                "Pacific Ocean",
            ],
            correctAnswer: 3,
        },
        {
            question: "What is the chemical symbol for water?",
            answers: ["H2O", "O2", "CO2", "He"],
            correctAnswer: 0,
        },
    ];
    const { mobileView } = useAppSelector((state) => state.layout);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeIndex, setActiveIndex] = useState(
        mobileView == true ? null : 1
    );
    const [finalSelect, SetFinalSelect] = useState("");
    const [paused, setPaused] = useState(false);
    const [showWinnerScreen, setShowWinnerScreen] = useState(false);
    // const [showAllMedals, setShowAllMedals] = useState(false);
    const [playerNames, setPlayerNames] = useState([
        { name: "Sunny", score: 1000 },
        { name: "Priyanka", score: 2200 },
        { name: "Hitesh", score: 3300 },
        { name: "Deepak A", score: 2000 },
        { name: "Deepak B", score: 1200 },
        { name: "Deepak C", score: 3200 },
    ]);
    const [nextButtonShow, setNextButtonShow] = useState(false);
    const [scores, setScores] = useState(
        playerNames.reduce((acc, player) => {
            acc[player.name] = player.score;
            return acc;
        }, {})
    );

    const [isUpgrading, setIsUpgrading] = useState(false);
    // Filter player names based on the search term
    const filteredPlayers = searchTerm
        ? playerNames.filter((name) =>
              name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : playerNames;
    useEffect(() => {
        if (timeLeft > 0 && quizStarted) {
            const timer = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev === 1) {
                        // handleNextQuestion();
                        playAudio("answer_change");
                        // handleSubmitAnswer();
                        if (paused == true) {
                            setIsSubmitted(true); // Mark as submitted
                            setShowExplanation(true); // Show the explanation
                            console.log("sdsdsd");
                            setShowWinnerScreen(false);
                        }
                        setPaused(true);

                        setTimeout(() => {
                            setPaused(false);
                        }, 6000);
                        return 0;
                        // setShowTimeoutPopup(true); // Show popup when time is up
                    }
                    setAnimationClass("animate__heartBeat");
                    return prev - 1;
                });
                setTimeout(() => {
                    setAnimationClass(""); // Reset the animation class after 1 second
                }, 1000);
            }, 1000);
            return () => clearInterval(timer);
        } else if (timeLeft === 0 && quizStarted) {
            // Show WinnerScreen for 2 minutes 

            // setShowWinnerScreen(true);
            setIsUpgrading(true);
            // setTimeout(() => {
            //     setShowWinnerScreen(false); // Hide WinnerScreen after 2 minutes
            //     handleNextQuestion(); // Proceed to next question
            // }, 120000); // 2 minutes in milliseconds
        }
    }, [timeLeft, quizStarted, paused]);

    useEffect(() => {
        if (timeLeft > 60) {
            setTimerColor("bg-success"); // Green for more than 60 seconds
        } else if (timeLeft > 30) {
            setTimerColor("bg-warning"); // Yellow for 30-60 seconds
        } else {
            setTimerColor("bg-danger"); // Red for less than 30 seconds
        }
    }, [timeLeft]);
    const handleStartQuiz = () => {
        setQuizStarted(true);
        SetFinalSelect("");
        playAudio("start");
    };

    const handleNextQuestion = () => {
        setUserAnswer(null); // Reset user's answer
        setIsCorrect(null); // Reset the correctness flag
        setShowExplanation(false); // Hide the explanation
        SetFinalSelect("");
        setNextButtonShow(false);

        console.log("winner screen ");
        if (currentQuestion < quizData.length - 1) {
            setQuestionAnimationClass("animate__fadeOut");
            setAnswerAnimationClass("animate__fadeOut"); // Apply fade-out animation
            setTimeLeft(2);
            setTimeout(() => {
                setCurrentQuestion(currentQuestion + 1); // Move to the next question
                setUserAnswer(null); // Reset selected answer
                setIsSubmitted(false);
                setShowTimeoutPopup(false); // Close the popup
                setQuestionAnimationClass("animate__fadeIn"); // Apply fade-in animation for new question
                setAnswerAnimationClass("animate__fadeInLeftBig"); // Apply fade-in animation for answers
            }, 500); // Delay to allow the fade-out animation to complete
        } else {
            setShowWinnerScreen(true);

            // setShowFireworks(true);
        }
    };

    const handleSubmitAnswer = (index) => {
        if (userAnswer === null) return; // Do nothing if no answer is selected

        const correctIndex = quizData[currentQuestion].correctAnswer;
        // console.log(quizData[currentQuestion].answers[index]);

        if (userAnswer === correctIndex) {
            setIsCorrect(true); // Correct answer
            setCorrectAnswersCount((prev) => prev + 1); // Increment correct answer count
            // playAudio("correct"); // Play correct answer sound
        } else {
            setIsCorrect(false); // Wrong answer
            // playAudio("wrong"); // Play wrong answer sound
        }
    };
    const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleJumpToQuestion = () => {
        const jumpTo = parseInt(jumpQuestion) - 1;
        if (jumpTo >= 0 && jumpTo < quizData.length) {
            setCurrentQuestion(jumpTo); // Set the question index based on input
            setUserAnswer(null); // Reset user's answer
            setIsCorrect(null); // Reset the correctness flag
            setShowExplanation(false); // Hide the explanation
        }
    };

    const togglePopup = () => {
        setQuizStarted(false);
        setCurrentQuestion(0);
        setCorrectAnswersCount(0);
        setTimeLeft(120);
    };
    const handleNextQuestionFromTimeout = () => {
        setShowTimeoutPopup(false); // Close the timeout popup
        handleNextQuestion(); // Move to the next question
    };
    const playAudio = (type) => {
        let audioSrc = "";
        switch (type) {
            case "correct":
                audioSrc = "/assets/audio/right.mp3";
                break;
            case "wrong":
                audioSrc = "/assets/audio/wrong.mp3";
                break;
            case "answer_change":
                audioSrc = "/assets/audio/answer_chnage.mp3"; // Add this case
                break;
            case "start":
                audioSrc = "/assets/audio/answer_chnage.mp3"; // Add this case
                break;
            default:
                break;
        }
        if (audioSrc) {
            const audio = new Audio(audioSrc);
            audio.play();
        }
    };
    const progressPercentage = ((currentQuestion + 1) / quizData.length) * 100;

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    useEffect(() => {
        const handleResize = () => {
            setActiveIndex(window.innerWidth < 768 ? null : 1);
        };

        handleResize(); // Set initial value based on current screen width
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const handleUpgrade = () => {
        setIsUpgrading(true);

        const updatedPlayers = playerNames.map((player) => ({
            ...player,
            score: player.score + Math.floor(Math.random() * 100), // Random increment
        }));

        updatedPlayers.forEach((player) => {
            const targetScore = player.score;
            const currentScore = scores[player.name] || 0;

            let count = currentScore;
            const interval = setInterval(() => {
                count++;
                setScores((prev) => ({ ...prev, [player.name]: count }));
                if (count >= targetScore) clearInterval(interval);
            }, 20); // Animation speed
        });

        // After scores animate, reorder the players
        setTimeout(() => {
            const sortedPlayers = [...updatedPlayers].sort(
                (a, b) => b.score - a.score
            );
            setPlayerNames(sortedPlayers);
            setIsUpgrading(false);

            setNextButtonShow(true);
        }, 2000); // Delay to allow score animation to finish
    };
    return (
        <>
            <div
                className={` ${
                    showWinnerScreen
                        ? ""
                        : " page-body quiz-bg d-md-flex justify-content-center "
                }`}>
                {showWinnerScreen ? (
                    <WinnerScreen
                        showAllMedals={false}
                        showWinnerScreen={showWinnerScreen}
                        currentQuestion={currentQuestion}
                        questionlength={quizData.length}
                        handleNextQuestion={handleNextQuestion}
                        setShowWinnerScreen={setShowWinnerScreen}
                    />
                ) : (
                    <>
                        <div
                            className="quiz-container mx-auto pt-2  text-center"
                            style={{ padding: "30px" }}>
                            <div className="position-relative w-100">
                                <H2 className="bg-light-dark px-3 d-inline-block py-2 rounded-2 fs-1 fw-bold mb-3">
                                    Teacher’s Screen
                                </H2>
                            </div>
                            {!quizStarted ? (
                                <div className="_1HvZ2MIJpyR9NmMRQbeokO">
                                    <div className="d-flex flex-column align-items-center mb-4">
                                        <QRCode
                                            value={`https://1webbb.com/quiz-game`}
                                            size={128}
                                        />{" "}
                                        {/* Display QR code */}
                                        <h3 className="mt-2">
                                            Game PIN: {gamePin}
                                        </h3>{" "}
                                        {/* Display Game PIN */}
                                    </div>
                                    <h2 className="animate__animated animate__fadeInDown fs-1 fw-bold">
                                        OK here goes!
                                    </h2>
                                    <div className="my-4">
                                        <h4>Have fun playing the quiz</h4>
                                    </div>
                                </div>
                            ) : (
                                <div className="quiz-content w-100 position-relative">
                                    <>
                                        <QuizmaterialBoxValue
                                            fold={false}
                                            bgColor="bg-white"
                                        />
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex gap-2">
                                                <div className="total-question">
                                                    Question{" "}
                                                    {currentQuestion + 1} of{" "}
                                                    {quizData.length}
                                                </div>
                                            </div>
                                            <p
                                                className={`px-4 mb-0 ${timerColor} py-2 animate__animated ${animationClass}`}>
                                                {Math.floor(timeLeft / 60)}:
                                                {("0" + (timeLeft % 60)).slice(
                                                    -2
                                                )}
                                            </p>
                                        </div>

                                        <div
                                            className={`question-container mb-3 animate__animated ${questionAnimationClass}`}>
                                            <h2 className="fs-1">
                                                {
                                                    quizData[currentQuestion]
                                                        .question
                                                }
                                            </h2>
                                        </div>

                                        <div className="answers-grid">
                                            {quizData[
                                                currentQuestion
                                            ].answers.map((answer, index) => {
                                                const isSelected =
                                                    userAnswer === index;
                                                const isCorrectAnswer =
                                                    quizData[currentQuestion]
                                                        .correctAnswer ===
                                                    index;

                                                return (
                                                    <div
                                                        key={index}
                                                        className={`option-item animate__animated ${answerAnimationClass}`}>
                                                        <button
                                                            className={`m-2 w-100 py-2 px-4 ${
                                                                isCorrectAnswer
                                                                    ? "bg-success"
                                                                    : ""
                                                            }`}>
                                                            {answer}
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {showExplanation && (
                                            <div className="explanation mt-2">
                                                {isCorrect === false && (
                                                    <p className="text-danger">
                                                        The correct answer was:{" "}
                                                        {
                                                            quizData[
                                                                currentQuestion
                                                            ].answers[
                                                                quizData[
                                                                    currentQuestion
                                                                ].correctAnswer
                                                            ]
                                                        }
                                                    </p>
                                                )}
                                                <p>
                                                    {isCorrect === true
                                                        ? "Well done!"
                                                        : "Try again next time!"}
                                                </p>
                                            </div>
                                        )}

                                        <Progress
                                            value={
                                                (currentQuestion + 1) *
                                                (100 / quizData.length)
                                            }
                                            className="mt-5"
                                        />
                                    </>
                                </div>
                            )}
                        </div>
                        <div className="quiz-sidebar p-3 bg-light ">
                            <div className="my-3">
                                {nextButtonShow && (
                                    <div
                                        className="  end-0"
                                        style={{ top: "17px" }}>
                                        <Btn
                                            color="secondary"
                                            className="d-block fs-3 fw-bold w-100"
                                            onClick={() =>
                                                handleNextQuestion()
                                            }>
                                            {" "}
                                            Next Qestion
                                        </Btn>
                                    </div>
                                )}
                                {!nextButtonShow && isUpgrading ? (
                                    <button
                                        className="btn btn-secondary fs-3 fw-bold d-block w-100"
                                        onClick={handleUpgrade}
                                        disabled={!isUpgrading}>
                                        Refresh Ranks
                                    </button>
                                ) : (
                                    ""
                                )}
                                {!quizStarted ? (
                                    <button
                                        className="btn btn-secondary fs-3 fw-bold d-block w-100"
                                        onClick={handleStartQuiz}>
                                        Start Game
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div
                                className="d-flex justify-content-between"
                                onClick={() => toggleAccordion(1)}>
                                <H4 className="w-100 fs-4 text-center my-2">
                                    Player Rank
                                </H4>

                                <span>
                                    {/* Accordion toggle icon */}
                                    <i
                                        className={`bi text-dark  ${
                                            activeIndex === 1
                                                ? "bi-chevron-up"
                                                : "bi-chevron-down"
                                        }`}></i>
                                </span>
                            </div>
                            <Collapse isOpen={activeIndex}>
                                <input
                                    type="text"
                                    placeholder="Search players..."
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    className="form-control mb-3 mt-2"
                                />
                                <ul
                                    className="list-unstyled overflow-auto"
                                    style={{ height: "530px" }}>
                                    <AnimatePresence>
                                        {playerNames.map((player) => (
                                            <motion.li
                                                key={player.name}
                                                initial={{
                                                    opacity: 0,
                                                    scale: 0.9,
                                                }}
                                                animate={{
                                                    opacity: 1,
                                                    scale: 1,
                                                }}
                                                exit={{
                                                    opacity: 0,
                                                    scale: 0.9,
                                                }}
                                                transition={{ duration: 0.5 }}
                                                layout // Enables reordering animation
                                                className="mb-3">
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={`https://api.dicebear.com/5.x/avataaars/svg?seed=${player.name}`}
                                                        alt={`Avatar for ${player.name}`}
                                                        className="rounded-circle me-3"
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                        }}
                                                    />
                                                    <p className="text-black mb-0">
                                                        {player.name}
                                                    </p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p className="mt-2 mb-0 text-black">
                                                        Score:{" "}
                                                        {scores[player.name]}
                                                    </p>
                                                </div>
                                            </motion.li>
                                        ))}
                                    </AnimatePresence>
                                </ul>
                            </Collapse>
                        </div>
                    </>
                )}
            </div>
            {/* {showWinnerScreen ?  <WinnerScreen /> :''} */}
            {showTimeoutPopup && (
                <TimeoutPopup
                    isOpen={showTimeoutPopup}
                    onNext={handleNextQuestionFromTimeout}
                    togglePopup={togglePopup}
                    correctAnswer={
                        quizData[currentQuestion].answers[
                            quizData[currentQuestion].correctAnswer
                        ]
                    }
                    onClose={() => setShowTimeoutPopup(false)}
                />
            )}
        </>
    );
};

export default TechersquizScreen;
