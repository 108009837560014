import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badges, Btn, H3, H6, LI, SVG } from "../../../../AbstractElements";
import { Messages } from "../../../../utils/Constant";
import MessageBox from "./MessageBox";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import CommonModal from "../../../../Component/Ui-Kits/Modal/Common/CommonModal";
import { X } from "react-feather";

const HeaderMessage = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <LI className={`${ isOpen ? 'actvie-righticon' : ''}`} onClick={() => setIsOpen(!isOpen)}>
      <div className="message">
        <FontAwesomeIcon icon={fas.faEnvelope} />
        <Badges pill color="secondary">
          4
        </Badges>
      </div>
      {/* <div className="onhover-show-div message-dropdown">
        <H6 className="f-18 mb-0 dropdown-title">{Messages} </H6>
        <MessageBox />
      </div> */}
      <CommonModal
        isOpen={isOpen}
        toggle={()=>setIsOpen(!isOpen)}
        modalBodyClassName="p-0"
        heading="Login"
        size="lg"
      >
        <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
          <div className="modal-header justify-content-between">
            <H3 className="pb-0">
              {Messages}            
            </H3>
            <div className="text-end d-flex ">
              <div
                onClick={()=>setIsOpen(!isOpen)}
                className="search-close-icon">
                <X />
              </div>
            </div>
          </div>          
          <div className="px-4 mx-1 py-2">
            <MessageBox />
          </div>
        </div>
      </CommonModal>
    </LI>
  );
};

export default HeaderMessage;
