import { apiSlice } from "../../apiSlice";
import { ADMIN_STATIC_PAGE } from "../../../Api";

interface SuccessData {
  data: any;
  message: string;
  success: boolean;
}

export const staticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStaticsPageData: builder.mutation<SuccessData, { id: number, slug: string }>({
      query: (data) => ({
        url: `${ADMIN_STATIC_PAGE}/${data.id}/${data.slug}`,
      }), 
    }),
  }),
});

export const { useGetStaticsPageDataMutation } = staticsApiSlice;
