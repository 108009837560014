import { Card, Col } from 'reactstrap'
import RightChatBody from '../../PrivateChat/UserChat/RightChatBody'
import RightGroupChatHeader from './RightGroupChatHeader'
import UserChat from '../../PrivateChat/UserChat/UserChat'
interface UserGroupChatProps {
  responsive: boolean;
  ToogleContactList: () => void;
}
const UserGroupChat: React.FC<UserGroupChatProps> = ({ToogleContactList }) => {
  return (
    <Col xxl="9" xl="8" md="8" className="box-col-9">
      <Card className="right-sidebar-chat responsive_chat">
        <RightGroupChatHeader ToogleContactList={ToogleContactList }/>
        <RightChatBody />
      </Card>
    </Col>
  )
}

export default UserGroupChat