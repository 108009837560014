import CustomSelect from "./CustomSelect";
import { Field } from "formik"; 

const scheduleOptions = [
  { label: "Full Time", value: 1 },
  { label: "Part Time", value: 2 },
  { label: "Freelancing", value: 3 },
];

function ScheduleType(props: any) { 
  const { name, className, isMulti, placeholder } = props;  
  
  return (
    <Field
      className={`custom-select ${className}`}
      name={name}
      options={scheduleOptions}
      component={CustomSelect}
      placeholder={placeholder}
      isMulti={isMulti}
    />
  );
}

export default ScheduleType;
