import { useState } from "react";
import { Field, Formik } from "formik";
import {
    SurveyAndQuizzesFormValidationProp,
    TabsurveyAndQuizzesFormValidationSchema,
    surveyAndQuizzesFormInitialValue,
    surveyAndQuizzesFormValidationSchema,
} from "../../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { useAppDispatch } from "../../../../ReduxToolkit/Hooks";
import { setFormValues } from "../../../../ReduxToolkit/Reducers/QuizMaterialSlice";
import AddSurveyAndQuizzesForm from "../SurveyAndQuizzes/AddSurveyAndQuizzesForm";
import { H4, H5, LI, UL } from "../../../../AbstractElements";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import AddManageMyAlbumsForm from "../../ManageContents/ManageMyAlbums/AddManageMyAlbumsForm";
import {
    manageAlbumFormInitialValue,
    manageAlbumFormValidationSchema,
} from "../../../../Data/Tools/ManageContents/FormControlsValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
const ThirdQuizSurvey = ({setFormType,setCategory}) => {
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    // const handleSubmit = (values: SurveyAndQuizzesFormValidationProp) => {
    //     console.log(values, "hellooooooooooo");
    //     dispatch(setFormValues(values));
    //     setSubmitError(false);
    // };
   
    
    const [activeCheckbox, setActiveCheckbox] = useState<'first' | 'second'>('first');

    const handleCheckboxChange = (checkbox: 'first' | 'second') => {
      // Toggle between checkboxes
      setActiveCheckbox(checkbox);
    };
    const handleSubmit = (formValues: any) => {
        console.log(formValues);
        // You can also handle other submit actions here, like validation or API calls
      };
    return (
        <>
            <H4 className="mb-2">Add New knowledge </H4> 
            <div className="mx-2 mb-3"> 
        <div className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${activeCheckbox === "first" ? "transform-scale" : ""}`}>
        <label>
          <Input
            type="checkbox"
            checked={activeCheckbox === 'first'}
            onChange={() => handleCheckboxChange('first')}
          />
                                                  <H5 className="ms-2">{`Select an existing quiz or survey`}</H5>

        </label>
        </div>
        <div className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${activeCheckbox === "second" ? "transform-scale" : ""}`}>
        <label>
          <Input
            type="checkbox"
            checked={activeCheckbox === 'second'}
            onChange={() => handleCheckboxChange('second')}
          />
                                              <H5 className="ms-2">{`Create a new quiz or survey`}</H5>

        </label>
        </div>
      </div>
            {activeCheckbox == "first" ? (
                <div className="px-4  py-2">
                    <FormGroup>
                        <Input type="select">
                            <option>{`Select`}</option>
                            <option>
                                Free for Commercial and Non-profit use
                            </option>

                            <option>Free for Non-Profit use only</option>
                            {/* <option>View Only. No right to download or use. </option>
                            <option>Display only on shopping pages. Not for social media usages</option> */}
                        </Input>
                    </FormGroup>
                </div>
            ):  (
                <>
                 <Row className="pb-3">
      <Col  md='7'>
                                <H4>{`Notes:`}</H4>
                                <div className='border rounded border-danger p-2 mt-2'>
                                   
                                    <UL className='list-content '>
                                        <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Left Monthly Balance of Free Submission: 1 `}</LI>
                                        <LI><FontAwesomeIcon icon={fas.faCircle} className='pe-2' size="xs"/>{`Price (30 Days) for additional submission: 3 USD (for 3 additional submissions)  `}</LI>
                                         </UL>
                                </div>
                                </Col>
                            </Row>
                <Formik
                    initialValues={surveyAndQuizzesFormInitialValue}
                    onSubmit={handleSubmit} 
                    validationSchema={TabsurveyAndQuizzesFormValidationSchema}>
                    {   ({ errors, values, setFieldValue, handleSubmit  } ) => (
                        <AddSurveyAndQuizzesForm
                            submitErrors={submitErrors}
                            setSubmitError={setSubmitError}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                            addquizsurveysTab={false}
                            testquizsetting={false}
        setFormType={setFormType} 
        setCategory={setCategory}
                            
                        />
                    )}
                </Formik>
                </>
            ) }
        </>
    );
};

export default ThirdQuizSurvey;
