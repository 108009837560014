import InvoiceTwoContainer from "../../../../../Component/Application/Ecommerce/Invoices/Invoice-2/Invoice-2";

const InvoiceTwo = () => {
  return (
    <div className="page-body">
      <InvoiceTwoContainer />
    </div>
  );
};

export default InvoiceTwo;
