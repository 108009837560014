 
import {
    Table,
    Col,
    Row, 
} from "reactstrap";
import { P } from "../../../../AbstractElements";
const exampleData = [
    {
        activityName: "Dharmik",
        checkIn: "2024-09-22 10:00",
        checkOut: "2024-09-22 12:00",
        duration: "2 hours",
        totalAttendance: "10",
        attendees: "John Doe, Jane Smith",
        supervisor: "Alice Brown",
        comment: "No issues",
        comment: "No issues",
        comment: "No issues",
        comment: "No issues",
        comment: "No issues",
    },
    // Add more rows as needed
];
const attendancedetails = [
    {
        activityName: "Dharmik",
        checkIn: "2024-09-22 10:00",
        checkOut: "2024-09-22 12:00",
        duration: "2 hours",
        totalAttendance: "10",
        attendees: "John Doe, Jane Smith",
        supervisor: "Alice Brown",
    },
    // Add more rows as needed
];
const AttendanceHistory = ({
    Noofreservations,
    attendancedetailsTable,
    toggleattendancedetailsPopup,
    attendanceData,
    togglereservations,
    reservationhistoryValue,
    attendancedetailsValue,
    reservationsattendance,
}) => {
    return (
        <>
            <div className="attendance-history-section">
                {reservationhistoryValue ? (
                    <Row>
                        <Col sm="3">
                            <P className="fw-bold">
                                Activity ID:{" "}
                                <span className="fw-normal">12</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Activity Name:{" "}
                                <span className="fw-normal">Movie Watch</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Activity Date and Time:{" "}
                                <span className="fw-normal">12 Nov</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Activity Location:{" "}
                                <span className="fw-normal">Vesu</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Supervisor:{" "}
                                <span className="fw-normal"><u style={{color:"blue",textDecoration:"underline"}}>Dharmik</u></span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                No. of Reservations:{" "}
                                <span className="fw-normal"><u style={{color:"blue",textDecoration:"underline"}}>14</u></span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                No. Attendees:{" "}
                                <span className="fw-normal"><u style={{color:"blue",textDecoration:"underline"}}>13</u></span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Users: <span className="fw-normal">All</span>{" "}
                            </P>
                        </Col>
                    </Row>
                ) : (
                    ""
                )}
                {attendancedetailsValue ? (
                    <Row>
                        <Col sm="3">
                            <P className="fw-bold">
                                Username: <span className="fw-normal">12</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Activity ID:{" "}
                                <span className="fw-normal">Movie Watch</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Activity Name:{" "}
                                <span className="fw-normal">12 Nov</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Activity Date and Time:{" "}
                                <span className="fw-normal">Vesu</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Activity Location:{" "}
                                <span className="fw-normal">Dharmik</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Supervisor:{" "}
                                <span className="fw-normal">14</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Reservation Date and Time:{" "}
                                <span className="fw-normal"> 13</span>{" "}
                            </P>
                        </Col>
                        <Col sm="3">
                            <P className="fw-bold">
                                Total Attendance Duration Per Activity:{" "} 
                                <span className="fw-normal"> All</span>{" "}
                            </P>
                        </Col>
                    </Row>
                ) : (
                    ""
                )}
                {Noofreservations ? (
                    <Table bordered responsive className="mt-3">
                        <thead>
                            <tr>
                                <th >Username </th>
                                <th>Reservation Date</th>
                                <th>Check in Date/ Hour</th>
                                <th>Check Out Date/ Hour </th>
                                <th>Attendance Duration per check-in</th>
                                <th>Total Attendance per Activity</th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exampleData.map((item, index) => (
                                <tr key={index}>
                                    <td style={{color:'blue', textDecoration:'underline',cursor:'pointer'}} onClick={toggleattendancedetailsPopup}>
                                        {item.activityName}
                                    </td>
                                    <td>{item.checkIn}</td>
                                    <td>{item.checkOut}</td>
                                    <td>{item.duration}</td>
                                    <td>{item.totalAttendance}</td>
                                    <td>{item.totalAttendance}</td>
                                    <td>{item.attendees}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    ""
                )}

                {reservationsattendance ? (
                    <Table bordered responsive className="mt-3">
                        <thead>
                            <tr>
                                <th>Activity ID</th>
                                <th>Activity Name </th>
                                <th>Date Added</th>
                                <th>Activity Date and Time </th>
                                <th>Activity Location </th>
                                <th>Reservation Date</th>
                                <th>Check in Date/ Hour</th>
                                <th>Check Out Date/ Hour </th>
                                <th>Attendance Duration per check-in </th>
                                <th>Total Attendance per Activity</th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exampleData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.activityName}</td>
                                    <td>{item.checkIn}</td>
                                    <td>{item.checkOut}</td>
                                    <td>{item.duration}</td>
                                    <td>{item.totalAttendance}</td>
                                    <td>{item.totalAttendance}</td>
                                    <td>{item.attendees}</td>
                                    <td>{item.attendees}</td>
                                    <td>{item.attendees}</td>
                                    <td>{item.attendees}</td>
                                    <td>{item.attendees}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    ""
                )}
                {attendancedetailsTable ? (
                    <Table bordered responsive className="mt-3">
                        <thead>
                            <tr>
                          
                                <th>Reservation Date</th>
                                <th>Check in Date/ Hour</th>
                                <th>Check Out Date/ Hour </th>
                                <th>Attendance Duration per check-in </th>
                                <th>Total Attendance per Activity</th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendancedetails.map((item, index) => (
                                <tr key={index}>
                                    
                                    <td>{item.totalAttendance}</td>
                                    <td>{item.totalAttendance}</td>
                                    <td>{item.attendees}</td>
                                    <td>{item.attendees}</td>
                                    <td>{item.attendees}</td>
                                    <td>{item.attendees}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};
export default AttendanceHistory;
