import { Link, useLocation } from "react-router-dom"; 
import { ManageOnlineStore } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import { Col, Row } from "reactstrap";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { useState } from "react";

const ManageOnlineSubMenu = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const { mobileView } = useAppSelector((state) => state.layout);
  const isActive = (path) => {
    // Check if the current path starts with the path of the link
    return currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`);
  };

  const [isOpen, setIsOpen] = useState(false); 

  
  return (
    <div>
          <Row>
          {mobileView ? (
                  <>
                      
              <Col md="12">
                  {isOpen ? (
                      <IoClose size='2em' onClick={() => setIsOpen(false)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                  ) : (
                      <HiOutlineMenuAlt2 size='2em' onClick={() => setIsOpen(true)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                  )}
              </Col>
              <Col md="12">
                  {isOpen ? (
                      <>
                          {ManageOnlineStore.map((item, index) => { 
                              const activeClass = isActive(item.path) ? 'sub-active' : '';

                              return (
                                <Link
                                  key={index}
                                  to={`${process.env.PUBLIC_URL}/${item.path}`}
                                  className={`border d-inline-block p-2 m-1 rounded ${activeClass}`}
                                >
                                  <span>
                                    {item.title} {item.total_contest >= 0 ? `(${item.total_contest})` : ''}
                                  </span>
                                </Link>
                              );
                          })}
                      </>
                  ) : ("")}
              </Col>
              </>
          ) : (
                  <Col md="12">
                      {ManageOnlineStore.map((item, index) =>{
                          
                          const activeClass = isActive(item.path) ? 'sub-active' : ''; 
                          return (
                      
                            <Link
                            key={index}
                            to={`${process.env.PUBLIC_URL}/${item.path}`}
                            className={`border d-inline-block p-2 m-1 rounded ${activeClass}`}
                          >
                            <span>
                              {item.title} {item.total_contest >= 0 ? `(${item.total_contest})` : ''}
                            </span>
                          </Link>
                          )
                      }
                      )}
                  </Col>
              )}
          </Row>
    </div>
  );
};

export default ManageOnlineSubMenu;


 
 
