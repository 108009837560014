import { useNavigate } from 'react-router-dom';
import { Btn, H6, LI, SVG } from '../../../../AbstractElements';  
import { setActiveIcon } from '../../../../ReduxToolkit/Reducers/ActiveHeaderRightIcon';
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmbulance, faCirclePlus } from '@fortawesome/free-solid-svg-icons';

const Upload = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeIcon = useAppSelector((state) => state.activeIcon.activeIcon);

  const handleClick = () => {
    dispatch(setActiveIcon('upload')); // Set this icon as active
    navigate(`${process.env.PUBLIC_URL}/upload`);
  };

  return (
    <LI
      className={`onhover-dropdown py-1 px-1 rounded-2 bg-primary ${activeIcon === 'upload' ? 'text-white actvie-righticon ' : ''}`}
      onClick={handleClick}
    >
      <Btn  className={`px-0 py-0 fs-6 uploadbtn btn-primary ${activeIcon === 'upload' ? 'text-white actvie-righticon' : ''}`}>+ Upload</Btn>
      {/* <SVG
        aria-hidden="true"<i class="fa-solid fa-CirclePlus"></i>
        focusable="false"
        data-prefix="fas"
        data-icon="cloud-arrow-up"
        className="svg-inline--fa fa-cloud-arrow-up"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
      >
        <path
          fill="currentColor"
          d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128l-368 0zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39L296 392c0 13.3 10.7 24 24 24s24-10.7 24-24l0-134.1 39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
        ></path>
      </SVG> */}
    </LI>
  );
};

export default Upload;
