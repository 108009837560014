import * as Yup from "yup";

export interface PasswordFormValidationProp{
    old_password:string;
    password:string; 
    password_confirmation: string; 
}

export const passwordFormInitialValue:PasswordFormValidationProp = {
    old_password:"",
    password:"", 
    password_confirmation: ""
}


export const passwordFormSchema = Yup.object().shape({
    old_password:Yup.string().required("Required"),
    password:Yup.string().required("Required"), 
    password_confirmation: Yup.string().required("Required"), 
});