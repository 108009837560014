import SchedulesMain from "../../../../../Component/Tools/ManageOnlineStore/ManageBranches/Schedules/SchedulesMain";

const AddSchedule = () => {   
  return (
    <div className="page-body">
       <SchedulesMain/>
    </div>
  );
};

export default AddSchedule;
