import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup, 
  Label,
  Row,
} from "reactstrap";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { Btn } from "../../../AbstractElements";
import {
  ChangePassword,
  ConfirmPassword,
  CurrentPassword,
  NewPassword,
  SaveChanges,
} from "../../../utils/Constant";
import { Form, ErrorMessage, Field } from "formik";

function PasswordForm(props: any) {
  const { errors, submitErrors, setSubmitErrors, values, setFieldValue } =
    props;

  return (
    <Form>
      <Card>
        <CardHeaderCommon title={ChangePassword} tagClass={"card-title mb-0"} />
        <CardBody>
          <Row>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>
                  {CurrentPassword}
                  <span className="font-danger">*</span>
                </Label>
                <Field
                  name="old_password"
                  type="password"
                  placeholder={CurrentPassword}
                  className={`form-control ${
                    submitErrors &&
                    `${errors.old_password ? "is-invalid" : "is-valid"}`
                  }`}
                />
                <ErrorMessage
                  name="old_password"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>
                  {NewPassword}
                  <span className="font-danger">*</span>
                </Label>
                <Field
                  name="password"
                  type="password"
                  placeholder={NewPassword}
                  className={`form-control ${
                    submitErrors &&
                    `${errors.password ? "is-invalid" : "is-valid"}`
                  }`}
                />
                <ErrorMessage
                  name="password"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label>
                  {ConfirmPassword}
                  <span className="font-danger">*</span>
                </Label>
                <Field
                  name="password_confirmation"
                  type="password"
                  placeholder={ConfirmPassword}
                  className={`form-control ${
                    submitErrors &&
                    `${
                      errors.password_confirmation ? "is-invalid" : "is-valid"
                    }`
                  }`}
                />
                <ErrorMessage
                  name="password_confirmation"
                  component="span"
                  className="invalid-feedback"
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-center">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitErrors(true)}
          >
            {SaveChanges}
          </Btn>
        </CardFooter>
      </Card>
    </Form>
  );
}

export default PasswordForm;
