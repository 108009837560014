import { Container, Row } from "reactstrap";
import LeftSidebar from "../PrivateChat/LeftSidebar/LeftSidebar";
import UserGroupChat from "./UserGroupChat/UserGroupChat";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Communication, PrivateCChat } from "../../../../utils/Constant";
import UserStatus from "./UserGroupChat/UserStatus";
import CommunicationSubMenu from "../CommunicationSubMenu";
import { useEffect, useState } from "react";

const GroupChatContainer = () => {
  const [responsive, Setresponsive] = useState(true);

  const ToogleContactList = () => {
    Setresponsive(!responsive);
  };

  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      console.log("responsive");
      
      setIsResponsive(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
 

  return (
    <>
      <Breadcrumbs mainTitle={PrivateCChat} parent={Communication} />
      <CommunicationSubMenu />
      <Container fluid>
        <Row className="g-0">
          {isResponsive ? (
            responsive ? (
              <LeftSidebar ToogleContactList={ToogleContactList} />
            ) : (
              <UserGroupChat
                responsive={responsive}
                ToogleContactList={ToogleContactList}
              />
            )
          ) : (
            <>
              <LeftSidebar ToogleContactList={ToogleContactList} />
              <UserGroupChat
                responsive={responsive}
                ToogleContactList={ToogleContactList}
              />
              {/* <UserStatus /> */}
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default GroupChatContainer;
