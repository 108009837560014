import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Maintenance from "../../../Pages/Maintenance/Index";

const MaintanceRouterData = () => {
  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route
          path={`${process.env.PUBLIC_URL}/maintenance`}
          element={<Maintenance />}
        />

        <Route
          path={`${process.env.PUBLIC_URL}` || "/*"}
          element={<Navigate to={`${process.env.PUBLIC_URL}/maintenance`} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default MaintanceRouterData;
