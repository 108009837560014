import { useEffect } from "react";
import CustomSelect from "./CustomSelect";
import { Field, useFormikContext } from "formik";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { useGetStateDataMutation } from "../../ReduxToolkit/Reducers/State/stateApiSlice";
import { setStates } from "../../ReduxToolkit/Reducers/commonInputSlice";
import Loading from "../Loading/Loading";

function State(props: any) {
  const { states } = useAppSelector((state: any) => state.common);
  const { name, className, isMulti, placeholder, countryId, setFieldValue, values } = props;
  const [getStateDataApiCall, { isLoading }] = useGetStateDataMutation();
  const dispatch = useAppDispatch();
 
  useEffect(() => {
    const getStateData = async (countryId: any) => {
      const response = await getStateDataApiCall(countryId).unwrap();  
      const { data, success }: any = response; 
      
      if (success === true) {
        dispatch(setStates([]));
        // Map the fetched countries to your desired structure
        let filter_states = data.map((item: any) => {
          return { label: item.name, value: item.id };
        });

        // Add the clearable option
        filter_states = [...filter_states]; 
        // Dispatch the countries to the Redux store
        dispatch(setStates(filter_states)); 
      }
    };
    if (countryId > 0) {
      getStateData(countryId);
    }
  }, [countryId]);  
  const handleChange = (selectedOption: any) => {
    setFieldValue(name, selectedOption); // Update Formik's state with selected value
  };
  if (!states || states.length === 0) return <div>Data not found!</div>; 

  return (
    <Field
      className={`custom-select ${className}`}
      name={name}
      options={states}
      component={CustomSelect}
      placeholder={placeholder}
      isMulti={isMulti}
      onChange={handleChange}
    />
  );
}

export default State;
