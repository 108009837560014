// Full list of Animate.css animations 
export const animationNames = [
    "bounce", "flash", "pulse", "rubberBand", "shakeX", "shakeY", "headShake",
    "swing", "tada", "wobble", "jello", "heartBeat",
    "backInDown", "backInLeft", "backInRight", "backInUp",
    "backOutDown", "backOutLeft", "backOutRight", "backOutUp",
    "bounceIn", "bounceInDown", "bounceInLeft", "bounceInRight", "bounceInUp",
    "bounceOut", "bounceOutDown", "bounceOutLeft", "bounceOutRight", "bounceOutUp",
    "fadeIn", "fadeInDown", "fadeInDownBig", "fadeInLeft", "fadeInLeftBig",
    "fadeInRight", "fadeInRightBig", "fadeInUp", "fadeInUpBig", "fadeInTopLeft",
    "fadeInTopRight", "fadeInBottomLeft", "fadeInBottomRight",
    "fadeOut", "fadeOutDown", "fadeOutDownBig", "fadeOutLeft", "fadeOutLeftBig",
    "fadeOutRight", "fadeOutRightBig", "fadeOutUp", "fadeOutUpBig", "fadeOutTopLeft",
    "fadeOutTopRight", "fadeOutBottomRight", "fadeOutBottomLeft",
    "flip", "flipInX", "flipInY", "flipOutX", "flipOutY",
    "lightSpeedInRight", "lightSpeedInLeft", "lightSpeedOutRight", "lightSpeedOutLeft",
    "rotateIn", "rotateInDownLeft", "rotateInDownRight", "rotateInUpLeft", "rotateInUpRight",
    "rotateOut", "rotateOutDownLeft", "rotateOutDownRight", "rotateOutUpLeft", "rotateOutUpRight",
    "slideInDown", "slideInLeft", "slideInRight", "slideInUp",
    "slideOutDown", "slideOutLeft", "slideOutRight", "slideOutUp",
    "zoomIn", "zoomInDown", "zoomInLeft", "zoomInRight", "zoomInUp",
    "zoomOut", "zoomOutDown", "zoomOutLeft", "zoomOutRight", "zoomOutUp",
    "hinge", "jackInTheBox", "rollIn", "rollOut",
  ];
export const NextScreenanimationNames = [
 
    "fadeInRight", "fadeInRightBig", "fadeInUp", "fadeInUpBig", "fadeInTopLeft",
    "fadeInTopRight", "fadeInBottomLeft", "fadeInBottomRight",
    "fadeOut", "fadeOutDown", "fadeOutDownBig", "fadeOutLeft", "fadeOutLeftBig",
     
  ];
export const CorrectAnsweranimationNames = [
  
    "flipInX", "fadeOutDown", "fadeOutDownBig", "fadeOutLeft", "fadeOutLeftBig",
    "fadeOutRight", "fadeOutRightBig", "fadeOutUp", "fadeOutUpBig", "fadeOutTopLeft",
    "fadeOutTopRight", "fadeOutBottomRight", "fadeOutBottomLeft",
    "flip", "fadeOut", "flipInY", "flipOutX", "flipOutY",
     
  ];
export const WrongAnsweranimationNames = [
    
    "lightSpeedInRight", "lightSpeedInLeft", "lightSpeedOutRight", "lightSpeedOutLeft",
    "rotateIn", "rotateInDownLeft", "rotateInDownRight", "rotateInUpLeft", "rotateInUpRight",
    "rotateOut", "rotateOutDownLeft", "rotateOutDownRight", "rotateOutUpLeft", "rotateOutUpRight",
    "slideInDown", "slideInLeft", "slideInRight", "slideInUp",
 
  ];
export const WinneranimationNames = [
     
    "rollIn", "slideOutLeft", "slideOutRight", "slideOutUp",
    "zoomIn", "zoomInDown", "zoomInLeft", "zoomInRight", "zoomInUp",
    "zoomOut", "zoomOutDown", "zoomOutLeft", "zoomOutRight", "zoomOutUp",
    "hinge", "jackInTheBox", "slideOutDown", "rollOut",
  ];