import { Route, Routes } from "react-router-dom";
import Layout from "../Layout/Layout";
import { routes } from "./Route";
import Error400 from "../Pages/OtherPages/Error/Error400/Error400";
import { useAppSelector } from "../ReduxToolkit/Hooks";
import { getFilterUrl, getDeveloperFilterUrl } from "./index";

const LayoutRoutes = () => {
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);
  const { userInfo }: any = useAppSelector((state) => state.auth);
  const { authUser }: any = (userInfo && userInfo) || {};

  const routesOff = getFilterUrl(routes, websiteSettingsData);
  const routesOffDeveloper = getDeveloperFilterUrl(routes, websiteSettingsData);

  let enableRoutes = authUser !== undefined && authUser.roles.includes("Developer") ? routes.filter((x) => !routesOffDeveloper.includes(x)) : routes.filter((x) => !routesOff.includes(x));

  return (
    <Routes>
      <Route path="*" element={<Error400 />} />
      {enableRoutes.map(({ path, Component }, i) => (
        <Route element={<Layout />} key={i + 1}>
          <Route path={path} element={Component} />
        </Route>
      ))}
    </Routes>
  );
};

export default LayoutRoutes;
