import { Field } from "formik";
import CustomSelect from "./CustomSelect";
import { Option } from "./CustomSelect";

interface CategoryDataDropProps {
  name: string;
  className?: string;
  placeholder?: string;
  isMulti?: boolean;
  onCategoryChange?: (category: string | null) => void;
}

const CategoryDataDrop = ({
  name,
  className,
  placeholder,
  isMulti = false,
  onCategoryChange,
}: CategoryDataDropProps) => {
  const options: Option[] = [
    { label: 'Category 1', value: 'category1' },
    { label: 'Category 2', value: 'category2' },
    { label: 'Category 3', value: 'category3' },
  ];

  // Handle the category change and pass it to the parent
  const handleChange = (selectedValue: string | string[] | null) => {
    
    
    const selectedOption = Array.isArray(selectedValue)
    ? selectedValue  // Take the first item from the array
    : selectedValue;  // Use as is if it's already an object
  
  // Log the selected option for debugging
  
  // Ensure the correct format is passed to onCategoryChange
  if (onCategoryChange) {
  console.log(selectedOption);

    onCategoryChange(selectedOption);
  }
  };

  return (
    <Field
      name={name}
      component={CustomSelect}
      className={className}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      onCategoryChange={handleChange} // Pass the handleChange function
    />
  );
};

export default CategoryDataDrop;