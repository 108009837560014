import { Label } from "reactstrap";
import "./SubCategory.css";
import { ErrorMessage, Field } from "formik";
import CustomSelect from "./CustomSelect";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { useGetSubCategoryDataMutation } from "../../ReduxToolkit/Reducers/Category/categoryApiSlice";
import { toast } from "react-toastify";
import { setSubCategories } from "../../ReduxToolkit/Reducers/commonInputSlice";
import { useEffect } from "react";

const SubCategory = (props: any) => {
  
  const { className, isMulti, name } = props;

  const { subCategories } = useAppSelector((state) => state.common);

  const [getSubCategoryDataApiCall, { isLoading }] =
    useGetSubCategoryDataMutation();

  const dispatch = useAppDispatch(); 

  useEffect(() => {
    const getSubCategoryData = async () => {
      try {
        const response = await getSubCategoryDataApiCall().unwrap();
        const { data, success, message } = response;
        if (success === true) {
          let filterSubCategories = data.map((item: any) => {
            return { label: item.name, value: item.id };
          });
          // Add the clearable option
          filterSubCategories = [...filterSubCategories];
          dispatch(setSubCategories(filterSubCategories));
        }
      } catch (error: any) {
        toast.error(error?.data?.message);
      }
    };
    getSubCategoryData();
  }, [dispatch,setSubCategories]);


  if (isLoading) return <div>Data not found!</div>;

  if (!subCategories || subCategories.length === 0)
    return <div>Data not found!</div>;

  return (
    <>
      <Label check>
        Category <span className="txt-danger">*</span>
      </Label>
      <Field
        className={`custom-select ${className || ""}`}
        name={name}
        options={subCategories}
        component={CustomSelect}
        placeholder="Please select a Category"
        isMulti={isMulti || false}
      />
      <ErrorMessage name={name} component="span" className="invalid-feedback" />
    </>
  );
};

export default SubCategory;
