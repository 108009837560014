// import { useEffect, useState } from "react";
// import {
//     Button,
//     Card,
//     CardBody,
//     Col,
//     Form,
//     Label,
//     Modal, 
//     Row, 
//     Table,
// } from "reactstrap";
// import {
//     Btn, 
//     H3,
//     H4,  
//     LI,
//     P,
//     SVG,
//     UL,
// } from "../../../AbstractElements";
// import { ProductInterface } from "./Product";
// import { useAppSelector } from "../../../ReduxToolkit/Hooks"; 
// import { ProductItemInterface } from "../../../Types/Application/Ecommerce/Product";
// import ProductDetails from "./ProductDetails";
// import { Link } from "react-router-dom";
// import { ServiceType } from "../../../Types/Application/Ecommerce/Ecommerce"; 
// import {  Formik } from "formik";
// import {
//     specialParametersFormInitialValue,
//     specialParametersFormValidationSchema,
//     surveyAndQuizzesFormInitialValue,
//     SurveyAndQuizzesFormValidationProp,
//     surveyAndQuizzesFormValidationSchema,
// } from "../../../Data/Tools/BrandSupportTools/FormControlsValidation"; 
// import ExamModal from "./ExamModal";

// export const servicesData: ServiceType[] = [
//     {
//         icon: "Truck",
//         title: "Free Shipping",
//         subtitle: "Free Shipping World Wide",
//     },
//     {
//         icon: "Clock",
//         title: "24 X 7 Service",
//         subtitle: "Online Service For New Customer",
//     },
// ];

// export const clothsDetailsData = ["General Parameters ", "Quiz Materials"];

// export interface ProductModalInterface {
//     value: boolean;
//     setOpenModal: (value: boolean) => void;
//     dataId: undefined | number; 
// }

// const ProductModal = ({
//     value,
//     setOpenModal,
//     dataId, 
// }: ProductModalInterface) => {
//     const [open, setOpen] = useState(value);
//     const [activeTab, setActiveTab] = useState(1);
//     const { productItem } = useAppSelector((state) => state.product);
//     const [singleProduct, setSingleProduct] = useState<
//         ProductInterface | undefined | [] | any
//     >([]);
//     const [currentIndex, setCurrentIndex] = useState<number>(0);
   
//     useEffect(() => {
//         if (dataId && productItem.length > 0) {
//             const index = productItem.findIndex(
//                 (product: ProductItemInterface) => product.id === dataId
//             );
//             if (index !== -1) {
//                 setCurrentIndex(index);
//                 setSingleProduct(productItem[index]);
//             }
//         }
//     }, [dataId, productItem]);
//     useEffect(() => {
//         setSingleProduct(productItem[currentIndex]);
//     }, [currentIndex, productItem]);

//     const onCloseModal = () => {
//         setOpen(false);
//         setOpenModal(false);
//     };
//     const [currentId, setCurrentId] = useState<number>(dataId || 0);

//     const handleNext = () => {
//         if (currentIndex < productItem.length - 1) {
//             setCurrentIndex((prevIndex) => prevIndex + 1);
//         }
//     };

//     // Navigate to previous product
//     const handlePrevious = () => {
//         if (currentIndex > 0) {
//             setCurrentIndex((prevIndex) => prevIndex - 1);
//         }
//     };

//     const productSocial = [
//         {
//             link: "https://www.facebook.com/",
//             iconName: "facebook",
//         },
//         {
//             link: "https://accounts.google.com/",
//             iconName: "google-plus",
//         },
//         {
//             link: "https://twitter.com/",
//             iconName: "twitter",
//         },
//         {
//             link: "https://www.instagram.com/",
//             iconName: "instagram",
//         },
//         {
//             link: "https://rss.app/",
//             iconName: "rss",
//         },
//     ];


//     const handleSubmit = (values: SurveyAndQuizzesFormValidationProp) => {
//         console.log(values);
//         setSubmitError(false);
//     };
//     const [quizopen, setquizopen] = useState<boolean>(false);

//     const onClosequizzModal = () => {
//         setquizopen(false);
//     };
//     const handlePopupClick = () => {
//         setquizopen(true);
//     };
//     return (
//         <Modal
//             wrapClassName="product-box"
//             fade
//             centered
//             size="xl"
//             isOpen={open}
//             toggle={onCloseModal}
//             className="modal-dialog-scrollable">
//             <div className="modal-header" onClick={onCloseModal}>
//                 <H4> Title</H4>
//                 <Btn className="py-0" close></Btn>
//             </div>

//             <div className="modal-body bg-light">
//                 <Formik
//                     initialValues={surveyAndQuizzesFormInitialValue}
//                     onSubmit={handleSubmit}
//                     validationSchema={surveyAndQuizzesFormValidationSchema}>
//                     {({ errors, values, setFieldValue }) => (
//                         <Form className="form-wizard">
//                             <Row className="product-box">
//                                 <Col lg="4" sm="12">
//                                     <ProductDetails />
//                                 </Col>
//                                 <Col>
//                                     <Card>
//                                         <CardBody>
//                                             <div className="product-page-details">
//                                                 {/* <H3>{singleProduct.name}</H3> */}
//                                                 <H3>Title</H3>
//                                             </div>
//                                             <p>Type: Test or Quiz</p>
//                                             <div className="product-price">
//                                                 $26.00
//                                                 <del>$350.00 </del>
//                                             </div>
//                                             <UL className="product-color flex-row">
//                                                 <LI className="bg-primary" />
//                                                 <LI className="bg-secondary" />
//                                                 <LI className="bg-success" />
//                                                 <LI className="bg-info" />
//                                                 <LI className="bg-warning" />
//                                             </UL>
//                                             <hr />
//                                             <span className="fw-medium">
//                                                 Description:
//                                             </span>
//                                             <P>
//                                                 {
//                                                     "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve."
//                                                 }
//                                             </P>
//                                             <hr />
//                                             <div>
//                                                 <Table className="product-page-width">
//                                                     <tbody>
//                                                         <tr>
//                                                             <td>
//                                                                 <b>
//                                                                     Timezone:{" "}
//                                                                 </b>
//                                                             </td>
//                                                             <td className="txt-success">
//                                                                 IST
//                                                             </td>
//                                                         </tr>
//                                                         <tr>
//                                                             <td>
//                                                                 <b>
//                                                                     Recipient
//                                                                     Type:
//                                                                 </b>
//                                                             </td>
//                                                             <td>
//                                                                 Only Followers
//                                                             </td>
//                                                         </tr>
//                                                         <tr>
//                                                             <td>
//                                                                 <b>
//                                                                     Expiry Date
//                                                                     + Hour:
//                                                                 </b>
//                                                             </td>
//                                                             <td>14/Sep/2024</td>
//                                                         </tr>
//                                                         <tr>
//                                                             <td
//                                                                 style={{
//                                                                     alignContent:
//                                                                         "baseline",
//                                                                 }}>
//                                                                 <b>
//                                                                     Prerequisites
//                                                                     and
//                                                                     Requirements:
//                                                                 </b>
//                                                             </td>
//                                                             <td>
//                                                                 Before starting,
//                                                                 it's important
//                                                                 to ensure that
//                                                                 you meet certain
//                                                                 prerequisites to
//                                                                 streamline your
//                                                                 experience with
//                                                                 the project. A
//                                                                 solid
//                                                                 understanding of
//                                                                 HTML, CSS, and
//                                                                 JavaScript is
//                                                                 crucial as these
//                                                                 form the
//                                                                 foundation of
//                                                                 modern web
//                                                                 development.
//                                                                 Additionally,
//                                                                 familiarity with
//                                                                 React.js,
//                                                                 especially in
//                                                                 terms of
//                                                                 component
//                                                                 lifecycle,
//                                                                 hooks, and state
//                                                                 management, will
//                                                                 be invaluable.
//                                                             </td>
//                                                         </tr>
//                                                         <tr>
//                                                             <td
//                                                                 style={{
//                                                                     alignContent:
//                                                                         "baseline",
//                                                                 }}>
//                                                                 <b>Keywords:</b>
//                                                             </td>
//                                                             <td>
//                                                                 High-Quality
//                                                                 Photos,Stock
//                                                                 Images,Free
//                                                                 Photos,
//                                                                 Royalty-Free
//                                                                 Images,
//                                                                 Photography
//                                                                 Collection, HD
//                                                                 Photos, Curated
//                                                                 Photo Library,
//                                                                 Creative Commons
//                                                                 Photos, Free
//                                                                 Image Downloads,
//                                                                 Professional
//                                                                 Photography,
//                                                                 Background
//                                                                 Images, Nature
//                                                                 Photos
//                                                             </td>
//                                                         </tr>
//                                                     </tbody>
//                                                 </Table>
//                                             </div>
//                                             <hr />
//                                             <Row>
//                                                 <Col md="4">
//                                                     <H4 className="product-title">{`ShareIt`}</H4>
//                                                 </Col>
//                                                 <Col md="8">
//                                                     <div className="product-icon">
//                                                         <UL className="product-social simple-list flex-row justify-content-center">
//                                                             {productSocial.map(
//                                                                 (
//                                                                     data,
//                                                                     index
//                                                                 ) => (
//                                                                     <LI
//                                                                         className="d-inline-block"
//                                                                         key={
//                                                                             index
//                                                                         }>
//                                                                         <Link
//                                                                             to={
//                                                                                 data.link
//                                                                             }
//                                                                             target="_blank">
//                                                                             <i
//                                                                                 className={`fa fa-${data.iconName}`}
//                                                                             />
//                                                                         </Link>
//                                                                     </LI>
//                                                                 )
//                                                             )}
//                                                         </UL>
//                                                     </div>
//                                                 </Col>
//                                             </Row>
//                                             <hr />
//                                             <Row>
//                                                 <Col md="4">
//                                                     <H4 className="product-title f-w-600">{`RateNow`}</H4>
//                                                 </Col>
//                                                 <Col md="8">
//                                                     <div className="d-flex">
//                                                         <div className="rating">
//                                                             <i className="fa fa-star"></i>
//                                                             <i className="fa fa-star"></i>
//                                                             <i className="fa fa-star"></i>
//                                                             <i className="fa fa-star"></i>
//                                                             <i className="fa fa-star"></i>
//                                                         </div>
//                                                         <span>
//                                                             (250 review)
//                                                         </span>
//                                                     </div>
//                                                 </Col>
//                                             </Row>
//                                         </CardBody>
//                                     </Card>
//                                 </Col>
//                                 <Col xxl="3" md="8" sm="12" className="box-col-6 ms-auto">
//                                     <Card>
//                                         <CardBody>
//                                             <Row>
//                                                 <Col className="mb-3">
//                                                     <h4>General Parameters</h4>
//                                                 </Col>
//                                             </Row>
//                                             <Formik
//                                                 initialValues={
//                                                     specialParametersFormInitialValue
//                                                 }
//                                                 onSubmit={handleSubmit}
//                                                 validationSchema={
//                                                     specialParametersFormValidationSchema
//                                                 }>
//                                                 {({
//                                                     errors,
//                                                     values,
//                                                     setFieldValue,
//                                                 }) => (
//                                                     <Form className="form-wizard">
//                                                         <Row>
//                                                             <Col
//                                                                 sm="12"
//                                                                 className="mb-3">
//                                                                 <Label check>
//                                                                     Country:
//                                                                     India
//                                                                 </Label>
//                                                             </Col>
//                                                             <Col
//                                                                 sm="12"
//                                                                 className="mb-3">
//                                                                 <Label check>
//                                                                     State:
//                                                                     Gujrat
//                                                                 </Label>
                                                                
//                                                             </Col>
//                                                             <Col
//                                                                 sm="12"
//                                                                 className="mb-3">
//                                                                 <Label check>
//                                                                     Course:
//                                                                     Bachelor
//                                                                     Journalism
//                                                                 </Label>
//                                                                 </Col>
//                                                             <Col
//                                                                 sm="12"
//                                                                 className="mb-3">
//                                                                 <Label check>
//                                                                     Book: Don
//                                                                     Quixote
//                                                                 </Label>
//                                                                 </Col>
//                                                             <Col
//                                                                 sm="12"
//                                                                 className="mb-3">
//                                                                 <Label check>
//                                                                     Exam: UPSC
//                                                                     exams
//                                                                 </Label>
//                                                                </Col>
//                                                         </Row>
//                                                     </Form>
//                                                 )}
//                                             </Formik>
//                                         </CardBody>
                                        
//                                     </Card>
//                                 </Col>
//                             </Row>
//                         </Form>
//                     )}
//                 </Formik>
//             </div>
//           { quizopen &&   <ExamModal value={quizopen} setquizopen={setquizopen} />}
             
//             <div className="modal-footer ">
//             <Button onClick={handlePopupClick}>
//                                             Start Now
//                                         </Button> 
//                 <div className=" next_previous  position-relative pt-0">
//                     <Btn
//                         onClick={handlePrevious}
//                         className="border"
//                         disabled={currentId === 0}>
//                         <div className="d-flex align-items-center gap-sm-2 gap-1">
//                             Previous <SVG iconId=" back-arrow" />
//                         </div>
//                     </Btn>
//                     <Btn
//                         onClick={handleNext}
//                         className="border"
//                         disabled={currentId === productItem.length - 1}>
//                         <div className="d-flex align-items-center gap-sm-2 gap-1">
//                             Next <SVG iconId="front-arrow" />
//                         </div>
//                     </Btn>
//                 </div>
//             </div>
//         </Modal>
//     );
// };

// export default ProductModal;
import { useEffect, useState } from "react";
import {
    Button,
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import {
  Btn,
  FeatherIcons,
  H3,
  H4,
  H5,
  Image,
  LI,
  P,
  SVG,
  UL,
} from "../../../AbstractElements";
import { ProductInterface } from "./Product";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import { dynamicImage } from "../../../Service";
import { ProductItemInterface } from "../../../Types/Application/Ecommerce/Product";
import ProductDetails from "./ProductDetails";
import { Link } from "react-router-dom";
import { ServiceType } from "../../../Types/Application/Ecommerce/Ecommerce";
import { Href, PostContent } from "../../../utils/Constant";
import { specialParametersFormInitialValue, specialParametersFormValidationSchema, surveyAndQuizzesFormInitialValue, SurveyAndQuizzesFormValidationProp, surveyAndQuizzesFormValidationSchema } from "../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { Formik } from "formik";
import ExamModal from "./ExamModal";
import AdSense from "../../WebsiteSettings/AdSense/Index";

export const servicesData: ServiceType[] = [
  {
    icon: "Truck",
    title: "Free Shipping",
    subtitle: "Free Shipping World Wide",
  },
  {
    icon: "Clock",
    title: "24 X 7 Service",
    subtitle: "Online Service For New Customer",
  },
];

export const clothsDetailsData = ["Fabric", "Video", "Details", "Brand"];

export interface ProductModalInterface {
  value: boolean;
  setOpenModal: (value: boolean) => void;
  dataId: undefined | number;
}

const ProductModal = ({
  value,
  setOpenModal,
  dataId,
  ModalType,
  modalBodyClassName
}: ProductModalInterface) => {
  const [open, setOpen] = useState(value);
  const [activeTab, setActiveTab] = useState(1);
  const { productItem } = useAppSelector((state) => state.product);
  const [singleProduct, setSingleProduct] = useState<
  ProductInterface | undefined | [] | any
  >([]);
  const [currentId, setCurrentId] = useState<number>(dataId || 0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    productItem.forEach((product: ProductItemInterface) => {
      if (product.id === dataId) setSingleProduct(product);
    });
  });
 
   
    useEffect(() => {
        if (dataId && productItem.length > 0) {
            const index = productItem.findIndex(
                (product: ProductItemInterface) => product.id === dataId
            );
            if (index !== -1) {
                setCurrentIndex(index);
                setSingleProduct(productItem[index]);
            }
        }
    }, [dataId, productItem]);
    useEffect(() => {
        setSingleProduct(productItem[currentIndex]);
    }, [currentIndex, productItem]);
  const onCloseModal = () => {
    setOpen(false);
    setOpenModal(false);
  };
  const handleSubmit = (values: SurveyAndQuizzesFormValidationProp) => {
    console.log(values);
    setSubmitError(false);
};
const [quizopen, setquizopen] = useState<boolean>(false);

const onClosequizzModal = () => {
    setquizopen(false);
};
const handlePopupClick = () => {
    setquizopen(true);
};
    const handleNext = () => {
        if (currentIndex < productItem.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    // Navigate to previous product
    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };
  const brandDetailsData = ["Clothing", "Bags", "Footwear"];
  const productSocial = [
    {
      link: "https://www.facebook.com/",
      iconName: "facebook",
    },
    {
      link: "https://accounts.google.com/",
      iconName: "google-plus",
    },
    {
      link: "https://twitter.com/",
      iconName: "twitter",
    },
    {
      link: "https://www.instagram.com/",
      iconName: "instagram",
    },
    {
      link: "https://rss.app/",
      iconName: "rss",
    },
  ];
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);

  return (
    <Modal
      wrapClassName="product-box"
      fade
      centered
      size="xl"
      isOpen={open}
      toggle={onCloseModal}
      wrapClassName="product-box"
 
            className={` modal-dialog-scrollable ${ modalBodyClassName ? modalBodyClassName : "" }`}
    >
     { ModalType == 'product' ? 
     <><div className="modal-header" onClick={onCloseModal}>
      <H3>Product 1</H3>
        <Btn className="py-0" close></Btn>
      </div>
      <div className="modal-body bg-light">
        <Row className="product-box">
          <Col lg="4" sm="12">
            <ProductDetails />
          </Col>
          { websiteSettingsData?.google_ads_index === "on" && <Col lg="3" sm="12">
                    <AdSense />
                    </Col>}
          <Col lg={websiteSettingsData?.google_ads_index === "on" ? "5" :'12'} sm="12">
          <Card>
                                        <CardBody> 
                                            <div>
                                                <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b className="  ">
                                                                A Previous Product or Service Contest as a Reference:
                                                                </b>
                                                            </td>
                                                            <td>A  showcase and promote innovative ideas</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Category:</b>
                                                            </td>
                                                            <td>Free Commercial</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Name of a Product or Service:</b>
                                                            </td>
                                                            <td>Eaque mollitia volup</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Inventory Number :</b>
                                                            </td>
                                                            <td>54848asasas848 </td>
                                                        </tr> 
                                                        
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    alignContent:
                                                                        "baseline",
                                                                }}>
                                                                <b>Keywords:</b>
                                                            </td>
                                                            <td>
                                                                High-Quality
                                                                Photos,Stock
                                                                Images,Free
                                                                Photos,
                                                                Royalty-Free
                                                                Images,
                                                                Photography
                                                                Collection, HD
                                                                Photos, Curated
                                                                Photo Library,
                                                                Creative Commons
                                                                Photos, Free
                                                                Image Downloads,
                                                                Professional
                                                                Photography,
                                                                Background
                                                                Images, Nature
                                                                Photos
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <H3 className="my-2 fw-bold text-gray">Branch and Price Setup</H3>
                                                <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Country :
                                                                </b>
                                                            </td>
                                                            <td>iran</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                State:
                                                                </b>
                                                            </td>
                                                            <td>wild</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                City :
                                                                </b>
                                                            </td>
                                                            <td>surat</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Branch:
                                                                </b>
                                                            </td>
                                                            <td>Branch 1</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Select Groups :
                                                                </b>
                                                            </td>
                                                            <td>Life Style</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Usernames:
                                                                </b>
                                                            </td>
                                                            <td>crosils</td>
                                                        </tr> 
                                                         
                                                    </tbody>
                                                </Table>
                                                <H3 className="my-2 fw-bold text-gray">Price Addons</H3>
                                                <p><b>Addon Category:</b> Category 1</p>
                                                <H3 className="my-2 fw-bold text-gray">Sharing</H3>
                                                <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Display on My Media Center:
                                                                </b>
                                                            </td>
                                                            <td>To Anyone</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Select Groups :
                                                                </b>
                                                            </td>
                                                            <td>Life Style</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Usernames:
                                                                </b>
                                                            </td>
                                                            <td>crosils</td>
                                                        </tr> 
                                                         
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <hr /> 
                                        </CardBody>
                                    </Card>
          </Col> 
          
        </Row>
      </div></> : 
      ModalType == "quiz" ? 
      <>
        <div className="modal-header" onClick={onCloseModal}>
                 <H4> Title</H4>
                  <Btn className="py-0" close></Btn>
             </div>

              <div className="modal-body bg-light">
                  <Formik
                    initialValues={surveyAndQuizzesFormInitialValue}
                    onSubmit={handleSubmit}
                    validationSchema={surveyAndQuizzesFormValidationSchema}>
                    {({ errors, values, setFieldValue }) => (
                        <Form className="form-wizard">
                            <Row className="product-box">
                                <Col lg="4" sm="12">
                                    <ProductDetails />
                                </Col>
                                <Col lg="3" sm="12" className="box-col-6 ">
                                    
                                    {websiteSettingsData?.google_ads_index === "on" &&   <AdSense/>}
                                </Col>
                                <Col lg={websiteSettingsData?.google_ads_index === "on" ? '5':'9'} sm='12'>
                                    <Card>
                                        <CardBody>
                                            <div className="product-page-details">
                                                {/* <H3>{singleProduct.name}</H3> */}
                                                <H3>Title</H3>
                                            </div>
                                            <p>Type: Test or Quiz</p>
                                            <div className="product-price">
                                                $26.00
                                                <del>$350.00 </del>
                                            </div>
                                            <UL className="product-color flex-row">
                                                <LI className="bg-primary" />
                                                <LI className="bg-secondary" />
                                                <LI className="bg-success" />
                                                <LI className="bg-info" />
                                                <LI className="bg-warning" />
                                            </UL>
                                            <hr />
                                            <span className="fw-medium">
                                                Description:
                                            </span>
                                            <P>
                                                {
                                                    "Rock Paper Scissors Various Dots Half Sleeves Girl’s Regular Fit T-Shirt I 100% Cotton T Shirt with Half Sleeve Round Neck I Regular Wear Solid Kids Tees and Black Sleeve."
                                                }
                                            </P>
                                            <hr />
                                            <div>
                                                <Table className="product-page-width">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                    Timezone:{" "}
                                                                </b>
                                                            </td>
                                                            <td className="txt-success">
                                                                IST
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                    Recipient
                                                                    Type:
                                                                </b>
                                                            </td>
                                                            <td>
                                                                Only Followers
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                    Expiry Date
                                                                    + Hour:
                                                                </b>
                                                            </td>
                                                            <td>14/Sep/2024</td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    alignContent:
                                                                        "baseline",
                                                                }}>
                                                                <b>
                                                                    Prerequisites
                                                                    and
                                                                    Requirements:
                                                                </b>
                                                            </td>
                                                            <td>
                                                                Before starting,
                                                                it's important
                                                                to ensure that
                                                                you meet certain
                                                                prerequisites to
                                                                streamline your
                                                                experience with
                                                                the project. A
                                                                solid
                                                                understanding of
                                                                HTML, CSS, and
                                                                JavaScript is
                                                                crucial as these
                                                                form the
                                                                foundation of
                                                                modern web
                                                                development.
                                                                Additionally,
                                                                familiarity with
                                                                React.js,
                                                                especially in
                                                                terms of
                                                                component
                                                                lifecycle,
                                                                hooks, and state
                                                                management, will
                                                                be invaluable.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    alignContent:
                                                                        "baseline",
                                                                }}>
                                                                <b>Keywords:</b>
                                                            </td>
                                                            <td>
                                                                High-Quality
                                                                Photos,Stock
                                                                Images,Free
                                                                Photos,
                                                                Royalty-Free
                                                                Images,
                                                                Photography
                                                                Collection, HD
                                                                Photos, Curated
                                                                Photo Library,
                                                                Creative Commons
                                                                Photos, Free
                                                                Image Downloads,
                                                                Professional
                                                                Photography,
                                                                Background
                                                                Images, Nature
                                                                Photos
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <hr />
                                            <Row>
                                                <Col md="4">
                                                    <H4 className="product-title">{`ShareIt`}</H4>
                                                </Col>
                                                <Col md="8">
                                                    <div className="product-icon">
                                                        <UL className="product-social simple-list flex-row justify-content-center">
                                                            {productSocial.map(
                                                                (
                                                                    data,
                                                                    index
                                                                ) => (
                                                                    <LI
                                                                        className="d-inline-block"
                                                                        key={
                                                                            index
                                                                        }>
                                                                        <Link
                                                                            to={
                                                                                data.link
                                                                            }
                                                                            target="_blank">
                                                                            <i
                                                                                className={`fa fa-${data.iconName}`}
                                                                            />
                                                                        </Link>
                                                                    </LI>
                                                                )
                                                            )}
                                                        </UL>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col md="4">
                                                    <H4 className="product-title f-w-600">{`RateNow`}</H4>
                                                </Col>
                                                <Col md="8">
                                                    <div className="d-flex">
                                                        <div className="rating">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                        <span>
                                                            (250 review)
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col className="mb-3">
                                                    <h4>General Parameters</h4>
                                                </Col>
                                            </Row>
                                            <Formik
                                                initialValues={
                                                    specialParametersFormInitialValue
                                                }
                                                onSubmit={handleSubmit}
                                                validationSchema={
                                                    specialParametersFormValidationSchema
                                                }>
                                                {({
                                                    errors,
                                                    values,
                                                    setFieldValue,
                                                }) => (
                                                    <Form className="form-wizard">
                                                        <Row>
                                                            <Col
                                                                sm="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    Country:
                                                                    India
                                                                </Label>
                                                            </Col>
                                                            <Col
                                                                sm="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    State:
                                                                    Gujrat
                                                                </Label>
                                                                
                                                            </Col>
                                                            <Col
                                                                sm="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    Course:
                                                                    Bachelor
                                                                    Journalism
                                                                </Label>
                                                                </Col>
                                                            <Col
                                                                sm="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    Book: Don
                                                                    Quixote
                                                                </Label>
                                                                </Col>
                                                            <Col
                                                                sm="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    Exam: UPSC
                                                                    exams
                                                                </Label>
                                                               </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik> 
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
          { quizopen &&   <ExamModal value={quizopen} setquizopen={setquizopen} />}
             
            <div className="modal-footer ">
            <Button onClick={handlePopupClick}>
                                            Start Now
                                        </Button> 
                <div className=" next_previous  position-relative pt-0">
                    <Btn
                        onClick={handlePrevious}
                        className="border"
                        disabled={currentId === 0}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Previous <SVG iconId=" back-arrow" />
                        </div>
                    </Btn>
                    <Btn
                        onClick={handleNext}
                        className="border"
                        disabled={currentId === productItem.length - 1}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Next <SVG iconId="front-arrow" />
                        </div>
                    </Btn>
                </div>
            </div>
      </>
      :ModalType == "articals" ? <>
      <div className="modal-header" onClick={onCloseModal}>
                {/* <H4>{singleProduct.name}</H4>  */}
                <H4> Title</H4>
                <Btn className="py-0" close></Btn>
            </div>

            <div className="modal-body bg-light">
                <Formik
                    initialValues={surveyAndQuizzesFormInitialValue}
                    onSubmit={handleSubmit}
                    validationSchema={surveyAndQuizzesFormValidationSchema}>
                    {({ errors, values, setFieldValue }) => (
                        <Form className="form-wizard">
                            <Row className="product-box">
                                <Col lg="4" sm="12">
                                    <ProductDetails />
                                </Col>
                                { websiteSettingsData?.google_ads_index === "on" && <Col lg="3" sm="12">
                    <AdSense />
                    </Col>}
                                <Col lg={websiteSettingsData?.google_ads_index === "on" ? "5" :'12'} sm="12">
                                    <Card>
                                        <CardBody> 
                                            <div>
                                                <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                 Title:
                                                                </b>
                                                            </td>
                                                            <td> Technology</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Category:</b>
                                                            </td>
                                                            <td>Free Commercial</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                 Description:
                                                                </b>
                                                            </td>
                                                            <td>Technology is the application of conceptual knowledge to achieve practical goals, especially in a reproducible way.[1] The word technology can also mean the products resulting from such efforts,[2][3] including both tangible tools such as utensils or machines, and intangible ones such as software. Technology plays a critical role in science, engineering, and everyday life.</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                {PostContent}:
                                                                </b>
                                                            </td>
                                                            <td>Technological advancements have led to significant changes in society. The earliest known technology is the stone tool, used during prehistory, followed by the control of fire—which in turn contributed to the growth of the human brain and the development of language during the Ice Age, according to the cooking hypothesis. The invention of the wheel in the Bronze Age allowed greater travel and the creation of more complex machines. More recent technological inventions, including the printing press, telephone, and the Internet, have lowered barriers to communication and ushered in the knowledge economy.</td>
                                                        </tr> 
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    alignContent:
                                                                        "baseline",
                                                                }}>
                                                                <b>Keywords:</b>
                                                            </td>
                                                            <td>
                                                                High-Quality
                                                                Photos,Stock
                                                                Images,Free
                                                                Photos,
                                                                Royalty-Free
                                                                Images,
                                                                Photography
                                                                Collection, HD
                                                                Photos, Curated
                                                                Photo Library,
                                                                Creative Commons
                                                                Photos, Free
                                                                Image Downloads,
                                                                Professional
                                                                Photography,
                                                                Background
                                                                Images, Nature
                                                                Photos
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <H3 className="my-2 fw-bold">Sharing</H3>
                                                <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Display on My Media Center:
                                                                </b>
                                                            </td>
                                                            <td> To Followers Only</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Select Groups :
                                                                </b>
                                                            </td>
                                                            <td>Life Style</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Usernames:
                                                                </b>
                                                            </td>
                                                            <td>crosils</td>
                                                        </tr> 
                                                         
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <hr /> 
                                        </CardBody>
                                    </Card>
                                </Col> 
                               
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div> 
            <div className="modal-footer "> 
                <div className=" next_previous  position-relative pt-0">
                    <Btn
                        onClick={handlePrevious}
                        className="border"
                        disabled={currentId === 0}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Previous <SVG iconId=" back-arrow" />
                        </div>
                    </Btn>
                    <Btn
                        onClick={handleNext}
                        className="border"
                        disabled={currentId === productItem.length - 1}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Next <SVG iconId="front-arrow" />
                        </div>
                    </Btn>
                </div>
                {/* <CommonButton step={true} /> */}
            </div>
      </>: ModalType == "customerWish" ? <>
      <div className="modal-header" onClick={onCloseModal}>
                {/* <H4>{singleProduct.name}</H4>  */}
                <H4> Customer Wishes</H4>
                <Btn className="py-0" close></Btn>
            </div>

            <div className="modal-body bg-light">
                <Formik
                    initialValues={surveyAndQuizzesFormInitialValue}
                    onSubmit={handleSubmit}
                    validationSchema={surveyAndQuizzesFormValidationSchema}>
                    {({ errors, values, setFieldValue }) => (
                        <Form className="form-wizard">
                            <Row className="product-box">
                                <Col lg="4" sm="12">
                                    <ProductDetails />
                                </Col>
                                { websiteSettingsData?.google_ads_index === "on" && <Col lg="3" sm="12">
                    <AdSense />
                    </Col>}
                                <Col lg={websiteSettingsData?.google_ads_index === "on" ? "5" :'12'} sm="12">
                                    <Card>
                                        <CardBody> 
                                            <div>
                                                <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                 Title:
                                                                </b>
                                                            </td>
                                                            <td> Latest Customer Wishes</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Category:</b>
                                                            </td>
                                                            <td>Free Commercial</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Product or Service Name:</b>
                                                            </td>
                                                            <td>Value 1</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Url:</b>
                                                            </td>
                                                            <td> <a href="1weeb.com">1weeb.com</a> </td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Vender’s Country:</b>
                                                            </td>
                                                            <td>India</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Desired Budget:</b>
                                                            </td>
                                                            <td>10</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Max Budget:</b>
                                                            </td>
                                                            <td>500</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                 Description:
                                                                </b>
                                                            </td>
                                                            <td>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores dolorem obcaecati commodi numquam est libero sint accusantium quo ea explicabo.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio, iure possimus eum nisi ab sed quas veritatis. Quaerat, veniam voluptates.</td>
                                                        </tr> 
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    alignContent:
                                                                        "baseline",
                                                                }}>
                                                                <b>Keywords:</b>
                                                            </td>
                                                            <td>
                                                                High-Quality
                                                                Photos,Stock
                                                                Images,Free
                                                                Photos,
                                                                Royalty-Free
                                                                Images,
                                                                Photography
                                                                Collection, HD
                                                                Photos, Curated
                                                                Photo Library,
                                                                Creative Commons
                                                                Photos, Free
                                                                Image Downloads,
                                                                Professional
                                                                Photography,
                                                                Background
                                                                Images, Nature
                                                                Photos
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <H3 className="my-2 fw-bold">Sharing</H3>
                                                <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Display on My Media Center:
                                                                </b>
                                                            </td>
                                                            <td>To Anyone</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Select Groups :
                                                                </b>
                                                            </td>
                                                            <td>Life Style</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Usernames:
                                                                </b>
                                                            </td>
                                                            <td>crosils</td>
                                                        </tr> 
                                                         
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <hr /> 
                                        </CardBody>
                                    </Card>
                                </Col> 
                                
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div> 
            <div className="modal-footer "> 
                <div className=" next_previous  position-relative pt-0">
                    <Btn
                        onClick={handlePrevious}
                        className="border"
                        disabled={currentId === 0}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Previous <SVG iconId=" back-arrow" />
                        </div>
                    </Btn>
                    <Btn
                        onClick={handleNext}
                        className="border"
                        disabled={currentId === productItem.length - 1}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Next <SVG iconId="front-arrow" />
                        </div>
                    </Btn>
                </div>
                {/* <CommonButton step={true} /> */}
            </div>
      </>:ModalType == "jobOpening" ? <>
      <div className="modal-header" onClick={onCloseModal}>
                {/* <H4>{singleProduct.name}</H4>  */}
                <H4> Title</H4>
                <Btn className="py-0" close></Btn>
            </div>

            <div className="modal-body bg-light"> 
                            <Row className="product-box">
                                <Col lg="4" sm="12">
                                    <ProductDetails />
                                </Col>
                                { websiteSettingsData?.google_ads_index === "on" && <Col lg="3" sm="12">
                    <AdSense />
                    </Col>}
                                <Col lg={websiteSettingsData?.google_ads_index === "on" ? "5" :'12'} sm="12">
                                    <Card>
                                        <CardBody> 
                                            <div>
                                                <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                 Title:
                                                                </b>
                                                            </td>
                                                            <td> Technology</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>A Previous Job Opening as a Reference (Optional):</b>
                                                            </td>
                                                            <td>Free Commercial</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Category:</b>
                                                            </td>
                                                            <td>Free Non-profie use</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Required Skills (keywords):</b>
                                                            </td>
                                                            <td>React,html</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Minimum Years of experience:</b>
                                                            </td>
                                                            <td>5yrs</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Hiring Duration:</b>
                                                            </td>
                                                            <td>1Day</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Schedule Type:</b>
                                                            </td>
                                                            <td>Full Time</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Salary:</b>
                                                            </td>
                                                            <td>500$</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Job Location Type:</b>
                                                            </td>
                                                            <td>Remote</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                            <b>Job Location:</b>
                                                            </td>
                                                            <td>india</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                 Description:
                                                                </b>
                                                            </td>
                                                            <td>Technology is the application of conceptual knowledge to achieve practical goals, especially in a reproducible way.[1] The word technology can also mean the products resulting from such efforts,[2][3] including both tangible tools such as utensils or machines, and intangible ones such as software. Technology plays a critical role in science, engineering, and everyday life.</td>
                                                        </tr> 
                                                        
                                                         
                                                    </tbody>
                                                </Table>
                                                <H3 className="my-2 fw-bold">Sharing</H3>
                                                <Table className="product-page-width">
                                                    <tbody>  
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Display on My Media Center:
                                                                </b>
                                                            </td>
                                                            <td> To Followers Only</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Select Groups :
                                                                </b>
                                                            </td>
                                                            <td>Life Style</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Usernames:
                                                                </b>
                                                            </td>
                                                            <td>crosils</td>
                                                        </tr> 
                                                         
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <hr /> 
                                        </CardBody>
                                    </Card>
                                </Col> 
                              
                            </Row>
                        
            </div> 
            <div className="modal-footer "> 
                <div className=" next_previous  position-relative pt-0">
                    <Btn
                        onClick={handlePrevious}
                        className="border"
                        disabled={currentId === 0}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Previous <SVG iconId=" back-arrow" />
                        </div>
                    </Btn>
                    <Btn
                        onClick={handleNext}
                        className="border"
                        disabled={currentId === productItem.length - 1}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Next <SVG iconId="front-arrow" />
                        </div>
                    </Btn>
                </div>
                {/* <CommonButton step={true} /> */}
            </div>
      </>:""}
    </Modal>
  );
};

export default ProductModal;
