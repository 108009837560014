import KnowledgeLessonContainer from "../../../Component/Application/KnowledgeLesson/KnowledgeLessonContainer";

 

const KnowledgeLesson = () => {
  return (
    <div className="page-body"> 
      <KnowledgeLessonContainer  /> 
    </div>
  );
};

export default KnowledgeLesson;
