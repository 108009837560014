import { apiSlice } from "../../apiSlice";
import { GROUPS_LIST, GROUP_SAVE } from "../../../Api";

interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const groupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupsData: builder.mutation<SuccessDataProps, void>({
      query: () => ({
        url: GROUPS_LIST,
        method: "GET",
      }),
    }),
    saveGroupData: builder.mutation<SuccessDataProps, any>({
      query: (data) => ({
        url: GROUP_SAVE,
        method: "POST",
        body: data
      }),
    }),
  }),
});

export const { useGetGroupsDataMutation, useSaveGroupDataMutation } = groupApiSlice;
