import { useState } from "react";
import { Card, CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import AddManageMyAlbumsForm from "../../../../Component/Tools/ManageContents/ManageMyAlbums/AddManageMyAlbumsForm";
import { Formik } from "formik";
import {
    ManageAlbumFormValidationProp,
    manageAlbumFormInitialValue,
    manageAlbumFormValidationSchema,
} from "../../../../Data/Tools/ManageContents/FormControlsValidation";
import { ManageContents } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import { Link } from "react-router-dom";
import ManageSubMenu from "../ManageSubMenu/ManageSubMenu";

const AddManageMyAlbums = () => {
    const [submitErrors, setSubmitError] = useState<boolean>(false);

    const handleSubmit = (
        values: ManageAlbumFormValidationProp,
        { resetForm }: { resetForm: () => void }
    ) => {
        console.log(values);
        setSubmitError(false);
        resetForm();
    };

    return (
        <div>
           <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageSubMenu />
                    </CardHeader>
                    </Container>
            <Container className="card p-4" fluid>
                <Formik
                    initialValues={manageAlbumFormInitialValue}
                    onSubmit={handleSubmit}
                    validationSchema={manageAlbumFormValidationSchema}>
                    {({ errors, values, setFieldValue }) => (
                        <AddManageMyAlbumsForm
                            submitErrors={submitErrors}
                            setSubmitError={setSubmitError}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Formik>
            </Container>
        </div>
    );
};

export default AddManageMyAlbums;
