
import { Card, CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  ManageOnlineStoreTitle,
  NewAddonCategory,
} from "../../../../utils/Constant"; 
import AddonCategoriesMain from "../../../../Component/Tools/ManageOnlineStore/AddonCategories/AddonCategoriesMain";
import Message from "../../../../Component/CommonMessage/Message";
import { Link } from "react-router-dom";
import { AddonCategoriesTableAction, ManageOnlineStore } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import AddCategoriesSubMenu from "./AddCategoriesSubMenu/AddCategoriesSubMenu";
const AddAddonCategories = () => {

  return (
    <div className="page-body">
      <Breadcrumbs
        mainTitle={`${NewAddonCategory} (Manage Online Store)`}
        parent={ManageOnlineStoreTitle}
      /> 
      <Container  fluid>
      <CardHeader className='pb-0'> 
                <div>
                 <ManageOnlineSubMenu />
                </div>
              </CardHeader> 
        <Card className=" p-2">  
          <AddonCategoriesMain/>
        </Card>
      </Container>
    </div>
  );
};

export default AddAddonCategories;
