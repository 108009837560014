import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import {
  SignUpToAccount,
  Login,
  AlreadhaveAccount,
  AfterLoginRedirectUrl,
} from "../../utils/Constant";
import { H2 } from "../../AbstractElements";
import { SignupForm } from "./SignupForm";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import {
  SignupValidationProp,
  signupFormInitialValue,
  firstSection,
  secondSection,
} from "../../Data/Signup/SignupFormValidation";

import Loading from "../Loading/Loading";
import RegisterForm from "./RegisterForm";
import { toast } from "react-toastify";
import { useCreateRegistrationMutation } from "../../ReduxToolkit/Reducers/Authentication/RegistrationApiSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../ReduxToolkit/Hooks";
import { setCredentials } from "../../ReduxToolkit/Reducers/Authentication/authSlice";
import { Logo } from "../Images";
import * as Yup from "yup";

const SignupAccount = ({ formValue, getUserData }: SignupForm) => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [isUnderAge, setIsUnderAge] = useState<boolean>(false);

  const { userInfo } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [register, { isLoading }] = useCreateRegistrationMutation();

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get("redirect") || AfterLoginRedirectUrl;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  const signupFormSchema =
    isUnderAge === true
      ? Yup.object().shape({
          ...firstSection,
          ...secondSection,
        })
      : Yup.object().shape({
          ...firstSection,
        });

  const handleSignup = async (values: SignupValidationProp) => {
    if (values.password !== values.password_confirmation) {
      toast.error("Password do not match.", { position: "bottom-right" });
    } else {
      try {
        const res = await register(values).unwrap();
        dispatch(setCredentials({ ...res.data }));

        toast.success("Registration successfully", {
          position: "bottom-right",
        });

        navigate(redirect);
      } catch (error) {
        toast.error("Something went wrong", { position: "bottom-right" });
      }
    }
    setSubmitError(false);
  };

  if (isLoading) return <Loading />;

  return (
    // <div className="content">
    <div className="login-card login-dark">
      <div className="wizard-title mb-4 text-center">
        {/* <H5 className="text-muted mb-4">{EmailPassWord}</H5> */}
      </div>
      <div className=" mx-auto ">
        <Row className="d-flex justify-content-center">
          <Col md="7" lg="7" sm="12">
            <div>
              <Link className="logo text-center m-4" to={"/"}>
                <Logo width={100} className="" />
              </Link>
            </div>
            <div className="theme-form">
              <H2 className="pb-4">{SignUpToAccount}</H2>
              <Formik
                initialValues={signupFormInitialValue}
                onSubmit={handleSignup}
                validationSchema={signupFormSchema}
              >
                {({ errors, setFieldValue, values }) => (
                  <RegisterForm
                    formValue={formValue}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    values={values}
                    getUserData={getUserData}
                    submitErrors={submitErrors}
                    setSubmitError={setSubmitError}
                    setIsUnderAge={setIsUnderAge}
                  />
                )}
              </Formik>
              <Col xs="12 pt-4">
                {AlreadhaveAccount}
                <Link to={`${process.env.PUBLIC_URL}/login`}> {Login}</Link>
              </Col>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    // </div>
  );
};

export default SignupAccount;
