import { useEffect, useRef } from "react";
import UserHeader from "../../../Component/App/MyMediaCenter/About/UserHeader"
import CalenderContainer from "../../../Component/Application/Calender/Calender"
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { MyMediaCenter } from "../../../utils/Constant";

const Calender = () => {
  useEffect(()=>{
    var scroll_top  =  window.innerWidth > 415 ? 360 : 230;
    window.scrollTo({ top: scroll_top, behavior: "smooth" });
  });
  return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`Calendar`} parent={MyMediaCenter} />
      <UserHeader/>
      <CalenderContainer />
    </div>
  )
}

export default Calender