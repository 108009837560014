import { Row } from "reactstrap";
import ProductLeftSidebar from "./ProductLeftSidebar/ProductLeftSidebar";
import ProductTabContent from "./ProductTabContent/ProductTabContent";
import TabBrandSupportLeftSIde from "./TabAddQuizzLeftSIde";
import TabBrandSupporRightSide from "./TabAddQuizzRightSide";

const TabAddQuizz = () => {
  return (
    <Row className="  g-3">
      <TabBrandSupportLeftSIde/>
      <TabBrandSupporRightSide />
    </Row>
  );
};

export default TabAddQuizz;
