
import Popup from "./Cookies/Popup";
import Content from "./Announcements/Content"; 
import AdsPopup from "../App/Ads/AdsPopup";

function WebsiteSettings() {
  
  return (
    <>
      <Popup /> 
      <Content />
    </>
  );
}

export default WebsiteSettings;
