import * as Yup from "yup";

export interface updateAddressValidationProp {
  id: number;
  full_name: string;
  country_id: number | null;
  state_id: number | null;
  city_id: number | null;
  mobile_number: string;
  pincode: string;
  intercom_code: number | null;
  addressline1: string;
  addressline2: string;
  landmark: string;
  is_default: string;
}

export const updateAddressFormInitialValue: updateAddressValidationProp = {
  id: 0,
  full_name: "",
  country_id: null,
  state_id: null,
  city_id: null,
  mobile_number: "",
  pincode: "",
  intercom_code: null,
  addressline1: "",
  addressline2: "",
  landmark: "",
  is_default: "",
};

export interface AddressValidationProp {
  full_name: string;
  country_id: number | null;
  state_id: number | null;
  city_id: number | null;
  mobile_number: number | null;
  pincode: number | null;
  intercom_code: number | null;
  addressline1: string;
  addressline2: string;
  landmark: string;
  is_default: string;
}

export const addressFormInitialValue: AddressValidationProp = {
  full_name: "",
  country_id: null,
  state_id: null,
  city_id: null,
  mobile_number: null,
  pincode: null,
  intercom_code: null,
  addressline1: "",
  addressline2: "",
  landmark: "",
  is_default: "",
};

export const addressInformationFormSchema = Yup.object().shape({
  full_name: Yup.string()
    .min(3, "Full name must be at least 3 characters")
    .max(25, "Full name must be at most 25 characters")
    .required("Required"),
  country_id: Yup.number().required("Required"),
  city_id: Yup.number().required("Required"),
  addressline1: Yup.string()
    .min(3, "Apartment, House no., Building must be at least 3 characters")
    .max(30, "Apartment, House no., Building must be at most 30 characters")
    .required("Required"),
  addressline2: Yup.string()
    .min(3, "Area, Street, Sector, Village must be at least 3 characters")
    .max(30, "Area, Street, Sector, Village must be at most 30 characters")
    .required("Required"),
  mobile_number: Yup.number()
    .required("Required")
    .typeError("Please enter number value only"),
  
});
