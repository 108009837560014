import { apiSlice } from "../../../apiSlice";
import { GET_REGISTRATION_TYPES } from "../../../../Api";

interface Types {
  data: any;
  message: string;
  success: boolean;
}

export const registrationTypesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrationTypes: builder.query<Types, void>({
      query: (countryId) => ({
        url: GET_REGISTRATION_TYPES,
        method: "POST",
        body: {
          country_id: countryId, 
        },
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetRegistrationTypesQuery } = registrationTypesApiSlice;
