import React from 'react'
import Sharing from '../SurveyAndQuizzes/Sharing'
import { Card, CardBody, Col, Form, Label } from 'reactstrap'
import { Field, Formik } from 'formik'
import { createPostInitialValue, createPostSchema } from '../../../../Data/Post/CreatePostValidationSchema'

const StudyMaterialShare  = () => {
  const handleSubmit = () => {};

  return (
    < >
    <Formik
                                initialValues={createPostInitialValue}
                                onSubmit={handleSubmit}
                                validationSchema={createPostSchema}>
                                {({ errors, values, setFieldValue }) => (
                                    <>
                                        <Form>
                                          <CardBody>
                                        <Col sm="12" className="mb-3">
                    <Label check>Select Play Time</Label>
                    <Field
                        type="datetime-local"
                        name="dateHoursExpiry"
                        placeholder="Expiry Date + Hour (Optional)"
                        className="form-control"
                    />
                </Col>
                                        {/* <Sharing /> this is used in future Dont delete*/}
                                          </CardBody>
                                        </Form>
                                        </>)}
                                        </Formik>
    </>
  )
}

export default StudyMaterialShare 