function Symbol(props: any) {
  const { value } = props;

  let content;

  if (value === "on") {
    content = <i className="fa fa-check txt-success"></i>;
  } else if (value === "off") {
    content = <i className="fa fa-times txt-danger"></i>;
  } else {
    content = value;
  }
  return content;
}

export default Symbol;
