import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import CommonSwitchSpan from "../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import { ErrorMessage, Field } from "formik";
import { H6 } from "../../../../AbstractElements";

function GameSettingsForm(props: any) {
  const { submitErrors, errors, setFieldValue,values ,addquizsurveysTab} = props;

  const formTypeHandler = (name: string, flag: string) => {
    setFieldValue(name, flag);
  };

  return (
    <>
      <Row>
        <Col className="mb-3">
          <h4 style={{ color: addquizsurveysTab ? "" : "gray" }}>Game Settings</h4>
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="mb-3">
          <Label check>Player Type <span className="txt-danger">*</span></Label>
          <Field
            as="select"
            name="playerType"
            className={`mb-2 form-control ${
              submitErrors &&
              `${errors.specialParameters ? "is-invalid" : "is-valid"}`
            }`}
          >
            <option value={""}>Select player type</option>
            <option value={"1"}>Single Player</option>
            <option value={"2"}>A Group at the Same Time</option>
          </Field>
          <ErrorMessage
            name="playerType"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>Pin Number to access the game</Label>
          <Row>
            <Col sm="auto" className="mb-3 ms-4">
              {/* <FormGroup check>
                <Field
                  id="gamePinToAccess1"
                  name="gamePinToAccess"
                  type="radio"
                  value="yes"
                  onChange={() => formTypeHandler("gamePinToAccess", "yes")}
                  className="form-check-input"
                />
                <Label className="mb-0" for="gamePinToAccess1" check>
                  Yes
                </Label>
              </FormGroup> */}
              <FormGroup
            className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                values.gamePinToAccess === "yes"
                    ? "transform-scale"
                    : ""
            }`}
            check>
            <Label
                className={`mb-0 ${
                    submitErrors &&
                    `${
                        errors.formType
                            ? "text-danger"
                            : "text-success"
                    }`
                }`}
                for="gamePinToAccess1"
                check>
                <Input
                     id="gamePinToAccess1"
                  name="gamePinToAccess"
                    value="yes"
                    type="checkbox"
                    onChange={() => formTypeHandler("gamePinToAccess", "yes")}
                    className="form-check-input"
                />
                <H6 className="ms-2">Yes</H6>
            </Label>
        </FormGroup>
            </Col>
            <Col sm="auto" className="mb-3 ms-4"> 
              <FormGroup
            className={`form-check form-check-inline popup_rediobtn radio-primary mt-2 px-2 ${
                values.gamePinToAccess === "no"
                    ? "transform-scale"
                    : ""
            }`}
            check>
            <Label
                className={`mb-0 ${
                    submitErrors &&
                    `${
                        errors.formType
                            ? "text-danger"
                            : "text-success"
                    }`
                }`}
                for="gamePinToAccess2"
                check>
                <Input
                     id="gamePinToAccess2"
                  name="gamePinToAccess"
                    value="no"
                    type="checkbox"
                    onChange={() => formTypeHandler("gamePinToAccess", "no")}
                    className="form-check-input"
                />
                <H6 className="ms-2">No</H6>
            </Label>
        </FormGroup>
            </Col>
          </Row>
        </Col> 
      </Row>
    </>
  );
}

export default GameSettingsForm;
