import { Field } from "formik";
import { Col, FormGroup, Label } from "reactstrap";
import { useEffect, useCallback } from "react";
import Loading from "../Loading/Loading";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { useGetRegistrationTypesQuery } from "../../ReduxToolkit/Reducers/Registration/Type/typeApliSlice";
import { setRegistrationTypes } from "../../ReduxToolkit/Reducers/commonInputSlice";

function Filter(props: any) {

  const { filter, setFilter, countryId } = props;
   
  const { registrationTypes } = useAppSelector((state) => state.common);
  const { data: fetchRegistrationTypes, isLoading } =
    useGetRegistrationTypesQuery(countryId);

  const dispatch = useAppDispatch();

  const handleFilterRadio = useCallback((value: number) => {
    setFilter((prev: any) =>
      prev.includes(value)
        ? prev.filter((obj: any) => obj !== value)
        : [...prev, value]
    );
  }, [setFilter]);

  const checkValueExistOrNot = ((value:number) => {
    return filter.includes(value);
  });

  useEffect(() => {
    if (fetchRegistrationTypes?.data !== undefined) {
      let filterRegistrationTypes = Object.keys(
        fetchRegistrationTypes?.data
      ).map((item) => {
        return { label: fetchRegistrationTypes?.data[item], value: item };
      });
      if (filterRegistrationTypes?.length > 0) {
        dispatch(setRegistrationTypes(filterRegistrationTypes));
      }
    }
  }, [fetchRegistrationTypes?.data, dispatch]);

  // if (isLoading) return <Loading />;

  if (registrationTypes?.length < 0 || registrationTypes === undefined)
    return <div>Data not found!</div>;

  return (
    registrationTypes &&
    registrationTypes.map((item: any, index) => (
      <Col sm="auto ms-4" key={index}>
        <FormGroup check>
          <Field
            id={`filter-${index}`}
            name="hoursday"
            type="checkbox"
            className="form-check-input"
            onChange={() => handleFilterRadio(item.value)}
            value={item.value}
            checked={checkValueExistOrNot(item.value)}
          />
          <Label className={`mb-0`} for={`filter-${index}`} check>
            {item.label}
          </Label>
        </FormGroup>
      </Col>
    ))
  );
}

export default Filter;
