import { useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import { Image } from "../../AbstractElements";
import { dynamicImage } from "../../Service";
import { Btn, H4, P } from "../../AbstractElements";
import TwoFactorForm from "./TwoFactorForm";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import { useNavigate } from "react-router-dom";
import { AfterLoginRedirectUrl } from "../../utils/Constant";
import Loading from "../Loading/Loading";
import { useResendTwoFactorAutheticationCodeMutation } from "../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";
import { toast } from "react-toastify";
function TwoFactorConfirmation() {
  const { userInfo } : any = useAppSelector((state) => state.auth);

  const [resendTwoFACodeApiCall, { isLoading }] =
    useResendTwoFactorAutheticationCodeMutation();
  const navigate = useNavigate();
  const redirect = AfterLoginRedirectUrl;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  const resendCodeHandler = async () => {
    try {
      const response = await resendTwoFACodeApiCall().unwrap();
      const { message, success }: any = response;
      if (success === true) {
        toast.success(message,{ position: "bottom-right" });
      } else {
        toast.error(message,{ position: "bottom-right" });
      }
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong.",{ position: "bottom-right" });
    }
  };

  if (userInfo) return <Loading />;

  return (
    <div className="page-body">
      <Container fluid className="p-0">
        <Row className="m-0">
          <Col xs="12" className="p-0">
            <div className="login-card  login-dark">
              <Row className="g-1">
                <Col xxl="4" className="box-col-5">
                  <div className="authenticate">
                    <Image
                      className="img-fluid"
                      src={dynamicImage(`forms/email.png`)}
                      alt="image"
                    />
                  </div>
                </Col>
                <Col xxl="8" className="box-col-7">
                  <H4>Two Factory Authetication</H4>
                  <P>
                    A Two Factory Authetication code has been sent to your
                    email. This code will be valid for 15 minutes.
                  </P>
                  <TwoFactorForm />
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <div>
                      <span>Not received your code?</span>
                      <span>
                        <Btn className="txt-primary d-block w-100"
                          onClick={resendCodeHandler}
                        >
                          Resend
                        </Btn>
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TwoFactorConfirmation;
