import {useState} from 'react'
import { BrandTools,   ManageExternalTitle    } from '../../../../utils/Constant'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Label, Row } from 'reactstrap'
 
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
 
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu' 
 
import { branchPublicContactFormInitialValue, BranchPublicContactFormValidationProp, branchPublicContactFormValidationSchema, scheduleFormInitialValue, scheduleFormValidationSchema, SchedulesFormValidationProp } from '../../../../Data/Tools/ManageOnlineStore/FormControlsValidation'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import Message from '../../../../Component/CommonMessage/Message'
import City from '../../../../Component/CommonInput/City'
import Branches from '../../../../Component/CommonInput/Branches'
import PhoneField from '../../../../Component/Tools/ManageOnlineStore/ManageBranches/BranchPublicContact/PhoneField'
import EmailField from '../../../../Component/Tools/ManageOnlineStore/ManageBranches/BranchPublicContact/EmailField'
import { Btn } from '../../../../AbstractElements'
import Country from '../../../../Component/CommonInput/Country'
import State from '../../../../Component/CommonInput/State'
import SchedulesForm from '../../../../Component/Tools/ManageOnlineStore/ManageBranches/Schedules/SchedulesForm'
import CommonSwitchSpan from '../../../../Component/Forms/FormsWidgets/Switch/Common/CommonSwitchSpan'
import TitleInput from '../../../../Component/CommonInput/TitleInput'
import DescriptionInput from '../../../../Component/CommonInput/DescriptionInput'





const AddnewFequentlyQuestion = () => {
   
  const [submitErrors, setSubmitError] = useState<boolean>(false); 
  
     
     
    const handleSubmit = (values: SchedulesFormValidationProp) => {
      console.log(values);
      setSubmitError(false);
    };

  return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`${`Manage Posts`} (${BrandTools})`} parent={BrandTools} />
      <SubMenu />
      <Container fluid>
        <Row>
          <Col sm="12">
          <Card className="p-4">
        <Message message="Please add at least one branch first to be able to use this form."/>
        <Formik
                        initialValues={scheduleFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={scheduleFormValidationSchema}>
                        {({ errors ,values}) => (
                            <Form className="form-wizard">
                            <Row>
                              <Col sm="4" className="mb-3">
                                <Label check>
                                  Country <span className="txt-danger">*</span>
                                </Label> 
                                <Country name="country" isMulti={false} placeholder="Select"  className={`${
                                    submitErrors && `${errors.country ? "is-invalid" : "is-valid"}`
                                  }`} />
                                <ErrorMessage
                                  name="country"
                                  component="span"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col sm="4" className="mb-3">
                                <Label check>State</Label> 
                                <State name='state' isMulti={false} placeholder="Select"/>
                              </Col>
                              <Col sm="4" className="mb-3">
                                <Label check>City</Label>
                                <City name='city' isMulti={false} placeholder='Select'/>
                              </Col>
                              <Col sm="4" className="mb-3">
                                <Label check>Branch</Label>
                                <Branches name="branch" isMulti={true} placeholder="Select"/>
                              </Col>
                              <Col sm="4" className="mt-2">
                                 <Button className="btn btn-primary">
                                  Select Branch
                                 </Button>
                              </Col>
                              <Row> 
                            <Col sm="4" className="mb-3">
                                <Label check>
                                  Final Branch List  
                                </Label> 
                                <ErrorMessage
                                  name="finalBranchList"
                                  component="span"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Row> 
                              </Row>
                            </Row>
                            <Col sm="4" className="mb-3">
        <TitleInput title='Title' values={values.title} name="title"  placeholder="Title"/>  
        </Col>
        <FieldArray
                      name="questions"
                      render={(arrayHelpers) => (
                        <>
                          {values.questions.map((_, index) => (
                            <Row key={index}>
                              <Col sm="6" className="mb-3">
                              <Label>{`Question ${index + 1}`}</Label>
                                <DescriptionInput
                                  name={`questions.${index}.question`}
                                  placeholder={`Question ${index + 1}`}
                                  // label={`Question ${index + 1}`}
                                />
                              </Col>
                              <Col sm="6" className="mb-3">
                              <Label>{`Answer ${index + 1}`}</Label>

                                <DescriptionInput
                                  name={`questions.${index}.answer`}
                                  placeholder={`Answer ${index + 1}`}
                                  label={`Answer ${index + 1}`}
                                />
                              </Col>
                              <Col sm="12" className="mb-3">
                                <Button
                                  color="danger"
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                  disabled={values.questions.length === 1}
                                >
                                  Remove Question
                                </Button>
                              </Col>
                            </Row>
                          ))}
                          <Row>
                            <Col sm="12" className="mb-3">
                              <Button
                                color="success"
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({ question: "", answer: "" })
                                }
                              >
                                Another Question
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    />
                            </Row> 
                            
                             <Row>
                                    <Col sm="auto" className="mb-3">
                                      <Btn
                                        color="primary"
                                        type="submit"
                                        onClick={() => setSubmitError(true)}
                                      >
                                        Save
                                      </Btn>
                                    </Col>
                                  </Row>
                                </Form>
                        )}
                    </Formik>
        
        </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AddnewFequentlyQuestion