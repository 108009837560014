import { useNavigate } from "react-router-dom";
import { H6, LI, SVG } from "../../../../AbstractElements";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { setActiveIcon } from "../../../../ReduxToolkit/Reducers/ActiveHeaderRightIcon";

const SavedSearch = () => { 
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeIcon = useAppSelector((state) => state.activeIcon.activeIcon);

  const handleClick = () => {
    dispatch(setActiveIcon('savedserch')); // Set this icon as active
    navigate(`${process.env.PUBLIC_URL}/saved-search`);
  };
  return (
    <LI
      className={`onhover-dropdown ${activeIcon === 'savedserch' ? 'actvie-righticon' : ''}`}
      onClick={handleClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        className="bi bi-bookmark-star-fill"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5M8.16 4.1a.178.178 0 0 0-.32 0l-.634 1.285a.18.18 0 0 1-.134.098l-1.42.206a.178.178 0 0 0-.098.303L6.58 6.993c.042.041.061.1.051.158L6.39 8.565a.178.178 0 0 0 .258.187l1.27-.668a.18.18 0 0 1 .165 0l1.27.668a.178.178 0 0 0 .257-.187L9.368 7.15a.18.18 0 0 1 .05-.158l1.028-1.001a.178.178 0 0 0-.098-.303l-1.42-.206a.18.18 0 0 1-.134-.098z"
        />
      </svg>
    </LI>
  );
};

export default SavedSearch;
