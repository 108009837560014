import { useEffect, useCallback } from "react";
import { Card, Container } from "reactstrap";
import { Home, RegistrationPlan } from "../../utils/Constant";
import PricingPlans from "../../Component/Pricing/PricingPlans";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import { setGeoLocation } from "../../ReduxToolkit/Reducers/commonInputSlice";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";

const Pricing = () => {
  const dispatch = useAppDispatch();

  const getGeoInfo = useCallback(() => {
    fetch("https://ipapi.co/json/")
      .then((response: any) => response.json())
      .then((data: any) => {
        dispatch(
          setGeoLocation({
            ip: data.ip,
            countryName: data.country_name,
            countryCode: data.country_calling_code,
            city: data.city,
            timezone: data.timezone,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  },[dispatch]);

  useEffect(() => {
    getGeoInfo();
  }, [getGeoInfo]);

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={RegistrationPlan} parent={Home} />
      <Container fluid>
        <Card className="pt-0">
          <PricingPlans allregistrationplans={true}/>
        </Card>
      </Container>
    </div>
  );
};

export default Pricing;
