import { useEffect, useState } from "react"; 
import { Container } from "reactstrap";
import { setProductItem } from "../../../ReduxToolkit/Reducers/ProductSlice";
import DefaultFilter from "../Ecommerce/Products/ProductFeatures/DefaultFilter";
import ProductGrid from "../Ecommerce/Products/ProductGrid/ProductGrid";
import { productsData } from "../../../Data/Application/Ecommerce/Product";
import { useAppDispatch } from "../../../ReduxToolkit/Hooks";
 

export const productst= [
  { id:1, src:"faq/1.jpg", name:"", fillStar:true},
  { id:2, src:"faq/1.jpg", name:"", fillStar:true},
  { id:3, src:"faq/1.jpg", name:"", fillStar:true},
  { id:4, src:"faq/1.jpg", name:"", fillStar:true},
  { id:5, src:"faq/1.jpg", name:"", fillStar:true},
  { id:6, src:"faq/1.jpg", name:"", fillStar:true},
  { id:7, src:"faq/1.jpg", name:"", fillStar:true},
  { id:8, src:"faq/1.jpg", name:"", fillStar:true},
  { id:9, src:"faq/1.jpg", name:"", fillStar:true},
  { id:10, src:"faq/1.jpg", name:"", fillStar:true},    
];


const SurveysContainer = ({remove}) => {
  const [sideBarOn, setSideBarOn] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setProductItem(productsData));
  }, []);

  return (
    <> 
      <Container
        fluid
        className={`product-wrapper ${sideBarOn ? "sidebaron" : ""}`}
      >  
        <DefaultFilter remove={remove}/>      
        <div className="product-grid">
          <ProductGrid />
        </div>
      </Container>
    </>
  );
};

export default SurveysContainer;
