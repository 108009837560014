import { useState } from "react";
import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import { Btn } from "../../AbstractElements";
import CommonModal from "../Ui-Kits/Modal/Common/CommonModal";
import { H3 } from "../../AbstractElements";
import { ErrorMessage, Field, Form } from "formik";
import GroupMembersMain from "./GroupMembersMain";
import TextCounter from "../CommonInput/TextCounter";
import TagsInput from "../CommonInput/TagsInput";

const AddGroupForm = (props: any) => {
  const { errors, submitErrors, setSubmitError, values, setFieldValue } = props;

  // Add Group Members Popup
  const [openToModal, setOpenToModal] = useState(false);
  const openToModalToggle = () => setOpenToModal(!openToModal);

  // when click on the image file input.
  const uploadFileHandler = (event: any, setFieldValue: any) => {
    setFieldValue("uploadImage", event.target.files[0]);
  };

  return (
    <>
      <Form className="form-wizard">
        <Row>
          <Col sm="6" className="mb-3">
            <Label check>
              Group Name <span className="txt-danger">*</span>
            </Label>
            <Field
              type="text"
              name="group_name"
              placeholder="Group Name"
              maxLength="140"
              className={`form-control ${
                submitErrors &&
                `${errors.group_name ? "is-invalid" : "is-valid"}`
              }`}
            />
            <TextCounter
              length={values?.group_name?.length}
              totalLength="140"
            />
            <ErrorMessage
              name="group_name"
              component="span"
              className="invalid-feedback"
            />
          </Col>
          <Col sm="6" className="mb-3">
            <Label check>
              Group Description <span className="txt-danger">*</span>
            </Label>
            <Field
              type="textarea"
              name="group_description"
              placeholder="Group Description"
              maxLength="140"
              className={`form-control ${
                submitErrors &&
                `${errors.group_description ? "is-invalid" : "is-valid"}`
              }`}
            />
            <TextCounter
              length={values?.group_description?.length}
              totalLength="140"
            />
            <ErrorMessage
              name="group_description"
              component="span"
              className="invalid-feedback"
            />
          </Col>
          <FormGroup>
            <div className=" col-form-Label">
              <FormGroup className="mt-2">
                <TagsInput
                  name="group_members"
                  placeholder="Enter Usernames"
                  className={`form-control`}
                  setFieldValue={setFieldValue}
                />  
              </FormGroup>
            </div>
          </FormGroup>
        </Row>
        <Row>
          <Col sm="6" className="mb-3">
            <Label check>Image</Label>
            <FormGroup>
              <Input
                name="uploadImage"
                type="file"
                className="form-control"
                onChange={(event: any) =>
                  uploadFileHandler(event, setFieldValue)
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="mb-3">
            <Btn
              color="primary me-3 mb-3"
              type="submit"
              onClick={() => setSubmitError(true)}
            >
              <i className="icofont icofont-users"></i> Save Group
            </Btn>
            <span className="btn btn-primary mb-3" onClick={openToModalToggle}>
              <i className="icofont icofont-user-alt-7"></i> Add Group Members
              to Existing Group
            </span>
          </Col>
        </Row>
      </Form>
      <CommonModal
        isOpen={openToModal}
        toggle={openToModalToggle}
        heading="Add Group Members"
        size="lg"
      >
        <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
          <H3 className="modal-header justify-content-center border-0">
            Add Group Members
          </H3>
          <GroupMembersMain openToModalToggle={openToModalToggle} />
        </div>
      </CommonModal>
    </>
  );
};

export default AddGroupForm;
