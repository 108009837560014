import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import "animate.css";
import { Col, Row } from "reactstrap";
import { H4, Image } from "../../AbstractElements";
import { dynamicImage } from "../../Service";

const NextScreenQuizzAnimation = (props: any) => {
  const { animation, title } = props;
  const [selectedAnimation, setSelectedAnimation] = useState<string>("");

  // Set a default animation when the component mounts
  useEffect(() => {
    const defaultAnimation = animation.length > 0 ? animation[0] : ""; // Get the first animation if available
    setSelectedAnimation(`animate__${defaultAnimation}`); // Apply the default animation
  }, [animation]);

  const handleAnimationChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const value = e.target.value;
    setSelectedAnimation(`animate__${value}`); // Update the selected animation immediately
    setFieldValue("animation", value); // Update Formik state
  };

  return (
    <>
      <Row>
        <Col>
          <div
            className={`animate__animated ${selectedAnimation}`}
            style={{ height: "200px", marginBottom: "20px" }}
          >
            <Image
              className="img-fluid rounded-circle h-100"
              src={dynamicImage("young.png")}
              alt="user"
            />
          </div>
        </Col>
        <Col>
          <Formik initialValues={{ animation: animation[0] || "" }} onSubmit={() => {}}>
            {({ setFieldValue }) => (
              <Form>
                <div>
                  <label htmlFor="animation">Select Animation:</label>
                  <Field
                    as="select"
                    name="animation"
                    className="form-control"
                    onChange={(e) => handleAnimationChange(e, setFieldValue)} // Pass setFieldValue to the handler
                  >
                    {animation.map((name) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </Field>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default NextScreenQuizzAnimation;
