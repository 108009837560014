export const GradeData = [
    {
        id: 1,
        title: "Math Quiz",
        score: "85/100",
        type: "Multiple Choice",
        question: 10,
        answered: 8,
        unanswered: 2,
        correct: 7,
        mistake: 1,
        comment: "Great attempt!",
        date: "2025-01-17",
      },
      {
        id: 2,
        title: "History Quiz",
        score: "70/100",
        type: "True/False",
        question: 12,
        answered: 9,
        unanswered: 3,
        correct: 8,
        mistake: 1,
        comment: "Good effort, but needs improvement.",
        date: "2025-01-16",
      },
      {
        id: 3,
        title: "Science Quiz",
        score: "95/100",
        type: "Short Answer",
        question: 15,
        answered: 15,
        unanswered: 0,
        correct: 14,
        mistake: 1,
        comment: "Excellent performance!",
        date: "2025-01-15",
      },
      {
        id: 4,
        title: "Geography Quiz",
        score: "60/100",
        type: "Multiple Choice",
        question: 10,
        answered: 6,
        unanswered: 4,
        correct: 6,
        mistake: 0,
        comment: "Needs better understanding of continents.",
        date: "2025-01-14",
      },
      {
        id: 5,
        title: "English Quiz",
        score: "80/100",
        type: "Fill in the Blanks",
        question: 20,
        answered: 18,
        unanswered: 2,
        correct: 16,
        mistake: 2,
        comment: "Good job overall.",
        date: "2025-01-13",
      },
  ];