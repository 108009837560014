import ActiveChatContainer from "../../../../Component/Application/Chat/ActivitiesCChat/ActivitiesCChat"; 

const ActivitiesChat = () => {
  return (
    <div className="page-body">
      <ActiveChatContainer/>
    </div>
  );
};

export default ActivitiesChat;
