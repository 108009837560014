import { apiSlice } from "../../apiSlice";
import { REGISTER_NEW_ACCOUNT } from "../../../Api";
import { SignupValidationProp } from "../../../Data/Signup/SignupFormValidation";
interface Types {
  data: any;
  message: string;
  success: boolean;
}

export const registrationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createRegistration : builder.mutation<Types, SignupValidationProp>({
      query: (data) => ({
        url: REGISTER_NEW_ACCOUNT,
        method: "POST",
        body: data,
      }), 
    }),
  }),
});

export const { useCreateRegistrationMutation } = registrationApiSlice;
