import { useEffect, useState } from "react";
import Popup from "./Popup";
import {
  setCookie,
  getCookie,
} from "../../../Data/Ui-Kits/HelperClasses/HelperClassesData";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";

function Content() {
  const [scrollingModal, setScrollingModal] = useState(true);
  const [annoucementCookies, setAnncoucementCookies] = useState("");

  const scrollModalToggle = () => {
    setCookie("annoucement", true);
    setScrollingModal(!scrollingModal);
  };

  const { websiteSettingsData }: any = useAppSelector((state) => state.common);

  useEffect(() => {
    const cookiesName = getCookie("annoucement");
    setAnncoucementCookies(cookiesName);
  }, [annoucementCookies]);

  return (
    <>
      {websiteSettingsData && websiteSettingsData.announcement && annoucementCookies === "" && (
        <Popup
          centered
          size={"sm"}
          isOpen={scrollingModal}
          toggle={scrollModalToggle}
          modalBodyClassName={
            websiteSettingsData &&
            websiteSettingsData.type_announcement &&
            `bg-${websiteSettingsData.type_announcement}`
          }
          title="Annousement"
        >
          {websiteSettingsData && websiteSettingsData.announcement && (
            <p className="">
              I am pleased to announce to all of you that [employee name] has
              been promoted to [new role within the company].
            </p>
          )}
        </Popup>
      )}
    </>
  );
}

export default Content;
