import { useState } from "react";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { BrandTools, ManageAttendance } from "../../../../utils/Constant";
import {
    CardBody,
    Card,
    Col,
    Container,
    Row,
    CardHeader,
    Label,
    Form,
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
    AttendanceManagement,
    MyAtttendanceSystemTableAction,
} from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import DataTable, { TableColumn } from "react-data-table-component";
import { TableHeadColumn } from "../../../App/AccountSettings/MyRegistrationPlan";
import {
    AttendanceActionButtons,
    AttendanceSystemData,
    AttendanceSystemDataType,
    links,
} from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import Popup from "../../../../Component/MasterPopup/Popup";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu"; 
import ManageActivityView from "../../../../Component/Tools/BrandSupportTools/AttendanceSystem/ManageActivityView";
import ManageActivityEdit from "../../../../Component/Tools/BrandSupportTools/AttendanceSystem/ManageActivityEdit";
import PopUp from "../../../../Component/Application/Calender/DragCalendar/AttendanceHistory";
import MediaModal from "../../../../Component/App/MediaData/MediaModal";
import { items } from "../../../../Component/App/MediaData/MediaData";
import DatePicker from "react-multi-date-picker";
import { Field, Formik } from "formik";
import ReactDatePicker from "react-datepicker";
import AttendanceHistory from "../../../../Component/Application/Calender/DragCalendar/AttendanceHistory";
import TagsInput from "../../../../Component/CommonInput/TagsInput";
import QrcodeAttendance from "./QrcodeAttendance";
export const initialValues: DateRangeFormValues = {
    startDate: null,
    endDate: null,
    activity: null,
};
const ManageAttendanceSystem = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(AttendanceSystemData);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRow, setSelectedRow] =
        useState<AttendanceSystemDataType | null>(null);
    const [clickedRow, setClickedRow] =
        useState<AttendanceSystemDataType | null>(null);
    // const navigate = useNavigate();

    const handleRowSelected = (state: any) => {
        const rowValue = state.selectedRows;
        setSelectedRow(rowValue);
        // setData(state.selectedRows[0])
        console.log(`selelcteed row`, selectedRow);
        console.log(`selelcteed data`, state);
    };

    const openModalToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleActivityAction = (
        type: string,
        rowData: AttendanceSystemDataType
    ) => {
        // const data = rowData;
        setClickedRow(rowData);
        if (type == "edit") {
            setIsEditOpen(true);
            setIsOpen(!isOpen);
            // navigate(
            //   `${process.env.PUBLIC_URL}/tools/brand-support-tools/activities-and-attendance/edit-activity`,
            //   { state: data }
            // );
        } else if (type == "view") {
            // navigate(
            //   `${process.env.PUBLIC_URL}/tools/brand-support-tools/activities-and-attendance/view-activity`,
            //   { state: data }
            // );
            setIsEditOpen(false);
            setIsOpen(!isOpen);
        } else {
            // api call to delete the row
        }

        // setIsEditOpen(type);
    };
  
 
    const handlereservations = (id: string) => {
        console.log(`ID clicked: ${id} ${isEditOpen}`);
        // setIsOpen(!isOpen);
        setOpenModalTable(true);
        // Add your functionality here
    };
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [qrCodeModal, setqrCodeModal] = useState<boolean>(false);
    const [openModalTable, setOpenModalTable] = useState<boolean>(false);
    const [attendancedetails, setattendancedetails] = useState<boolean>(false);
    interface DateRangeFormValues {
        startDate: Date | null;
        endDate: Date | null;
        activity: string | null;
    }
    const handleIdClick = (id: string) => {
        console.log(`ID clicked: ${id} ${isEditOpen}`);
        // setIsOpen(!isOpen);
        setOpenModal(true);
        setOpenModalTable(true);
        // Add your functionality here
    };
    const handleQrClick = (id: string) => {
        console.log(`ID clicked: ${id} ${isEditOpen}`);
        // setIsOpen(!isOpen);
        setqrCodeModal(true);
        // setOpenModalTable(true);
        // Add your functionality here
    };
   
    const MyAttendanceColumnData: TableColumn<AttendanceSystemDataType>[] = [
        {
            name: <TableHeadColumn title={`ID`} />,
            selector: (row) => row["referenceid"],
            sortable: true,
            center: true,
            cell: (row) => (
                <button
                    onClick={() => handleIdClick(row["referenceid"])}
                    style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                    }}>
                    {row["referenceid"]}
                </button>
            ),
        },
        {
            name: <TableHeadColumn title={`QR Code`} />,
            selector: (row) => row["qrcode"],
            sortable: true,
            center: true,
            cell: (row) => (
                <button
                    onClick={() => handleQrClick(row["qrcode"])}
                    style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                    }}>
                    {row["qrcode"]}
                </button>
            ),
        },
        {
            name: <TableHeadColumn title={`Activity Type`} />,
            selector: (row) => row["activitytype"],
            sortable: true,
            center: true,
        }, 
        {
            name: <TableHeadColumn title={`Activity Title`} />,
            selector: (row) => row["activitytitle"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`No. of Reservations`} />,
            selector: (row) => row["reservations"],
            sortable: true,
            center: true,
            cell: (row) => (
                <button
                    onClick={() => handlereservations(row["reservations"])}
                    style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                        color:'blue',
                        textDecoration:"underline"
                    }}>
                    {row["reservations"]}
                </button>
            ),
        },
        {
            name: <TableHeadColumn title={`No. of Attendees`} />,
            selector: (row) => row["attendees"],
            sortable: true,
            center: true,
            cell: (row) => (
                <button
                    onClick={() => handlereservations(row["attendees"])}
                    style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                         color:'blue',
                        textDecoration:"underline"
                    }}>
                    {row["attendees"]}
                </button>
            ),
        },
        {
            name: <TableHeadColumn title={`Description`} />,
            selector: (row) => row["description"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Category`} />,
            selector: (row) => row["subcategory"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Activity Location`} />,
            selector: (row) =>
                row["activitylocation"] ? "Physical Address" : "Online",
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Country`} />,
            selector: (row) => row["country"].join(","),
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`State`} />,
            selector: (row) => row["state"].join(","),
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`City`} />,
            selector: (row) => row["city"].join(","),
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Full Address`} />,
            selector: (row) => row["fulladdress"],
            sortable: true,
            center: true,
        }, 
        {
            name: <TableHeadColumn title={`Keywords`} />,
            selector: (row) => row["keywords"].join(","),
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Duration`} />,
            selector: (row) => row["duration"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Start Date`} />,
            selector: (row) => row["startdates"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Expiry Date`} />,
            selector: (row) => row["expirydate"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Leaving Activity`} />,
            selector: (row) => (row["leavingactivity"] ? "Yes" : "No"),
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Break in Activity`} />,
            selector: (row) => (row["breakinactivity"] ? "Yes" : "No"),
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Public Activity`} />,
            selector: (row) => row["publicactivity"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Supervisor Confirmirmation`} />,
            selector: (row) => (row["supervisorconfirm"] ? "Yes" : "No"),
            sortable: true,
            center: true,
        },
        // {
        //     name: <TableHeadColumn title={`Supervisor Name`} />,
        //     selector: (row) => row["supervisorname"],
        //     sortable: true,
        //     center: true,
        // },
        {
            name: <TableHeadColumn title={`Manual Check`} />,
            selector: (row) => (row["manualcheck"] ? "Yes" : "No"),
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Invite Users`} />,
            selector: (row) => row["inviteusers"].join(","),
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Meeting URL`} />,
            selector: (row) => row["meetingurl"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Phone Number`} />,
            selector: (row) => row["phonenumber"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Meeting Platform`} />,
            selector: (row) => row["meetingplatform"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Activity's Frequency`} />,
            selector: (row) => row["activityfrequency"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Rate Ranges`} />,
            selector: (row) => row["dateranges"],
            sortable: true,
            center: true,
        },
        {
            name: <TableHeadColumn title={`Thumbnail`} />,
            selector: (row) => row["image"],
            sortable: true,
            center: true,
        },

        {
            name: <TableHeadColumn title={`Actions`} />,
            cell: (row) => (
                <AttendanceActionButtons
                    handleActivityAction={handleActivityAction}
                    rowData={row}
                />
            ),
            center: false,
            right: true,
        },
    ];
    const [popup, setPopup] = useState(false);
    const handleOpenPopup = () => {
        console.log("hello");

        setPopup(true);
        //  setIsOpen(!isOpen);
    };
    const [isHistoryPopupOpen, setIsHistoryPopupOpen] = useState(false);
    const toggleHistoryPopup = () => {
        setIsHistoryPopupOpen(!isHistoryPopupOpen);
    };
    const toggleattendancedetailsPopup = () => {
      setattendancedetails(!attendancedetails);
       
    };
    const togglereservations = () => {
        setOpenModalTable(!openModalTable);
    };
    return (
        <div className="page-body">
            <Breadcrumbs
                mainTitle={`${`Activities and Attendance`} (${BrandTools})  `}
                parent="brand-support-tools"
            />
            <CardHeader className="pb-0 pt-0  px-0">
                <SubMenu />
            </CardHeader>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                        <div className="mt-2">
                                        {MyAtttendanceSystemTableAction?.map(
                                            (action, index) => {
                                                return (
                                                    <>
                                                        {action.actionName !=
                                                        "My Reservations and Attendance" ? (
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/${action.path}`}>
                                                                <span
                                                                    key={index}
                                                                    className="border rounded p-2 m-1 text-white bg-dark ps-2 pe-2">
                                                                    {
                                                                        action.actionName
                                                                    }{" "}
                                                                </span>
                                                            </Link>
                                                        ) : (
                                                            <span
                                                                key={index}
                                                                className="border rounded p-2 m-1 text-white bg-dark ps-2 pe-2"
                                                                onClick={
                                                                    toggleHistoryPopup
                                                                }>
                                                                {
                                                                    action.actionName
                                                                }{" "}
                                                            </span>
                                                        )}
                                                    </>
                                                );
                                            }
                                        )}
                                    </div>
                            <CardBody className="p-2">
                                <>
                                   
                                    <div className="ms-3 mt-3">
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={(values) => {
                                                console.log(
                                                    "Selected Date Range:",
                                                    values
                                                );
                                            }}>
                                            {({ setFieldValue, values }) => (
                                                <Form>
                                                    <Row>
                                                        <Col sm="1">
                                                            <FormGroup>
                                                            <div className="d-flex flex-column">
              <Label htmlFor="activityType">Activity Type:</Label>
              <Field
                name="activityType"
                as={Input}
                type="select"
                id="activityType"
              >
                <option value="">{`Select`}</option>
                <option value="freeCommercial">
                Appointment
                </option>
                <option value="freeNonProfit">
                Class Room
                </option>
                <option value="freeNonProfit">
                Event
                </option>
                <option value="freeNonProfit">
                Work Shift
                </option>
              </Field>
            </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="1">
                                                            <FormGroup>
                                                                <div className="d-flex flex-column">
                                                                    <Label htmlFor="startDate">
                                                                        From:
                                                                    </Label>
                                                                    <Field name="startDate">
                                                                        {({
                                                                            field,
                                                                        }: any) => (
                                                                            <ReactDatePicker
                                                                                selected={
                                                                                    values.startDate
                                                                                }
                                                                                onChange={(
                                                                                    date
                                                                                ) =>
                                                                                    setFieldValue(
                                                                                        "startDate",
                                                                                        date
                                                                                    )
                                                                                }
                                                                                dateFormat="yyyy/MM/dd"
                                                                                placeholderText="Select start date"
                                                                                className="form-control flatpickr-input"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="1">
                                                            <FormGroup>
                                                                <div className="d-flex flex-column">
                                                                    <Label htmlFor="endDate">
                                                                        To:
                                                                    </Label>
                                                                    <Field name="endDate">
                                                                        {({
                                                                            field,
                                                                        }: any) => (
                                                                            <ReactDatePicker
                                                                                selected={
                                                                                    values.endDate
                                                                                }
                                                                                onChange={(
                                                                                    date
                                                                                ) =>
                                                                                    setFieldValue(
                                                                                        "endDate",
                                                                                        date
                                                                                    )
                                                                                }
                                                                                dateFormat="yyyy/MM/dd"
                                                                                placeholderText="Select end date"
                                                                                minDate={
                                                                                    values.startDate
                                                                                }
                                                                                className="form-control flatpickr-input"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="2">
                                                            <FormGroup>
                                                                <div className="d-flex flex-column">
                                                                    <Label htmlFor="activity">
                                                                    Search Activities:
                                                                    </Label>
                                                                    <Field
                                                                        name="activity"
                                                                        className="form-control"
                                                                        type="text"
                                                                        placeholder="enter a keyword, title or category"></Field>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col
                                                            sm="1"
                                                            className="align-self-center">
                                                            <Button type="submit">
                                                                Filter
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                    <div className="table-responsive pt-2">
                                        <DataTable
                                            className="custom-scrollbar"
                                            columns={MyAttendanceColumnData}
                                            data={data}
                                            striped={true}
                                            pagination
                                            selectableRows
                                            onSelectedRowsChange={
                                                handleRowSelected
                                            }
                                            clearSelectedRows={toggleDelete}
                                            onRowClicked={handleRowSelected}
                                        />
                                    </div>
                                    <Popup
                                        title={
                                            isEditOpen
                                                ? "Edit Activity"
                                                : "View Activity"
                                        }
                                        openModalToggle={openModalToggle}
                                        isOpen={isOpen}
                                        size={`xl`}>
                                        {isEditOpen ? (
                                            <ManageActivityEdit
                                                rowData={clickedRow}
                                            />
                                        ) : (
                                            <ManageActivityView
                                                rowData={clickedRow}
                                            />
                                        )}
                                    </Popup>
                                    {isHistoryPopupOpen && ( 
                                          <Modal
                                          isOpen={isHistoryPopupOpen}
                                          toggle={toggleHistoryPopup}
                                          size="xl">
                                          <ModalHeader
                                              toggle={toggleHistoryPopup}>
                                             My Reservations and Attendance
                                          </ModalHeader>
                                          <ModalBody>
                                              {/* You can display additional data here */}
                                              <Formik
                                            initialValues={initialValues}
                                            onSubmit={(values) => {
                                                console.log(
                                                    "Selected Date Range:",
                                                    values
                                                );
                                            }}>
                                            {({ setFieldValue, values }) => (
                                                <Form>
                                                    <Row>
                                                        <Col sm="3">
                                                            <FormGroup>
                                                                <div className="d-flex flex-column">
                                                                    <Label htmlFor="startDate">
                                                                        From:
                                                                    </Label>
                                                                    <Field name="startDate">
                                                                        {({
                                                                            field,
                                                                        }: any) => (
                                                                            <ReactDatePicker
                                                                                selected={
                                                                                    values.startDate
                                                                                }
                                                                                onChange={(
                                                                                    date
                                                                                ) =>
                                                                                    setFieldValue(
                                                                                        "startDate",
                                                                                        date
                                                                                    )
                                                                                }
                                                                                dateFormat="yyyy/MM/dd"
                                                                                placeholderText="Select start date"
                                                                                className="form-control flatpickr-input"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="3">
                                                            <FormGroup>
                                                                <div className="d-flex flex-column">
                                                                    <Label htmlFor="endDate">
                                                                        To:
                                                                    </Label>
                                                                    <Field name="endDate">
                                                                        {({
                                                                            field,
                                                                        }: any) => (
                                                                            <ReactDatePicker
                                                                                selected={
                                                                                    values.endDate
                                                                                }
                                                                                onChange={(
                                                                                    date
                                                                                ) =>
                                                                                    setFieldValue(
                                                                                        "endDate",
                                                                                        date
                                                                                    )
                                                                                }
                                                                                dateFormat="yyyy/MM/dd"
                                                                                placeholderText="Select end date"
                                                                                minDate={
                                                                                    values.startDate
                                                                                }
                                                                                className="form-control flatpickr-input"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="4" className="mb-3">
          <Label check> Activity</Label>
          <Field as="select" className="form-control" name="reference-coupon">
            <option value={""}> All</option>
            <option value={""}>I Reserved</option>
            <option value={""}>I Atteneded </option>
          </Field>
        </Col>
                                                        <Col
                                                            sm="1"
                                                            className="align-self-center">
                                                            <Button type="submit">
                                                                Filter
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                              <AttendanceHistory
                                                  reservationsattendance={
                                                      true
                                                  }
                                                  //  attendanceData={exampleData}
                                                  togglereservations={
                                                      togglereservations
                                                  }
                                                  particularuserpopup={true}
                                              />
                                          </ModalBody>
                                      </Modal>
                                    )}
                                    {openModalTable && (
                                        <Modal
                                            isOpen={openModalTable}
                                            toggle={togglereservations}
                                            size="xl">
                                            <ModalHeader toggle={togglereservations}>
                                                No. Of Reservations
                                            </ModalHeader>
                                            <ModalBody>
                                                {/* You can display additional data here */}
                                                
                                                <AttendanceHistory
                                                     reservationhistoryValue={true}
                                                    Noofreservations={true}
                                                    //  attendanceData={exampleData}
                                                    toggleattendancedetailsPopup={
                                                      toggleattendancedetailsPopup
                                                    }
                                                    particularuserpopup={true}
                                                />
                                            </ModalBody>
                                        </Modal>
                                    )}
                                    { attendancedetails && ( 
                                          <Modal
                                          isOpen={attendancedetails}
                                          toggle={toggleattendancedetailsPopup}
                                          size="xl">
                                          <ModalHeader
                                              toggle={toggleattendancedetailsPopup}>
                                             Attendance Details
                                          </ModalHeader>
                                          <ModalBody>
                                              {/* You can display additional data here */}
                                              <Formik
      initialValues={{ filter: '', user: '' }}
      onSubmit={(values) => {
        console.log('Selected filter:', values.filter, 'Users:', values.user);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit} className="form-wizard my-3">
          <Row>
            <Col sm="6">
              <Label htmlFor="filter" className="form-label">
                Select Filter:
              </Label>
              <Field
                as="select"
                name="filter"
                className="form-control"
                onChange={handleChange}
              >
                <option value="">-- Select an Option --</option>
                <option value="users">Users</option>
                <option value="all">All</option>
                <option value="reservations">Reservations</option>
                <option value="attendees">Attendees</option>
              </Field>
            </Col>

            <Col sm="4">
              <Label check className="form-label">
                Users (optional)
              </Label>
              <TagsInput
                name="user"
                placeholder="User Search"
                setFieldValue={setFieldValue} // This allows setting the value from TagsInput
              />
            </Col>
          </Row>
          <button type="submit" className="btn btn-primary mt-3">
            Apply Filter
          </button>
        </Form>
      )}
    </Formik>
                                              <AttendanceHistory 
                                                  attendancedetailsTable={true}
                                                  attendancedetailsValue={true}
                                                  //  attendanceData={exampleData}
                                                  togglereservations={
                                                      togglereservations
                                                  }
                                                  particularuserpopup={true}
                                              />
                                          </ModalBody>
                                      </Modal>
                                    )}
                                    {openModal && (
                                        <MediaModal
                                            value={openModal}
                                            setOpenModal={setOpenModal}
                                            // dataId={dataId}
                                            items={items}
                                        />
                                    )}
                                    {qrCodeModal && (
                                        <QrcodeAttendance
                                            value={qrCodeModal}
                                            setqrCodeModal={setqrCodeModal}
                                            // dataId={dataId}
                                            items={items}
                                            
                                        />
                                    )}
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ManageAttendanceSystem;
