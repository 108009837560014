import { Row, Col, Label } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { ErrorMessage, Field, Form } from "formik";
import TextCounter from "../../../CommonInput/TextCounter";
import TagsInput from "../../../CommonInput/TagsInput";
import Sharing from "../SurveyAndQuizzes/Sharing";
import TitleInput from "../../../CommonInput/TitleInput";
import DescriptionInput from "../../../CommonInput/DescriptionInput";

const AddManageExternalLinkForm = (props: any) => { 

  const { errors, submitErrors, setSubmitError, values, setFieldValue } = props;

  return (
    <Form className="form-wizard">
      <Row className="mb-3">
        <Col sm="4">  

<TitleInput title=' Title of your links' values={values.couponName} name="title"  placeholder="Enter title of your links"/>  

        </Col>
        <Col sm="4">
        <DescriptionInput  name='description'  className={`${
              submitErrors &&
              `${errors.description ? "is-invalid" : "is-valid"}`
            }`} placeholder="Enter Opinion/Description" values={values.description} lable='Opinion/Description'/>
         
        </Col>
        <Col sm="4">
          <Label check>Keywords <span className="txt-danger">*</span></Label>  
          <TagsInput name="keywords" className={`${
              submitErrors &&
              `${errors.keywords ? "is-invalid" : "is-valid"}`
            }`} placeholder="Add keywords" setFieldValue={setFieldValue} />
            <ErrorMessage
            name="keywords"
            component="span"
            className="invalid-feedback"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="4">
          <Label check>
            External Links <span className="txt-danger">*</span>
          </Label>
          <Field
            name="externallink"
            type="text"
            placeholder="Enter your external link"
            className={`form-control ${
              submitErrors && `${errors.externallink ? "is-invalid" : "is-valid"}`
            }`}
          />
          <ErrorMessage
              name="externallink"
              component="span"
              className="invalid-feedback"
            />
        </Col>
      </Row>
      {/* <Sharing nomediacenter={true}  /> this is used in future Dont delete*/}
      <Row>
        <Col sm="auto" className="mb-3">
          <Btn color="primary" type="submit" onClick={() => setSubmitError(true)}>Add Links</Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default AddManageExternalLinkForm;
