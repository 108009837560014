import React, { useEffect, useState } from 'react';
import { H1, Image } from '../../../AbstractElements';
import { dynamicImage } from '../../../Service';
import { Col, Row } from 'reactstrap';
import QuizmaterialBoxValue from './QuizmaterialBoxValue';
import QuetionGroup from './QuetionGroup';

interface Question {
  text: string;
  options: string[];
  correct: number; // Index of the correct option
  explanation?: string;
}

interface ReviewQuizProps {
  questions: Question[];
  userAnswers: number[]; // Array of user's selected option indices
  onSubmit?: () => void; // Optional submit handler
}

const ReviewQuiz: React.FC<ReviewQuizProps> = ({ questions, userAnswers, onSubmit }) => {
  // Calculate the score based on correct (+1.5) and incorrect (-2) answers
  let totalScore = 0;
  questions.forEach((question, index) => {
    if (userAnswers[index] === question.correct) {
      totalScore += 1.5; // Add +1.5 for correct answers
    } else {
      totalScore -= 2; // Deduct -2 for incorrect answers
    }
  });

  const totalQuestions = questions.length;
  const maxPossibleScore = totalQuestions * 1.5;
  const scorePercentage = (totalScore / maxPossibleScore) * 100;

  // Determine medal category
  let medal = '';
  if (scorePercentage === 100) {
    medal = 'Gold';
  } else if (scorePercentage >= 70) {
    medal = 'Silver';
  } else if (scorePercentage >= 40) {
    medal = 'Bronze';
  } else {
    medal = "Failed";
  }

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // End loading after 5 seconds
    }, 5000);

    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, []);

  if (isLoading) {
    return (
      <div className="loading-video-container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div>
          <Image className="img-fluid rounded" alt="user" src={dynamicImage(`download.gif`)} />
          <div className='text-center'>
            <H1>Waiting for Results.....</H1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='overflow-auto px-5'>
      <div className='d-flex justify-content-between'>
        <h2 className='mt-3'><b>Your Score:</b> {totalScore.toFixed(1)} out of {maxPossibleScore.toFixed(1)}</h2>
        <h1 className='mt-3'><b>Status: </b>{medal}</h1>
      </div>
      <div className='progress mt-3' style={{ height: '30px' }}>
        <div
          className='progress-bar'
          role='progressbar'
          style={{ width: `${scorePercentage}%`, backgroundColor: medal === 'Gold' ? 'gold' : medal === 'Silver' ? 'silver' : medal === 'Bronze' ? '#cd7f32' : 'grey' }}
          aria-valuenow={scorePercentage}
          aria-valuemin='0'
          aria-valuemax='100'
        >
          {scorePercentage.toFixed(0)}%
        </div>
      </div>
      <QuetionGroup />

      <div>
        {questions.map((question, index) => {
          const userAnswerIndex = userAnswers[index]; // Get the index of the user's answer
          const userAnswer = userAnswerIndex !== undefined ? question.options[userAnswerIndex] : "Not answered";

          return (
            <div key={index} className="question-review my-5">
              <h4>{index + 1}. {question.text}</h4>
              <Row className='align-items-start'>
                <Col>
                  <div className='answers-grid ms-3 me-auto mt-2'>
                    {question.options.map((ans, ansIndex) => {
                      const isUserAnswer = ansIndex === userAnswerIndex;
                      const isCorrectAnswer = ansIndex === question.correct;

                      return (
                        <div className='option-item' key={ansIndex}>
                          <button
                            className={`py-3 px-3 ${isUserAnswer ? (isCorrectAnswer ? 'btn-success' : 'btn-danger') : (isCorrectAnswer ? 'btn-success' : '')}`}
                          >
                            {ans}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col>
                  {userAnswerIndex !== undefined && userAnswerIndex !== question.correct && (
                    <div className='mt-3 p-3 border border-danger rounded'>
                      <strong>Explanation:</strong> {question.explanation}
                    </div>
                  )}
                </Col>
              </Row>
              <p className='mt-3 ms-3'>
                <strong>Status:</strong> {userAnswerIndex !== undefined && userAnswerIndex === question.correct ? "Correct" : "Incorrect"}
              </p>
              <ul className='mt-3 ms-3'>
                <li>{userAnswerIndex !== undefined && userAnswerIndex === question.correct ? "You got this question right! (+1.5 points)" : "You got this question wrong (-2 points)"}</li>
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReviewQuiz;
