import ProductListContainer from "../../../../Component/Application/Ecommerce/ProductList/ProductList";

const ProductList = () => {
  return (
    <div className="page-body">
      <ProductListContainer />
    </div>
  );
};

export default ProductList;
