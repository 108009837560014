import { useState } from "react";
import { Row, Col, Label, Table, Input } from "reactstrap";
import { ErrorMessage, Formik, Form } from "formik";
import CategoryDataDrop from "../../../../../CommonInput/CategoryDataDrop";
import { H3 } from "../../../../../../AbstractElements";

// Mock data for demonstration
const data = {
  category1: [
    { addon: "Addon 1", name: "Mineral water", quantity: "1ltr", price: "0.00$" },
    { addon: "Addon 2", name: "Pepsi", quantity: "500ml", price: "0.00$" },
  ],
  category2: [
    { addon: "Addon 1", name: "Coca Cola", quantity: "600ml", price: "0.00$" },
    { addon: "Addon 2", name: "Sprite", quantity: "500ml", price: "0.00$" },
  ],
  category3: [
    { addon: "Addon 1", name: "Fanta", quantity: "300ml", price: "0.00$" },
    { addon: "Addon 2", name: "7UP", quantity: "1ltr", price: "0.00$" },
  ],
};

function CategoryTable() {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [extraPrices, setExtraPrices] = useState<{ [key: string]: string }>({});
  const handleCategorySelect = (selectedOptions: any) => {
    // Ensure selectedOptions is an array
    const categories = Array.isArray(selectedOptions)
      ? selectedOptions.map((option: any) => option.value)
      : []; // Default to an empty array if not an array
    
    setSelectedCategory(selectedOptions);

    console.log(categories);
    
  };
  const handleExtraPriceChange = (category: string, index: number, value: string) => {
    setExtraPrices((prevPrices) => ({
      ...prevPrices,
      [`${category}-${index}`]: value,
    }));
  };
  const renderTables = () => {
    return selectedCategory.map((category, index) => (
      <>
           <H3 className="py-2">Category {index+1}</H3>
      <Table bordered key={index} className="mb-3">
        <thead>
          <tr>
            <th>Select</th>
            <th>Addon</th>
            <th>Option Name</th>
            <th>Addon Quantity</th>
            <th>Addon Extra Price</th>
          </tr>
        </thead>
        <tbody>
          {data[category]?.map((item, i) => (
            <tr key={i}>
              <th scope="row">
                <Input type="checkbox" />
              </th>
              <td>{item.addon}</td>
              <td>{item.name}</td>
              <td>{item.quantity}</td>
              <td>
                  <Input
                    type="text"
                    value={extraPrices[`${category}-${i}`] || "  "}
                    onChange={(e) =>
                      handleExtraPriceChange(category, i, e.target.value)
                    }
                  />
                </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </>
    ));
  };

  return (
    <Formik initialValues={{ category: "" }}>
      <Form>
        <Row>
          <Col sm="4" className="mb-3">
            <Label check>
              Addon Category <span className="txt-danger">*</span>
            </Label>
            <CategoryDataDrop
              name="category"
              isMulti={true} // Enable multi-select
              placeholder="Select"
              onCategoryChange={handleCategorySelect}
            />
            <ErrorMessage
              name="category"
              component="span"
              className="invalid-feedback"
            />
          </Col>
        </Row>
        <Row className="mb-4">
          {renderTables()}
        </Row>
      </Form>
    </Formik>
  );
}

export default CategoryTable;
