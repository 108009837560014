import { useEffect } from "react";
import CustomSelect from "./CustomSelect";
import { Field } from "formik";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { useGetLanguageDataQuery } from "../../ReduxToolkit/Reducers/Language/languageApiSlice";
import { setLanguages } from "../../ReduxToolkit/Reducers/commonInputSlice"; 

function Language(props: any) {
  const { languages } = useAppSelector((state: any) => state.common);
  const { data: fetchLanguages, isLoading } = useGetLanguageDataQuery();
  const { name, className, isMulti, placeholder } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchLanguages?.data) {
      // Map the fetched countries to your desired structure
      let filter_language = fetchLanguages.data.map((item: any) => {
        return { label: item.name, value: item.id };
      });

      // Add the clearable option
      filter_language = [...filter_language];

      // Dispatch the countries to the Redux store
      dispatch(setLanguages(filter_language));
    }
  }, [fetchLanguages?.data, dispatch]);

  if (isLoading) return <div>Data not found!</div>;

  if (!languages || languages.length === 0) return <div>Data not found!</div>;

  return (
    <Field
      className={`custom-select ${className}`}
      name={name}
      options={languages}
      component={CustomSelect}
      placeholder={placeholder}
      isMulti={isMulti}
    />
  );
}

export default Language;
