export const AttendanceSystemData = [
    {
      referenceid: "REF123",
      qrcode: "dummy1",
      activitytype: "appointment",
      activitytitle: "Meeting with Client",
      reservations:'4',
      attendees:'4',
      description: "Discuss project details with the client.",
      activitylocation: true,
      country: ["USA"],
      state: ["California"],
      city: ["San Francisco"],
      fulladdress: "123 Market St, San Francisco, CA 94103",
      category: "Business",
      keywords: ["meeting", "client", "project"],
      duration: "2",
      startdates: new Date("2024-08-15").toISOString(),
      expirydate: new Date("2024-08-16").toISOString(),
      leavingactivity: false,
      breakinactivity: false,
      publicactivity: "Private",
      latearrival: 10,
      supervisorconfirm: true,
      supervisorname: "John Doe",
      manualcheck: false,
      inviteusers: ["user1@example.com", "user2@example.com"],
      meetingurl: "https://example.com/meeting",
      phonenumber: "123-456-7890",
      meetingplatform: "Zoom",
      image: null,
      instructions: "",
      activityfrequency: false,
      dateranges: ""
    },
    {
      referenceid: "REF124",
      qrcode: "dummy2",
  
      activitytype: "training",
      activitytitle: "Team Building Workshop",
      description: "A workshop for improving team collaboration.",
      activitylocation: false,
      country: ["India", "USA", "China"],
      state: [],
      city: [],
      fulladdress: "",
      category: "Training",
      keywords: ["workshop", "team", "collaboration"],
      duration: "4",
      startdates: new Date("2024-08-15").toISOString(),
      expirydate: new Date("2024-08-16").toISOString(),
      leavingactivity: true,
      breakinactivity: true,
      publicactivity: "Public",
      latearrival: 5,
      supervisorconfirm: false,
      supervisorname: "",
      manualcheck: true,
      inviteusers: ["user3@example.com"],
      meetingurl: "",
      phonenumber: "",
      meetingplatform: "",
      image: null,
      instructions: "",
      activityfrequency: false,
      dateranges: ""
    },
    {
      referenceid: "REF125",
      qrcode: "dummy3",
  
      activitytype: "appointment",
      activitytitle: "Doctor Appointment",
      description: "Regular check-up with Dr. Smith.",
      activitylocation: true,
      country: ["USA"],
      state: ["New York"],
      city: ["New York City"],
      fulladdress: "456 Park Ave, New York, NY 10022",
      category: "Health",
      keywords: ["doctor", "check-up"],
      duration: "1",
      startdates: new Date("2024-08-15").toISOString(),
      expirydate: new Date("2024-08-16").toISOString(),
      leavingactivity: false,
      breakinactivity: false,
      publicactivity: "Private",
      latearrival: 15,
      supervisorconfirm: true,
      supervisorname: "Jane Smith",
      manualcheck: false,
      inviteusers: [],
      meetingurl: "",
      phonenumber: "987-654-3210",
      meetingplatform: "",
      image: null,
      instructions: "",
      activityfrequency: false,
      dateranges: ""
    },
    {
      referenceid: "REF126",
      qrcode: "dummy4",
  
      activitytype: "appointment",
      activitytitle: "Lunch with Partner",
      description: "Discuss partnership opportunities over lunch.",
      activitylocation: true,
      country: ["USA"],
      state: ["Texas"],
      city: ["Houston"],
      fulladdress: "789 Main St, Houston, TX 77002",
      category: "Networking",
      keywords: ["lunch", "partner", "networking"],
      duration: "1.5",
      startdates: new Date("2024-08-15").toISOString(),
      expirydate: new Date("2024-08-16").toISOString(),
      leavingactivity: false,
      breakinactivity: true,
      publicactivity: "Private",
      latearrival: 5,
      supervisorconfirm: false,
      supervisorname: "",
      manualcheck: true,
      inviteusers: ["user4@example.com", "user5@example.com"],
      meetingurl: "",
      phonenumber: "",
      meetingplatform: "",
      image: null,
      instructions: "",
      activityfrequency: false,
      dateranges: ""
    },
    {
      referenceid: "REF127",
      activitytype: "conference",
      activitytitle: "Tech Conference 2024",
      description: "Annual technology conference.",
      activitylocation: true,
      country: ["USA"],
      state: ["Nevada"],
      city: ["Las Vegas"],
      fulladdress: "123 Las Vegas Blvd, Las Vegas, NV 89101",
      category: "Conference",
      keywords: ["tech", "conference"],
      duration: "8",
      startdates: new Date("2024-08-15").toISOString(),
      expirydate: new Date("2024-08-16").toISOString(),
      leavingactivity: true,
      breakinactivity: true,
      publicactivity: "Public",
      latearrival: 0,
      supervisorconfirm: false,
      supervisorname: "",
      manualcheck: false,
      inviteusers: ["user6@example.com", "user7@example.com"],
      meetingurl: "https://example.com/conference",
      phonenumber: "555-123-4567",
      meetingplatform: "WebEx",
      image: null,
      instructions: "",
      activityfrequency: false,
      dateranges: ""
    },
    {
      referenceid: "REF128",
      activitytype: "appointment",
      activitytitle: "Interview with Candidate",
      description: "Interview for the open developer position.",
      activitylocation: true,
      country: ["USA"],
      state: ["Washington"],
      city: ["Seattle"],
      fulladdress: "456 Pike St, Seattle, WA 98101",
      category: "Hiring",
      keywords: ["interview", "candidate", "developer"],
      duration: "1",
      startdates: new Date("2024-08-15").toISOString(),
      expirydate: new Date("2024-08-16").toISOString(),
      leavingactivity: false,
      breakinactivity: false,
      publicactivity: "Private",
      latearrival: 5,
      supervisorconfirm: true,
      supervisorname: "Michael Brown",
      manualcheck: false,
      inviteusers: [],
      meetingurl: "",
      phonenumber: "555-987-6543",
      meetingplatform: "",
      image: null,
      instructions: "",
      activityfrequency: false,
      dateranges: ""
    },
    {
      referenceid: "REF129",
      activitytype: "webinar",
      activitytitle: "ReactJS Best Practices",
      description: "Webinar on best practices for using ReactJS.",
      activitylocation: false,
      country: [],
      state: [],
      city: [],
      fulladdress: "",
      category: "Education",
      keywords: ["webinar", "ReactJS", "best practices"],
      duration: "2",
      startdates: new Date("2024-08-15").toISOString(),
      expirydate: new Date("2024-08-16").toISOString(),
      leavingactivity: false,
      breakinactivity: true,
      publicactivity: "Public",
      latearrival: 0,
      supervisorconfirm: false,
      supervisorname: "",
      manualcheck: false,
      inviteusers: ["user8@example.com", "user9@example.com"],
      meetingurl: "https://example.com/webinar",
      phonenumber: "",
      meetingplatform: "Google Meet",
      image: null,
      instructions: "",
      activityfrequency: false,
      dateranges: ""
    },
    {
      referenceid: "REF130",
      activitytype: "appointment",
      activitytitle: "Board Meeting",
      description: "Quarterly board meeting.",
      activitylocation: true,
      country: ["USA"],
      state: ["Illinois"],
      city: ["Chicago"],
      fulladdress: "789 Wacker Dr, Chicago, IL 60601",
      category: "Business",
      keywords: ["meeting", "board", "quarterly"],
      duration: "3",
      startdates: new Date("2024-08-15").toISOString(),
      expirydate: new Date("2024-08-16").toISOString(),
      leavingactivity: false,
      breakinactivity: false,
      publicactivity: "Private",
      latearrival: 10,
      supervisorconfirm: true,
      supervisorname: "Alice Johnson",
      manualcheck: true,
      inviteusers: ["user10@example.com"],
      meetingurl: "",
      phonenumber: "555-321-0987",
      meetingplatform: "",
      image: null,
      instructions: "",
      activityfrequency: false,
      dateranges: ""
    }
  ];