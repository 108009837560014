import { apiSlice } from "../../apiSlice";
import { GET_STATE_BY_COUNTRY } from "../../../Api";

interface State {
  data: any;
  message: string;
  success: boolean;
}

export const stateApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStateData: builder.mutation<State, { country_id : string }>({
      query: (country_id) => ({
        url: `${GET_STATE_BY_COUNTRY}${country_id}`,
        method: 'GET', 
      }), 
    }),
  }),
});

export const { useGetStateDataMutation } = stateApiSlice;
