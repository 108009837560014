import { CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs"; 
import {
  AddCouponTitle,
  ManageOnlineStoreTitle,
} from "../../../../utils/Constant";
import CouponMain from "../../../../Component/Tools/ManageOnlineStore/ManageMyCoupon/CouponMain";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";

const AddCoupon = () => {
  
  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={`${AddCouponTitle} (Manage Online Store)`} parent={ManageOnlineStoreTitle} />
      <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
      <Container className="card p-4" fluid>
          <CouponMain/>
      </Container>
    </div>
  );
};

export default AddCoupon;
