import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Collapse, Container, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Btn, H3 } from "../../../AbstractElements";
import TextCounter from "../../CommonInput/TextCounter";
import SubCategory from "../../CommonInput/SubCategory";
import TagsInput from "../../CommonInput/TagsInput";
import Country from "../../CommonInput/Country";
import City from "../../CommonInput/City";
import State from "../../CommonInput/State";
import ReactDatePicker from "react-datepicker";
import MultiDatePicker from "../../CommonInput/MultiDatePicker";
import Timeslot from "../../Tools/ManageOnlineStore/ManageBranches/Schedules/Timeslot";
import AttendanceHistory from "../../Application/Calender/DragCalendar/AttendanceHistory";
 


const ActivityEventForm = (props: any) => {
  const { errors, submitErrors, setSubmitError, values, setFieldValue } = props;
  const [activityType, setActivityType] = useState("");
  const [publicActivity, setPublicActivity] = useState("");
  
  const [activityLocation, setActivityLocation] = useState(false);
  const [activityFrequency, setActivityFrequency] = useState(false);
  const [leavingActivity, setLeavingActivity] = useState(true);
  const [breakinActivity, setBreakinActivity] = useState(true);
  const [supervisorConfirm, setSupervisorConfirm] = useState(true);
  const [manualCheck, setManualCheck] = useState(true);
  const [isAppointment, setIsAppointment] = useState(false);
  const [images, setImages] = useState([] as any);
  const imageURLS = useState([]);
  const [imageMaxLength, setImageMaxLength] = useState(false);
  const [isGeneralInfoOpen, setIsGeneralInfoOpen] = useState(true);
  const [isRequirementOpen , setIsRequirementOpen] = useState(true);
  const [isManagementSettingOpen , setIsManagementSettingOpen] = useState(true);
  const [isLocationActivityOpen , setLocationActivityOpen] = useState(true);
  const [isDateTimeOpen , setDateTimeOpen] = useState(true)
  const [selectedates, setSelectedDates] = useState([]);


  const [startDate, setStartDate] = useState<Date | null>(null);
  const [expireDate, setExpireDate] = useState<Date | null>(null);
  const handleChange = (date: Date) => setStartDate(date);

  const WEEKDAYS = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const whoCanSee = ["Any User","Followers Only","Who has the Activity’s link","Invited Users only"];

  useEffect(() => {
    if (activityType === "appointment") {
      setFieldValue("activitylocation", activityLocation);
    } else {
      setFieldValue("activitylocation", undefined);
    }    
    if(values.multiDate){
      setSelectedDates(values?.multiDate?.split(','));
    }
  }, [activityType, activityLocation, setFieldValue]);

  const setSelectedDateList = (data:any) =>{
    console.log("called......"+JSON.stringify(data));
    setSelectedDates(data);
  }

  const handleActivityType = (event: any) => {
    const value = event.target.value;
    setActivityType(value);
    if (value === "appointment") {
      setIsAppointment(true);
    } else {
      setIsAppointment(false);
      setActivityLocation(false);
    }
  };

  const handlePublicActivity = (event: any) => {
    const value = event.target.value;
    setPublicActivity(value);
    console.log(`public ${publicActivity}`);
  };

  const handleLeavingActivityChange = (event: any) => {
    const value = event.target.value === "true";
    setLeavingActivity(value);
    // console.log("online/offline", value);
  };

  const handleBreakinActivityChange = (event: any) => {
    const value = event.target.value === "true";
    setBreakinActivity(value);
    // console.log("online/offline", value);
  };
  const handleActivityLocationChange = (event: any) => {
    const value = event.target.value === "true";
    setActivityLocation(value);
    // console.log("online/offline", value);
  };
  const handleManualCheckChange = (event: any) => {
    const value = event.target.value === "true";
    setManualCheck(value);
    // console.log("online/offline", value);
  };
  const handleSupervisorConfirmChange = (event: any) => {
    const value = event.target.value === "true";
    setSupervisorConfirm(value);
    console.log("online/offline", value);
  };
  const handleActivityFrequency = (event: any) => {
    const value = event.target.value === "true";
    setActivityFrequency(value);
    // console.log("online/offline", value);
  };

  // const handleSchedule = () => {
  //   setAutoSchedule(!autoSchedule);
  // };
  

  const onImageChange = (e: any) => {
    let totalfiles = e.target.files.length;
    if (totalfiles < 1 ) return;
    else if(totalfiles > 10){
      setImages([]);
      setImageMaxLength(true);
      e.target.value= '';
      return;
    }
    else if(totalfiles >0 && totalfiles <=10){
      setImages([...e.target.files]);
    }
   
    images.forEach((image:any) => {
      if(imageURLS.length < 10){
        imageURLS.push(URL.createObjectURL(image))
      }
    });  
    
  }
  const [openModalTable, setOpenModalTable] = useState<boolean>(false);
  const [attendancedetails, setattendancedetails] = useState<boolean>(false);

  const togglereservations = () => {
    setOpenModalTable(!openModalTable);
};
const toggleattendancedetailsPopup = () => {
  console.log("helllo");
  
  setattendancedetails(!attendancedetails);
   
};  
const handlereservations = (id: string) => { 
  // setIsOpen(!isOpen);
  setOpenModalTable(true);
  // Add your functionality here
};
  return (
    <Form className="form-wizard ">     
      <Row>
        <Col className="mb-3">
          <H3 className="">General Info</H3>
        </Col>
      </Row>
      <Row className="">
         
        <Col sm="6" className="  d-flex flex-column p-2  ">
          <Label check>
          <b> Activity Type:</b>  Appointment
          </Label>
          
        </Col>
        <Col sm="6" className="mb-3">
          <Label check>
            <b>Activity Title:</b> Exam And Practical  
          </Label>
         
        </Col>
      </Row>
      <Row className=" ">
        <Col sm="6" className=" ">
          <Label check>
            <b>Description:</b> Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius nemo hic et accusantium ipsum molestias facere. Reprehenderit accusamus iure explicabo iste laboriosam. Deserunt eum necessitatibus, maiores doloremque velit dolorum modi. 
          </Label>
         
        </Col>
        <Col sm="6" className=" ">
        <Label check>
        <b>Category:</b> Student 
          </Label> 
        </Col>
        <Col sm="6" className=" ">
          <Label check> <b>Duration:</b> 15Min </Label>
          
        </Col>
      </Row> 
      <Row className="  mb-3">
        <Col sm="6">
          <Label check> <b>Keywords:</b> Sometime </Label> 
        </Col>
        
        <Col sm="6" className="  ">
          <Label check><b>Who Can Open the Activity Page:</b> User</Label>
           
        </Col> 
        <Col sm="6" className="  ">
          <Label check><b>No. of Reservations:</b> <span className="link-underline-primary " onClick={handlereservations} style={{color:"blue"}}><u>4</u></span></Label>
          {openModalTable && (
                                        <Modal
                                            isOpen={openModalTable}
                                            toggle={togglereservations}
                                            size="xl">
                                            <ModalHeader toggle={togglereservations}>
                                                No. Of Reservations
                                            </ModalHeader>
                                            <ModalBody>
                                                {/* You can display additional data here */}
                                                
                                                <AttendanceHistory
                                                     reservationhistoryValue={true}
                                                    Noofreservations={true} 
                                                    toggleattendancedetailsPopup={
                                                      toggleattendancedetailsPopup
                                                    }
                                                    particularuserpopup={true}
                                                />
                                            </ModalBody>
                                        </Modal>
                                    )} 
                                     { attendancedetails && ( 
                                          <Modal
                                          isOpen={attendancedetails}
                                          toggle={toggleattendancedetailsPopup}
                                          size="xl">
                                          <ModalHeader
                                              toggle={toggleattendancedetailsPopup}>
                                             Attendance Details
                                          </ModalHeader>
                                          <ModalBody>
                                              {/* You can display additional data here */}
                                              <Formik
      initialValues={{ filter: '', user: '' }}
      onSubmit={(values) => {
        console.log('Selected filter:', values.filter, 'Users:', values.user);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit} className="form-wizard my-3">
          <Row>
            <Col sm="6">
              <Label htmlFor="filter" className="form-label">
                Select Filter:
              </Label>
              <Field
                as="select"
                name="filter"
                className="form-control"
                onChange={handleChange}
              >
                <option value="">-- Select an Option --</option>
                <option value="users">Users</option>
                <option value="all">All</option>
                <option value="reservations">Reservations</option>
                <option value="attendees">Attendees</option>
              </Field>
            </Col>

            <Col sm="4">
              <Label check className="form-label">
                Users (optional)
              </Label>
              <TagsInput
                name="user"
                placeholder="User Search"
                setFieldValue={setFieldValue} // This allows setting the value from TagsInput
              />
            </Col>
          </Row>
          <button type="submit" className="btn btn-primary mt-3">
            Apply Filter
          </button>
        </Form>
      )}
    </Formik>
                                              <AttendanceHistory 
                                                  attendancedetailsTable={true}
                                                  attendancedetailsValue={true}
                                                  //  attendanceData={exampleData}
                                                  togglereservations={
                                                      togglereservations
                                                  }
                                                  particularuserpopup={true}
                                              />
                                          </ModalBody>
                                      </Modal>
                                    )}
        </Col> 
        <Col sm="6" className="  ">
          <Label check><b>No. of Attendees:</b> <span className="link-underline-primary " style={{color:"blue"}}><u>4</u></span></Label>
           
        </Col> 
      </Row>
      
      <Row>
        <Col className="mb-3">
          <H3>Requirements & Instructions</H3>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="mb-3">
          <Label check><b>Instructions & Requirements:</b> suitable response </Label>
          
        </Col>
        <Col sm="12" className="mb-3 ">
          <Label check>
           <b>A participant must Checkout/CheckIn when taking a break:</b>  Yes 
          </Label> 
        </Col>
        <Col sm="12" className="mb-3 ">
          <Label check>
           <b> A participant must checkout before leaving the activity:</b> No 
          </Label> 
        </Col>
      </Row> 
      <Row>
        <Col className="mb-3">
          <H3>Activity's Location</H3>
        </Col>
      </Row>
      <Row>  

        {(activityType !== "appointment" ||
          (activityType === "appointment" && activityLocation)) && (
          <Col sm="4" className="mb-3">
            <Label check>
             <b>Country:</b> India
            </Label> 
          </Col>
        )}
        {(activityType !== "appointment" ||
          (activityType === "appointment" && activityLocation)) && (
          <Col sm="4" className="mb-3">
            <Label check><b>State:</b> Gujrat</Label> 
          </Col>
        )}
        {(activityType !== "appointment" ||
          (activityType === "appointment" && activityLocation)) && (
          <Col sm="4" className="mb-3">
            <Label check>
             <b>City:</b> Surat
            </Label> 
          </Col>
        )}

        {(activityType !== "appointment" ||
          (activityType === "appointment" && activityLocation)) && (
          <Col sm="6" className="mb-3">
            <Label check>
             <b>Full Address:</b> City Light Road RahulRaj mall
            </Label>
          </Col>
        )}
      </Row> 
      <Row>
        <H3 className="textAlign-left mb-3">Dates and Hours</H3>
        <Col sm="6" className="mb-3 ">
          <Label check><b>Activity's Frequency:</b> One Time</Label>
        </Col>
      {(!activityFrequency) &&    ( 
        <>
        <Col sm="6" className="mb-3">
          <Label check><b>Start Date:</b> 10 Sep</Label> 
        </Col>
        <Col sm="6" className="mb-3">
          <Label check><b>Expiry Date:</b> 20 Sep</Label>
         
        </Col>
        </>)
      }
        {activityFrequency && !isAppointment && (
          <Col sm="4" className="mb-3">
            <Label>Date Range</Label>
            <MultiDatePicker
              name="multiDate"
              className="multiDate"
              setFieldValue={setFieldValue}
              setSelectedDateList={setSelectedDateList}
              returnDateList={true}
            />
          </Col>
        )}
        {/* <Col sm="4" className="mb-3">
          <div className="">
            <Label className="col-form-label m-r-10" check>
              Auto Schedule?
            </Label>     
            <br />
            <div className="d-flex">
              <div className="flex-shrink-0  text-end icon-state switch-outline">
                <CommonSwitchSpan
                  name="autoSchedule"
                  onClick={handleSchedule}
                  color={"primary"}
                />
              </div>
              <Label className="m-l-10" check></Label>
            </div>
          </div>
        </Col> */}
      </Row>   
    </Form>
  );
};

export default ActivityEventForm;
