import InvoiceFourContainer from "../../../../../Component/Application/Ecommerce/Invoices/Invoice-4/Invoice-4";

const InvoiceFour = () => {
  return (
    <div className="page-body">
      <InvoiceFourContainer />
    </div>
  );
};

export default InvoiceFour;
