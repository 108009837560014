import { Card, Col, Input, InputGroup, InputGroupText } from "reactstrap";
import { SearchHere } from "../../../../../utils/Constant";
import ChatOptionTab from "./ChatOptionTab";
import { useState } from "react";
import { useAppDispatch } from "../../../../../ReduxToolkit/Hooks";
import { searchMember } from "../../../../../ReduxToolkit/Reducers/ChatSlice";
import ContactWrapper from "./ContactWrapper";
import SearchContacts from "./SearchContacts";
interface LeftSidebarProps {
  ToogleContactList: () => void;
}
const LeftSidebar : React.FC<LeftSidebarProps> = ({ToogleContactList }) => {
  
  const dispatch = useAppDispatch();
  
  return (
    <Col xxl="3" xl="4" md="4" className="box-col-4">
      <Card className="left-sidebar-wrapper">
        <SearchContacts />
        <ContactWrapper ToogleContactList={ToogleContactList }/>
      </Card>
    </Col>
  );
};

export default LeftSidebar;
