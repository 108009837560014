import { apiSlice } from "../../apiSlice";
import { USER_PERSONAL_INFO, PERSONAL_INFO_UPDATE, PERSONAL_INFO_BIRTHDAY_PROOF } from "../../../Api";

interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const personalInfoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPersonalInfo: builder.mutation<SuccessDataProps, void>({
      query: () => ({
        url: USER_PERSONAL_INFO,
        method: "GET",
      }),
    }),
    storePersonalInformation: builder.mutation<SuccessDataProps, any>({
      query: (body) => ({
        url: PERSONAL_INFO_UPDATE,
        method: "POST",
        body: body,
      }),
    }),
    updateBirthdayProof: builder.mutation<SuccessDataProps, any>({
      query: (body) => ({
        url: PERSONAL_INFO_BIRTHDAY_PROOF,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetPersonalInfoMutation,
  useStorePersonalInformationMutation,
  useUpdateBirthdayProofMutation
} = personalInfoApiSlice;
