import GroupChatImages from "./GroupChatImages"; 
import { Btn, FeatherIcons, Image, P } from "../../../../../AbstractElements";
import { dynamicImage } from "../../../../../Service";
import { useAppSelector } from "../../../../../ReduxToolkit/Hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { X } from "react-feather";
import InterviewMailHeader from "../../../Email/LetterBox/EmailRightSide/InterviewMail/InterviewMailHeader";
import InboxOption from "../../../Email/LetterBox/EmailRightSide/InterviewMail/InboxOption";

const RightGroupChatHeader = ({ToogleContactList}) => {
  const {selectedUser} = useAppSelector((state)=> state.chat)
  const user = JSON.parse(selectedUser);
  return (
    <div className="  align-items-center px-2">
      {/* <FontAwesomeIcon  icon={faArrowLeft}  style={{fontSize:"15px",marginRight:"20px"}} className="d-md-none" onClick={ToogleContactList}/> */}
        <div className="email-body">
        <InterviewMailHeader ToogleContactList={ToogleContactList}/>
        <div className="mail-body-wrapper">
          <div className="user-mail-wrapper">

          <div className="user-title">
            <div>
              <div className="rounded-border"> 
            <Image className="img-fluid rounded-circle" src={dynamicImage(user? user.image  : "user/1.jpg")} alt="user"/>

              </div>
              <div className="dropdown-subtitle">
                <P>{user ? user.name : 'Current user'}</P>
                <div className="onhover-dropdown">
                  <Btn className="btn p-0 dropdown-button">
                    To me <FeatherIcons iconName="ChevronDown" />
                  </Btn>
                  <div className="inbox-security onhover-show-div">
                    <P>
                      From: <span>pixelstrap &lt;pixelstrap3@gmail.com&gt;</span>
                    </P>
                    <P>
                      to: <span>donut.horry@gmail.com</span>
                    </P>
                    <P>
                      reply-to:
                      <span>&lt;pixelstrap3@gmail.com&gt;</span>
                    </P>
                    <P>
                      date: <span>Jun 13, 2024, 7:10 AM</span>
                    </P>
                    <P>
                      subject: <span>Important Account Security Update</span>
                    </P>
                    <P>
                      security: <span>standard encryption (TLS)</span>
                    </P>
                  </div>
                </div>
              </div>
            </div>
            <InboxOption handlePrint={''}  />
            {/* <div className="d-flex  d-md-none search-close-icon" onClick={ToogleContactList}>
           <X />
        </div> */}
          </div>
          </div>
        </div>
        </div>
      {/* <div className="common-space"> 
        <div className="chat-time">
          <div className="active-profile">
            <Image className="img-fluid rounded-circle" src={dynamicImage(user? user.image  : "user/1.jpg")} alt="user"/>
            <div className="status bg-success" />
          </div>
          <div>
            <span className="f-w-500">{user ? user.name : 'Current user'}</span>
            <P>Online</P>
          </div>
        </div>
        <div className="d-flex  d-md-none search-close-icon" onClick={ToogleContactList}>
           <X />
        </div>
      </div> */}
    </div>
  );
};

export default RightGroupChatHeader;
