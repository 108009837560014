function SuccessMessge({ message }: any) {
  return (
    <div className="dark alert alert-success fade show" role="alert">
      <p>
        <i className="fa fa-check"></i> {message}
      </p>
    </div>
  );
}

export default SuccessMessge;
