import React, {useState} from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { Btn } from '../../../AbstractElements'
import { supportTicketData } from '../../../Data/Miscellaneous/SupportTicket/SupportTicket'
import { TableHeadColumn } from '../../../Pages/App/AccountSettings/MyRegistrationPlan'
import PageHeader from '../../Dashboard/Default/PageHeader/PageHeader'

export interface ActionButtonProps{
    action:number;
  }

const ActionButtons: React.FC<ActionButtonProps> = ({action}) => {
    return(
        <div className="d-flex justify-content-between">
            <Btn size='sm' color='primary' className='mx-1 px-1'>
              <i className="fa fa-pencil" />
            </Btn>
            <Btn size='sm' color='success' className='mx-1 px-1'>
              <i className="fa fa-link" /> 
            </Btn>
            <Btn size='sm' color='danger' className='mx-1 px-1'>
              <i className="fa fa-trash" /> 
            </Btn>
        </div>
    );
}

export interface HistoryDataType{
    id:number;
}

export const transactionHistoryColumns: TableColumn<HistoryDataType>[] =[
    {
        name: <TableHeadColumn title={`ID`}/>,
        selector: (row) => row["id"],
        sortable: true,
        center: true,
        width: "80px",
    },
    {
        name: <TableHeadColumn title={`Transaction Type`}/>,
        selector: (row) => row["position"],
        sortable: true,
        center: true,
    },
    {
        name: <TableHeadColumn title={`Order Number`}/>,
        selector: (row) => row["office"],
        sortable: true,
        center: true,
    },
    {
        name: <TableHeadColumn title={`Currency`}/>,
        selector: (row) => row["currency"],
        sortable: true,
        center: true,
    },
    {
        name:<TableHeadColumn title={`Actions`}/>,
        cell: (row) => <ActionButtons action={1}/>,
        right: true,
    },
]

const TransactionHistoryContainer = () => {
    const toggleDelete = useState(false); 
    const handleRowSelected =() =>{}
    return (
        <Container fluid>
        <Card>
            <CardHeader className='py-0 px-0'>
                    <PageHeader/>
                </CardHeader>
            <CardBody>
                <Row className='px-3 py-3'>
                    <Col md="12">
                        <div className="table-responsive">                        
                            <DataTable className='custom-scrollbar' columns={transactionHistoryColumns} data={supportTicketData} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        </Container>
    )
}

export default TransactionHistoryContainer