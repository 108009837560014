import InvoiceThreeContainer from "../../../../../Component/Application/Ecommerce/Invoices/Invoice-3/Invoice-3";

const InvoiceThree = () => {
  return (
    <div className="page-body">
      <InvoiceThreeContainer />
    </div>
  );
};

export default InvoiceThree;
