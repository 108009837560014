import { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { ErrorMessage, Form, Field } from "formik";
import ScheduleType from "../../../Component/CommonInput/ScheduleType";
import Sharing from "../../../Component/Tools/BrandSupportTools/SurveyAndQuizzes/Sharing";
import TagsInput from "../../../Component/CommonInput/TagsInput";
import { H3, Btn } from "../../../AbstractElements";
import { Cancel, Save } from "../../../utils/Constant";
import JobLocationType from "../../../Component/CommonInput/JobLocationType";

function WorkAvailabilityForm(props: any) {
  const {
    setFieldValue,
    values,
    errors,
    setOpenModal,
    openModal,
    submitErrors,
    setSubmitErrors,
    resumeData,
  } = props;

  useEffect(() => {
    if (resumeData) {
      const { work_availability } = resumeData;
      setFieldValue("work", work_availability.work);
      setFieldValue("work_type", work_availability.work_type);
      setFieldValue(
        "work_job_title",
        work_availability.work_job_title &&
          work_availability.work_job_title.toString()
      );
      setFieldValue("work_location", work_availability.work_location);
      setFieldValue(
        "display_on_media_center",
        work_availability.display_on_media_center
      );
      setFieldValue("groups", work_availability.groups);
      setFieldValue(
        "individual_users",
        work_availability.individual_users &&
          work_availability.individual_users.toString()
      );
    }
  }, [resumeData]); 

  return (
    <Form className="mt-2">
      <Row>
        <Col sm="12">
          <div className="">
            <H3>
              {`Are you searching for a job ?`}{" "}
              <span className="font-danger">*</span>{" "}
            </H3>
            <div className="form-check form-check-inline radio radio-primary  mt-2 mx-2">
              <Field
                id={`radioinline_yes`}
                type="radio"
                name={`work`}
                value={1}
                checked={values.work === 1 || values.work === "1"}
              />
              <Label className="mb-0" for={`radioinline_yes`} check>
                YES
              </Label>
            </div>
            <div className="form-check form-check-inline radio radio-primary  mt-2 mx-2">
              <Field
                id={`radioinline_no`}
                type="radio"
                value={0}
                checked={values.work === 0 || values.work === "0"}
                name={`work`}
              />
              <Label className="mb-0" for={`radioinline_no`} check>
                NO
              </Label>
            </div>
          </div>
        </Col>
      </Row>
      {values.work == 1 && (
        <>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label check>{`Schedule Type`}</Label>
                <ScheduleType
                  name="work_type"
                  placeholder="Select Schedule Type"
                  className={``}
                  isMulti={true}
                />
              </FormGroup>
              <Col sm="auto">
                <FormGroup>
                  <div className=" col-form-Label">
                    <FormGroup className=" ">
                      <Label check className="mb-1">
                        Desired Job Titles/Positions:
                      </Label>
                      <TagsInput
                        name="work_job_title"
                        tags={
                          values.work_job_title &&
                          values.work_job_title.split(",")
                        }
                        description="Job Titles/Positions"
                        placeholder="Enter Job Titles/Positions"
                        className={`form-control pt-0`}
                        setFieldValue={setFieldValue}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
              <FormGroup>
                <Label check>{`Job Location Type:`}</Label>
                <JobLocationType
                  name="work_location" // ensure this matches your validation schema
                  placeholder="Select Job Location Type"
                  className={``}
                  isMulti={true}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="my-2 mb-4">
                <Btn
                  color="secondary"
                  type="submit"
                  onClick={() => setSubmitErrors(true)}
                  className="me-2"
                >
                  {Save}
                </Btn>
                <Btn color="primary" onClick={() => setOpenModal(!openModal)}>
                  {Cancel}
                </Btn>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
}

export default WorkAvailabilityForm;
