// Assuming calenderInitialData is defined like this
export const calenderInitialData = {
  calendarEvents: [
    {
      id: '1',
      title: 'Meeting with team',
      start: '2024-09-24T09:00:00', // Start date/time in ISO format
      end: '2024-09-24T10:00:00',   // End date/time in ISO format
      backgroundColor: '#1d7a8c',    // Optional: To set custom background color for the event
      borderColor: '#1d7a8c',        // Optional: To set custom border color
    },
    {
      id: '2',
      title: 'Client presentation',
      start: '2024-09-25T11:00:00',
      end: '2024-09-25T12:30:00',
      backgroundColor: '#ff5252',
      borderColor: '#ff5252',
    },
    {
      id: '3',
      title: 'Project Deadline',
      start: '2024-09-27T17:00:00',
      end: '2024-09-27T18:00:00',
      backgroundColor: '#66bb6a',
      borderColor: '#66bb6a',
    },
    // Add more default events here as needed
  ],
};
