import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import CardHeaderCommon from "../../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import {
  BirthDate,
  Emailaddress,
  UpdateProfile,
  Username,
} from "../../../../utils/Constant";
import CommonSwitchSpan from "../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import { Btn } from "../../../../AbstractElements";
import { Form, ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import Country from "../../../CommonInput/Country";
import State from "../../../CommonInput/State";
import City from "../../../CommonInput/City";
import { useUpdateBirthdayProofMutation } from "../../../../ReduxToolkit/Reducers/AccountSettings/personalInforApiSlice";
import { toast } from "react-toastify";
import Loading from "../../../Loading/Loading";
import Language from "../../../CommonInput/Language";
import BirthdateProofModal from "./BirthdateProofModal";
import BirthdateProofContent from "./BirthdateProofContent";

const PersonalInformationForm = (props: any) => {
  const [scrollingModal, setScrollingModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [showImageModal, setShowImageModal] = useState<any>();

  const {
    errors,
    submitErrors,
    setSubmitErrors,
    values,
    setFieldValue,
    personalInfo,
    getPersonalInfoData,
  } = props;

  const [updateBirthdayProofApi, { isLoading: birthdayProofLoading }] =
    useUpdateBirthdayProofMutation();

  useEffect(() => {
    if (personalInfo) {
      const { authUser }: any = personalInfo && personalInfo;
      setFieldValue("username", authUser.username);
      setFieldValue("full_name", authUser.full_name);
      setFieldValue("language_id", authUser.language_id);
      setFieldValue("country_id", authUser.country_id);
      setFieldValue("state_id", authUser.state_id);
      setFieldValue("city_id", authUser.city_id);
      setFieldValue("email", authUser.email);
      setFieldValue("recovery_email", authUser.recovery_email);
      setFieldValue("phone_number", authUser.phone_number);
      setFieldValue("website", authUser.website);
      setFieldValue("paypal_account", authUser.paypal_account);
      setFieldValue("date_of_birth", authUser.date_of_birth);
      setFieldValue("upload_birthday_proof", authUser.birthday_image);
      setFieldValue("birthday_verify_status", authUser.birthday_verify_status);
      setFieldValue("description", authUser.description);
      setFieldValue(
        "two_factor_auth",
        authUser.two_factor_auth !== "" ? true : false
      );
    }
  }, [personalInfo]);

  useEffect(() => {
    if (personalInfo) {
      const { authUser }: any = personalInfo && personalInfo;
      if (
        authUser.country_id !== "" &&
        values.country_id &&
        values.country_id !== authUser.country_id
      ) {
        setFieldValue("state_id", "");
        setFieldValue("city_id", "");
      }
    }
  }, [values.country_id]);

  // when click on the image file input.
  const uploadFileHandler = (event: any, setFieldValue: any) => {
    setFieldValue("upload_birthday_proof", event.target.files[0]);
    setSelectedFile(URL.createObjectURL(event.target.files[0]));
    scrollModalToggle();
  };

  const scrollModalToggle = () => {
    if (scrollingModal === true) {
      setScrollingModal(false);
      setFieldValue("upload_birthday_proof", "");
      setFieldValue("uploadImage", "");
      setSelectedFile("");
    } else {
      setScrollingModal(true);
    }
  };

  const showModalToggle = () => setShowImageModal(!showImageModal);

  const submitBirthdayProofHandler = async () => {
    try {
      if (values.date_of_birth === "") {
        toast.error("Please select birth date.");
      } else if (values.upload_birthday_proof === "") {
        toast.error("Please select birthday proof.");
      } else {
        const formData = new FormData();
        formData.append("date_of_birth", values.date_of_birth);
        formData.append("upload_birthday_proof", values.upload_birthday_proof);
        const response = await updateBirthdayProofApi(formData).unwrap();
        const { success, message } = response;
        if (success === true) {
          toast.success(message);
          setScrollingModal(false);
          setFieldValue("upload_birthday_proof", "");
          setFieldValue("uploadImage", "");
          setSelectedFile("");
          getPersonalInfoData();
        } else {
          toast.error(message);
        }
      }
    } catch (error: any) {
      toast.error(error?.data?.message);
    }
  };

  if (birthdayProofLoading) return <Loading />;

  return (
    <>
      <Form className="form-wizard">
        <Card>
          <CardHeaderCommon
            title={`Edit Personal Information`}
            tagClass={"card-title mb-0"}
          />
          <CardBody>
            <Row>
              <Col md="4">
                <Label>
                  {Username}
                  <span className="font-danger">*</span>
                </Label>
                <p className="p-1">
                  <b>{values.username}</b>
                </p>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>
                    {"Full name"}
                    <span className="font-danger">*</span>
                  </Label>
                  <Field
                    type="text"
                    name="full_name"
                    placeholder={"Full name"}
                    value={values.full_name}
                    className={`form-control ${
                      submitErrors &&
                      `${errors.full_name ? "is-invalid" : "is-valid"}`
                    }`}
                  />
                  <ErrorMessage
                    name="full_name"
                    component="span"
                    className="invalid-feedback"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>
                    {`Language`} <span className="font-danger">*</span>
                  </Label>
                  <Language
                    name="language_id"
                    isMulti={false}
                    className={`${
                      submitErrors &&
                      `${errors.language_id ? "is-invalid" : "is-valid"}`
                    }`}
                  />
                  <ErrorMessage
                    name="language_id"
                    component="span"
                    className="invalid-feedback"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>
                    {"Country"}
                    <span className="font-danger">*</span>
                  </Label>
                  <Country
                    placeholder="Select"
                    name="country_id"
                    isMulti={false}
                    className={`${
                      submitErrors &&
                      `${errors.country_id ? "is-invalid" : "is-valid"}`
                    }`}
                  />
                  <ErrorMessage
                    name="country_id"
                    component="span"
                    className="invalid-feedback"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>{"State"}</Label>
                  <State
                    name="state_id"
                    isMulti={false}
                    setFieldValue={setFieldValue}
                    values={values}
                    countryId={values.country_id}
                    placeholder="Select"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>{"City"}</Label>
                  <City
                    name="city_id"
                    isMulti={false}
                    countryId={values.country_id}
                    stateId={values.state_id}
                    placeholder="Select"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>
                    {`Primary ${Emailaddress}`}
                    <span className="font-danger">*</span>
                  </Label>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    className={`form-control ${
                      submitErrors &&
                      `${errors.lastname ? "is-invalid" : "is-valid"}`
                    }`}
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="invalid-feedback"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>{`Recovery Email (Recommended)`}</Label>
                  <Field
                    name="recovery_email"
                    type="email"
                    placeholder="Recovery Email  (Recommended)"
                    value={values.recovery_email}
                    className={`form-control`}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>{`Phone Number`}</Label>
                  <Field
                    name="phone_number"
                    type="text"
                    placeholder="Phone Number"
                    value={values.phone_number}
                    className={`form-control`}
                  />
                  {/* <PhoneInput
                    enableSearch={true}
                    preferredCountries={["us"]}
                    value={values.phone_number}
                    onChange={() => updatePhonenumber(values.phone_number,setFieldValue)}
                  /> */}
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>{`Official Website`}</Label>
                  <Field
                    name="website"
                    type="text"
                    placeholder="Official Website"
                    value={values.website}
                    className={`form-control`}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>{`Paypal Account`}</Label>
                  <Field
                    name="paypal_account"
                    type="text"
                    placeholder="Paypal Account"
                    value={values.paypal_account}
                    className={`form-control`}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label>{`Description`}</Label>
                  <Field
                    as="textarea"
                    name="description"
                    placeholder="Description"
                    value={values.description}
                    className={`form-control`}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>{BirthDate}</Label>
                  <InputGroup className="flatpicker-calender">
                    <Field
                      type="date"
                      name="date_of_birth"
                      value={values.date_of_birth}
                      className={`form-control`}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <BirthdateProofContent
                  values={values}
                  setFieldValue={setFieldValue}
                  uploadFileHandler={uploadFileHandler}
                  showModalToggle={showModalToggle} 
                />
                <BirthdateProofModal
                  size={"lg"}
                  isOpen={scrollingModal}
                  toggle={scrollModalToggle}
                  footer={true}
                  title={"Birth date proof"}
                  onSubmitClick={submitBirthdayProofHandler}
                >
                  <img src={selectedFile} width={"750px"} height={"750px"} />
                </BirthdateProofModal>
                <BirthdateProofModal
                  size={"lg"}
                  isOpen={showImageModal}
                  toggle={showModalToggle}
                  title={"View Birthday Proof"}
                  footer={false}
                  onSubmitClick={submitBirthdayProofHandler}
                >
                  <img
                    src={values.upload_birthday_proof}
                    width={"750px"}
                    height={"750px"}
                  />
                </BirthdateProofModal>
              </Col>
              <Col md="12" className="pt-4">
                <div className="">
                  <Label className="col-form-label m-r-10" check>
                    Two-Step Authentication
                  </Label>
                  <br />
                  <div className="d-flex">
                    <div className="flex-shrink-0  text-end icon-state switch-outline">
                      <CommonSwitchSpan
                        name="two_factor_auth"
                        color={"primary"}
                        defaultChecked
                      />
                    </div>
                    <Label className="m-l-10" check></Label>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-left">
            <Btn
              color="primary"
              type="submit"
              onClick={() => setSubmitErrors(true)}
            >
              {UpdateProfile}
            </Btn>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
};

export default PersonalInformationForm;
