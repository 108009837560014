import GroupChatContainer from "../../../../Component/Application/Chat/GroupChat/GroupChat";

const PrivateCChat = () => {
  return (
    <div className="page-body">
      <GroupChatContainer />
    </div>
  );
};

export default PrivateCChat;
