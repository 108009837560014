import ContactsContainer from "../../../Component/Application/Contacts/Contacts";

const Contacts = () => {
  return (
    <div className="page-body">
      <ContactsContainer />
    </div>
  );
};

export default Contacts;
