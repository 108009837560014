import { CardHeader, Col, Form, Input, Label, Row, Button } from "reactstrap";
import { ChooseYourCurrency, InitialCost, ProductStocks, SellingPrice } from "../../../../../../utils/Constant";
import TypesOfProduct from "./TypesOfProduct";
import { useDispatch } from "react-redux"; 
import BranchSetupPrice from "./BranchSetupPrice";
import { scheduleFormInitialValue, scheduleFormValidationSchema, SchedulesFormValidationProp } from "../../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import { Formik } from "formik";
import { useState } from "react";
import CategoryTable from "./CategoryTable";
import { H3 } from "../../../../../../AbstractElements";
import { setFormValue } from "../../../../../../ReduxToolkit/Reducers/AddProductServiceSlice";

const ProductFour = () => {
  const dispatch = useDispatch();
  const [forms, setForms] = useState([scheduleFormInitialValue]);
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleAddBranch = () => {
    setForms([...forms, scheduleFormInitialValue]); // Add a new form
  };

  const handleRemoveBranch = (index: number) => {
    setForms(forms.filter((_, i) => i !== index)); // Remove the form at the specified index
  };

  const handleSubmit = (values: SchedulesFormValidationProp) => {
    console.log(values);
    setSubmitError(false);
    // You can dispatch or handle form values submission here
    dispatch(setFormValue({
      country: values.country,
      state: values.state,
      city: values.city
    }));
  };
  // const handleSubmit = (values, { setSubmitting }) => {
  //   e.preventDefault();
  //   console.log("Form submitted with values:", values);
  //   setSubmitting(false); // Stop the loading state
  //   alert("Form submitted successfully!");
  // };
  return (
    <>
      <CardHeader className="ps-0 pt-2">
        <H3>{'Branch and Price Setup (step 4)'}</H3>
      </CardHeader>
      <div className="sidebar-body">
        {forms.map((formValues, index) => (
          <div key={index} className="mt-3">
          <Formik
  initialValues={formValues}
  onSubmit={(values, { setSubmitting }) => {
    console.log("Submitting form with values:", values); // Log values to verify
    handleSubmit(values);
    setSubmitting(false); // Stop submitting state
    alert("Form submitted successfully!"); // Display success message
  }}
  validationSchema={scheduleFormValidationSchema}
>
  {({ errors, setFieldValue, handleSubmit, values, validateField }) => {
    console.log("Current errors:", errors); // Log errors if any
    console.log("Current form values:", values); // Log form values
    return (
      <Form onSubmit={handleSubmit}> {/* Directly use handleSubmit */}
        <BranchSetupPrice setFieldValue={setFieldValue} submitErrors={submitErrors} errors={errors} validateField={validateField} values={values} />
        <Button type="submit">Submit</Button>
      </Form>
    );
  }}
</Formik>
            <div className="d-flex gap-2 flex-wrap">
            <Button color="primary" onClick={handleAddBranch}>
          Add Another Branch
        </Button>
        <Button color="danger" onClick={() => handleRemoveBranch(index)}>
                    Remove Branch
                  </Button>
            </div>
          </div>
        ))}
      
      </div>
    </>
  );
};

export default ProductFour;
