import { Fragment, useEffect } from "react";
import { Image, LI, P, UL } from "../../../../../AbstractElements";
import { contactWrapperData } from "../../../../../Data/Application/Chat/PrivateChat";
import { dynamicImage } from "../../../../../Service"; 
import { useAppDispatch } from "../../../../../ReduxToolkit/Hooks";
import { setAllMembers, setSelectedUser } from "../../../../../ReduxToolkit/Reducers/ChatSlice";

interface ContactWrapperProps {
  ToogleContactList: () => void; // Correct prop type definition
}

const ContactWrapper: React.FC<ContactWrapperProps> = ({ ToogleContactList }) => {
  const dispatch = useAppDispatch();

  const handleSelectContact = (item) => { 
    const user = JSON.stringify(item);
    console.log(user);
    ToogleContactList();
    // Dispatch the selected contact's data to the Redux store
    dispatch(setSelectedUser(JSON.stringify(item)));
  };

  useEffect(() => { 
    const fetchedMembers = contactWrapperData;  
    dispatch(setAllMembers(fetchedMembers));
  }, [dispatch]);

  return (
    <div className="contact-wrapper custom-scrollbar">
      {contactWrapperData.map((data, i) => (
        <Fragment key={i}>
          <P>{data.mainLetter}</P>
          {data.contactData.map((item, i) => (
            <UL
              className="border-0 simple-list"
              key={i}
            >
              <LI className="common-space" onClick={() => handleSelectContact(item)}>
                <div className="chat-time">
                  {item.image && (
                    <Image
                      className="img-fluid rounded-circle"
                      src={dynamicImage(item.image)}
                      alt="user"
                    />
                  )}
                  {item.imageUser && (
                    <div className={`custom-name bg-light-${item.color}`}>
                      <P className={`txt-${item.color} f-w-500`}>
                        {item.imageUser}
                      </P>
                    </div>
                  )}
                  <div>
                    <span className="f-w-600">{item.name}</span>
                    <P>{item.phoneNumber}</P>
                  </div>
                </div> 
              </LI>
            </UL>
          ))}
        </Fragment>
      ))}
    </div>
  );
};

export default ContactWrapper;
