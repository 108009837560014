import { H3 } from "../../../../AbstractElements";
import { Card, CardBody } from "reactstrap";
import { General } from "../../../../utils/Constant";
import GeneralInformation from "./GeneralInformation";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import {
  myReusmesFormSchema,
  myResumesFormInitialValue,
  MyResumesValidationProp,
} from "../../../../Data/App/AccountSettings/MyResumesAddNew";
import {
  useSaveResumeMutation,
  useUpdateResumeMutation,
  useGetResumeDataMutation,
} from "../../../../ReduxToolkit/Reducers/Resume/resumeApiSlice";
import { toast } from "react-toastify";
import Loading from "../../../Loading/Loading";
const AddEditResume = (props: any ) => {

  const { resumeData, getResumeData } = props;

  const [submitErrors, setSubmitError] = useState<boolean>(false); 

  const [saveResumeApiCall, { isLoading: saveLoading }] =
    useSaveResumeMutation();
  const [updateResumeApiCall, { isLoading: updateLoading }] =
    useUpdateResumeMutation(); 

  

  const handleSubmit = async (values: any) => {
    try {  
      const formData = new FormData(); 
      formData.append("id", values.id); 
      formData.append("display_resume_on_media_ceneter", values.display_resume_on_media_ceneter);
      formData.append("subcategories", values.subcategories);
      formData.append("job_title", values.job_title);
      formData.append("objective", values.objective);
      formData.append("summery", values.summery);
      formData.append("education", values.education);
      formData.append("training", values.training);
      formData.append("languages", values.languages);
      formData.append("skills", values.skills);
      formData.append("hobbies_interests", values.hobbies_interests);
      values.uploadImage && formData.append("recommendation", values.recommendation);
      values.work_history && values.work_history.map((item: any, index: number) => {
        formData.append(`work_history[${index}][name]`, item.name);
        formData.append(`work_history[${index}][description]`, item.description);
      });

      let response;
      if (values.id > 0) {
        response = await updateResumeApiCall(formData).unwrap();
      } else {
        response = await saveResumeApiCall(formData).unwrap();
      }

      const { message, success } = response;
      if (success === true) {
        toast.success(message);
        getResumeData();
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error.data.message || "Something went wrong.");
    } 
    setSubmitError(false);
  };

  if (saveLoading || updateLoading) return <Loading />;

  return (
    <Card>
      <div className="job-search">
        <CardBody className="pb-0">
          <div className="job-description">
            <H3 className="mb-0">{General}</H3>
            <Formik
              initialValues={myResumesFormInitialValue}
              onSubmit={handleSubmit}
              validationSchema={myReusmesFormSchema}
            >
              {({ errors, values, setFieldValue }) => (
                <GeneralInformation
                  submitErrors={submitErrors}
                  setSubmitError={setSubmitError}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  resumeData={resumeData}
                />
              )}
            </Formik>
          </div>
        </CardBody>
      </div>
    </Card>
  );
};

export default AddEditResume;
