import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H6 } from "../../AbstractElements";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import { PrivateMenuNames } from "../../Data/LayoutData/SidebarData";

function ChildMenu(props: any) {
  const { subItem, isDropdownActive, handleItemClick, menuNameMakeAsKey, websiteSettingsData } = props;

  const { userInfo } = useAppSelector((state) => state.auth);

  // Filter all menu base on logges in user and guest user.
  let filterChildMenu = userInfo
    ? subItem.Items
    : subItem.Items &&
      subItem.Items.filter(
        (item: any) => !PrivateMenuNames.includes(item.subtitle)
      );

  const childrenMenuAsKeyName =
  subItem.Items &&
  subItem.Items.map((item: any) => { 
      return item.subtitle && menuNameMakeAsKey(item.subtitle);
    });

  // get the filter disable.
  const disableChildrenMenu =
    childrenMenuAsKeyName &&
    childrenMenuAsKeyName.filter((item: any) => {
      return websiteSettingsData.frontend_modules[item] === "off";
    });

  // Disable menu remove from all menu object.
  filterChildMenu =
  subItem.Items &&
  subItem.Items.filter((item: any) => {
      return item.subtitle && !disableChildrenMenu.includes(menuNameMakeAsKey(item.subtitle));
    }); 

  return (
    <ul className="submenu">
      {filterChildMenu.map((label: any, ind: number) => (
        <li key={ind}>
          <Link
            to={label.path}
            className={`py-0 d-inline-block d-flex align-items-start ${
              isDropdownActive(label) ? "active" : ""
            }`}
            onClick={handleItemClick}
          >
            {label.icon && (
              <FontAwesomeIcon icon={label.icon} className="item-icon" />
            )}
            <div className="ms-2">
              <H6 className="fw-normal">{label.subtitle}</H6>
              <p className="tools_description text-muted mb-0">
                {label.description}
              </p>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default ChildMenu;
