import { apiSlice } from "../../apiSlice";
import { PHOTO_STOCK_UPLOAD, SOCIAL_MEDIA_UPLOAD } from "../../../Api";

interface SuccessDataProps {
    data: any;
    message: string;
    success: boolean;
}

export const photoUploadApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        uploadPhoto: builder.mutation< any, { file: File; albumId: string; attribution: string } >({
            query: ({ file, albumId, attribution }) => {
                const tokenData = JSON.parse(
                    localStorage.getItem("token") || "{}"
                );
                const token = tokenData?.token;
                console.log(file, albumId, attribution);

                const formData = new FormData();
                formData.append("files[]", file); // Append file
                formData.append("album_id", albumId); // Append album_id
                formData.append("attribution_required", attribution); // Append attribution_required
                 

                return {
                    url: SOCIAL_MEDIA_UPLOAD,
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
            },
        }),
        PhotostockuploadPhoto: builder.mutation< any, { file: File; albumId: string; attribution: string;price:string;license_type: string } >({
            query: ({ file, albumId, attribution,price,license_type  }) => {
                const tokenData = JSON.parse(
                    localStorage.getItem("token") || "{}"
                );
                const token = tokenData?.token;
                console.log(file, albumId, attribution);

                const formData = new FormData();
                formData.append("files[]", file); // Append file
                formData.append("album_id", albumId); // Append album_id
                formData.append("attribution_required", attribution); // Append attribution_required
                formData.append("price", price); // Append attribution_required
                formData.append("license_type", license_type); // Append attribution_required 

                return {
                    url: PHOTO_STOCK_UPLOAD,
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
            },
        }),
    }),
});

export const { useUploadPhotoMutation ,usePhotostockuploadPhotoMutation,} = photoUploadApiSlice;
