import { Row, Col, Label } from "reactstrap";
import { ErrorMessage, Field } from "formik";
import TextCounter from "../../../CommonInput/TextCounter";
import TitleInput from "../../../CommonInput/TitleInput";

const NewAddon = (props: any) => {

  const { errors, submitErrors, index, values } = props;  
  const { addons } = errors; 
  const addonError = addons?.length && addons[index];
  const currentValue = values?.addons?.length && values?.addons[index];

  return (
    <>
      <Row>
        <Col sm="4" className="mb-3">
          
           <TitleInput title='Addon Option' values={values.addOnOption} name="addOnOption"  placeholder="Addon Option"/>  

        </Col> 
        <Col sm="4" className="mb-3">
          <Label check>
            Addon Quantity <span className="txt-danger">*</span>
          </Label>
          <Field 
            name={`addons.${index}.addOnQuantity`}
            type="text"
            placeholder="Addon Quantity"
            className={`form-control ${
              submitErrors && `${addonError?.addOnQuantity ? "is-invalid" : "is-valid"}`
            }`}
          />
          <ErrorMessage
            name={`addons.${index}.addOnQuantity`}
            component="span"
            className="invalid-feedback"
          />
        </Col>
      </Row>
    </>
  );
};

export default NewAddon;
