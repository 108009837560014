import { Link } from "react-router-dom";
import Image from "../../CommonElements/Media"; 
import { SVG } from "../../AbstractElements";
import { setToggleSidebarPopup } from "../../ReduxToolkit/Reducers/LayoutSlice";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";

const HeaderLogo = () => {
  const dispatch = useAppDispatch();
  const { toggleSidebarPopup }: any = useAppSelector((state) => state.layout);
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);

  return (
    <div className="header-logo-wrapper col-auto p-0">
      <div className="logo-wrapper">
        <Link to={`${process.env.PUBLIC_URL}/dashboard/user-dashboard`}>
          {websiteSettingsData && websiteSettingsData.logo && (
            <Image
              className="img-fluid for-light"
              src={websiteSettingsData.logo}
              alt="logo"
            />
          )}
          {websiteSettingsData && websiteSettingsData.logo_light && (
            <Image
              className="img-fluid for-dark"
              src={websiteSettingsData.logo_light}
              alt="logo"
            />
          )}
        </Link>
      </div>
      <div
        className="toggle-sidebar"
        onClick={() => dispatch(setToggleSidebarPopup(!toggleSidebarPopup))}
      >
        <SVG className="sidebar-toggle" iconId="stroke-animation" />
      </div>
    </div>
  );
};

export default HeaderLogo;
