import { useEffect, useState } from "react";
import { InputGroup } from "reactstrap";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { Field } from "formik";
import moment from 'moment'

export function formatDate(values: string){ 
    if(values !== '' && values !== undefined){
        const updatedValue = values.split(',').map((item: any) => {
            return new DateObject().set({ day: item })
        }); 
        return updatedValue;
    }
    return null;
}

// Props : name, setFieldValue, placeholder, className
function MultiDatePicker(props: any) {
  const [value, setValue] = useState<any>(formatDate(props.value));

  const { setFieldValue, name , returnDateList, setSelectedDateList} = props;

  // useEffect(() => {
  //   let updatedValue = "";
  //   let newValues:any[] = [];
  //   let tempdate:any[] =[];
  //   if (value?.length) {
  //     updatedValue = value.map((item: any) => {       
  //       tempdate.push(item.day+'-'+item.month+'-'+item.year);
  //     }).toString();      
  //   }
  //   if(returnDateList){
  //   let sortedCars = tempdate.sort((a, b) => new Date(...a.split('-').reverse()) - new Date(...b.split('-').reverse()));
  //   sortedCars.map((date_item, index) =>{
  //     let dates = date_item.split('-');
  //     let moment_obj = moment(new Date(dates[2], dates[1], dates[0]));
  //     let cur_d = moment_obj.subtract(1, "month").format("D MMMM Y, dddd").toString();
  //     newValues.push(cur_d);
  //   })
  //   console.log("sorted- dates:::==>")
  //   console.log(JSON.stringify(sortedCars))
  //   setFieldValue(name, updatedValue);
  //   console.log(newValues);
  //   setSelectedDateList(newValues);
  //   }
  // }, [value]);
  useEffect(() => {
    let updatedValue = "";
    let newValues: any[] = [];
    let tempdate: any[] = [];
  
    if (value?.length) {
      value.forEach((item: any) => {
        tempdate.push(`${item.day}-${item.month}-${item.year}`);
      });
      updatedValue = tempdate.toString(); // moved here to ensure it's set properly
    }
  
    if (returnDateList) {
      let sortedCars = tempdate.sort((a, b) => 
        new Date(...a.split('-').reverse()).getTime() - new Date(...b.split('-').reverse()).getTime()
      );
      
      sortedCars.forEach((date_item, index) => {
        let dates = date_item.split('-');
        let moment_obj = moment(new Date(dates[2], dates[1] - 1, dates[0])); // corrected month index (0-based)
        let cur_d = moment_obj.format("D MMMM Y, dddd");
        newValues.push(cur_d);
      });
  
      console.log("sorted- dates:::==>");
      console.log(JSON.stringify(sortedCars));
      setFieldValue(name, updatedValue); // ensure the field is updated correctly
      console.log(newValues);
      setSelectedDateList(newValues); // update the selected date list
    }
  }, [value, name, returnDateList, setFieldValue, setSelectedDateList]);
  
  return (
    <InputGroup className="flatpicker-calender">
      <DatePicker
        inputClass={`form-control ${props?.className || ''}`}
        monthYearSeparator="::"
        multiple
        value={value}
        placeholder={props?.placeholder || 'Select multi date'} 
        onChange={setValue}
        minDate={new Date()}
        format={`DD-MM-YYYY`}
      />
      <Field type="hidden" name={name} />
    </InputGroup>
  );
}

export default MultiDatePicker;
