import { apiSlice } from "../../apiSlice";
import { GET_LANGUAGES } from "../../../Api";

interface Language {
  data: any;
  message: string;
  success: boolean;
}

export const languageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLanguageData: builder.query<Language, void>({
      query: () => ({
        url: GET_LANGUAGES,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetLanguageDataQuery } = languageApiSlice;
