import { Card, CardHeader, Col, Row } from "reactstrap";
import { referralactions } from "../../../Application/Users/MyReferralsContainer/MyReferralsContainer";
import { Link, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { useState } from "react";



const PageHeader = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Function to determine if the current path matches or starts with the link's path
  const isActive = (path) => {
    return currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`);
  };
  const { mobileView } = useAppSelector((state) => state.layout);

  const [isOpen, setIsOpen] = useState(false); 
  return (
    <Card className="earning-card mb-0">
      <CardHeader className="py-0 pb-1 ps-0">
        
<Row>
          {mobileView ? (
                  <>
                      
              <Col md="12">
                  {isOpen ? (
                      <IoClose size='2em' onClick={() => setIsOpen(false)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                  ) : (
                      <HiOutlineMenuAlt2 size='2em' onClick={() => setIsOpen(true)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                  )}
              </Col>
              <Col md="12">
                  {isOpen ? (
                      <>
                          {referralactions.map((item, index) => { 
                              const activeClass = isActive(item.path) ? 'sub-active' : '';

                              return (
                                <Link
            to={`${process.env.PUBLIC_URL}/${item.path}`}
            key={index}
            className={`border rounded d-inline-block p-2 m-1 border-primary ${activeClass}`}
          >
            <span>
              {item.title} {item.counter && item.counter >= 0 ? `(${item.counter})` : ''}
            </span>
          </Link>
                              );
                          })}
                      </>
                  ) : ("")}
              </Col>
              </>
          ) : (
                  <Col md="12">
                      {referralactions.map((item, index) =>{
                          
                          const activeClass = isActive(item.path) ? 'sub-active' : ''; 
                          return (
                      
                            <Link
                            to={`${process.env.PUBLIC_URL}/${item.path}`}
                            key={index}
                            className={`border rounded d-inline-block p-2 m-1 border-primary ${activeClass}`}
                          >
                            <span>
                              {item.title} {item.counter && item.counter >= 0 ? `(${item.counter})` : ''}
                            </span>
                          </Link>
                          )
                      }
                      )}
                  </Col>
              )}
          </Row>
        </CardHeader>
    </Card>
  );
};

export default PageHeader;

 