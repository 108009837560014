import { CardHeader, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
    ManageBranches,
    DeliveryWorkersTitle,
} from "../../../../utils/Constant";
import DeliveryWorkersMain from "../../../../Component/Tools/ManageOnlineStore/DeliveryWorkers/DeliveryWorkersMain";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import DeliverySubMenu from "../DeliverySubMenu/DeliverySubMenu";

const AddDeliveryWorkers = () => {
    return (
        <div className="page-body">
            <Breadcrumbs
                mainTitle={`${DeliveryWorkersTitle} (Manage Online Store)`}
                parent={ManageBranches}
            />
            <Container fluid>
                <CardHeader className="pb-0 pt-0  ">
                    <ManageOnlineSubMenu />
                </CardHeader>
            </Container>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <div>
                            <DeliverySubMenu />
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="  p-4" fluid> 
                <DeliveryWorkersMain />
            </Container>
        </div>
    );
};

export default AddDeliveryWorkers;
