import React, { useState } from "react";
import CommonModal from "../Ui-Kits/Modal/Common/CommonModal";
import { Btn, H3, SVG } from "../../AbstractElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { X } from "react-feather";

export interface PopupModal {
  children?: React.ReactNode;
  title: string;
  isOpen: boolean;
  size: string;
  backdrop?: boolean;
  isClose?: boolean;
  openModalToggle: () => void;
  CommonFooter?: boolean;
}

const handleNext = () => {};

const handlePrevious = () => {};

const Popup: React.FC<PopupModal> = ({
  title,
  isOpen,
  size,
  backdrop,
  isClose,
  CommonFooter,
  openModalToggle,
  children,
}) => { 
  return (
    <CommonModal
      isOpen={isOpen}
      toggle={openModalToggle}
      modalBodyClassName="px-3"
      size={size}
      backdrop={backdrop}
    >
      <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
        <div className="modal-header  ">
          <H3 className="w-100 text-left"> {title}</H3>
          <div onClick={openModalToggle} className="search-close-icon">
            <X />
          </div>
          {isClose ?? (
            <div className="w-10 justify-content-end" onClick={openModalToggle}>
              {/* <FontAwesomeIcon icon={fas.faClose} size={`xl`} className='pe-2 pull-right'/> */}
            </div>
          )}
        </div>
        <div style={{ height: "auto", overflow: "auto" }}>{children}</div>
      </div>
      {CommonFooter && (
        <div className="modal-footer ">
          <div className="next_previous position-relative pt-0">
            <Btn onClick={handlePrevious} className="border">
              <div className="d-flex align-items-center gap-sm-2 gap-1">
                Previous <SVG iconId=" back-arrow" />
              </div>
            </Btn>
            <Btn onClick={handleNext} className="border">
              <div className="d-flex align-items-center gap-sm-2 gap-1">
                Next <SVG iconId="front-arrow" />
              </div>
            </Btn>
          </div>
        </div>
      )}
    </CommonModal>
  );
};

export default Popup;
