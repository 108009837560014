import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import ProductListFilterHeader from "./ProductListFilterHeader";
import CollapseFilterData from "./CollapseFilterData";
import { useMemo, useState } from "react";
import {
  ManageStore,
  ProductServiceList,
  SearchTableButton,
} from "../../../../utils/Constant";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  productListTableData,
  productListTableDataColumn,
} from "../../../../Data/Application/Ecommerce/ProductList";
import { BranchsAction, ManageOnlineStore } from "../../../../Data/Tools/ManageContents/ManageContentButtons";
import { Link } from "react-router-dom";
import BranchSubMenu from "../../../../Pages/Tools/ManageOnlineStore/ManageBranches/BranchSubMenu/BranchSubMenu";

const ProductListContainer = () => {
  const [filterText, setFilterText] = useState("");

  const filteredItems = productListTableData.filter(
    (item) =>
      item.category &&
      item.category.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="dataTables_filter d-flex align-items-center">
        <Label className="me-2">{SearchTableButton}:</Label>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilterText(e.target.value)
          }
          type="search"
          value={filterText}
        />
      </div>
    );
  }, [filterText]);

  return (
    <>
      <Breadcrumbs mainTitle={ProductServiceList} parent={ManageStore} />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
            <CardHeader className='pb-0'> 
                <div>
                {ManageOnlineStore.map((item, index)=>{
                  return(
                  <>
                    <Link to={`${process.env.PUBLIC_URL}/${item.path}`} key={index}><span className='border p-2 m-1'>{item.title} {item.total_contest >= 0 ? `(${item.total_contest})`:``}</span></Link>
                  </>)
                })}
                </div>
              </CardHeader>
              <CardBody>
                <div className="list-product-header">
                <div className='d-flex justify-content-between'>
                  <div>
                    <BranchSubMenu/>
                  </div>
                  <ProductListFilterHeader />
                    </div>
                  <CollapseFilterData />
                </div>
                <div className="list-product">
                  <div className="table-responsive">
                    <DataTable
                      data={filteredItems}
                      columns={productListTableDataColumn}
                      striped
                      highlightOnHover
                      pagination
                      selectableRows
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProductListContainer;
