import { apiSlice } from "../../apiSlice";
import { GROUP_MEMBER_SAVE } from "../../../Api";

interface SuccessDataProps {
  data: any;
  message: string;
  success: boolean;
}

export const memberApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({ 
    saveGroupMemberData: builder.mutation<SuccessDataProps, any>({
      query: (data) => ({
        url: GROUP_MEMBER_SAVE,
        method: "POST",
        body: data
      }),
    }),
  }),
});

export const { useSaveGroupMemberDataMutation } = memberApiSlice;
