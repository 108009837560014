import { useEffect} from "react";
import { Container } from 'reactstrap'
import UserHeader from '../../../../Component/App/MyMediaCenter/About/UserHeader'
import ExternalLinksData from '../../../../Component/App/MyMediaCenter/ExternalLinksData/ExternalLinksData'
import { Parent1Web } from "../../../../utils/Constant";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";

const ExternalLinks = () => {
  useEffect(()=>{
    var scroll_top  =  window.innerWidth > 415 ? 360 : 210;
    window.scrollTo({ top: scroll_top, behavior: "smooth" });
  });
  return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`External Links`} parent={Parent1Web} />
      <UserHeader/>
      <Container fluid className="search-page">
          <ExternalLinksData/>
      </Container>
    </div>
  )
}

export default ExternalLinks