import { ErrorMessage, Field } from "formik";
import { Col, Label } from "reactstrap";
// import { PhoneInput } from 'react-international-phone';
// import 'react-international-phone/style.css';
import { useState } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function PhoneField(props: any) {
  const { submitErrors, errors, item, index } = props;
  const [phone, setPhone] = useState('');
  const { phones } = errors;
  const phoneError = phones?.length && phones[index];

  return (
    <Col sm={item !== '1' ? '3' : '4'} className="mb-3">
      <Label check>Public Phone Number</Label>
      {/* <Field
        name={`phones.${index}.phone`}
        type="text"
        placeholder="Phone Number"
        className={`form-control ${
          submitErrors && `${phoneError?.phone ? "is-invalid" : "is-valid"}`
        }`}
      /> */}
      <PhoneInput
        country="us"
        value={phone}
        enableSearch={true}
        onChange={(phone) => setPhone(phone)}
        style={{width:'100%'}}
        />
      <ErrorMessage
        name={`phones.${index}.phone`}
        component="span"
        className="invalid-feedback"
      />
    </Col>
  );
}

export default PhoneField;
