import AddPostContainer from "../../../../Component/Miscellaneous/Blog/AddPost/AddPost";

const AddPost = () => {
  return (
    <div className="page-body">
      <AddPostContainer />
    </div>
  );
};

export default AddPost;
