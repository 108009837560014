import InvoiceFiveContainer from "../../../../../Component/Application/Ecommerce/Invoices/Invoice-5/Invoice-5";

const InvoiceFive = () => {
  return (
    <div className="page-body">
      <InvoiceFiveContainer />
    </div>
  );
};

export default InvoiceFive;
