import { Row, Col, Label, Input } from "reactstrap";
import { Btn } from "../../AbstractElements";
import { ErrorMessage, Field, Form } from "formik";
import TextCounter from "../CommonInput/TextCounter";
import Groups from "../CommonInput/Groups";
import TagsInput from "../CommonInput/TagsInput";

const AddGroupMemberForm = (props: any) => {
  const { errors, submitErrors, setSubmitError, values, setFieldValue } = props;
  return (
    <Form className="form-wizard">
      <Row>
        <Col sm="4" className="mb-3"> 
          <Groups
            title="Groups"
            className={`${
              submitErrors &&
              `${errors.group_id ? "is-invalid" : "is-valid"}`
            }`}
            isRequired={true}
            name="group_id"
            isMulti={false}
          />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>
            Members <span className="txt-danger">*</span>
          </Label>
          <TagsInput
            name="group_members"
            placeholder="Enter Usernames"
            className={`form-control ${
              submitErrors &&
              `${errors.group_members ? "is-invalid" : "is-valid"}`
            }`}
            setFieldValue={setFieldValue}
          />
          <ErrorMessage
              name="group_members"
              component="span"
              className="invalid-feedback"
            />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>Invitation Note</Label>
          <Field
            type="textarea"
            name="invitation_note_group"
            placeholder="Invitation Note"
            className="form-control"
            maxLength="140"
          />
          <TextCounter
            length={values?.invitation_note_group?.length}
            totalLength="140"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="auto" className="mb-3">
          <Btn
            color="primary me-3"
            type="submit"
            onClick={() => setSubmitError(true)}
          >
            <i className="icofont icofont-users"></i> Add Members
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default AddGroupMemberForm;
