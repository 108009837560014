import React, { useState } from "react";
import { Card, Col, Row, CardHeader, Container } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { PostLinks } from "../../../Data/Post/PostSubMenu";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import { IoClose } from "react-icons/io5";
import { HiOutlineMenuAlt2 } from "react-icons/hi";

const PostsubMenu = () => {
    const [activeTab, setActiveTab] = useState("1");
    const location = useLocation();

    // Function to determine if the path is active
    const isActive = (path) => {
        return location.pathname.startsWith(
            `${process.env.PUBLIC_URL}/${path}`
        );
    };

    const [isOpen, setIsOpen] = useState(false);
    const { mobileView } = useAppSelector((state) => state.layout);

    return (
        <div className="email-wrap bookmark-wrap pb-1">
            <Container fluid>
                <Row>
                    {mobileView ? (
                        <>
                            <Col md="12">
                                {isOpen ? (
                                    <IoClose
                                        size="2em"
                                        onClick={() => setIsOpen(false)}
                                        className="p-1 rounded-circle"
                                        style={{ backgroundColor: "#b6c8e7" }}
                                    />
                                ) : (
                                    <HiOutlineMenuAlt2
                                        size="2em"
                                        onClick={() => setIsOpen(true)}
                                        className="p-1 rounded-circle"
                                        style={{ backgroundColor: "#b6c8e7" }}
                                    />
                                )}
                            </Col>
                            <Col md="12">
                                {isOpen ? (
                                    <>
                                        {PostLinks.map((item, index) => {
                                            const linkPath = `${process.env.PUBLIC_URL}/${item.path}`;
                                            const active = isActive(item.path);
                                            return (
                                                <Link
                                                    to={linkPath}
                                                    key={index}
                                                    className={`border border-primary d-inline-block p-2 m-1 rounded ${
                                                        active
                                                            ? "sub-active"
                                                            : ""
                                                    }`}>
                                                    {item.title}{" "}
                                                    {item.total_contest >= 0
                                                        ? `(${item.total_contest})`
                                                        : ``}
                                                </Link>
                                            );
                                        })}
                                    </>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </>
                    ) : (
                        <Col md="12">
                            {PostLinks.map((item, index) => {
                                const linkPath = `${process.env.PUBLIC_URL}/${item.path}`;
                                const active = isActive(item.path);
                                return (
                                    <Link
                                        to={linkPath}
                                        key={index}
                                        className={`border border-primary d-inline-block p-2 m-1 rounded ${
                                            active ? "sub-active" : ""
                                        }`}>
                                        {item.title}{" "}
                                        {item.total_contest >= 0
                                            ? `(${item.total_contest})`
                                            : ``}
                                    </Link>
                                );
                            })}
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default PostsubMenu;
