import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { BlogDiscardButton, BlogPostButton, Description, Keywords, PostTitlePlaceholder, Subject, To } from '../../../../utils/Constant'
import RadioTypeForm from './RadioTypeForm'
import { Typeahead } from "react-bootstrap-typeahead";
import { postTypeData } from '../../../../Data/Miscellaneous/Blog/BlogDetails';
import TagsInput from '../../../CommonInput/TagsInput';
import { ErrorMessage, Field, Formik } from 'formik';
import { manageAlbumFormValidationSchema } from '../../../../Data/Tools/ManageContents/FormControlsValidation';
import { Btn, H3 } from '../../../../AbstractElements';
import { useState } from 'react';
import CommonModal from '../../../Ui-Kits/Modal/Common/CommonModal';
import InviteContactMain from '../../../Communication/InviteContactMain';
import GroupMain from '../../../Communication/GroupMain';
import TextCounter from '../../../CommonInput/TextCounter';
import { createPostInitialValue, createPostSchema } from '../../../../Data/Post/CreatePostValidationSchema';


export const tags =["text","rest","pest"];

const FormPost = () => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const setFieldValue =() =>{}
  const handleSubmit =() =>{

  }
  const [openToModal, setOpenToModal] = useState(false);
  const openToModalToggle = () => { setOpenToModal(!openToModal);};

  const [openFromModal, setOpenFromModal] = useState(false);
  const openFromModalToggle = () => setOpenFromModal(!openFromModal);
  return (
    // <Form className="needs-validation">
    //   <Row>
    //     <Col sm="12">
    //       <FormGroup>
    //         <Label check>{Subject}:</Label>
    //         <Input type="text" placeholder={PostTitlePlaceholder} />
    //       </FormGroup>
    //       <RadioTypeForm />
    //       <FormGroup>
    //         <div className="col-form-Label">{PostCategory}: 
    //           {/* <Typeahead id="multiple-typeahead" className="mt-2" defaultSelected={postTypeData} labelKey="name" multiple options={postTypeData} placeholder={PostTypePlaceholder} /> */}
    //           <TagsInput name="keywords" placeholder="Add keywords" setFieldValue={setFieldValue}/>
    //         </div>
    //       </FormGroup>
    //       <div className="email-wrapper">
    //         <div className="theme-form">
    //           <FormGroup>
    //             <Label className='w-100' check>{PostContent}:</Label>
    //             <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} />
    //           </FormGroup>
    //         </div>
    //       </div>
    //     </Col>
    //   </Row>
    // </Form>
    <Formik
      initialValues = {createPostInitialValue}
      onSubmit={handleSubmit}
      validationSchema={createPostSchema}>
      {({ errors, values, setFieldValue }) => (
      <>
      <Form className="needs-validation">
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label check>{To} <span className="txt-danger">*</span>:</Label> 
              
              <CommonModal
                isOpen={openToModal}
                toggle={openToModalToggle}
                heading="Invite a Contact"
                size="lg"
              >
                <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                  <H3 className="modal-header justify-content-center border-0">
                    Invite a Contact
                  </H3>
                  <InviteContactMain />
                </div>
              </CommonModal>
              <CommonModal
                isOpen={openFromModal}
                toggle={openFromModalToggle}
                sizeTitle="Add a Group" 
                size="lg"
              >
                <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                  {/* <H3 className="modal-header justify-content-center border-0">
                    Add a Group
                  </H3> */}
                  <GroupMain />
                </div>
              </CommonModal>
              <FormGroup>
                                                        <div className=" col-form-Label"> 
                                                             <FormGroup className="mt-2">
                                                             <Field
                                                            type="text"
                                                            name="subject"
                                                             placeholder="Enter Usernames"
                                                            className={`form-control ${
                                                                submitErrors &&
                                                                `${
                                                                    errors.subject
                                                                        ? "is-invalid"
                                                                        : "is-valid"
                                                                }`
                                                            }`}
                                                        />
                                                        <span className="text-mutes small">* Press Enter or dot to add other Usernames</span>
                                                             </FormGroup>
                                                        </div>
                                                    </FormGroup>
              <Typeahead id="multiple-typeahead" className="mt-2" labelKey="name" multiple options={postTypeData} placeholder={`Select Groups`} />
                                                    <span>Create a New Group</span> 
                                                    <span
                                                            className="mt-1 btn btn-primary px-3 mx-2"
                                                            onClick={
                                                                openFromModalToggle
                                                            }>
                                                            
                                                            <i className="icofont icofont-users"></i>
                                                        </span>
            </FormGroup>
            <FormGroup>
              <Label check>{Subject} <span className="txt-danger">*</span>:</Label>
              <Field 
                type="text" 
                name="subject" 
                placeholder={`Subject`} 
                className={`form-control ${submitErrors && `${errors.subject ? "is-invalid" : "is-valid"}`}`}
              />
              <TextCounter length={values?.subject?.length} totalLength="140" />
              <ErrorMessage
                  name="subject"
                  component="span"
                  className="invalid-feedback"
              />  
            </FormGroup>                              
            <RadioTypeForm submitErrors={submitErrors} errors={errors} /> 
            
            <FormGroup>
              <div className="col-form-Label">{Keywords}: 
                <TagsInput name="keywords" placeholder="Add keywords" setFieldValue={setFieldValue}/>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      {/* <DropzoneFile /> */}
      <div className="btn-showcase">
        <Btn color="primary" type="submit"  onClick={() => setSubmitError(true)}>{`Save`}</Btn>
        {/* <Btn color="light" type="reset">
          {BlogDiscardButton}
        </Btn> */}
      </div>
      </>
      )}
</Formik>
  )
}

export default FormPost