import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import { Image } from "../../../AbstractElements";
import { dynamicImage } from "../../../Service";

function AdSense({listingAds}) {
  const { websiteSettingsData }: any = useAppSelector((state) => state.common);

  return (
    <>
      {websiteSettingsData && websiteSettingsData.google_ads_index === "on" && (
          <div className="ad-container">
         {listingAds ==1 ?<div className="ad">
            <div className="ad-content text-center">
              <img src={listingAds ? 'https://via.placeholder.com/500x600': 'https://via.placeholder.com/300x250'}  alt="Ad 1" className="ad-image img-fluid" />
              <p className="ad-text text-black">Ad 1: Special Offer!</p>
            </div>
          </div> :
          <>
          <div className="ad">
            <div className="ad-content text-center">
              <img src={listingAds ? 'https://via.placeholder.com/500x600': 'https://via.placeholder.com/300x250'}  alt="Ad 1" className="ad-image img-fluid" />
              <p className="ad-text text-black">Ad 1: Special Offer!</p>
            </div>
          </div>
          
          <div className="ad">
            <div className="ad-content text-center">
              <img src="https://via.placeholder.com/300x250" alt="Ad 2" className="ad-image img-fluid" />
              <p className="ad-text text-black">Ad 2: Buy One Get One Free!</p>
            </div>
          </div>
          </>
          }
        </div>
      )}
    </>
  );
}

export default AdSense;
