import { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Label,
    Modal,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Table,
} from "reactstrap";
import {
    Btn,
    FeatherIcons,
    H3,
    H4,
    H5,
    Image,
    LI,
    P,
    SVG,
    UL,
} from "../../../AbstractElements";
import { ProductInterface } from "./Product";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import { dynamicImage } from "../../../Service";
import { ProductItemInterface } from "../../../Types/Application/Ecommerce/Product";
import ProductDetails from "./ProductDetails";
import { Link } from "react-router-dom";
import { ServiceType } from "../../../Types/Application/Ecommerce/Ecommerce";
import { Href, PostContent } from "../../../utils/Constant";
import { ErrorMessage, Field, Formik } from "formik";
import {
    specialParametersFormInitialValue,
    specialParametersFormValidationSchema,
    surveyAndQuizzesFormInitialValue,
    SurveyAndQuizzesFormValidationProp,
    surveyAndQuizzesFormValidationSchema,
} from "../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import TextCounter from "../../CommonInput/TextCounter";
import CommonSwitchSpan from "../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan";
import ExamModal from "./ExamModal";
import AdSense from "../../WebsiteSettings/AdSense/Index";

export const servicesData: ServiceType[] = [
    {
        icon: "Truck",
        title: "Free Shipping",
        subtitle: "Free Shipping World Wide",
    },
    {
        icon: "Clock",
        title: "24 X 7 Service",
        subtitle: "Online Service For New Customer",
    },
];

export const clothsDetailsData = ["General Parameters ", "Quiz Materials"];

export interface ProductModalInterface {
    value: boolean;
    setOpenModal: (value: boolean) => void;
    dataId: undefined | number;
}

const KnowledgeLessonModal = ({
    value,
    setOpenModal,
    dataId,
    modalBodyClassName
}: ProductModalInterface) => {
    const [open, setOpen] = useState(value);
    const [activeTab, setActiveTab] = useState(1);
    const { productItem } = useAppSelector((state) => state.product);
    const [singleProduct, setSingleProduct] = useState<
        ProductInterface | undefined | [] | any
    >([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    // useEffect(() => {
    //   productItem.forEach((product: ProductItemInterface) => {
    //     if (product.id === dataId) setSingleProduct(product);
    //   });
    // });
    useEffect(() => {
        if (dataId && productItem.length > 0) {
            const index = productItem.findIndex(
                (product: ProductItemInterface) => product.id === dataId
            );
            if (index !== -1) {
                setCurrentIndex(index);
                setSingleProduct(productItem[index]);
            }
        }
    }, [dataId, productItem]);
    useEffect(() => {
        setSingleProduct(productItem[currentIndex]);
    }, [currentIndex, productItem]);

    const onCloseModal = () => {
        setOpen(false);
        setOpenModal(false);
    };
    const [currentId, setCurrentId] = useState<number>(dataId || 0);

    const handleNext = () => {
        if (currentIndex < productItem.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    // Navigate to previous product
    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };
    const brandDetailsData = ["Clothing", "Bags", "Footwear"];
    const productSocial = [
        {
            link: "https://www.facebook.com/",
            iconName: "facebook",
        },
        {
            link: "https://accounts.google.com/",
            iconName: "google-plus",
        },
        {
            link: "https://twitter.com/",
            iconName: "twitter",
        },
        {
            link: "https://www.instagram.com/",
            iconName: "instagram",
        },
        {
            link: "https://rss.app/",
            iconName: "rss",
        },
    ];
    const [submitErrors, setSubmitError] = useState<boolean>(false);

    const handleSubmit = (values: SurveyAndQuizzesFormValidationProp) => {
        console.log(values);
        setSubmitError(false);
    };
    const [quizopen, setquizopen] = useState<boolean>(false);

    const onClosequizzModal = () => {
        setquizopen(false);
    };
    const handlePopupClick = () => {
        setquizopen(true);
    };
    const { websiteSettingsData }: any = useAppSelector((state) => state.common);

    return (
        <Modal
            wrapClassName="product-box"
            fade
            centered
            size="xl"
            isOpen={open}
            toggle={onCloseModal}
            className={` modal-dialog-scrollable ${ modalBodyClassName ? modalBodyClassName : "" }`} >
            <div className="modal-header" onClick={onCloseModal}>
                {/* <H4>{singleProduct.name}</H4>  */}
                <H4> Title</H4>
                <Btn className="py-0" close></Btn>
            </div>

            <div className="modal-body bg-light">
                <Formik
                    initialValues={surveyAndQuizzesFormInitialValue}
                    onSubmit={handleSubmit}
                    validationSchema={surveyAndQuizzesFormValidationSchema}>
                    {({ errors, values, setFieldValue }) => (
                        <Form className="form-wizard">
                            <Row className="product-box">
                                <Col lg="4" sm="12">
                                    <ProductDetails />
                                </Col>
                                <Col   lg="3" sm="12" className="box-col-6 ">
                                    
                                    {websiteSettingsData?.google_ads_index === "on" && <AdSense/>}
                                </Col>
                                <Col lg={websiteSettingsData?.google_ads_index === "on" ? '5':'9'}  sm="12">
                                    <Card>
                                        <CardBody>
                                            <div className="product-page-details">
                                                {/* <H3>{singleProduct.name}</H3> */}
                                                <H3>Title</H3>
                                            </div>
                                            <p>Lesson Text: Test or Quiz</p>
                                            <div className="product-price">
                                                $26.00
                                                <del>$350.00 </del>
                                            </div>
                                            <UL className="product-color flex-row">
                                                <LI className="bg-primary" />
                                                <LI className="bg-secondary" />
                                                <LI className="bg-success" />
                                                <LI className="bg-info" />
                                                <LI className="bg-warning" />
                                            </UL>
                                            <hr />
                                            <p>Auto Publish Online: Yes</p>
                                            <div>
                                                <Table className="product-page-width">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Date + Hour to Publish Online:{" "}
                                                                </b>
                                                            </td>
                                                            <td className="txt-success">
                                                                IST
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Homework (Optional):
                                                                </b>
                                                            </td>
                                                            <td>
                                                                Only Followers
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                    Expiry Date
                                                                    + Hour:
                                                                </b>
                                                            </td>
                                                            <td>14/Sep/2024</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                Url:
                                                                </b>
                                                            </td>
                                                            <td>https://1webbb.com/see-all-contents</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                {PostContent}:
                                                                </b>
                                                            </td>
                                                            <td>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores dolorem obcaecati commodi numquam est libero sint accusantium quo ea explicabo.</td>
                                                        </tr> 
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    alignContent:
                                                                        "baseline",
                                                                }}>
                                                                <b>Keywords:</b>
                                                            </td>
                                                            <td>
                                                                High-Quality
                                                                Photos,Stock
                                                                Images,Free
                                                                Photos,
                                                                Royalty-Free
                                                                Images,
                                                                Photography
                                                                Collection, HD
                                                                Photos, Curated
                                                                Photo Library,
                                                                Creative Commons
                                                                Photos, Free
                                                                Image Downloads,
                                                                Professional
                                                                Photography,
                                                                Background
                                                                Images, Nature
                                                                Photos
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <hr />
                                            <Row>
                                                <Col md="4">
                                                    <H4 className="product-title">{`ShareIt`}</H4>
                                                </Col>
                                                <Col md="8">
                                                    <div className="product-icon">
                                                        <UL className="product-social simple-list flex-row justify-content-center">
                                                            {productSocial.map(
                                                                (
                                                                    data,
                                                                    index
                                                                ) => (
                                                                    <LI
                                                                        className="d-inline-block"
                                                                        key={
                                                                            index
                                                                        }>
                                                                        <Link
                                                                            to={
                                                                                data.link
                                                                            }
                                                                            target="_blank">
                                                                            <i
                                                                                className={`fa fa-${data.iconName}`}
                                                                            />
                                                                        </Link>
                                                                    </LI>
                                                                )
                                                            )}
                                                        </UL>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col md="4">
                                                    <H4 className="product-title f-w-600">{`RateNow`}</H4>
                                                </Col>
                                                <Col md="8">
                                                    <div className="d-flex">
                                                        <div className="rating">
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                        <span>
                                                            (250 review)
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row> 
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col className="mb-3">
                                                    <h4>General Parameters</h4>
                                                </Col>
                                            </Row>
                                            <Formik
                                                initialValues={
                                                    specialParametersFormInitialValue
                                                }
                                                onSubmit={handleSubmit}
                                                validationSchema={
                                                    specialParametersFormValidationSchema
                                                }>
                                                {({
                                                    errors,
                                                    values,
                                                    setFieldValue,
                                                }) => (
                                                    <Form className="form-wizard">
                                                        <Row>
                                                            <Col
                                                                sm="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    Country:
                                                                    India
                                                                </Label>
                                                                {/* <Field type="text" name="course" maxLen gth='140'placeholder="Course" className='form-control' />
          <TextCounter length={values?.course?.length} totalLength='140' /> */}
                                                            </Col>
                                                            <Col
                                                                sm="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    State:
                                                                    Gujrat
                                                                </Label>
                                                                {/* <Field type="text" name="course" maxLen gth='140'placeholder="Course" className='form-control' />
          <TextCounter length={values?.course?.length} totalLength='140' /> */}
                                                            </Col>
                                                            <Col
                                                                sm="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    Course:
                                                                    Bachelor
                                                                    Journalism
                                                                </Label>
                                                                {/* <Field type="text" name="course" maxLen gth='140'placeholder="Course" className='form-control' />
          <TextCounter length={values?.course?.length} totalLength='140' /> */}
                                                            </Col>
                                                            <Col
                                                                sm="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    Book: Don
                                                                    Quixote
                                                                </Label>
                                                                {/* <Field type="text" name="book" maxLength='140' placeholder="Book" className='form-control'/>
          <TextCounter length={values?.book?.length} totalLength='140' /> */}
                                                            </Col>
                                                            <Col
                                                                sm="12"
                                                                className="mb-3">
                                                                <Label check>
                                                                    Exam: UPSC
                                                                    exams
                                                                </Label>
                                                                {/* <Field type="text" name="exam" maxLength='140' placeholder="Exam" className='form-control'/>
          <TextCounter length={values?.exam?.length} totalLength='140' /> */}
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                        
                                    </Card>
                                </Col>
                                
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div> 
            <div className="modal-footer "> 
                <div className=" next_previous  position-relative pt-0">
                    <Btn
                        onClick={handlePrevious}
                        className="border"
                        disabled={currentId === 0}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Previous <SVG iconId=" back-arrow" />
                        </div>
                    </Btn>
                    <Btn
                        onClick={handleNext}
                        className="border"
                        disabled={currentId === productItem.length - 1}>
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                            Next <SVG iconId="front-arrow" />
                        </div>
                    </Btn>
                </div>
                {/* <CommonButton step={true} /> */}
            </div>
        </Modal>
    );
};

export default KnowledgeLessonModal;
