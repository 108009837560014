import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  CardTitle,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Btn, H2, H5, H6, Image, SVG } from "../../../AbstractElements";
import {
  useGetAssistantAccountMutation,
  useSwitchAssitantAccountMutation,
} from "../../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";
import { useAppSelector, useAppDispatch } from "../../../ReduxToolkit/Hooks";
import Loading from "../../../Component/Loading/Loading";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  setCredentials,
  setToken,
  setChooseAccountFlag,
} from "../../../ReduxToolkit/Reducers/Authentication/authSlice";
import { useNavigate } from "react-router-dom";
import { AfterLoginRedirectUrl } from "../../../utils/Constant"; 
import { deleteCookie } from "../../../Data/Ui-Kits/HelperClasses/HelperClassesData";

const AccountList = () => {
  const [assistantData, setAssistantData] = useState([]); 

  const [getAssistantAccountApiCall, { isLoading: AssistantDataLoading }] =
    useGetAssistantAccountMutation();
  const [switchAssitantAccountApiCall, { isLoading: switchAssistantLoading }] =
    useSwitchAssitantAccountMutation();

  const { userInfo, chooseAccountFlag } = useAppSelector((state) => state.auth);
  const { authUser } = userInfo;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const redirect = AfterLoginRedirectUrl;

  useEffect(() => {
    if ((userInfo && userInfo?.accounts === 0) || chooseAccountFlag === true) {
      navigate(redirect);
    }
  }, [userInfo,navigate,redirect,chooseAccountFlag]);

  const getAssistantAccountData = async () => {
    const response = await getAssistantAccountApiCall().unwrap();
    const { data, success }: any = response;
    if (success === true) {
      setAssistantData(data);
    }
  };

  useEffect(() => {
    getAssistantAccountData();
  }, []);

  const clickOnChooseAccount = async (id: number) => {
    try {
      const response = await switchAssitantAccountApiCall({
        account_id: id,
      }).unwrap();
      
      const { success, message, data } = response;
      if (success === true) {
        dispatch(setToken({ token: data?.token }));
        dispatch(setCredentials({ ...data }));
        dispatch(setChooseAccountFlag(true));
        deleteCookie();
        toast.success(message);
        navigate(redirect);
      } else {
        toast.error(message);
      }
    } catch (error: any) {
      toast.error(error?.data?.message);
    }
  };

  const filterAccountList = (event: any) => {
    if (event?.target.value) {
      const filterData = assistantData.filter((item: any) =>
        item.username.toLowerCase().includes(event?.target.value)
      );
      setAssistantData(filterData);
    } else {
      getAssistantAccountData();
    }
  };

  if (AssistantDataLoading) return <Loading />;
  if(switchAssistantLoading) return <Loading />;

  return (
    <div className="page-body">
      <Row>
        <Col md="12" className="p-0">
          <div className="account-list login-dark">
            <Container>
              <Row>
                <Col md={6} className="p-3">
                  <CardHeader>
                    <CardTitle>
                      <H2>{"Choose Account"}</H2>
                    </CardTitle>
                  </CardHeader>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-3">
                  <CardHeader>
                    <CardTitle>
                      <H6>{"My Login Account (Root)"}</H6>
                    </CardTitle>
                  </CardHeader>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  {authUser && (
                    <Card className="social-profile pt-4">
                      <CardBody>
                        <div
                          className="social-img-wrap"
                          style={{ marginTop: "-8%" }}
                        >
                          <div className="social-img">
                            <Image
                              src={authUser.avatar}
                              alt={authUser.username}
                            />
                          </div>
                          <div className="edit-icon">
                            <SVG iconId="profile-check" />
                          </div>
                        </div>
                        <div className="social-details">
                          <H5 className="mb-1">
                            <Link
                              to={`${process.env.PUBLIC_URL}/app/socialapp`}
                            >
                              {authUser.username}
                            </Link>
                          </H5>
                          <span className="f-light">{authUser.email}</span>
                        </div>
                        <Card className="pt-4">
                          <Btn
                            onClick={() => clickOnChooseAccount(authUser.id)}
                            className={`btn btn-primary`}
                          >{`Choose`}</Btn>
                        </Card>
                      </CardBody>
                    </Card>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-3">
                  <CardHeader>
                    <CardTitle>
                      <H6>{"Assisted accounts"}</H6>
                    </CardTitle>
                  </CardHeader>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="p-3">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search other account"
                    onChange={filterAccountList}
                  />
                </Col>
              </Row>
              <Row>
                {assistantData &&
                  assistantData.map((item): any => {
                    const { id, email, avatar, username }: any = item;
                    return (
                      <Col md={3} key={id}>
                        <Card className="social-profile pt-4">
                          <CardBody>
                            <div
                              className="social-img-wrap"
                              style={{ marginTop: "-8%" }}
                            >
                              <div className="social-img">
                                <Image src={avatar} alt={username} />
                              </div>
                              <div className="edit-icon">
                                <SVG iconId="profile-check" />
                              </div>
                            </div>
                            <div className="social-details">
                              <H5 className="mb-1">
                                <Link
                                  to={`${process.env.PUBLIC_URL}/app/socialapp`}
                                >
                                  {username}
                                </Link>
                              </H5>
                              <span className="f-light">{email}</span>
                            </div>
                            <Card className="pt-4">
                              <Btn
                                onClick={() => clickOnChooseAccount(id)}
                                className={`btn btn-primary`}
                              >{`Choose`}</Btn>
                            </Card>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AccountList;
