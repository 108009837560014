import { useState } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { AccountSettings, AccountHistoryTitle } from "../../../utils/Constant";
import { CardBody, Card, Col, Container, Row } from "reactstrap";
import {
  supportTicketData,
  accountHistoryData,
} from "../../../Data/Tools/BrandSupportTools/ButtonActions";
import { Link } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { SupportDataType } from "./MyResumes";
import { TableHeadColumn } from "./MyRegistrationPlan";
import UserBanner from "../../../Component/App/User/UserBanner";
function AccountHistory() {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState(accountHistoryData);
  const [selectedData, setSelectedData] = useState();

  const handleRowSelected = () => {};

  const openPopup = (type: boolean) => {
    setIsOpen(!isOpen);
    setIsEditOpen(type);
  };

  const openModalToggle = () => {
    setIsOpen(!isOpen);
  };

  const externalColumns: TableColumn<any>[] = [
    {
      name: <TableHeadColumn title={`ID`} />,
      selector: (row) => row["id"],
      sortable: true,
      center: false,
    }, 
    {
      name: <TableHeadColumn title={`Username`} />,
      selector: (row) => row["username"],
      sortable: true,
      center: false,
    },
    {
        name: <TableHeadColumn title={`Action`} />,
        selector: (row) => row["action"],
        sortable: true,
        center: false,
      },
    {
      name: <TableHeadColumn title={`URL`} />,
      selector: (row) => row["url"],
      sortable: true,
      center: false,
    },
    {
      name: <TableHeadColumn title={`Data`} />,
      selector: (row) => row["data"],
      sortable: true,
      center: false,
    },
    {
      name: <TableHeadColumn title={`Datetime`} />,
      selector: (row) => row["datetime"],
      sortable: true,
      center: false,
    },
  ];

  return (
    <div className="page-body">
      <Breadcrumbs
        mainTitle={AccountHistoryTitle}
        parent={AccountSettings}
      />
      <UserBanner />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="p-2">
                <>
                  <div className="table-responsive pt-2">
                    <DataTable
                      className="custom-scrollbar"
                      columns={externalColumns}
                      data={data}
                      striped={true}
                      pagination
                      selectableRows
                      onSelectedRowsChange={handleRowSelected}
                      clearSelectedRows={toggleDelete}
                    />
                  </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AccountHistory;
